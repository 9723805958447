import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';


function PaginationComponent({ totalCount, page, route, setPage, limit, setLimit }) {

  const [pagesCount, setPagesCount] = useState(0);
  useEffect(() => {
    if (!limit) {
      limit = 15;
    }
    setPagesCount(Math.ceil(totalCount / limit))
  }, [totalCount, limit])

  if (pagesCount < page) {
    return null; 
  }

  // środkowe routy
  function getRoutes() {
    const firstRoute = page - 1;
    const currentRoute = page;
    const thirdRoute = page + 1;
    return <Fragment>
      {page > 1 && <li className="page-item">
        <Link to={`${route}/page/${firstRoute}`} className="page-link" onClick={() => setPage(firstRoute)}>{firstRoute}</Link>
      </li>}
      <li className="page-item active">
        <span className="page-link">{currentRoute}</span>
      </li>
      {thirdRoute < pagesCount &&
        <li className="page-item">
          <Link to={`${route}/page/${thirdRoute}`} className="page-link" onClick={() => setPage(thirdRoute)}>{thirdRoute}</Link>
        </li>}
    </Fragment>
  }
  if (!totalCount) return null;

  return <div className="paginacja d-flex flex-wrap justify-content-end">
    <div className="perpage mr-3">Pozycji na stronie
    <select id="perpageselect" className="custom-select" value={limit || 15} onChange={(e) => setLimit(e.target.value)}>
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <nav aria-label="...">
      <ul className="pagination">
        {/* Strzałka w lewo */}
        {page == 1 ? <li className="page-item disabled">
          <span className="page-link">Poprzednie</span>
        </li> : <li className="page-item">
            <Link className="page-link" to={`${route}/page/${page - 1}`} onClick={() => setPage(page - 1)}>Poprzednie</Link>
          </li>}


        {page > 2 && <Link className="first-route" to={`${route}/page/${1}`} onClick={() => setPage(1)}>
          <span className="single-route">1</span>
        </Link>}
        {pagesCount > 3 && page !== 2 && page !== 1 && <div className="route-breaker">
          <span>...</span>
        </div>}
        {getRoutes()}
        {page == pagesCount ? <li className="page-item disabled">
          <span className="page-link">Następne</span>
        </li> :
          <li className="page-item">
            <Link className="page-link" to={`${route}/page/${page + 1}`} onClick={() => setPage(page + 1)}>Następne</Link>
          </li>}
      </ul>
    </nav>
  </div >
}

export default PaginationComponent;