
import * as Yup from 'yup';

export const customerInitialState = {
    name: '',
    gusName: '',
    NIP: '',
    address: {
        area_1: '',
        area_2: '',
        area_3: '',
        locality: '',
        postCode: '',
        street: '',
        streetNumber: '',
        coordinates: [52.237049, 21.017532]
    },
    contacts: [{
        description: '',
        email: '',
        phone: '',
        position: ''
    }],
    overhead: 0,
    hourlyRate: 0
}

export const customerValidationSchema = Yup.object().shape({
    name: Yup.string().required('Nazwa jest wymagana').min(10, 'Minimialna ilość znaków nazwy firmy (10)'),
    NIP: Yup.string().required('NIP jest wymagany'),
    address: Yup.object().shape({
        area_1: Yup.string(),
        area_2: Yup.string(),
        area_3: Yup.string(),
        locality: Yup.string().required('Miasto jest wymagane'),
        postCode: Yup.string(),
        street: Yup.string().required('Ulica jest wymagana'),
        streetNumber: Yup.string().required('Numer ulicy jest wymagany'),
        coordinates: Yup.array()
    }),
    contacts: Yup.array().of(
        Yup.object().shape({
            description: Yup.string().required('Opis jest wymagany'),
            email: Yup.string().email("Niepoprawny adres e-mail"),
            phone: Yup.string(),
            position: Yup.string(),
        })
    ),
    overhead: Yup.number().required('Narzut jest wymagany'),
    hourlyRate: Yup.number().required('Stawka godzinowa klienta jest wymagana').min(0, 'Stawka godzinowa nie może być mniejsza niż 0')
});

