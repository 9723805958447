import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux'

import ServiceComponent from '../components/Service.component';

import {
   getService,
   clearService,
   saveService
} from '../actions/services.actions';

const ServiceContainer = ({
   service,
   getService,
   clearService,
   saveService
}) => {
   const { id } = useParams();

   useEffect(() => {
      if (id) {
         getService(id)
      }

      return () => {
         clearService()
      }
   }, [])

   return (
      <ServiceComponent
         service={service}
         saveService={saveService}
      />
   );
}

const mapStateToProps = (store) => ({
   service: store.services.service
});

const mapDispatchToProps = (dispatch) => {
   return {
      getService: (serviceId) => dispatch(getService(serviceId)),
      clearService: () => dispatch(clearService()),
      saveService: (serviceId, data) => saveService(serviceId, data, dispatch),
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceContainer);
