import {
    SET_DEVICE_INFO,
    CLEAR_DEVICE_INFO
} from '../actions/inspector.actions';

const defaultState = {
    deviceInfo: null,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_DEVICE_INFO:
            return {
                ...state,
                deviceInfo: action.deviceInfo
            }
        case CLEAR_DEVICE_INFO:
            return {
                ...state,
                deviceInfo: null
            }
        default:
            return state;

    }
}