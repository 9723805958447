import _ from "lodash";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import AclManager from '../../application/services/aclManager';
import ConfirmModal from '../../assets/components/ConfirmModal.component';
import Pagination from "../../assets/components/Pagination.component";
import SearchComponent from '../../assets/components/Search.component';
import dodajIcon from "../../assets/gfx/dodaj.svg";
import ComponentFilters from './ComponentFilters.component';
import ComponentsListCard from "./ComponentsListCard.component";
import SortListComponent from '../../assets/components/SortList.component';

const Components = ({ components, page, total, limit, setPage, setLimit, setQuery, setFilters, deleteComponent }) => {

    const [componentToDelete, setComponentToDelete] = useState(null);

    const fields = [
        { id: 'name', name: 'Komponent' },
        { id: 'type', name: 'Typ' },
        { id: 'componentValue', name: 'Wartość' },
    ]

    const handleDelete = () => {
        deleteComponent(_.get(componentToDelete, '_id'));
        setComponentToDelete(null);
    }


    const renderItems = () => {
        if (!_.isEmpty(components)) {
            return components.map((item, index) => <ComponentsListCard key={index} item={item} handleDelete={setComponentToDelete} index={index} />);
        }
        return <tr><td colSpan="4">Brak elementów</td></tr>;
    }

    return (
        <div className="tab-pane fade show active" id="komponenty" role="tabpanel" aria-labelledby="komponenty-tab">
            <h3 className="mt-3 mb-3">Komponenty</h3>
            <div className="container-fluid p-0">
                <div className="row p-0">
                    <div className="col-12 col-md-6 ">
                        {AclManager.isAllowedMethod('device/component', 'create') && <Link type="submit" className="btn btn-primary mb-3 float-md-left mr-3" to="/component">Dodaj <img src={dodajIcon} /></Link>}
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                        <SearchComponent setQuery={setQuery} />
                        <ComponentFilters setFilters={setFilters} />
                    </div>
                </div>
            </div>

            <div className="table-responsive ramkatabeli ikonki mb-2">
                <table className="table table-hover rounded-10 tabela-left ikonki">
                    <thead>
                        <SortListComponent fields={fields} defaultSortBy='name' setFilters={setFilters} defaultSortType={1} />
                    </thead>
                    <tbody>
                        {renderItems()}
                    </tbody>
                </table>

            </div>
            <Pagination totalCount={total || 0} route={"/components"} page={parseInt(page)} setPage={(page) => setPage(page, true)} limit={limit} setLimit={setLimit} />
            {componentToDelete && <ConfirmModal
                id="confirmDelete"
                label="Czy na pewno chcesz usunąć ten komponent?"
                positionName={_.get(componentToDelete, 'name')}
                onConfirm={handleDelete}
                onCancel={() => setComponentToDelete(null)}
            />}
        </div>
    )
}

export default Components;