import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import _ from "lodash";
import AclManager from '../../application/services/aclManager';
import AddIcon from "../../assets/gfx/dodaj.svg";
import ConfirmModal from '../../assets/components/ConfirmModal.component';
import ContractsListCard from "./ContractsListCard.component";
import Pagination from '../../assets/components/Pagination.component';
import SearchComponent from '../../assets/components/Search.component';
import ContractFilters from './ContractFilters.component';
import SortListComponent from '../../assets/components/SortList.component';

const ContractsComponent = ({ contracts, page, total, limit, setPage, setLimit, setQuery, setFilters, deleteContract }) => {

    const [contractToDelete, setContractToDelete] = useState(null);

    const fields = [
        { id: 'number', name: 'Numer umowy' },
        { id: 'conclusionDate', name: 'Termin od' },
        { id: 'expiryDate', name: 'Termin do' },
        { id: 'reactionTime', name: 'Czas reakcji' },
        { id: 'customer.name', name: 'Dotyczy klienta' }
    ]

    const handleDelete = () => {
        deleteContract(_.get(contractToDelete, '_id'));
        setContractToDelete(null);
    }

    const handleCancelConfirm = () => {
        setContractToDelete(null);
    }

    const renderContracts = () => {
        if (!_.isEmpty(contracts)) {
            return contracts.map((contract, index) => <ContractsListCard key={index} contract={contract} handleDelete={setContractToDelete} index={index + 1} />);
        }
        return <tr><td colSpan="8">Brak elementów</td></tr>;
    }

    return (
        <Fragment>
            {contractToDelete && <ConfirmModal
                id="confirmDelete"
                label="Czy na pewno chcesz usunąć ten kontrakt?"
                positionName={_.get(contractToDelete, 'number')}
                onConfirm={handleDelete}
                onCancel={handleCancelConfirm}
            />}
            <div className="row mb-2">
                <div className="col-12 col-md-12 pt-3 d-flex btns-filtry">
                    <h3 className="mt-0 mb-2 w-100">Umowy</h3>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="row p-0">
                    <div className="col-12 col-md-6 ">
                        {AclManager.isAllowedMethod('customer/customer', 'create') && <Link type="submit" className="btn btn-primary mb-3 float-md-left mr-3" to="/contract">Dodaj <img src={AddIcon} /></Link>}
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                        <SearchComponent setQuery={setQuery} />
                        <ContractFilters setFilters={setFilters} />
                    </div>
                </div>
            </div>
            <div className="table-responsive ikonki ramkatabeli tabela-left mb-3">
                <table id="klienci" className="table table-hover rounded-10">
                    <thead>
                        <SortListComponent fields={fields} defaultSortBy='conclusionDate' setFilters={setFilters} defaultSortType={1} />
                    </thead>
                    <tbody>
                        {renderContracts()}
                    </tbody>
                </table>
            </div>
            <Pagination totalCount={total || 0} route={"/contracts"} page={parseInt(page)} setPage={(page) => setPage(page, true)} limit={limit} setLimit={setLimit} />
        </Fragment>
    )
}

ContractsComponent.propTypes = {
    contracts: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
    setQuery: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    deleteContract: PropTypes.func.isRequired
};

export default ContractsComponent;