import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from 'lodash';
import PropTypes from 'prop-types';

import GaleriaIcon from '../../assets/gfx/galeria.svg';
import DokumentIcon from '../../assets/gfx/dokument.svg';
import CopyToClipIcon from '../../assets/gfx/copytoclip.svg';
import KoszIcon from '../../assets/gfx/kosz.svg';

import AclManager from '../../application/services/aclManager';


const MediasListCard = ({
    index,
    media, media: { mimetype, src, name, createdAt },
    setSelectedImage,
    setMediaToDelete
}) => {

    const fileType = () => {
        if (_.includes(mimetype, "image")) {
            return (
                <td><span data-toggle="modal" data-target="#photoGallery"
                    style={{ textDecoration: "underline", cursor: 'pointer' }}
                    onClick={() => setSelectedImage(media)}
                >
                    <img src={GaleriaIcon} />Zdjęcie</span></td>
            )
        }
        return <td>
            <a href={`${src}`} style={{ cursor: 'pointer' }} title="Zobacz PDF" className="text-secondary">
                <img src={DokumentIcon} />Dokument
        </a>
        </td>
    }

    const cutFileName = (fileName, cutNumber) => {
        fileName = fileName.substring(fileName.lastIndexOf("/") + 1);

        if (fileName.length > cutNumber) {
            const sliceCount = fileName.length - cutNumber;
            fileName = `...${fileName.slice(sliceCount)}`;
        }
        return fileName;
    }


    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(src);
        toast.info('Skopiowano do schowka');
    }

    return (

        <tr>
            <td>{index + 1}.</td>
            {fileType()}
            <td>{cutFileName(name, 25)}</td>
            <td><span >{cutFileName(src, 25)}</span></td>
            <td>{moment(createdAt).format('DD.MM.YYYY')}</td>
            <td className="text-right">
                <span
                    style={{ cursor: 'pointer' }}
                    className="text-secondary" title="Kopiuj do schowka"
                    onClick={() => handleCopyToClipboard()}
                >
                    <img className="akcja schowek" src={CopyToClipIcon} />
                </span>
                {
                    AclManager.isAllowedMethod('media/media', 'delete') &&
                    <span
                        style={{ cursor: 'pointer' }}
                        href="#" data-toggle="modal" data-target="#confirmDelete" title="Usuń"
                        onClick={() => setMediaToDelete(media)}
                    >
                        <img className="akcja usun" src={KoszIcon} />
                    </span>
                }

            </td>
        </tr>
    )
}

export default MediasListCard;


MediasListCard.propTypes = {
    media: PropTypes.object,
    media: PropTypes.shape({
        mimetype: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
    })
}