const permissionsList = {
   roleName: '',
   permissions: [
      //Klienci
      {
         "module": "customer",
         "controller": "customer",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "update", name: 'Edycja', value: false },
            { action: "delete", name: 'Usuwanie', value: false },
         ]
      },
      //Umowy
      {
         "module": "customer",
         "controller": "contract",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "update", name: 'Edycja', value: false },
            { action: "delete", name: 'Usuwanie', value: false },
            { action: "downloadContract", name: 'Pobieranie umowy', value: false },
         ]
      },
      //Urządzenia
      {
         "module": "device",
         "controller": "device",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "update", name: 'Edycja', value: false },
            { action: "delete", name: 'Usuwanie', value: false },
         ]
      },
      //Komponenty
      {
         "module": "device",
         "controller": "component",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "update", name: 'Edycja', value: false },
            { action: "delete", name: 'Usuwanie', value: false },
         ]
      },
      //Zakresy czynności
      {
         "module": "device",
         "controller": "serviceRange",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "update", name: 'Edycja', value: false },
            { action: "delete", name: 'Usuwanie', value: false },
         ]
      },
      //Kosztorysy
      {
         "module": "expense",
         "controller": "expense",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "update", name: 'Edycja', value: false },
            { action: "delete", name: 'Usuwanie', value: false },
         ]
      },
      //Biblioteka mediów
      {
         "module": "media",
         "controller": "media",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "delete", name: 'Usuwanie', value: false },
         ]
      },
      //Magazyny
      {
         "module": "warehouse",
         "controller": "warehouse",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "update", name: 'Edycja', value: false },
            { action: "delete", name: 'Usuwanie', value: false },
            { action: "changeWarehouseStock", name: 'changeWarehouseStock', value: false },
         ]
      },
      //Stany magazynowe
      {
         "module": "warehouse",
         "controller": "item",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
         ]
      },
      //Przesunięcia magazynowe / historia
      {
         "module": "warehouse",
         "controller": "transfer",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
         ]
      },
      //Zlecenia
      {
         "module": "service",
         "controller": "service",
         "actions": [
            { action: "get", name: "Wyświetlanie", value: false },
            { action: "create", name: "Tworzenie", value: false },
            { action: "update", name: 'Edycja', value: false },
            { action: "delete", name: 'Usuwanie', value: false },
            { action: "completeService", name: 'Realizacja', value: false },//zzakończenie zlecenia
         ]
      }
   ]
}

export default permissionsList;