import React from 'react';
import { Link } from 'react-router-dom';

import ResursIcon from '../../assets/gfx/resurs.svg';
import PropTypes from 'prop-types';
import _ from "lodash";

const CustomerDeviceListCardComponent = ({ device, index }) => {

    return (
        <tr>
            <th scope="row">{index}</th>
            <td>
                <Link to={`/devices/${_.get(device, '_id')}`}>
                    {device.name || ''}
                </Link>
            </td>
            <td>
                <Link to={`/user/${_.get(device, 'conservator._id')}`}>
                    <div className="usercolor-container rounded-circle float-left mr-2">
                        <div className="usercolor rounded-circle "></div>
                    </div>
                    {_.get(device, 'conservator.name') || ''}
                </Link>
            </td>
            <td><a href="#" title="Generuj RESURS urządzenia" className="text-secondary"><img src={ResursIcon} /> Generuj</a></td>
        </tr>
    )
}

CustomerDeviceListCardComponent.propTypes = {
    device: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default CustomerDeviceListCardComponent;