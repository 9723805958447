import moment from 'moment';
import {
   SET_SERVICE,
   SET_SERVICES,
   SET_QUERY,
   SET_OFFSET,
   SET_LIMIT,
   SET_FILTERS,
   SET_PAGE,
   SET_ERRORS,
   CLEAR_SERVICE,
   CLEAR_SERVICES,
   CLEAR_QUERY,
   CLEAR_LIMIT,
   CLEAR_FILTERS,
   CLEAR_ERRORS
} from '../actions/services.actions';

const defaultState = {
   isLoading: false,
   service: {},
   services: [],
   query: '',
   queryFields: [
      'number[or]',
      'type[or]',
      'conservator[or]'
   ],
   params: {
      limit: 15,
      offset: 0,
      'dateFrom[gte]': moment().startOf('day').format("X"),
      'dateTo[lte]': moment().endOf('day').format("X")
   },
   filters: {
      'sort[dateFrom]': 1,
   },
   total: 0,
   errors: {}
}

export default (state = defaultState, action) => {
   switch (action.type) {
      // SERVICE
      case SET_SERVICE:
         return {
            ...state,
            service: action.service
         };
      case CLEAR_SERVICE:
         return {
            ...state,
            service: {},
         }
      // SERVICES
      case SET_SERVICES:
         return {
            ...state,
            services: action.services,
            total: action.total
         };
      case CLEAR_SERVICES:
         return {
            ...state,
            services: [],
            total: 0
         }
      // QUERY
      case SET_QUERY:
         return {
            ...state,
            query: action.query
         }
      case CLEAR_QUERY:
         return {
            ...state,
            query: ''
         }
      // PAGE
      case SET_PAGE: // TU NIE MA CLEAR, robi to clearPage z akcji
         return {
            ...state,
            page: action.page
         }
      // OFFSET
      case SET_OFFSET: // TU NIE MA CLEAR, robi to clearPage z akcji
         return {
            ...state,
            params: {
               ...state.params,
               offset: action.offset
            }
         }
      // LIMIT
      case SET_LIMIT:
         return {
            ...state,
            params: {
               ...state.params,
               limit: action.limit
            }
         }
      case CLEAR_LIMIT:
         return {
            ...state,
            params: {
               ...defaultState.params,
               limit: defaultState.params.limit,
            }
         }
      // FILTERS
      case SET_FILTERS:
         return {
            ...state,
            filters: action.filters
         }
      case CLEAR_FILTERS:
         return {
            ...state,
            filters: {}
         }
      // ERRORS
      case SET_ERRORS:
         return {
            ...state,
            errors: action.errors
         }
      case CLEAR_ERRORS:
         return {
            ...state,
            errors: {}
         }
      default:
         return state;
   }
}