const serviceTypes = [
   {
      value: 'udt_examination',
      name: 'Badanie UDT'
   }, {
      value: 'fault',
      name: 'Awaria'
   }, {
      value: 'conservation',
      name: 'Konserwacja'
   }, {
      value: 'measurement',
      name: 'Pomiary'
   }, {
      value: 'other',
      name: 'Inne'
   }, {
      value: 'repair',
      name: 'Naprawa'
   }
];

export default serviceTypes;