import {
    SET_CONTRACT,
    SET_CONTRACTS,
    SET_QUERY,
    SET_OFFSET,
    SET_LIMIT,
    SET_FILTERS,
    SET_PAGE,
    CLEAR_CONTRACT,
    CLEAR_CONTRACTS,
    CLEAR_QUERY,
    CLEAR_LIMIT,
    CLEAR_FILTERS
} from '../actions/contracts.actions';

const defaultState = {
    contract: null,
    contracts: [],
    total: 0,
    page: 1,
    query: '',
    queryFields: [
        'description[or]',
        'number[or]',
        'reactionTime[or]'
    ],
    params: {
        limit: 15,
        offset: 0,
    },
    filters: {
        'sort[conclusionDate]': -1,
    }
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_CONTRACT:
            return {
                ...state,
                contract: action.contract
            }
        case CLEAR_CONTRACT:
            return {
                ...state,
                contract: null
            }
        case SET_CONTRACTS:
            return {
                ...state,
                contracts: action.contracts,
                total: action.total
            }
        case CLEAR_CONTRACTS:
            return {
                ...state,
                contracts: []
            }
        case SET_PAGE: // TU NIE MA CLEAR, robi to clearPage z akcji
            return {
                ...state,
                page: action.page
            }
        case SET_OFFSET: // TU NIE MA CLEAR, robi to clearPage z akcji
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.offset
                }
            }
        case SET_LIMIT:
            return {
                ...state,
                params: {
                    ...state.params,
                    limit: action.limit
                }
            }
        case CLEAR_LIMIT:
            return {
                ...state,
                params: {
                    ...defaultState.params,
                    limit: defaultState.params.limit,
                }
            }
        case SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        case CLEAR_QUERY:
            return {
                ...state,
                query: ''
            }
        case SET_FILTERS:
            return {
                ...state,
                filters: action.filters
            }
        case CLEAR_FILTERS:
            return {
                ...state,
                filters: {}
            }
        default:
            return state;

    }
}