import Api from '../../application/services/api';
import { isLoading } from "../../application/actions/application.actions";

const NAMESPACE = "DEVICES";
// AKCJE SETUJACE
export const SET_DEVICE = `SET_DEVICE_${NAMESPACE}`;
export const SET_DEVICES = `SET_DEVICES_${NAMESPACE}`;
export const SET_DEVICES_SERVICES = `SET_DEVICES_SERVICES_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;

// AKCJE CLEARUJACE
export const CLEAR_DEVICE = `CLEAR_DEVICE_${NAMESPACE}`;
export const CLEAR_DEVICES = `CLEAR_DEVICES_${NAMESPACE}`;
export const CLEAR_DEVICES_SERVICES = `CLEAR_DEVICES_SERVICES_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEARY_QUERY_${NAMESPACE}`;
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;


// METODY SETUJACE
export const setDevice = (device) => dispatch => {
    dispatch({ type: SET_DEVICE, device });
}

export const setDevices = (devices, total) => dispatch => {
    dispatch({ type: SET_DEVICES, devices, total });
}
export const setDevicesServices = (services) => dispatch => {
    dispatch({ type: SET_DEVICES_SERVICES, services });
}

export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/devices', true));
}

export const setOffset = (offset) => dispatch => {
    dispatch({ type: SET_OFFSET, offset: offset });
}

export const setLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setPage(1, true));
}

export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/devices', true));
}

export const setPage = (page, reload = false) => (dispatch, getState) => {
    const { devices: { params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });
    reload && dispatch(getDevices());
    window.scrollTo(0, 0);
}

// METODY CLEARUJACE
export const clearDevice = () => dispatch => {
    dispatch({ type: CLEAR_DEVICE });
}
export const clearDevices = () => dispatch => {
    dispatch({ type: CLEAR_DEVICES });
}
export const clearDevicesServices = () => dispatch => {
    dispatch({ type: CLEAR_DEVICES_SERVICES });
}
export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setPage(1, reload));

    if (replaceUrl) {
        window.history.replaceState(null, '', replaceUrl);
    }

    window.scrollTo(0, 0);
}

// Pozostałe

export const getDevice = (id) => async dispatch => {

    dispatch(isLoading(true));
    const result = await Api.get(`device/device/${id}?joinValues=true`);

    if (result && result.success) {
        dispatch(setDevice(result.document));
    }

    dispatch(isLoading(false));
}

export const getDevices = () => async (dispatch, getState) => {

    dispatch(isLoading(true));

    const { devices: { params, filters, query, queryFields } } = getState();
    const tmpParams = Object.assign({}, params, filters);

    // FILTRY PO QUERY I QUERYFIELDS
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }

    tmpParams.joinValues = true;

    const result = await Api.get("device/device", tmpParams);

    if (result && result.success) {
        dispatch(setDevices(result.documents, result.total));
    }

    dispatch(isLoading(false));
}

export const getDevicesServices = (id) => async (dispatch) => {

    dispatch(isLoading(true));

    const params = {
        'device.deviceId[in]': id,
        "joinValues": true
    }

    const result = await Api.get("service/service", params);

    if (result && result.success) {
        dispatch(setDevicesServices(result.documents));
    }

    dispatch(isLoading(false));
}

export const saveDevice = (data) => async (dispatch) => {
    if (data._id) {
        return dispatch(editDevice(data));
    } else {
        return dispatch(createDevice(data));
    }
}

export const createDevice = (data) => async dispatch => {
    dispatch(isLoading(true));
    const result = await Api.post("device/device", data);
    dispatch(isLoading(false));
    return result;
}

const editDevice = (customer) => async dispatch => {
    dispatch(isLoading(true));
    const result = await Api.put('device/device', customer, customer._id);
    dispatch(isLoading(false));

    return result;
}

export const deleteDevice = (id) => async (dispatch) => {

    dispatch(isLoading(true));
    const result = await Api.delete('device/device', id);

    dispatch(getDevices());
    dispatch(clearPage());
    dispatch(isLoading(false));

    return result;
}


export const deactivateDevice = (id) => async dispatch => {
    dispatch(isLoading(true));
    const result = await Api.put(`device/device/changeActiveStatus/${id}`, { active: false });

    dispatch(getDevice(id));
    dispatch(isLoading(false));

    return result;
}

export const activateDevice = (id, nextServiceDate) => async dispatch => {
    dispatch(isLoading(true));
    const result = await Api.put(`device/device/changeActiveStatus/${id}`, { active: true, nextServiceDate: nextServiceDate });

    dispatch(getDevice(id));
    dispatch(isLoading(false));

    return result;
}
