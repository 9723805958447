import Api from '../../application/services/api';
import { isLoading } from '../../application/actions/application.actions';
import _ from 'lodash';

export const NAMESPACE = "CONTRACTS";

// AKCJE SETUJACE
export const SET_CONTRACT = `SET_CONTRACT_${NAMESPACE}`;
export const SET_CONTRACTS = `SET_CONTRACTS_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;

// AKCJE CLEARUJACE
export const CLEAR_CONTRACT = `CLEAR_CONTRACT_${NAMESPACE}`;
export const CLEAR_CONTRACTS = `CLEAR_CONTRACTS_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEAR_QUERY_${NAMESPACE}`;
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;

// METODY SETUJACE
export const setContract = (contract) => dispatch => {
    dispatch({ type: SET_CONTRACT, contract });
}

export const setContracts = (contracts, total) => dispatch => {
    dispatch({ type: SET_CONTRACTS, contracts, total });
}

export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/contracts', true));
}

export const setOffset = (offset) => dispatch => {
    dispatch({ type: SET_OFFSET, offset: offset });
}

export const setLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setPage(1, true));
}

export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/contracts', true));
}

export const setPage = (page, reload = false) => (dispatch, getState) => {

    const { contracts: { params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    reload && dispatch(getContracts());

    window.scrollTo(0, 0);
}

// METODY CLEARUJACE
export const clearContract = () => dispatch => {
    dispatch({ type: CLEAR_CONTRACT });
}
export const clearContracts = () => dispatch => {
    dispatch({ type: CLEAR_CONTRACTS });
}
export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setPage(1, reload));

    if (replaceUrl) {
        window.history.replaceState(null, '', '/contracts');
    }

    window.scrollTo(0, 0);
}

// POBIERANIE KONTRAKTÓW
export const getContracts = () => async (dispatch, getState) => {

    dispatch(isLoading(true));

    const { contracts: { params, filters, query, queryFields } } = getState();
    const tmpParams = Object.assign({}, params, filters);

    // FILTRY PO QUERY I QUERYFIELDS
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }

    const result = await Api.get("customer/contract", tmpParams);

    if (result && result.success) {
        dispatch(setContracts(result.documents, result.total));
    }

    dispatch(isLoading(false));
}

// POBIERANIE KPOJEDYNCZEGO KONTRAKTU
export const getContract = (id, additional = {}) => async (dispatch, getState) => {

    dispatch(isLoading(true));
    const { contracts: { params } } = getState();
    const result = await Api.get(`customer/contract/${id}`, Object.assign({}, params, additional));

    if (result && result.success) {
        dispatch(setContract(result.document));
    }

    dispatch(isLoading(false));
}

// METODA ZAPISUJĄCA KONTRAKT
export const saveContract = (contract) => async dispatch => {
    const tmpContract = parseData(contract);

    if (!tmpContract._id) {
        return createContract(tmpContract, dispatch); // dodanie kontraktu
    } else {
        return editContract(tmpContract, dispatch); // edycja kontraktu
    }
}

// METODA USUWAJĄCA KONTRAKT
export const deleteContract = (contractId) => async (dispatch) => {

    dispatch(isLoading(true));
    const result = await Api.delete('customer/contract', contractId);

    dispatch(getContracts());
    dispatch(isLoading(false));

    return result;
}


// DODANIE KONTRAKTU
const createContract = async (contract, dispatch) => {
    dispatch(isLoading(true));
    const result = await Api.post('customer/contract', contract, true);
    dispatch(isLoading(false));

    return result;
}

// EDYCJA KONTRAKTU
const editContract = async (contract, dispatch) => {
    dispatch(isLoading(true));
    const result = await Api.put('customer/contract', contract, contract._id, true);
    dispatch(isLoading(false));

    return result;
}

const parseData = contract => {
    const tmpObj = _.cloneDeep(contract);

    if (_.get(tmpObj, 'customer.customerId')) {
        tmpObj.customer = _.get(tmpObj, 'customer.customerId');
    }

    if (_.get(tmpObj, 'devices[0].deviceId')) {
        tmpObj.devices = tmpObj.devices.map((device) => {
            return device.deviceId;
        });
    }
    return tmpObj;
}