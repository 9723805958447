import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AclManager from '../../application/services/aclManager';

import CustomerDeviceListCardComponent from './CustomerDeviceListCard.component';
import CustomerServiceListCardComponent from './CustomerServiceListCard.component';
import CustomerContractListCardComponent from './CustomerContractListCard.component';
import CustomerExpenseListCardComponent from './CustomerExpenseListCard.component';

import Pagination from '../../assets/components/Pagination.component';
import EditIcon from '../../assets/gfx/edytuj.svg';


const CustomerDetailsComponent = ({ customer, customerDevices, customerServices, customerContracts, customerExpenses, total, page, limit, setTabPage, setLimit, setActiveTab }) => {

    const renderContactsList = () => {
        return <React.Fragment>
            {_.get(customer, 'contacts[0]') && <React.Fragment>
                {customer.contacts.map((contact, index) => {
                    return <React.Fragment key={`contact-component-${index}`}>
                        <hr className="mt-5" />
                        {_.get(contact, 'desctipion') !== "" && <h3 className="text-right">
                            <span className="font-weight-light float-left">Osoba kontaktowa:</span>{contact.description || 'brak informacji'}
                        </h3>}
                        {_.get(contact, 'phone') !== "" && <h3 className="text-right">
                            <span className="font-weight-light float-left">Telefon:</span> <a href={`tel:+48${contact.phone}`}>+48 {contact.phone.match(/.{1,3}/g).join(" ") || 'brak informacji'}</a>
                        </h3>}
                        {_.get(contact, 'email') !== "" && <h3 className="text-right">
                            <span className="font-weight-light float-left">Adres e-mail:</span> <a href={`mailto:${contact.email}`}>{contact.email || 'brak informacji'}</a>
                        </h3>}
                        {_.get(contact, 'position') !== "" && <h3 className="text-right">
                            <span className="font-weight-light float-left">Stanowisko:</span> {contact.position || 'brak informacji'}
                        </h3>}
                    </React.Fragment>
                })}
            </React.Fragment>}
        </React.Fragment>;
    }

    const renderDevices = () => {
        if (!_.isEmpty(customerDevices)) {
            return customerDevices.map((device, index) => <CustomerDeviceListCardComponent key={`customer-device-card-${index}`} device={device} index={index + 1} />);
        }
        return <tr><td colSpan="4" className="text-center">Brak elementów</td></tr>;
    }

    const renderServices = () => {
        if (!_.isEmpty(customerServices)) {
            return customerServices.map((service, index) => <CustomerServiceListCardComponent key={`customer-service-card-${index}`} service={service} index={index + 1} />);
        }
        return <tr><td colSpan="8" className="text-center">Brak elementów</td></tr>;
    }

    const renderContracts = () => {
        if (!_.isEmpty(customerContracts)) {
            return customerContracts.map((contract, index) => <CustomerContractListCardComponent key={`customer-contract-card-${index}`} contract={contract} index={index + 1} />);
        }
        return <tr><td colSpan="7" className="text-center">Brak elementów</td></tr>;
    }

    const renderExpenses = () => {
        if (!_.isEmpty(customerExpenses)) {
            return customerExpenses.map((expense, index) => <CustomerExpenseListCardComponent key={`customer-expense-card-${index}`} expense={expense} index={index + 1} />);
        }
        return <tr><td colSpan="7" className="text-center">Brak elementów</td></tr>;
    }

    const scrollToElement = (el) => {
        setTimeout(() => {
            document.getElementById(el).scrollIntoView({ behavior: "smooth" })
        }, 200);
    }

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                    <h3 className="w-50 mt-3 mb-3">Szczegóły klienta</h3>
                    {AclManager.isAllowedMethod('customer/customer', 'update') && <Link to={`/customer/${customer._id}`}>
                        <button type="submit" className="btn btn-outline-primary float-md-right">Edytuj <img src={EditIcon} /></button>
                    </Link>}
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-5 col-sm-12 d-flex justify-content-center flex-wrap align-items-center">
                            <div className="w-100">
                                <h2 id="nazwa-urzadzenia" className="text-center">{customer.name || 'brak informacji'}</h2>
                                <h3 id="nazwa-urzadzenia" className="text-center">{customer.gusName || ''}</h3>
                                <h4 className="w-100 text-center"><span className="font-weight-light ">NIP:</span> {customer.NIP || 'brak informacji'}</h4>
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                            {_.get(customer, 'address') && <React.Fragment>
                                <h3 className="text-right">
                                    <span className="font-weight-light float-left">Adres rozliczeniowy:</span>{_.get(customer, 'address.postCode')} {_.get(customer, 'address.locality')} <br />ul. {_.get(customer, 'address.street')} {_.get(customer, 'address.streetNumber')}
                                </h3>
                            </React.Fragment>}
                            {renderContactsList()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {AclManager.isAllowedMethod('device/device', 'get') && <li className="nav-item">
                            <a className="nav-link" onClick={() => {
                                setActiveTab('devices');
                                scrollToElement('urzadzenia-tab')
                            }} id="urzadzenia-tab" data-toggle="tab" href="#urzadzenia" role="tab" aria-controls="urzadzenia" aria-selected="false">Urządzenia</a>
                        </li>}
                        {AclManager.isAllowedMethod('service/service', 'get') && <li className="nav-item">
                            <a className="nav-link" onClick={() => {
                                setActiveTab('services')
                                scrollToElement('zlecenia-tab')
                            }} id="zlecenia-tab" data-toggle="tab" href="#zlecenia" role="tab" aria-controls="zlecenia" aria-selected="false">Zlecenia</a>
                        </li>}
                        {AclManager.isAllowedMethod('customer/contract', 'get') && <li className="nav-item">
                            <a className="nav-link" onClick={() => {
                                setActiveTab('contracts')
                                scrollToElement('umowy-tab')
                            }} id="umowy-tab" data-toggle="tab" href="#umowy" role="tab" aria-controls="umowy" aria-selected="false">Umowy</a>
                        </li>}
                        {AclManager.isAllowedMethod('expense/expense', 'get') && <li className="nav-item">
                            <a className="nav-link" onClick={() => {
                                setActiveTab('expenses')
                                scrollToElement('kosztorysy-tab')
                            }} id="kosztorysy-tab" data-toggle="tab" href="#kosztorysy" role="tab" aria-controls="kosztorysy" aria-selected="false">Kosztorysy</a>
                        </li>}
                    </ul>
                    <div className="tab-content pt-3" id="myTabContent">
                        {AclManager.isAllowedMethod('device/device', 'get') && <div className="tab-pane fade" id="urzadzenia" role="tabpanel" aria-labelledby="urzadzenia-tab">
                            <div className="table-responsive ramkatabeli mb-2">
                                <table className="table table-hover rounded-10 tabela-left tabela-zlecenia">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Nazwa</th>
                                            <th>Konserwator</th>
                                            <th>RESURS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderDevices()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex float-right mt-1 mb-4">
                                <Pagination totalCount={total || 0} route={`/customers/${customer._id}`} page={parseInt(page)} setPage={(page) => setTabPage(page)} limit={limit} setLimit={setLimit} />
                            </div>
                        </div>}
                        {AclManager.isAllowedMethod('service/service', 'get') && <div className="tab-pane fade" id="zlecenia" role="tabpanel" aria-labelledby="zlecenia-tab">
                            <div className="table-responsive ramkatabeli tabela-left mb-3">
                                <table className="table table-hover rounded-10 tabela-left tabela-zlecenia">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Numer</th>
                                            <th>Typ</th>
                                            <th>Godzina rozpoczęcia</th>
                                            <th>Godzina zakończenia</th>
                                            <th>Czas realizacji</th>
                                            <th>Konserwator</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderServices()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex float-right mt-1 mb-4">
                                <Pagination totalCount={total || 0} route={`/customers/${customer._id}`} page={parseInt(page)} setPage={(page) => setTabPage(page)} limit={limit} setLimit={setLimit} />
                            </div>
                        </div>}
                        {AclManager.isAllowedMethod('customer/contract', 'get') && <div className="tab-pane fade" id="umowy" role="tabpanel" aria-labelledby="umowy-tab">
                            <div className="table-responsive ramkatabeli tabela-left mb-3">
                                <table className="table table-hover rounded-10 tabela-left tabela-zlecenia">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Numer umowy</th>
                                            <th>Termin od</th>
                                            <th>Termin do</th>
                                            <th>Ilość urządzeń</th>
                                            <th>Czas reakcji</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderContracts()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex float-right mt-1 mb-4">
                                <Pagination totalCount={total || 0} route={`/customers/${customer._id}`} page={parseInt(page)} setPage={(page) => setTabPage(page)} limit={limit} setLimit={setLimit} />
                            </div>
                        </div>}
                        {AclManager.isAllowedMethod('expense/expense', 'get') && <div className="tab-pane fade" id="kosztorysy" role="tabpanel" aria-labelledby="kosztorysy-tab">
                            <div className="table-responsive ramkatabeli tabela-left mb-3">
                                <table className="table table-hover rounded-10 tabela-left tabela-zlecenia">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Nazwa</th>
                                            <th>Data utworzenia</th>
                                            <th>Klient</th>
                                            <th>Ilość komponentów</th>
                                            <th>Roboczogodziny</th>
                                            <th>Wartość</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderExpenses()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex float-right mt-1 mb-4">
                                <Pagination totalCount={total || 0} route={`/customers/${customer._id}`} page={parseInt(page)} setPage={(page) => setTabPage(page)} limit={limit} setLimit={setLimit} />
                            </div>
                        </div>}
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

CustomerDetailsComponent.propTypes = {
    customer: PropTypes.object.isRequired,
    customerDevices: PropTypes.array.isRequired,
    customerServices: PropTypes.array.isRequired,
    customerContracts: PropTypes.array.isRequired,
    customerExpenses: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    setTabPage: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired
};

export default CustomerDetailsComponent;