import React from 'react';
import moment from 'moment';

const Date = ({ id, label, onChange, value, errors, disabled, isRequired }) => {

    const parseData = (value) => {
        let tmpDate = '';
        if (!value) return '';

        if (value !== "" && value.indexOf('Z') >= 0) {
            tmpDate = moment(value).format('YYYY-MM-DD');
        } else if (value !== "") {
            tmpDate = moment(parseInt(value) * 1000).format('YYYY-MM-DD');
        }

        return tmpDate;
    }

    const renderErrors = () => {
        if (!errors) return;

        if (Array.isArray(errors)) {
            return errors.map((e, i) => {
                return <div key={i} className="errormsg">{e}</div>
            })
        }

        if (typeof errors === 'string') {
            return <div className="errormsg">{errors}</div>
        }
    }

    return (
        <div className={`form-group d-flex flex-wrap w-100 flex-column justify-content-end ${errors ? 'error' : ''}`}>
            <label >{label} {isRequired && <span className="is-required">*</span>}</label>
            <input
                disabled={disabled}
                type="date"
                className="form-control"
                value={parseData(value)}
                onChange={e => onChange(id, moment(e.target.value).format('X'))} />

            {renderErrors()}
        </div>
    )
}

export default Date;