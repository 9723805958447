const PermissionsModuleList = [
   { name: 'Nazwa', controller: '' },
   { name: 'Klienci', controller: 'customer' },
   { name: 'Umowy', controller: 'contract' },
   { name: 'Urządzenia', controller: 'device' },
   { name: 'Komponenty', controller: 'component' },
   { name: 'Zakresy czynności', controller: 'serviceRange' },
   { name: 'Kosztorysy', controller: 'expense' },
   { name: 'Biblioteka mediów', controller: 'media' },
   { name: 'Magazyny', controller: 'warehouse' },
   { name: 'Stany magazynowe', controller: 'item' },
   { name: 'Przesunięcia magazynowe', controller: 'transfer' },
   { name: 'Zlecenia', controller: 'service' },
]

export default PermissionsModuleList;