// import { logout } from '../actions/app.action';
import _ from "lodash";
import { toast } from 'react-toastify';

class Api {

  constructor() {
    this.host = 'https://fixlift-api.overcloud.usermd.net/';
    // this.host = 'http://localhost:3001/';
    this.headers = {
      'Content-Type': 'application/json'
    }
  }

  getImageUrl() {
    return this.host.substr(0, this.host.length - 1);
  }

  getHost() {
    return this.host;
  }

  getToken() {
    return localStorage.getItem('token') ? `JWT:${localStorage.getItem('token')}` : false;
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  getDispatch() {
    return this.dispatch;
  }

  async get(url, params, isFormData, customGet = false) {
    if (params && Object.keys(params).length > 0) {
      url += "?" + Object.keys(params).map(function (key) {
        return key + '=' + params[key];
      }).join('&');
    }

    return await this.request(customGet ? url : this.host + url, 'GET', null, isFormData);
  }

  async post(url, data, isFormData) {
    return await this.request(this.host + url, 'POST', data, isFormData);
  }

  async put(url, data, id, isFormData) {
    if (id) {
      url += `/${id}`;
    }
    return await this.request(this.host + url, 'PUT', data, isFormData);
  }

  async delete(url, id) {
    if (id) {
      url += `/${id}`;
    }
    return await this.request(this.host + url, 'DELETE', null);
  }

  setFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
        if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          this.setFormData(obj[property], fd, formKey);
        } else {
          fd.append(formKey, obj[property]);
        }
      }
    }
    return fd;
  };

  async request(url, method, data, isFormData = false) {

    if (this.getToken()) {
      this.headers.authorization = this.getToken();
    }

    let options = {
      method: method,
      headers: this.headers
    }

    if (isFormData) {
      options.headers = { authorization: this.getToken() };
    }

    if (data && Object.keys(data).length > 0) {
      if (isFormData) {
        delete options.headers['Content-Type'];
        options.body = this.setFormData(data);
      } else {
        options.body = JSON.stringify(data);
      }
    }

    return await fetch(url, options).then((response) => {
      switch (response.status) {
        case 201:
          if (method === "POST") {
            toast.success("Zapisano");
          } else {
            toast.success("Zaktualizowano");
          }
          break;
        case 209:
          toast.success("Usunięto");
          break;
        case 422:
          toast.error("Błąd walidacji");
          break;
        case 404:
          toast.error("Brak zasobu");
          break;
        case 403:
          toast.error("Brak uprawnień");
          break;
        case 401:
          let withoutRoutes = ["/login", "/inspector"];
          if (!_.find(withoutRoutes, (route) => new RegExp(route, "gi").test(window.location.pathname))) {
            toast.info("Brak autoryzacji");
          }
          break;
        case 500:
          toast.error("Błąd serwera");
          break;
      }
      return response.json();

    }).then((data) => {
      return data;
    });

  }
}

export default new Api();