import React from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

import DodajIcon from '../../assets/gfx/dodaj.svg'
import TextInput from '../../assets/components/TextInput.component';

import { mediaInitialValues, mediaValidationSchema } from '../schemas/media.schema';

const Media = ({
    saveMedia
}) => {

    const history = useHistory();

    return (
        <React.Fragment>

            <div className="row mb-2">
                <div className="col-12 col-md-12 pt-3 d-flex btns-filtry">
                    <h3 className="mt-0 mb-3 w-100">Dodaj media</h3>
                </div>
            </div>
            <Formik
                initialValues={mediaInitialValues}
                validationSchema={mediaValidationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                onSubmit={(values, { setErrors }) => {
                    saveMedia(values).then(res => {
                        if (res.success) {
                            history.push('/medias');
                        } else {
                            if (res.errors) {
                                setErrors(res.errors);
                            }
                        }
                    });
                }}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit}>

                        <div className="row mb-2">
                            <div className="col-12 col-md-6 col-lg-4">
                                <TextInput
                                    isRequired={true}
                                    id="name"
                                    label="Nazwa pliku"
                                    type="text"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.name}
                                    errors={formik.errors.name}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="custom-file">
                                    <input type="file" className="form-control-file" id="exampleFormControlFile1"
                                        onChange={(e) => {
                                            formik.setFieldValue("file", e.target.files[0]);
                                        }}
                                    />
                                    {formik.errors.file && <div className="errormsg">{formik.errors.file}</div>}
                                </div>
                            </div>


                        </div><button type="submit" className="btn btn-primary mb-5 mt-4">Prześlij <img src={DodajIcon} /></button>
                    </form>
                )}
            </Formik>

        </React.Fragment>
    )
}

export default Media;