import { SET_ROLES, SET_ERRORS, CLEAR_ERRORS, CLEAR_ROLES } from '../actions/roles.actions';

const defaultState = {
   isLoading: false,
   roles: [],
   total: 0
}

export default (state = defaultState, action) => {
   switch (action.type) {
      case SET_ROLES:
         return {
            ...state,
            roles: action.roles,
            total: action.total
         };
      case CLEAR_ROLES:
         return {
            ...state,
            roles: [],
            total: 0
         }
      case SET_ERRORS:
         return {
            ...state,
            errors: action.errors
         }
      case CLEAR_ERRORS:
         return {
            ...state,
            errors: null
         }
      default:
         return state;

   }
}