import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Api from "../../application/services/api";
import PropTypes from 'prop-types';
import ArrowIcon from '../../assets/gfx/arrow-right.svg';
import DownloadIcon from '../../assets/gfx/pobierz.svg';
import LogoIcon from '../../assets/gfx/logo.svg';

import mapStyles from '../../assets/js/mapStyle.json';


const SERVICE_TYPES = {
    'udt_examination': 'Badanie UDT',
    'fault': 'Awaria',
    'conservation': 'Konserwacja',
    'measurement': 'Pomiary',
    'other': 'Inne'
};

const COMPONENT_STATUS_TYPES = {
    "none": "Brak",
    "working": "Komponent sprawny, bez uwag",
    "needs_repair": "Sprawny z zaleceniem naprawy",
    "needs_replace": "Niesprawny z zaleceniem wymiany",
    "needs_adjustment": "Sprawny z zaleceniem regulacji"
};

const containerStyle = {
    width: '100%',
    height: '200px'
};


const InspectorComponent = ({ deviceInfo, deviceInfo: { lastServices, name, _id, udtNumber, serialNumber, address, gallery } }) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAKRv4pARQD586LVg3a1tfG22WDu4-XNqA" // klucz do wpięcia u klienta na jego koncie (aktualnie prywatny)
    });


    const renderGalleryImages = () => {
        if (!gallery) return;

        return gallery.map((image, i) => {
            return (
                <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                    <img className="d-block w-100" src={`${image}`} data-src={`${image}`} alt="First slide" />
                </div>
            )
        })
    }

    const badgeColor = (status) => {
        switch (status) {
            case 'working':
                return 'badge-success';
            case 'needs_repair':
            case 'needs_replace':
                return 'badge-danger';
            case 'needs_adjustment':
                return 'badge-warning';
            default:
                return 'badge-dark';
        }
    }

    const renderLastServicesCard = (components) => {
        if (!components) return;

        return components.map((component, i) => {
            return (
                <div key={i} className="card">
                    <div className="card-body">
                        <div className="d-flex flex-row flex-wrap">
                            <div className="col-sm-12 col-md-12 card-title mb-3 font-weight-bold text-left p-0">
                                {component.componentName || 'Inne'}
                            </div>
                            <div className={`badge badge-pill ${badgeColor(component.status)} font-weight-light text-center p-2 w-100 mb-2`}>
                                {COMPONENT_STATUS_TYPES[component.status] || '-'}
                            </div>
                        </div>
                        {/* <a target="_blank" href={`${Api.getHost()}service/service/getServiceReport/${service._id}`}><img src={DownloadIcon} /></a> */}
                        {/* <ul className="clearListStyle inspektor-czynnosci">
                            {
                                (component.activities && component.activities.length > 0) &&
                                component.activities.map((activity, i) => {
                                    return <li key={i}>{activity.description}</li>
                                })
                            }
                        </ul> */}
                    </div>
                </div>
            )
        })
    }

    // const renderLastServices = () => {
    //     if (!lastServices || lastServices.length === 0) return 'Brak ostatnich zleceń';

    //     return lastServices.map((service, i) => {
    //         return (
    //             <div key={i} id="accordion" role="tablist" className="inspektor-fazy w-100">
    //                 <div className="card">
    //                     <a data-toggle="collapse" href={`#collapse${i}`} aria-expanded="false" aria-controls={`collapse${i}`}>
    //                         <div className="card-header" role="tab" id="headingOne">
    //                             <h5 className="mb-0 p-0 d-flex col-sm-12 col-md-6 justify-content-start">
    //                                 <img className="show-more-arrow mr-3" src={ArrowIcon} /><span>{moment(service.dateTo).format("DD.MM.YYYY HH:mm")}</span>
    //                             </h5>
    //                         </div>
    //                     </a>
    //                     <div id={`collapse${i}`} className="collapse" role="tabpanel" aria-labelledby="headingOne">
    //                         <div className="card-body">
    //                             <h3 className="mt-3 mb-3">Typ zlecenia: {SERVICE_TYPES[service.type] || '-'}</h3>
    //                             <a target="_blank" href={`${Api.getHost()}service/service/getServiceReport/${service._id}`}>Pobierz raport <img src={DownloadIcon} /></a>
    //                             {/* <div className="inspektor-komponenty card-deck"><br/>
    //                                 {renderLastServicesCard(service.components)}
    //                             </div> */}
    //                         </div>
    //                     </div>
    //                 </div>

    //             </div>
    //         )
    //     })
    // }
    return (
        <React.Fragment>
            <div className="top-logo"><img className="logo" src={LogoIcon} /></div>
            <div className="p-3" id="wrapper">
                <div className="container container-fluid">
                    <h3 className="mt-3 mb-3">Szczegóły urządzenia</h3>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 metryka">
                            <h2 id="nazwa-urzadzenia" className="w-100 kolor">{name}</h2>
                            <div className="d-flex justify-content-start">
                                <h4 className="mr-4"><span className="font-weight-light">ID:</span> {_id}</h4>
                                <h4 className="mr-4"><span className="font-weight-light">UDT:</span> {udtNumber}</h4>
                                <h4 className="mr-4">
                                    <span className="font-weight-light">Nr fabryczny:</span>
                                    {serialNumber}
                                </h4>
                            </div>
                            <hr />
                            <h3 className="mt-3 mb-3">Adres</h3>
                            <div className="row">
                                <div className="col-6 col-md-6 col-sm-12 border-top-0 border-bottom-0 border-left-0 border">
                                    ul. {_.get(address, 'street')} {_.get(address, 'streetNumber')}<br />{_.get(address, 'postCode')} {_.get(address, 'locality')}</div>
                                <div className="col-6 col-md-6 col-sm-12 align-self-center">
                                    {
                                        isLoaded && address.coordinates && address.coordinates.length === 2 && <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={{ lat: address.coordinates[0], lng: address.coordinates[1] }}
                                            zoom={15}
                                            options={{
                                                styles: mapStyles,
                                                disableAutoPan: true,
                                                disableDefaultUI: true
                                            }}
                                        >
                                            <Marker
                                                position={{ lat: address.coordinates[0], lng: address.coordinates[1] }}
                                            />
                                        </GoogleMap>
                                    }
                                </div>
                                {/* <div className="col-6 col-md-6 col-sm-12 align-self-center">{_.get(address, 'coordinates[0]')}, {_.get(address, 'coordinates[1]')}</div> */}
                                {_.get(deviceInfo, 'accessTips') && <div className="col-12"><hr /><h3 className="mt-1 mb-3">Wskazówki</h3></div>}
                                {_.get(deviceInfo, 'accessTips') && <div className="col-12">{_.get(deviceInfo, 'accessTips', '-')}</div>}
                                <div className="col-12 col-sm-12">
                                    <hr />
                                </div>
                                {_.get(deviceInfo, 'description', '-') && <div className="col-12 mt-3">
                                    <h3 className="mt-0 mb-3 text-left">Opis urządzenia</h3>
                                    <div className="mb-3">{_.get(deviceInfo, 'description', '-')}</div>
                                </div>}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 d-flex justify-content-center flex-wrap ">
                            <div className="col-sm-12 col-md-12 p-0">
                                <div id="galeriaurzadzenia" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner" role="listbox">
                                        {renderGalleryImages()}
                                    </div>
                                    <a className="carousel-control-prev" href="#galeriaurzadzenia" role="button"
                                        data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Poprzedni</span>
                                    </a>
                                    <a className="carousel-control-next" href="#galeriaurzadzenia" role="button"
                                        data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Następny</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-sm-12 mb-4">
                            <h3 className="mt-3 mb-3">Pobierz pełny raport</h3>
                            {/* {renderLastServices()} */}
                            <a target="_blank" href={`${Api.getHost()}service/service/getDeviceFullReport/${_id}`}>Pobierz raport <img src={DownloadIcon} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

InspectorComponent.propTypes = {
    deviceInfo: PropTypes.object.isRequired
};

export default InspectorComponent;