import React from 'react';

const Select = ({ id, label, options, onChange, errors, value, dontShowDefaultOption, disabled, isRequired }) => {

    const renderOptions = () => {
        if (options) {
            return options.map((o, i) => {
                return (
                    <option
                        key={i}
                        value={o.value}
                    >{o.name}</option>
                )
            })
        }
    }

    const renderErrors = () => {
        if (!errors) return;

        if (Array.isArray(errors)) {
            return errors.map((e, i) => {
                return <div className="errormsg">{e}</div>
            })
        }

        if (typeof errors === 'string') {
            return <div className="errormsg">{errors}</div>
        }
    }

    return (
        <div className={`form-group d-flex flex-wrap w-100 flex-column justify-content-end ${errors ? 'error' : null}`}>
            <label >{label} {isRequired && <span className="is-required">*</span>}</label>
            <select className="custom-select" onChange={(e) => onChange(id, e.target.value)} value={value} disabled={disabled || false}>
                {!dontShowDefaultOption && <option value="">Wybierz</option>}
                {renderOptions()}
            </select>
            {renderErrors()}
        </div>
    )
}

export default Select;