import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";

import Device from '../components/Device.component';
import { saveDevice, getDevice, clearDevice } from '../actions/devices.actions';

const DeviceContainer = ({
    saveDevice,
    getDevice,
    device,
    clearDevice,
    customers
}) => {

    const { id } = useParams();

    useEffect(() => {
        id && getDevice(id);
        return () => {
            clearDevice();
        };
    }, []);


    return (
        <Device
            saveDevice={saveDevice}
            device={device}
            customers={customers}
        />
    );
}

const mapStateToProps = (store) => ({
    history: store.app.history,
    device: store.devices.device

});

const mapDispatchToProps = (dispatch) => {
    return {
        saveDevice: (form) => dispatch(saveDevice(form)),
        getDevice: (id) => dispatch(getDevice(id)),
        clearDevice: () => dispatch(clearDevice())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceContainer);
