import Api from '../../application/services/api';
import { isLoading } from '../../application/actions/application.actions';
import _ from 'lodash';

export const NAMESPACE = "EXPENSES";

// AKCJE SETUJACE
export const SET_EXPENSE = `SET_EXPENSE_${NAMESPACE}`;
export const SET_EXPENSES = `SET_EXPENSES_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;
export const SET_SETTINGS = `SET_SETTINGS_${NAMESPACE}`;

// AKCJE CLEARUJACE
export const CLEAR_EXPENSE = `CLEAR_EXPENSE_${NAMESPACE}`;
export const CLEAR_EXPENSES = `CLEAR_EXPENSES_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEAR_QUERY_${NAMESPACE}`;
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;
export const CLEAR_SETTINGS = `CLEAR_SETTINGS_${NAMESPACE}`;

// METODY SETUJACE
export const setExpense = (expense) => dispatch => {
    dispatch({ type: SET_EXPENSE, expense });
}

export const setExpenses = (expenses, total) => dispatch => {
    dispatch({ type: SET_EXPENSES, expenses, total });
}

export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/expenses', true));
}

export const setOffset = (offset) => dispatch => {
    dispatch({ type: SET_OFFSET, offset: offset });
}

export const setLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setPage(1, true));
}

export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/expenses', true));
}

export const setSettings = (settings) => (dispatch) => {
    dispatch({ type: SET_SETTINGS, settings });
}

export const setPage = (page, reload = false) => (dispatch, getState) => {

    const { expenses: { params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    reload && dispatch(getExpenses());

    window.scrollTo(0, 0);
}

// METODY CLEARUJACE
export const clearExpense = () => dispatch => {
    dispatch({ type: CLEAR_EXPENSE });
}
export const clearExpenses = () => dispatch => {
    dispatch({ type: CLEAR_EXPENSES });
}
export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setPage(1, reload));

    if (replaceUrl) {
        window.history.replaceState(null, '', '/expenses');
    }

    window.scrollTo(0, 0);
}
export const clearSettings = () => dispatch => {
    dispatch({ type: CLEAR_SETTINGS });
}


// POBIERANIE USTWIEŃ
export const getSettings = () => async (dispatch, getState) => {

    dispatch(isLoading(true));
    const result = await Api.get('application/settings', { select: 'vatRate,deliveryRate' });

    if (result && result.success) {
        dispatch(setSettings(_.omit(result.document, ['_id', 'holydays', 'workingHours', 'addEventToSaturday', 'addEventsToSunday', 'serviceTime'])));
    }

    dispatch(isLoading(false));

    return true;
}


// POBIERANIE KOSZTORYSOW
export const getExpenses = () => async (dispatch, getState) => {

    dispatch(isLoading(true));

    const { expenses: { params, filters, query, queryFields } } = getState();
    const tmpParams = Object.assign({}, params, filters);

    // FILTRY PO QUERY I QUERYFIELDS
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }

    const result = await Api.get("expense/expense", tmpParams);

    if (result && result.success) {
        dispatch(setExpenses(result.documents, result.total));
    }

    dispatch(isLoading(false));
}

// POBIERANIE KPOJEDYNCZEGO KOSZTORYSU
export const getExpense = (id) => async (dispatch, getState) => {

    dispatch(isLoading(true));
    const { expenses: { params, settings } } = getState();
    const result = await Api.get(`expense/expense/${id}`, params);

    if (result && result.success) {
        dispatch(setExpense(parseData(result.document, 'get')));
    }

    dispatch(isLoading(false));
}

// METODA ZAPISUJĄCA KOSZTORYS
export const saveExpense = (expense) => async dispatch => {
    const tmpExpense = parseData(expense, 'save');

    if (!tmpExpense._id) {
        return createExpense(tmpExpense, dispatch); // dodanie kosztorysu
    } else {
        return editExpense(tmpExpense, dispatch); // edycja kosztorysu
    }
}

// METODA USUWAJĄCA KOSZTORYS
export const deleteExpense = (expenseId) => async (dispatch) => {

    dispatch(isLoading(true));
    const result = await Api.delete('expense/expense', expenseId);

    dispatch(getExpenses());
    dispatch(isLoading(false));

    return result;
}

// DODANIE KOSZTORYSU
const createExpense = async (expense, dispatch) => {
    dispatch(isLoading(true));
    const result = await Api.post('expense/expense', expense);
    dispatch(isLoading(false));

    return result;
}

// EDYCJA KOSZTORYSU
const editExpense = async (expense, dispatch) => {
    dispatch(isLoading(true));
    const result = await Api.put('expense/expense', expense, expense._id);
    dispatch(isLoading(false));

    return result;
}

const parseData = (expense, type) => {

    const tmpObj = _.cloneDeep(expense);

    if (type == 'save') {
        if (_.get(tmpObj, 'activities')) {
            tmpObj.activities = tmpObj.activities.map(activity => {
                return activity.name;
            });
        }
        if (_.get(tmpObj, 'customer.customerId')) {
            tmpObj.customer = _.get(tmpObj, 'customer.customerId');
        }
        if (_.get(tmpObj, 'usedComponents')) {
            tmpObj.usedComponents = tmpObj.usedComponents.map(component => {
                return {
                    componentId: _.get(component, 'component.componentId'),
                    amount: component.amount
                }
            });
        }
    } else if (type == 'get') {
        if (_.get(tmpObj, 'activities')) {
            tmpObj.activities = tmpObj.activities.map(activity => {
                return { name: activity }
            });
        }
        if (_.get(tmpObj, 'usedComponents')) {
            tmpObj.usedComponents = tmpObj.usedComponents.map(component => {
                return {
                    component: component,
                    amount: component.amount
                }
            });
        }
    }

    return tmpObj;
}