import _ from "lodash";
import React from 'react';
import edytujIcon from "../../assets/gfx/edytuj.svg";

const WarehouseComponentListCard = ({ component, index }) => {

    const typeLabels = {
        "component": "Komponent",
        "subcomponent": "Część"
    };

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{component.name || "-"}</td>
            <td>{typeLabels[component.type] || "Inny"}</td>
            <td>{component.amount || 0} szt.</td>
            <td>{_.round(component.componentValue / component.amount, 2)} PLN</td>
            <td>{component.componentValue} PLN</td>
            {/* <td><a href="#" alt="Edytuj komponent" data-toggle="modal" data-target="#modal-stan-magazynowy"><img className="akcja edytuj" src={edytujIcon} /></a> </td> */}
        </tr>
    )
}

export default WarehouseComponentListCard;