import React, { Fragment } from 'react';
import _ from "lodash";

const TextSelectInput = ({
    inputId,
    onInputChange,
    inputError,
    inputValue,
    selectId,
    onSelectChange,
    selectError,
    placeholder,
    selectValue,
    label,
    type,
    options,
    isRequired
}) => {

    const renderErrors = () => {
        let errors = [];
        if (_.isArray(inputError) && !_.isEmpty(inputError)) {
            _.each(inputError, (err, index) => {
                errors.push(<div key={`${inputId}err${index}`} className="errormsg">{err}</div>)
            });
        } else if (_.isString(inputError)) {
            errors.push(<div key={`${inputId}err0`} className="errormsg">{inputError}</div>)
        }

        if (_.isArray(selectError) && !_.isEmpty(selectError)) {
            _.each(selectError, (err, index) => {
                errors.push(<div key={`${selectId}err${index}`} className="errormsg">{err}</div>)
            });
        } else if (_.isString(selectError)) {
            errors.push(<div key={`${selectId}err0`} className="errormsg">{selectError}</div>)
        }
        return <Fragment>{errors}</Fragment>;
    }

    const renderOptions = () => {
        if (options) {
            return options.map((o, i) => {
                return (
                    <option
                        key={i}
                        value={o.value}
                    >{o.name}</option>
                )
            })
        }
    }

    return (
        <React.Fragment>
            <label>{label} {isRequired && <span className="is-required">*</span>}</label>
            <div className={`form-group d-flex flex-wrap w-100 flex-column justify-content-end ${inputError || selectError ? 'error' : ''}`}>
                <div className="input-group">
                    <input
                        type={type}
                        className={`form-control ${inputError ? 'error' : ''}`}
                        placeholder={placeholder || ''}
                        onChange={(e) => onInputChange(inputId, e.target.value)}
                        value={inputValue || ""}
                    />
                    <div id={selectId} className="input-group-append">
                        <select className="custom-select bg-light rounded-left" value={selectValue} onChange={(e) => onSelectChange(selectId, e.target.value)}>
                            <option>Wybierz</option>
                            {renderOptions()}
                        </select>
                    </div>
                </div>
                {renderErrors()}
            </div>
        </React.Fragment>
    )
}

export default TextSelectInput;