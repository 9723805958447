
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import DeleteIcon from "../gfx/kosz.svg";
import AddIcon from '../gfx/dodaj.svg';

const UploadFilesComponent = ({ multiple, files, uploadedFiles, handleChangeFiles, handleChangeUploadedFiles, index, isRequired, disabled }) => {

  const [fileList, setFileList] = useState([]);
  const [uploadedfilesList, setUploadedFilesList] = useState([]);

  useEffect(() => {
    setFilesToFileList(files || []);
  }, [files]);

  useEffect(() => {
    setUploadedFilesToFileList(uploadedFiles || []);
  }, [uploadedFiles]);

  const setFilesToFileList = (files) => {
    const filesTmp = [];
    files.map((file, index) => {
      filesTmp.push({
        name: cutFileName(file.substring(file.lastIndexOf("/") + 1), 25),
        fileIndex: index
      });
    });
    setFileList(filesTmp);
  }

  const setUploadedFilesToFileList = (files) => {
    const filesTmp = [];

    files.map((file, index) => {
      filesTmp.push({
        name: cutFileName(file.name, 25),
        fileIndex: index
      });
    });
    setUploadedFilesList(filesTmp);
  }

  const handleNewFiles = (newFiles) => {
    handleChangeUploadedFiles([...(uploadedFiles || []), ...newFiles]);
  }

  const cutFileName = (fileName, cutNumber) => {
    if (fileName.length > cutNumber) {
      const sliceCount = fileName.length - cutNumber;
      fileName = `...${fileName.slice(sliceCount)}`;
    }
    return fileName;
  }

  const handleRemoveFile = (fileIndex, type) => {
    if (type == 'file') {
      handleChangeFiles(files.filter((file, index) => index != fileIndex));
    } else if (type == 'uploadedFile') {
      handleChangeUploadedFiles(uploadedFiles.filter((file, index) => index != fileIndex));
    }
  }

  const renderFileList = () => {
    return <div className="mb-3">
      {fileList.map(file => {
        return <div key={`single-file-${file.fileIndex}`} className="plik-instrukcji mb-1">
          <img onClick={() => handleRemoveFile(file.fileIndex, 'file')} className="akcja usun mr-3" src={DeleteIcon} />
          {file.name}
        </div>
      })}
      {fileList.length > 0 && <br />}
      {(uploadedfilesList && uploadedfilesList.length > 0) && <label htmlFor="galeria">Nowe pliki</label>}
      {uploadedfilesList.map(file => {
        return <div key={`single-uploaded-file-${file.fileIndex}`} className="plik-instrukcji mb-1">
          <img onClick={() => handleRemoveFile(file.fileIndex, 'uploadedFile')} className="akcja usun mr-3" src={DeleteIcon} />
          {file.name}
        </div>
      })}
    </div>
  }

  return (
    <div className="form-group">
      <label htmlFor="galeria">Załącznik {isRequired && <span className="is-required">*</span>}</label><br />
      {renderFileList()}
      <label htmlFor={`file-input${!!index || 100}`}>
        <div id="galeria" className="btn btn-link p-0">
          Dodaj załącznik<div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5">
            <img className="mt-1" src={AddIcon} />
          </div>
        </div>
      </label>
      <input type="file" name='uploadedFiles' className="file-input" id={`file-input${!!index || 100}`} onChange={(e) => handleNewFiles(e.target.files)} multiple={!!multiple} disabled={disabled} />
    </div>
  )
}

UploadFilesComponent.propTypes = {
  multiple: PropTypes.bool,
  files: PropTypes.array,
  uploadedFiles: PropTypes.array,
  handleChangeFiles: PropTypes.func.isRequired,
  handleChangeUploadedFiles: PropTypes.func.isRequired
}

export default UploadFilesComponent;