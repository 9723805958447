import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ServiceRangeListCard from "./serviceRangeListCard.component";
import _ from "lodash";
import dodajIcon from "../../assets/gfx/dodaj.svg";
import Pagination from "../../assets/components/Pagination.component";
import SearchComponent from '../../assets/components/Search.component';
import ServiceRangeFilters from './serviceRangeFilters.component';
import ConfirmModal from '../../assets/components/ConfirmModal.component';
import AclManager from '../../application/services/aclManager';
import SortListComponent from '../../assets/components/SortList.component';

const ServiceRanges = ({ serviceRanges, page, total, limit, setPage, setLimit, setQuery, setFilters, setServiceRange, deleteServiceRange }) => {

    const [serviceRangeToDelete, setServiceRangeToDelete] = useState(null);

    const fields = [
        { id: 'name', name: 'Nazwa' },
        { id: 'interval', name: 'Interwał konserwacji' },
        { id: '', name: 'Ilość czynności' },
    ]

    const handleDelete = () => {
        deleteServiceRange(_.get(serviceRangeToDelete, '_id'));
        setServiceRangeToDelete(null);
    }

    const renderItems = () => {
        if (!_.isEmpty(serviceRanges)) {
            return serviceRanges.map((item, index) => <ServiceRangeListCard key={index} item={item} handleClone={setServiceRange} handleDelete={setServiceRangeToDelete} index={index} />);
        }
        return <tr><td colSpan="4">Brak elementów</td></tr>;
    }

    return (
        <div className="tab-pane fade show active" id="komponenty" role="tabpanel" aria-labelledby="komponenty-tab">
            <h3 className="mt-3 mb-3">Zestawy czynności</h3>
            <div className="container-fluid p-0">
                <div className="row p-0">
                    <div className="col-12 col-md-6 ">
                        {AclManager.isAllowedMethod('device/serviceRange', 'create') && <Link type="submit" className="btn btn-primary mb-3 float-md-left mr-3" to="/serviceRange">Dodaj <img src={dodajIcon} /></Link>}
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                        <SearchComponent setQuery={setQuery} />
                        <ServiceRangeFilters setFilters={setFilters} />
                    </div>
                </div>
            </div>
            <div className="table-responsive ramkatabeli ikonki mb-2">
                <table className="table table-hover rounded-10 tabela-left ikonki">
                    <thead>
                        <SortListComponent fields={fields} defaultSortBy='interval' setFilters={setFilters} defaultSortType={1} />
                    </thead>
                    <tbody>
                        {renderItems()}
                    </tbody>
                </table>

            </div>
            <Pagination totalCount={total || 0} route={"/serviceRanges"} page={parseInt(page)} setPage={(page) => setPage(page, true)} limit={limit} setLimit={setLimit} />
            {serviceRangeToDelete && <ConfirmModal
                id="confirmDelete"
                label="Czy na pewno chcesz usunąć ten zestaw czynności?"
                positionName={_.get(serviceRangeToDelete, 'name')}
                onConfirm={handleDelete}
                onCancel={() => setServiceRangeToDelete(null)}
            />}
        </div>
    )
}

export default ServiceRanges;