import React from 'react';
import { connect } from 'react-redux'
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from "react-router-dom";

import './app.css'

import { logout } from '../actions/application.actions';

import LoginContainer from './Login.container';
import LoaderComponent from '../components/Loader.component';
import InspectorContainer from '../../inspector/containers/Inspector.container';
import MainModules from './MainModules.container';

const App = (props) => {

    return (
        <Router>
            <LoaderComponent isVisible={props.isLoading} />
            <Switch>
                <Route {...props} path="/login" exact>
                    <LoginContainer />
                </Route>
                <Route {...props} path="/inspector/:id" exact>
                    <InspectorContainer />
                </Route>
                <MainModules {...props} />
            </Switch>
        </Router>
    );
}

const mapStateToProps = (store) => ({
    isLoading: store.app.isLoading,
    user: store.app.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);


