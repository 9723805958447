import { combineReducers } from 'redux';
import testReducer from '../test/reducer/test.reducer.';
import appReducer from '../application/reducer/app.reducer';
import settingsReducer from '../settings/reducers/settings.reducer';
import mediasReducer from '../medias/reducers/medias.reducer';
import customersReducer from '../customers/reducers/customers.reducer';
import contractsReducer from '../contracts/reducers/contracts.reducer';
import expensesReducer from '../expenses/reducers/expenses.reducer';
import componentsReducer from '../components/reducers/components.reducer';
import serviceRangesReducer from '../serviceRanges/reducers/serviceRanges.reducer';
import warehousesReducer from '../warehouses/reducers/warehouses.reducer';
import usersReducer from '../users/reducers/users.reducer';
import rolesReducer from '../roles/reducers/roles.reducer';
import inspectorReducer from '../inspector/reducers/inspector.reducer';
import devicesReducer from '../devices/reducers/devices.reducer';
import servicesReducer from '../services/reducers/services.reducer';

export default combineReducers({
    test: testReducer,
    app: appReducer,
    settings: settingsReducer,
    medias: mediasReducer,
    customers: customersReducer,
    contracts: contractsReducer,
    expenses: expensesReducer,
    components: componentsReducer,
    serviceRanges: serviceRangesReducer,
    warehouses: warehousesReducer,
    users: usersReducer,
    roles: rolesReducer,
    inspector: inspectorReducer,
    devices: devicesReducer,
    services: servicesReducer
})