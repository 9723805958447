import * as Yup from 'yup';

export const profileInitialState = {
   oldPassword: '',
   newPassword: '',
}

export const profileValidationSchema = Yup.object().shape({
   oldPassword: Yup.string()
      .required('Nazwa jest wymagana'),
   newPassword: Yup.string()
      .required('Nazwa jest wymagana')
});