import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import _ from 'lodash';
import mapStyles from '../js/mapStyle.json';

import PropTypes from 'prop-types';
import { getPlaces, getPlaceDetails } from '../../application/actions/application.actions';
import { GoogleMap, useJsApiLoader, Marker, LoadScript } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '200px',
    marginBottom: '24px'
};

const AddressComponent = ({ onChange, value, errors, getPlaces, getPlaceDetails, isRequired }) => {

    const [token, setToken] = useState(null);
    const [showPlaces, setShowPlaces] = useState(false);
    const [places, setPlaces] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [targetPlace, setTargetPlace] = useState({
        "streetNumber": "",
        "street": "",
        "locality": "",
        "area_2": "",
        "area_1": "",
        "postCode": "",
        "coordinates": [52.237049, 21.017532] // warszawka
    });

    useEffect(() => {
        if (!_.isEmpty(value)) { // ustawiamy value z rodzica jeżeli jest (ZACHOWAĆ STRUKTURĘ Z targetPlace!!)
            setTargetPlace(value);
            setShowMap(true);
        }
        return () => {

        }
    }, [value])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAKRv4pARQD586LVg3a1tfG22WDu4-XNqA" // klucz do wpięcia u klienta na jego koncie (aktualnie prywatny)
    });

    // funkcja renderujaca token dla google API
    const randomUUID = () => {
        let s = [], itoh = '0123456789ABCDEF';
        for (let i = 0; i < 36; i++) s[i] = Math.floor(Math.random() * 0x10);
        s[14] = 4;
        s[19] = (s[19] & 0x3) | 0x8;
        for (let i = 0; i < 36; i++) s[i] = itoh[s[i]];
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
    }

    const onChangHandler = (e) => {
        const currentValue = e.target.value;

        if (currentValue !== "") {
            if (!token) { // generowanie tokenu by używać go w ciągu szukania
                setToken(randomUUID());
            }

            getPlaces(currentValue, token).then((findedPlaces) => {
                if (!_.isEmpty(findedPlaces)) {
                    setPlaces(findedPlaces);
                    setShowPlaces(true);
                }
            });
        } else {
            setToken(null);
        }
    }

    const onFocus = () => {
        if (!_.isEmpty(places)) {
            setShowPlaces(true);
        }
    }

    const onBlur = () => {
        setTimeout(() => {
            setShowPlaces(false);
        }, 500);
    }

    const handleChoosePlace = placeId => {
        if (placeId) {
            getPlaceDetails(placeId).then((placeDetails) => {
                if (!_.isEmpty(placeDetails)) {
                    // ustawimy aktualne miejsce i datę
                    setTargetPlace(placeDetails);
                    // pokazujemy mapę i inputy z koordynatami
                    setShowMap(true);
                    // ustawiamy wartość na inpucie
                    document.getElementById('adres-autocomplete').value = `${placeDetails.street} ${placeDetails.streetNumber}, ${placeDetails.locality}, Polska`;
                    // setujemy funkcją od rodzica wartość wybranego miejsca
                    if (_.isFunction(onChange)) {
                        onChange(placeDetails);
                    }
                }
                setShowPlaces(false);
            });
        }
    }

    const handleMapClick = e => {

        let currentPlace = { ...targetPlace, coordinates: [e.latLng.lat(), e.latLng.lng()] };
        setTargetPlace(currentPlace);

        // setujemy funkcją od rodzica wartość wybranego miejsca
        if (_.isFunction(onChange)) {
            onChange(currentPlace);
        }
    }

    return (
        <React.Fragment>
            <div className="col-12 col-md-6 col-lg-4 d-flex flex-wrap">
                <div className={`form-group w-100 ${errors && Object.keys(errors).length > 0 ? "error" : ""} pos-relative`}>
                    <label htmlFor="adres-urzadzenia">Adres {isRequired && <span className="is-required">*</span>}</label>
                    <input
                        type="text"
                        className="form-control autocomplete"
                        onChange={(e) => onChangHandler(e)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        id="adres-autocomplete"
                        autoComplete="off"
                    />
                    {showPlaces && <ul className="list-group w-100 autocomplete  pos-absolute">
                        {places.map((place, index) => {
                            return <li key={`place-key=${index}`} className="list-group-item" onClick={() => handleChoosePlace(place.value)}>{place.name}</li>
                        })}
                    </ul>}
                    {errors && Object.keys(errors).length > 0 && <div className="errormsg">Wypełnij poprawnie adres</div>}
                </div>
                <fieldset disabled>
                    <div className="form-group w-100">
                        <div className="d-flex flex-nowrap">
                            <input type="text" className="form-control col-7 mr-2 mb-2" readOnly={true} value={targetPlace.street || ""} id="ulica" placeholder="Ulica" />
                            <input type="text" className="form-control mb-2" id="numer" readOnly={true} value={targetPlace.streetNumber || ""} placeholder="Numer" />
                        </div>
                        <div className="d-flex flex-nowrap">
                            <input type="text" className="form-control mb-2" id="numer" readOnly={true} value={targetPlace.postCode || ""} placeholder="Kod pocztowy" />
                            <input type="text" className="form-control col-7 ml-2 mb-2" readOnly={true} value={targetPlace.locality || ""} id="miasto" placeholder="Miasto" />
                        </div>
                        <input type="text" className="form-control w-100 mb-2" id="kraj" readOnly={true} value={targetPlace.country || ""} placeholder="Kraj" />
                    </div>
                    {showMap && isLoaded && <div className="form-group">
                        <label htmlFor="dlugosc-gps">Koordynaty GPS</label>
                        {isLoaded && <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{ lat: targetPlace.coordinates[0], lng: targetPlace.coordinates[1] }}
                            zoom={15}
                            onClick={(e) => handleMapClick(e)}
                            options={{
                                styles: mapStyles,
                                disableAutoPan: true,
                                disableDefaultUI: true
                            }}
                        >
                            <Marker
                                position={{ lat: targetPlace.coordinates[0], lng: targetPlace.coordinates[1] }}
                            />
                        </GoogleMap>}
                    </div>}
                    <div className="d-flex flex-nowrap align-items-end">
                        <input type="text" className="form-control mr-1" readOnly={true} value={_.get(targetPlace, 'coordinates[0]', '') || ""} id="szerokosc-gps" />
                        <input type="text" className="form-control ml-1" readOnly={true} value={_.get(targetPlace, 'coordinates[1]', '') || ""} id="dlugosc-gps" />
                    </div>
                </fieldset>
            </div>
        </React.Fragment >
    )
}

AddressComponent.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.object,
    errors: PropTypes.object,
    getPlaces: PropTypes.func,
    getPlaceDetails: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPlaces: (query, token) => dispatch(getPlaces(query, token)),
        getPlaceDetails: (placeId) => dispatch(getPlaceDetails(placeId))
    }
};

export default connect(null, mapDispatchToProps)(AddressComponent);
