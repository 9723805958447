import * as Yup from 'yup';


export const deviceInitialValues = {
    _id: null,
    name: '',
    maintenanceCompany: '',
    udtNumber: '',
    serialNumber: '',
    type: '',
    manufacturer: '',
    ceMarking: false,
    customer: null,
    buildingType: '',
    administrationName: '',
    administrationPhone: '',
    conservator: null,
    electricalMeasurmentsExpirationDate: '',
    udtExaminationDate: '',
    firstServiceDate: '',
    estimatedNumberOfCycles: '',
    description: '',
    accessTips: '',
    components: [],
    // components2: [],
    address: {
        area_1: '',
        area_2: '',
        area_3: '',
        locality: '',
        postCode: '',
        street: '',
        streetNumber: '',
        coordinates: [52.237049, 21.017532]
    },
    parameters: [],
    gallery: [],
    udtInspector: '',
    installer: '',
    installationDate: '',
    numberOfCycles: ''
}


export const deviceValidationSchema = Yup.object({
    _id: Yup.string().nullable(),
    name: Yup.string().required('Wpisz nazwę urządzenia'),
    maintenanceCompany: Yup.string().min(3, 'Minimialna ilość znaków firmy konserwującej (3)').required('Firma serwisująca jest wymagana'),
    udtNumber: Yup.string(),
    serialNumber: Yup.string(),
    type: Yup.string().required('Typ urządzenia jest wymagany'),
    manufacturer: Yup.string().required('Producent urządzenia jest wymagany'),
    buildingType: Yup.string().required('Rodzaj budynku jest wymagany'),
    administrationName: Yup.string(),
    administrationPhone: Yup.string(),
    accessTips: Yup.string(),
    firstServiceDate: Yup.string().when('_id', {
        is: null,
        then: Yup.string().required('Data pierwszej konserwacji jest wymagana')
    }),
    estimatedNumberOfCycles: Yup.string().when('_id', {
        is: null,
        then: Yup.string().required('Przewidywana ilośc cykli jest wymagana')
    }),
    electricalMeasurmentsExpirationDate: Yup.number().required('Data ważności pomiarów elektrycznych jest wymagana'),
    udtExaminationDate: Yup.number().required('Data ważności pomiarów elektrycznych jest wymagana'),
    address: Yup.object().shape({
        area_1: Yup.string(),
        area_2: Yup.string(),
        area_3: Yup.string(),
        locality: Yup.string().required('Miasto jest wymagane'),
        postCode: Yup.string(),
        street: Yup.string().required('Ulica jest wymagana'),
        streetNumber: Yup.string().required('Numer ulicy jest wymagany'),
        coordinates: Yup.array()
    }),
    conservator: Yup.object().required('Konserwator jest wymagany').shape({
        name: Yup.string(),
        _id: Yup.string().required('Id konserwatora jest wymagane'),
    }).default(null).nullable(),
    customer: Yup.object().required('Klient jest wymagany').shape({
        _id: Yup.string().required('Id klienta jest wymagane'),
        name: Yup.string()
    }).default(null).nullable(),
    components: Yup.array().of(
        Yup.object().shape({
            name: Yup.string(),
            componentId: Yup.string().required('Komponent jest wymagany'),
            parameters: Yup.array(),
            durabilitySource: Yup.string(),
            durabilityUnit: Yup.string(),
            estimatedDurability: Yup.number(),
            estimatedDurabilityYears: Yup.number(),
            installationDate: Yup.string().required('Data jest wymagana')
        })
    ).min(1, 'Komponenty są wymagane'),
    gallery: Yup.array(),
    parameters: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Nazwa parametru jest wymagana"),
            value: Yup.string().required("Wartość parametru jest wymagana")
        })
    ),
    udtInspector: Yup.string(),
    installer: Yup.string().required('Instalator jest wymagany'),
    installationDate: Yup.string().required('Data instalacji jest wymagana'),
    numberOfCycles: Yup.number().required('Liczba jazd jest wymagana'),
})
