import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import AclManager from '../../application/services/aclManager';

import DodajIcon from '../../assets/gfx/dodaj.svg';

import ConfirmModal from '../../assets/components//ConfirmModal.component';
import Search from '../../assets/components//Search.component';
import Pagination from '../../assets/components/Pagination.component';
import DevicesListCard from './DevicesListCard.component'
import SortListComponent from '../../assets/components/SortList.component';

const Devices = ({
    devices,
    deleteDevice,
    setQuery,
    total,
    page,
    limit,
    setLimit,
    setPage,
    setFilters
}) => {

    const [deviceToDelete, setDeviceToDelete] = useState(null);

    const fields = [
        { id: 'name', name: 'Nazwa' },
        { id: 'udtNumber', name: 'UDT' },
        { id: '', name: 'Nazwa klienta' }, 
        { id: '', name: 'Konserwator' }, 
        { id: '', name: 'RESURS' },
    ]

    const handleDelete = () => {
        deleteDevice(_.get(deviceToDelete, '_id'));
        setDeviceToDelete(null);
    }

    const handleCancelConfirm = () => {
        setDeviceToDelete(null);
    }

    const renderDevices = () => {
        if (_.isEmpty(devices)) {
            return <tr><td colSpan="7">Brak urządzeń</td></tr>
        }

        return devices.map((device, i) => {
            return (
                <DevicesListCard
                    key={i}
                    index={i}
                    device={device}
                    setDeviceToDelete={setDeviceToDelete}
                />
            )
        })
    }


    return (
        <React.Fragment>
            {deviceToDelete && <ConfirmModal
                id="confirmDelete"
                label="Czy na pewno chcesz usunąć tego klienta?"
                positionName={_.get(deviceToDelete, 'name')}
                onConfirm={handleDelete}
                onCancel={handleCancelConfirm}
            />}

            <h3 className="mt-3 mb-3">Urządzenia</h3>
            <div className="container-fluid p-0">
                <div className="row p-0">
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-wrap flex-md-nowrap justify-content-between justify-content-lg-start">
                        {
                            AclManager.isAllowedMethod('device/device', 'create') && <Link to="/device" className="btn btn-primary mr-2 mb-3">Dodaj Urządzenia <img src={DodajIcon} /></Link>
                        }
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                        <Search setQuery={setQuery} />
                    </div>
                </div>

                <div className="table-responsive ramkatabeli ikonki mb-2">
                    <table id="urzadzenia" className="table table-hover rounded-10 tabela-left">
                        <thead>
                            <SortListComponent fields={fields} defaultSortBy='name' setFilters={setFilters} defaultSortType={1} />
                        </thead>
                        <tbody>
                            {renderDevices()}
                        </tbody>
                    </table>
                </div>

                <Pagination
                    totalCount={total}
                    page={parseInt(page)}
                    setPage={(page) => setPage(page, true)}
                    limit={limit}
                    setLimit={setLimit}
                    route={"/devices"}
                />
            </div>

        </React.Fragment>
    )
}

export default Devices;