import React, { Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'

import { getExpense, saveExpense, getSettings, clearExpense, clearSettings } from '../actions/expenses.actions';
import ExpenseComponent from '../components/Expense.component';

const ExpenseContainer = (props) => {

    let { id } = useParams();

    useEffect(() => {
        id && props.getExpense(id);

        if (!id) {
            props.getSettings();
        }

        return () => {
            props.clearExpense();
            props.clearSettings();
        };
    }, []);

    return (
        <Fragment>
            {(!id || (id && props.expense)) && <ExpenseComponent {...props} />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    expense: store.expenses.expense,
    settings: store.expenses.settings
});

const mapDispatchToProps = (dispatch) => {
    return {
        getExpense: (id) => dispatch(getExpense(id)),
        getSettings: () => dispatch(getSettings()),
        saveExpense: (expenseData) => dispatch(saveExpense(expenseData)),
        clearExpense: () => dispatch(clearExpense()),
        clearSettings: () => dispatch(clearSettings())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseContainer);
