import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Autocomplete from '../../assets/components//Autocomplete.component';
import Checkbox from '../../assets/components//Checkbox.component';
import Date from '../../assets/components//Date.component';
import Select from '../../assets/components//Select.component';
import TextInput from '../../assets/components//TextInput.component';
import Time from '../../assets/components//Time.component';
import SelectMediaModal from '../../assets/components/SelectMediaModal.component';
import AddIcon from '../../assets/gfx/dodaj.svg';
import TrashIcon from '../../assets/gfx/kosz.svg';
import ServiceTypes from '../../scripts/serviceTypes';
import { serviceInitialState, serviceValidationSchema } from '../schemas/service.schema';

const ServiceComponent = ({ service, saveService }) => {

   const [initialValues, setInitialValues] = useState(serviceInitialState);
   const [serviceTypes, setServiceTypes] = useState([]);
   const history = useHistory();

   const udtExaminationTypes = [
      { value: 'periodic', name: 'Badanie okresowe' },
      { value: 'afterRepair', name: 'Po naprawie' },
      { value: 'afterFault', name: 'Po awarii' },
      { value: 'control', name: 'Badanie kontrolne' }
   ]

   useEffect(() => {
      if (localStorage.getItem('deviceData')) {
         let tmpInitialValues = _.cloneDeep(initialValues);
         let deviceData = JSON.parse(localStorage.getItem('deviceData'));

         if (deviceData.customer) tmpInitialValues.customer = deviceData.customer;
         if (deviceData.device) tmpInitialValues.device = deviceData.device;
         if (deviceData.conservator) tmpInitialValues.conservator = deviceData.conservator;

         if (_.get(deviceData.device, 'components')) {
            let components = handleGetComponentsFromDevice(null, _.get(deviceData.device, 'components'));
            tmpInitialValues.components = components;
         }
         setInitialValues(tmpInitialValues);
         localStorage.removeItem('deviceData');
      }
   }, [])

   useEffect(() => {
      setServiceTypes(_.filter(ServiceTypes, type => { return type.value != 'conservation' }));
   }, [ServiceTypes])

   useEffect(() => {
      if (_.get(service, "_id")) {
         service.timeFrom = moment(service.dateFrom).format('HH:mm');
         service.timeTo = moment(service.dateTo).format('HH:mm');
         service.dateFrom = moment(service.dateFrom).format('X');
         service.dateTo = moment(service.dateTo).format('X');
         if ('filingDate' in service) {
            service.filingDate = moment(service.filingDate).format('X');
         }

         service.componentsMaintenance = true;

         if (!_.isEmpty(service.device.components)) {
            service.device.components.forEach(deviceComponent => {
               let index = _.findIndex(service.components, cmp => cmp.componentId == deviceComponent.componentId);

               if (index > -1 && service.components[index]) {
                  service.components[index].checked = true;
               } else {
                  let tmpComponent = {
                     componentId: deviceComponent.componentId,
                     componentName: deviceComponent.name,
                     activities: [],
                     checked: false
                  };

                  if (!_.isEmpty(deviceComponent.serviceRanges)) {
                     deviceComponent.serviceRanges.forEach(serviceRange => {

                        if (!_.isEmpty(serviceRange.activities)) {
                           serviceRange.activities.forEach(activity => {

                              let tmpActivity = {
                                 serviceRangeId: serviceRange.serviceRangeId,
                                 serviceRangeName: serviceRange.name,
                                 description: activity.description || '',
                                 instruction: activity.instruction || ''
                              };

                              tmpComponent.activities.push(tmpActivity);
                           })
                        }

                     })
                  }

                  service.components.unshift(tmpComponent);
               }
            })
         }

         if (!_.isEmpty(service.components)) {
            service.components.map(cmp => {
               if (!cmp.componentId) cmp.checked = true;
               return cmp;
            })
         }

         setInitialValues(service);
      }

   }, [service])

   const handleGetComponentsFromDevice = (formik, components) => {
      let tmpComponents = [];

      components.map(component => {
         let tmpComponent = {
            componentId: component.componentId,
            componentName: component.name,
            activities: [],
            checked: false
         };

         if (!_.isEmpty(component.serviceRanges)) {
            component.serviceRanges.map(serviceRange => {

               if (!_.isEmpty(serviceRange.activities)) {
                  serviceRange.activities.map(activity => {

                     let tmpActivity = {
                        serviceRangeId: serviceRange.serviceRangeId,
                        serviceRangeName: serviceRange.name,
                        description: activity.description || '',
                        instruction: activity.instruction || ''
                     };

                     tmpComponent.activities.push(tmpActivity);
                  })
               }

            })
         }

         tmpComponents.push(tmpComponent);
      });

      // pushujemy dodatkowo 'INNE' czyli czynności niezwiązane z komponentem
      tmpComponents.push({
         activities: [],
         checked: true
      });

      if (formik) formik.setFieldValue('components', tmpComponents);
      else return tmpComponents;
   }

   const handleDeleteActivity = (formik, cmpIndex, actIndex) => {
      formik.setFieldValue(`components[${cmpIndex}].activities`, _.without(formik.values.components[cmpIndex].activities, _.nth(formik.values.components[cmpIndex].activities, actIndex)));
   }

   const handleAddNewActivity = (formik, cmpIndex) => {
      formik.setFieldValue(`components[${cmpIndex}].activities`, _.concat(formik.values.components[cmpIndex].activities, [{
         description: '',
         instruction: ''
      }]));
   }

   const clearForm = (formik) => {
      formik.setFieldValue('conservator', null);
      formik.setFieldValue('components', []);
      formik.setFieldValue('device', null);
   }

   const prepareData = async (values) => {
      if (values.dateFrom && values.timeFrom) {
         let dateFrom = moment.unix(values.dateFrom).format("MM/DD/YYYY")
         values.dateFrom = moment(moment(dateFrom + ' ' + values.timeFrom)).format('X');
      }

      if (values.dateTo && values.timeTo) {
         let dateTo = moment.unix(values.dateTo).format("MM/DD/YYYY")
         values.dateTo = moment(moment(dateTo + ' ' + values.timeTo)).format('X');
      }
      if (values.filingDate) {
         values.filingDate = moment.unix(values.filingDate).format('X');
      }

      if (values.device && values.device.deviceId) values.device = { deviceId: values.device.deviceId };

      values.components = _.remove(values.components, component => {
         return component.activities.length > 0 && component.checked
      });

      return values;
   }

   const renderServiceForm = () => {
      return <Formik
         initialValues={initialValues}
         validationSchema={serviceValidationSchema}
         enableReinitialize={true}
         validateOnChange={false}
         validateOnBlur={false}
         onSubmit={async (values, { setErrors }) => {
            const form = await prepareData(_.cloneDeep(values));

            saveService(_.get(service, '_id') ? _.get(service, '_id') : null, _.pickBy(form, _.identity)).then(res => {
               if (res.success) {
                  history.push(`/services/${res.document}`);
               } else {
                  if (res.errors) {
                     setErrors(res.errors);
                  }
               }
            });
         }}
      >
         {formik => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
               <div className="row">
                  <div className="col-12 col-md-4 col-lg-4 d-flex align-content-stretch">
                     <Select
                        isRequired={true}
                        id="type"
                        label="Typ zlecenia"
                        options={serviceTypes}
                        onChange={(element, val) => formik.setFieldValue(element, val)}
                        value={formik.values.type}
                        errors={formik.errors.type}
                     />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                     <Autocomplete
                        isRequired={true}
                        id="customer"
                        label="Klient"
                        url="customer/customer"
                        multi={false}
                        optionLabelField="name"
                        optionIdField="_id"
                        onOptionSelect={(id, customer) => {
                           formik.setFieldValue(id, customer ? { customerId: customer._id, name: customer.name } : null)
                           clearForm(formik);
                        }}
                        value={formik.values.customer}
                        errors={formik.errors.customer}
                        queryFields={['name']}
                        additionalParams={{ limit: 20 }}
                        prependText={null}
                        appendText={null}
                     />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                     <Autocomplete
                        isRequired={true}
                        id="device"
                        label="Urządzenie klienta"
                        url="device/device"
                        multi={false}
                        optionLabelField="name"
                        optionIdField="_id"
                        preload={true}
                        onOptionSelect={(id, device) => {
                           formik.setFieldValue(id, device ? {
                              deviceId: device._id,
                              name: device.name,
                              components: device.components || []
                           } : null)
                           if (device) {
                              handleGetComponentsFromDevice(formik, device.components);
                              if (_.get(device.conservator, "name")) {
                                 formik.setFieldValue('conservator', { conservatorId: _.get(device, 'conservator._id'), name: _.get(device, 'conservator.name') })
                              }
                           }
                           else formik.setFieldValue('components', []);
                        }}
                        value={formik.values.device}
                        errors={formik.errors.device}
                        queryFields={['name']}
                        readonly={!_.get(formik.values.customer, 'customerId')}
                        additionalParams={{ limit: 100, customer: _.get(formik.values.customer, 'customerId', ''), joinValues: true }}
                        prependText={null}
                        appendText={null}
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                     <Autocomplete
                        isRequired={true}
                        id="conservator"
                        label="Konserwator"
                        url="admin/user"
                        multi={false}
                        optionLabelField="name"
                        optionIdField="_id"
                        // onOptionSelect={(id, conservator) => formik.setFieldValue(id, conservator ? { conservatorId: conservator.id, name: conservator.name } : null)}
                        value={formik.values.conservator}
                        errors={formik.errors.conservator}
                        queryFields={['name']}
                        additionalParams={{ limit: 20, type: 'employee' }}
                        prependText={null}
                        appendText={null}
                        readonly={true}
                     />
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 d-flex align-content-stretch">
                     <Date
                        isRequired={true}
                        id="dateFrom"
                        label="Data wykonania od"
                        type="number"
                        onChange={formik.setFieldValue}
                        value={formik.values.dateFrom}
                        errors={formik.errors.dateFrom}
                     />
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 d-flex align-content-stretch">
                     <Time
                        isRequired={true}
                        id="timeFrom"
                        label="Godzina wykonania od"
                        onChange={formik.setFieldValue}
                        value={formik.values.timeFrom}
                        errors={formik.errors.timeFrom}
                     />
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 d-flex align-content-stretch">
                     <Date
                        isRequired={true}
                        id="dateTo"
                        label="Data wykonania do"
                        type="number"
                        onChange={formik.setFieldValue}
                        value={formik.values.dateTo}
                        errors={formik.errors.dateTo}
                     />
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 d-flex align-content-stretch">
                     <Time
                        isRequired={true}
                        id="timeTo"
                        label="Godzina wykonania do"
                        onChange={formik.setFieldValue}
                        value={formik.values.timeTo}
                        errors={formik.errors.timeTo}
                     />
                  </div>
                  {formik.values.type === "fault" && <div className="col-6 col-md-3 col-lg-2 d-flex align-content-stretch">
                     <Date
                        id="filingDate"
                        label="Data zgłoszenia"
                        onChange={formik.setFieldValue}
                        value={formik.values.filingDate}
                        errors={formik.errors.filingDate}
                     />
                  </div>}
                  {formik.values.type === "fault" && <div className="col-6 col-md-3 col-lg-2 d-flex align-content-stretch">
                     <TextInput
                        id={`reportingPerson`}
                        type="text"
                        label="Osoba zgłaszająca"
                        value={formik.values.reportingPerson}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.reportingPerson}
                     />
                  </div>}
                  {(formik.values.type === "udt_examination" || formik.values.type === 'measurement') && <div className="col-6 col-md-3 col-lg-6 d-flex align-content-stretch">
                     <TextInput
                        id="protocolNumber"
                        type="text"
                        label="Numer protokołu"
                        value={formik.values.protocolNumber}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.protocolNumber}
                     />
                  </div>}
                  {(formik.values.type === "udt_examination" || formik.values.type === "measurement") && <div className="col-6 col-md-3 col-lg-6 d-flex align-content-stretch">
                     <div className="form-group d-flex flex-wrap w-100 flex-column justify-content-end ">
                        <div className="input-group">
                           {formik.values.protocol && <div className="param-komp mb-1 col-12 text-left pl-0">
                              <img className="akcja usun mr-3" src={TrashIcon}
                                 onClick={() => {
                                    formik.setFieldValue(`protocol`, null)
                                    document.getElementById(`link-input`).value = "";
                                 }}
                              />
                              {formik.values.protocol}
                           </div>}
                           <input type="text" id={`link-input`} className="form-control" onChange={(e) => {
                              if (e.target.value.indexOf("http") >= 0) {
                                 e.target.value.trim() != "" && formik.setFieldValue(`protocol`, e.target.value);
                                 document.getElementById(`link-input`).value = "";
                              }
                           }} placeholder="Wklej link lub wybierz z biblioteki" aria-label="" aria-describedby="basic-addon1" />
                           <div className="input-group-append">
                              <SelectMediaModal
                                 modalId="protocol"
                                 buttonName="Biblioteka"
                                 onSelect={(src) => formik.setFieldValue(`protocol`, src)}
                              />
                           </div>
                        </div>
                     </div>
                  </div>}
                  {formik.values.type === "udt_examination" && <div className="col-12 col-md-4 col-lg-4 d-flex align-content-stretch">
                     <Select
                        isRequired={true}
                        id="udtExaminationType"
                        label="Rodzaj badania UDT"
                        options={udtExaminationTypes}
                        onChange={(element, val) => formik.setFieldValue(element, val)}
                        value={formik.values.udtExaminationType}
                        errors={formik.errors.udtExaminationType}
                     />
                  </div>}
               </div>
               <div className="row">
                  {_.get(formik.values, 'device') && <div className="col-12 col-md-4">
                     <Checkbox
                        id="componentsMaintenance"
                        label="Uwzględnij czynności konserwacyjne komponentów urządzenia"
                        value={formik.values.componentsMaintenance}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.componentsMaintenance}
                     />
                  </div>}
                  {!_.isEmpty(formik.values.components) && _.get(formik.values, 'componentsMaintenance') && <div className="col-12 col-md-4">
                     <div className="form-group">
                        <label>Wybierz komponenty do sprawdzenia</label>

                        {formik.values.components.map((component, index) => {
                           return _.get(component, 'componentId') && <div className="custom-control custom-checkbox w-100" key={index}>
                              <input
                                 type="checkbox"
                                 className="custom-control-input"
                                 id={`components[${index}].checked`}
                                 checked={!_.isEmpty(formik.values.components) && formik.values.components[index].checked}
                                 onChange={(e) => formik.setFieldValue(`components[${index}].checked`, e.target.checked)}
                              />
                              <label className="custom-control-label" htmlFor={`components[${index}].checked`}>
                                 {_.get(component, 'componentName', 'Brak')}
                              </label>
                           </div>
                        })}
                        {/* {formik.errors.components && <div className="errormsg">{formik.errors.components}</div>} */}
                     </div>
                  </div>}

                  {!_.isEmpty(formik.values.components) && <div className="col-sm-12 mt-3">
                     <label htmlFor="informacje">Dodatkowe czynności</label>

                     {formik.values.components.map((component, cmpIndex) => {
                        return _.get(component, 'checked') && <div className="card" key={`component-activities-${component.componentId}`}>
                           <div className="modal-header">
                              <h5 className="modal-title" id="confirmDelete">  {_.get(component, 'componentName', 'Inne')}</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Anuluj" onClick={() => formik.setFieldValue(`components[${cmpIndex}].checked`, false)}>
                                 <span aria-hidden="true">&times;</span>
                              </button>
                           </div>
                           <div className="table-responsive ramkatabeli ikonki">
                              <table className="table table-hover rounded-10">
                                 <thead>
                                    <tr>
                                       <th>Nazwa</th>
                                       <th className="w-50">Instrukcja</th>
                                       <th></th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {!_.isEmpty(component.activities) && component.activities.map((activity, actIndex) => {
                                       return <tr key={`activity-${actIndex}`}>
                                          {/* DESCRIPTION */}
                                          <td className="align-bottom">
                                             <TextInput
                                                id={`components[${cmpIndex}].activities[${actIndex}].description`}
                                                customClass='d-flex flex-wrap w-100 flex-column justify-content-end'
                                                type="text"
                                                value={_.get(formik, `values.components[${cmpIndex}].activities[${actIndex}].description`)}
                                                onChange={(id, val) => formik.setFieldValue(id, val)}
                                                errors={_.get(formik, `errors.components[${cmpIndex}].activities[${actIndex}].description`, null)}
                                             />
                                          </td>
                                          {/* INSTRUCTION */}
                                          <td className="align-bottom">
                                             <div className="input-group">
                                                {_.get(formik, `values.components[${cmpIndex}].activities[${actIndex}].instruction`) &&
                                                   <div key={`instruction${actIndex}`} className="param-komp mb-1 col-12 text-left pl-0">
                                                      <img className="akcja usun mr-3" src={TrashIcon}
                                                         onClick={() => {
                                                            formik.setFieldValue(`components[${cmpIndex}].activities.${actIndex}.instruction`, null)
                                                            document.getElementById(`link-input-${actIndex}`).value = "";
                                                         }}
                                                      />
                                                      {_.get(formik, `values.components[${cmpIndex}].activities[${actIndex}].instruction`, null)}
                                                   </div>
                                                }
                                                <input type="text" id={`link-input-${actIndex}`} className="form-control" onChange={(e) => {
                                                   if (e.target.value.indexOf("http") >= 0) {
                                                      e.target.value.trim() != "" && formik.setFieldValue(`components[${cmpIndex}].activities.${actIndex}.instruction`, e.target.value);
                                                      document.getElementById(`link-input-${actIndex}`).value = "";
                                                   }
                                                }} placeholder="Wklej link lub wybierz z biblioteki" aria-label="" aria-describedby="basic-addon1" />
                                                {/* INSTRUCTION ERRORS */}
                                                {_.get(formik, `errors.components[${cmpIndex}].activities[${actIndex}].instruction`, null) &&
                                                   <div className="errormsg">{formik.errors.components[cmpIndex].activities[actIndex].instruction}</div>
                                                }
                                                <div className="input-group-append">
                                                   <SelectMediaModal
                                                      modalId={`components${cmpIndex}activities${actIndex}instruction${actIndex}`}
                                                      buttonName="Wybierz"
                                                      onSelect={(src) => formik.setFieldValue(`components[${cmpIndex}].activities.${actIndex}.instruction`, src)}
                                                   />
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <span title="Usuń" onClick={() => handleDeleteActivity(formik, cmpIndex, actIndex)}>
                                                <img className="akcja usun" src={TrashIcon} />
                                             </span>
                                          </td>
                                       </tr>
                                    })}
                                    <tr>
                                       <td></td>
                                       <td colSpan="2" className="text-right">
                                          <button type="button" className="btn btn-link p-0 mt-3" onClick={() => handleAddNewActivity(formik, cmpIndex)}>
                                             Dodaj
                                             <div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5">
                                                <img className="mt-1 ml-0" src={AddIcon} />
                                             </div>
                                          </button>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     })}
                     {formik.errors.components && typeof formik.errors.components == 'string' && <div className="errormsg">{formik.errors.components}</div>}
                  </div>}
               </div>

               <button type="submit" className="btn btn-primary mb-3 mt-5">Zapisz<img src={AddIcon} /></button>
            </form>
         )}
      </Formik >
   }

   return (
      <Fragment>
         <h3 className="mt-3 mb-3">{`${!service._id ? 'Dodaj nowe' : 'Edytuj'} zlecenie`}</h3>
         {renderServiceForm()}
      </Fragment>
   )
}

ServiceComponent.propTypes = {
   service: PropTypes.object.isRequired,
   saveService: PropTypes.func.isRequired
};

export default ServiceComponent;