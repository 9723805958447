import _ from "lodash";
import React from 'react';
import { Link, useParams } from "react-router-dom";
import AclManager from '../../application/services/aclManager';
import dokumentIcon from "../../assets/gfx/dokument.svg";
import edytujIcon from "../../assets/gfx/edytuj.svg";

const ServiceRangeDetails = ({ serviceRange }) => {
    const { id } = useParams();

    const intervalNames = {
        1: "1 miesiąc",
        3: "3 miesiące",
        6: "6 miesięcy",
        12: "12 miesięcy"
    }

    function renderActivities() {
        if (_.isArray(serviceRange.activities)) {
            return serviceRange.activities.map((activity, index) => <tr key={`activity${index}`}>
                <td>{index + 1}.</td>
                <td>{activity.description}</td>
                <td>{activity.instruction ? <a href={activity.instruction} className="btn btn-link mr-1" target="_blank"><img src={dokumentIcon} /> Pobierz</a> : "-"}</td>
            </tr >);
        }
        return (<tr>
            <td>1.</td>
        </tr>);
    }

    function renderLinkedComponents() {
        if (_.isArray(serviceRange.linkedComponents)) {
            return serviceRange.linkedComponents.map((component, index) => <li key={`component${index}`}><Link to={`/components/${component._id}`}>{component.name}</Link></li>)
        } else {
            return "-";
        }
    }

    return (
        <div id="page-content-wrapper">
            <div className="row mb-3">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                    <h3 className="w-50 mt-3 mb-3">Szczegóły zestawu czynności</h3>
                    <div>
                        {AclManager.isAllowedMethod('device/serviceRange', 'update') && <Link to={`/serviceRange/${id}`} className="btn btn-outline-primary float-md-right">Edytuj <img src={edytujIcon} /></Link>}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-7 col-sm-12 d-flex justify-content-center flex-wrap align-items-center">

                                    <div className="w-100">
                                        <h2 id="nazwa-urzadzenia" className="text-center">{serviceRange.name || "-"}</h2>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <h3 className="text-right"><span className="font-weight-light float-left">Komponenty używające zestawu:</span>
                                        <ul className="clearListStyle">
                                            {renderLinkedComponents()}
                                        </ul></h3><hr />
                                    <h3 className="text-right"><span className="font-weight-light float-left">Interwał konserwacji:</span> 	{intervalNames[serviceRange.interval] || "-"}</h3>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="table-responsive ">
                        <table className="table table-bordered table-hover rounded-10">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nazwa</th>
                                    <th>Instrukcja</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderActivities()}
                            </tbody>
                        </table>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default ServiceRangeDetails;