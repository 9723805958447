import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux'

import ServicesComponent from '../components/Services.component';

import {
   getServices,
   deleteService,
   setPage,
   setLimit,
   setQuery,
   setFilters,
   clearPage,
   clearQuery,
   clearLimit,
   clearFilters,
   clearServices,
} from '../actions/services.actions';

const ServicesContainer = ({
   getServices,
   deleteService,
   services,
   setQuery,
   setPage,
   setFilters,
   setLimit,
   total,
   limit,
   clearPage,
   clearQuery,
   clearLimit,
   clearFilters,
   clearServices,
}) => {
   let { page } = useParams();

   useEffect(() => {
      setPage(page || 1);
      getServices();

      return () => {
         clearServices();
         clearPage();
         clearQuery();
         clearLimit();
         clearFilters();
      }
   }, [])

   return (
      <ServicesComponent
         services={services}
         deleteService={deleteService}
         page={page && parseInt(page) || 1}
         total={total}
         limit={limit}
         setPage={setPage}
         setLimit={setLimit}
         setQuery={setQuery}
         setFilters={setFilters}
      />
   );
}

const mapStateToProps = (store) => ({
   services: store.services.services,
   query: store.services.query,
   page: store.services.page,
   limit: store.services.params.limit,
   total: store.services.total,
});

const mapDispatchToProps = (dispatch) => {
   return {
      getServices: () => dispatch(getServices()),
      setPage: (page) => dispatch(setPage(page)),
      setLimit: (limit) => dispatch(setLimit(limit)),
      setQuery: (query) => dispatch(setQuery(query)),
      setFilters: (filtersData) => dispatch(setFilters(filtersData)),
      deleteService: (serviceId) => dispatch(deleteService(serviceId)),
      clearPage: () => dispatch(clearPage()),
      clearQuery: () => dispatch(clearQuery()),
      clearLimit: () => dispatch(clearLimit()),
      clearFilters: () => dispatch(clearFilters()),
      clearServices: () => dispatch(clearServices()),
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesContainer);
