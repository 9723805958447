import React from 'react';

const WelcomeComponent = () => {

  return (
    <div className="d-flex justify-content-center align-items-center mt-5 text-center">
      <h2>Witaj w panelu FiXLIFT</h2>
    </div>
  )
}

export default WelcomeComponent;