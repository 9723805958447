import Api from '../../application/services/api';
import moment from 'moment';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

export const NAMESPACE = 'USERS';

// AKCJE SETUJACE
export const SET_USER = `SET_USER_${NAMESPACE}`;
export const SET_USERS = `SET_USERS_${NAMESPACE}`;
export const SET_USER_DEVICES = `SET_USER_DEVICES_${NAMESPACE}`;
export const SET_USER_PERMISSIONS = `SET_USER_PERMISSIONS_${NAMESPACE}`;
export const SET_LAST_USER_ACTIVITIES = `SET_LAST_USER_ACTIVITIES_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;

// AKCJE CLEARUJACE
export const CLEAR_USER = `CLEAR_USER_${NAMESPACE}`;
export const CLEAR_USERS = `CLEAR_USERS_${NAMESPACE}`;
export const CLEAR_USER_DEVICES = `CLEAR_USER_DEVICES_${NAMESPACE}`;
export const CLEAR_USER_PERMISSIONS = `CLEAR_USER_PERMISSIONS_${NAMESPACE}`;
export const CLEAR_LAST_USER_ACTIVITIES = `CLEAR_LAST_USER_ACTIVITIES_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEAR_QUERY_${NAMESPACE}`
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;

// METODY SETUJACE
export const setUser = (user) => ({
    type: SET_USER,
    user,
});

export const setUsers = (users, total) => ({
    type: SET_USERS,
    users,
    total
});

export const setUserDevices = (userDevices) => ({
    type: SET_USER_DEVICES,
    userDevices
})

export const setUserPermissions = (userPermissions) => ({
    type: SET_USER_PERMISSIONS,
    userPermissions
})

export const setLastUserActivities = (lastUserActivities) => ({
    type: SET_LAST_USER_ACTIVITIES,
    lastUserActivities
})

export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/users', true));
}

export const setOffset = (offset) => dispatch => {
    dispatch({ type: SET_OFFSET, offset: offset });
}

export const setLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setPage(1, true));
}

export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/users', true));
}

export const setPage = (page, reload) => (dispatch, getState) => {
    const { users: { params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    reload && dispatch(getUsers());
    window.scrollTo(0, 0);
}

// METODY CLEARUJACE
export const clearUser = () => dispatch => {
    dispatch({
        type: CLEAR_USER
    });
}

export const clearUsers = () => dispatch => {
    dispatch({
        type: CLEAR_USERS
    });
}

export const clearUserDevices = () => dispatch => {
    dispatch({
        type: CLEAR_USER_DEVICES
    });
}

export const clearUserPermissions = () => dispatch => {
    dispatch({
        type: CLEAR_USER_PERMISSIONS
    });
}

export const clearLastUserActivities = () => dispatch => {
    dispatch({
        type: CLEAR_LAST_USER_ACTIVITIES
    });
}

export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setPage(1, reload)); 

    if (replaceUrl) {
        window.history.replaceState(null, '', replaceUrl);
    }
    window.scrollTo(0, 0);
}

// GETY
export const getUsers = () => async (dispatch, getState) => {

    dispatch(isLoading(true));

    const { users: { params, filters, query, queryFields } } = getState();
    const tmpParams = Object.assign({}, params, filters);

    // FILTRY PO QUERY I QUERYFIELDS
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }

    tmpParams['joinRole'] = true

    const result = await Api.get(`admin/user`, tmpParams);

    if (result && result.success) {
        dispatch(setUsers(result.documents, result.total));
    }

    dispatch(isLoading(false));
}

export const getUser = (userId) => async (dispatch) => {
    dispatch(isLoading(true));

    const result = await Api.get(`admin/user/${userId}/?joinRole=true&joinCustomer=true`);

    if (result && result.success) {
        dispatch(setUser(result.document));
    }

    dispatch(isLoading(false));
}

export const getLastUserActivities = (userId) => async dispatch => {
    dispatch(isLoading(true));

    const params = {
        conservator: userId,
        "dateFrom[gte]": moment().startOf('day').format("X")
    }

    const result = await Api.get(`service/service`, params);

    if (result && result.success) {
        dispatch(setLastUserActivities(result.documents));
    }

    dispatch(isLoading(false));
}

export const getUserDevices = (userId) => async dispatch => {
    dispatch(isLoading(true));

    const params = {
        conservator: userId
    }

    const result = await Api.get(`device/device`, params);

    if (result && result.success) {
        dispatch(setUserDevices(result.documents));
    }

    dispatch(isLoading(false));
}

export const getUserPermissions = (roleId) => async dispatch => {
    dispatch(isLoading(true));

    const result = await Api.get(`admin/role/${roleId}`);

    if (result && result.success) {
        dispatch(setUserPermissions(result.document));
    }

    dispatch(isLoading(false));
}

// CREATE
export const createUser = async (data, dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.post("admin/user", data);
    dispatch(isLoading(false));
    return result;
}

export const editUser = async (userId, data, dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.put(`admin/user/${userId}`, data);
    dispatch(isLoading(false));
    return result;
}

export const saveUser = async (userId, data, dispatch) => {
    if (userId) {
        return editUser(userId, data, dispatch);
    } else {
        return createUser(data, dispatch);
    }
}

// REMOVE
export const deleteUser = (userId) => async (dispatch) => {
    dispatch(isLoading(true));
    const result = await Api.delete(`admin/user/${userId}`);

    dispatch(getUsers());
    dispatch(isLoading(false));

    return result;
}