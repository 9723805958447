import {
    SET_WAREHOUSES,
    CLEAR_WAREHOUSES,
    SET_WAREHOUSE,
    CLEAR_WAREHOUSE,
    SET_WAREHOUSE_COMPONENTS,
    SET_WAREHOUSE_HISTORY,
    SET_QUERY,
    SET_OFFSET,
    SET_LIMIT,
    SET_FILTERS,
    SET_PAGE,
    CLEAR_QUERY,
    CLEAR_LIMIT,
    CLEAR_FILTERS,
    SET_ACTIVE_TAB
} from '../actions/warehouses.actions';

const defaultState = {
    warehouse: null,
    warehouses: [],
    components: [],
    history: [],
    total: 0,
    page: 1,
    query: '',
    queryFields: [
        'name[or]',
        'number[or]',
        'warehouseTo.name[or]',
        'components.name[or]'
    ],
    params: {
        limit: 15,
        offset: 0,
    },
    filters: {
        'sort[name]': 1
    },
    activeTab: "components"
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_WAREHOUSES:
            return {
                ...state,
                warehouses: action.warehouses,
                total: action.total
            }
        case CLEAR_WAREHOUSES:
            return {
                ...state,
                warehouses: [],
                total: 0
            }
        case SET_WAREHOUSE:
            return {
                ...state,
                warehouse: action.warehouse
            }
        case CLEAR_WAREHOUSE:
            return {
                ...state,
                warehouse: null,
                history: [],
                components: []
            }
        case SET_WAREHOUSE_COMPONENTS:
            return {
                ...state,
                components: action.components,
                total: action.total
            }
        case SET_WAREHOUSE_HISTORY:
            return {
                ...state,
                history: action.history,
                total: action.total
            }
        case SET_ACTIVE_TAB: {
            return {
                ...state,
                activeTab: action.tab
            }
        }
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            }
        case SET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.offset
                }
            }
        case SET_LIMIT:
            return {
                ...state,
                params: {
                    ...state.params,
                    limit: action.limit
                }
            }
        case CLEAR_LIMIT:
            return {
                ...state,
                params: {
                    ...defaultState.params,
                    limit: defaultState.params.limit,
                }
            }
        case SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        case CLEAR_QUERY:
            return {
                ...state,
                query: ''
            }
        case SET_FILTERS:
            return {
                ...state,
                filters: action.filters
            }
        case CLEAR_FILTERS:
            return {
                ...state,
                filters: {}
            }
        default:
            return state;

    }
}