import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import ServiceFilters from './ServiceFilters.component';

import AclManager from '../../application/services/aclManager';

import ServicesListCardComponent from './ServicesListCard.component';
import Pagination from '../../assets/components/Pagination.component';
import SearchComponent from '../../assets/components/Search.component'
import ConfirmModal from '../../assets/components/ConfirmModal.component';
import SortListComponent from '../../assets/components/SortList.component';

import AddIcon from '../../assets/gfx/dodaj.svg';

const ServicesComponent = ({ services, deleteService, page, total, limit, setPage, setLimit, setQuery, setFilters }) => {

   const [serviceToDelete, setServiceToDelete] = useState(null)
   const fields = [
      { id: 'number', name: 'Nr. zlecenia' },
      { id: 'type', name: 'Typ zlecenia' },
      { id: 'device.name', name: 'Urządzenie' },
      { id: 'dateFrom', name: 'Data rozpoczęcia' },
      { id: 'dateTo', name: 'Data zakończenia' },
      { id: '', name: 'Czas realizacji' },
      { id: 'conservator.name', name: 'Konserwator' },
      { id: '', name: 'Stan' } 
   ]

   const handleDelete = () => {
      deleteService(_.get(serviceToDelete, '_id'));
      setServiceToDelete(null);
   }

   const handleCancelConfirm = () => {
      setServiceToDelete(null);
   }

   return (
      <Fragment>
         {serviceToDelete && <ConfirmModal
            id="confirmDelete"
            label="Czy na pewno chcesz usunąć zlecenie?"
            positionName={_.get(serviceToDelete, 'number')}
            onConfirm={handleDelete}
            onCancel={handleCancelConfirm}
         />}
         <div className="row mb-2">
            <div className="col-12 col-md-12 pt-3 d-flex btns-filtry">
               <h3 className="mt-0 mb-2 w-100">Lista zleceń</h3>
            </div>
         </div>
         <div className="container-fluid p-0">
            <div className="row p-0">
               <div className="col-12 col-md-6 ">
                  {AclManager.isAllowedMethod('service/service', 'create') && <Link to="/service">
                     <button type="submit" className="btn btn-primary mb-3 float-md-left mr-3">Dodaj zlecenie  <img src={AddIcon} /></button>
                  </Link>}
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                  <SearchComponent setQuery={setQuery} />
                  <ServiceFilters setFilters={setFilters} />
               </div>
            </div>
         </div>
         <div className="table-responsive ramkatabeli tabela-left mb-5">
            <table id="zlecenia" className="table table-hover rounded-10 tabela-zlecenia">
               <thead>
                  <SortListComponent fields={fields} defaultSortBy='dateFrom' setFilters={setFilters} defaultSortType={-1} />
               </thead>
               <tbody>
                  {!_.isEmpty(services) ? services.map((service, index) => {
                     return <ServicesListCardComponent key={index} service={service} index={index + 1} handleDelete={(service) => setServiceToDelete(service)} />
                  }) : <tr><td colSpan="8" className="text-center">Brak zleceń</td></tr>}
               </tbody>
            </table>
         </div>
         <Pagination totalCount={total || 0} route={"/services"} page={parseInt(page)} setPage={setPage} limit={limit} setLimit={setLimit} />
      </Fragment>
   )
}

ServicesComponent.propTypes = {
   services: PropTypes.array.isRequired,
   page: PropTypes.number.isRequired,
   total: PropTypes.number.isRequired,
   limit: PropTypes.number.isRequired,
   setPage: PropTypes.func.isRequired,
   setLimit: PropTypes.func.isRequired,
   setQuery: PropTypes.func.isRequired,
   setFilters: PropTypes.func.isRequired,
   deleteService: PropTypes.func.isRequired,
   setFilters: PropTypes.func.isRequired
};

export default ServicesComponent;