import Api from '../../application/services/api';
import { isLoading } from '../../application/actions/application.actions';
import _ from 'lodash';

export const NAMESPACE = "CUSTOMERS";

// AKCJE SETUJACE
export const SET_CUSTOMER = `SET_CUSTOMER_${NAMESPACE}`;
export const SET_CUSTOMERS = `SET_CUSTOMERS_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;
export const SET_CUSTOMER_DEVICES = `SET_CUSTOMER_DEVICES_${NAMESPACE}`;
export const SET_CUSTOMER_SERVICES = `SET_CUSTOMER_SERVICES_${NAMESPACE}`;
export const SET_CUSTOMER_CONTRACTS = `SET_CUSTOMER_CONTRACTS_${NAMESPACE}`;
export const SET_CUSTOMER_EXPENSES = `SET_CUSTOMER_EXPENSES_${NAMESPACE}`;
export const SET_ACTIVE_TAB = `SET_ACTIVE_TAB_${NAMESPACE}`;

// AKCJE CLEARUJACE
export const CLEAR_CUSTOMER = `CLEAR_CUSTOMER_${NAMESPACE}`;
export const CLEAR_CUSTOMERS = `CLEAR_CUSTOMERS_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEARY_QUERY_${NAMESPACE}`;
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;
export const CLEAR_CUSTOMER_DEVICES = `CLEAR_CUSTOMER_DEVICES_${NAMESPACE}`;
export const CLEAR_CUSTOMER_SERVICES = `CLEAR_CUSTOMER_SERVICES_${NAMESPACE}`;
export const CLEAR_CUSTOMER_CONTRACTS = `CLEAR_CUSTOMER_CONTRACTS_${NAMESPACE}`;
export const CLEAR_CUSTOMER_EXPENSES = `CLEAR_CUSTOMER_EXPENSES_${NAMESPACE}`;
export const CLEAR_ACTIVE_TAB = `CLEAR_ACTIVE_TAB_${NAMESPACE}`;

// METODY SETUJACE
export const setCustomer = (customer) => dispatch => {
    dispatch({ type: SET_CUSTOMER, customer });
}

export const setCustomers = (customers, total) => dispatch => {
    dispatch({ type: SET_CUSTOMERS, customers, total });
}

export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/customers', true));
}

export const setOffset = (offset) => dispatch => {
    dispatch({ type: SET_OFFSET, offset: offset });
}

export const setLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setPage(1, true));
}

export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/customers', true));
}

export const setCustomerDevices = (customerDevices, total) => (dispatch) => {
    dispatch({ type: SET_CUSTOMER_DEVICES, customerDevices, total });
}

export const setCustomerServices = (customerServices, total) => (dispatch) => {
    dispatch({ type: SET_CUSTOMER_SERVICES, customerServices, total });
}

export const setCustomerContracts = (customerContracts, total) => (dispatch) => {
    dispatch({ type: SET_CUSTOMER_CONTRACTS, customerContracts, total });
}

export const setCustomerExpenses = (customerExpenses, total) => (dispatch) => {
    dispatch({ type: SET_CUSTOMER_EXPENSES, customerExpenses, total });
}

export const setActiveTab = (activeTabName) => (dispatch, getState) => {

    const { customers: { customer } } = getState();

    dispatch({ type: SET_ACTIVE_TAB, activeTabName });
    dispatch(clearTabPage(`/customers/${customer._id}`, true));

}

export const setPage = (page, reload = false) => (dispatch, getState) => {

    const { customers: { params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    reload && dispatch(getCustomers());

    window.scrollTo(0, 0);
}

export const setTabPage = (page, customerId, reload = false) => (dispatch, getState) => {

    const { customers: { activeTab, params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    if (reload && activeTab && activeTab != '') {
        switch (activeTab) {
            case 'devices':
                dispatch(getCustomerDevices(customerId));
                break;
            case 'services':
                dispatch(getCustomerServices());
                break;
            case 'contracts':
                dispatch(getCustomerContracts());
                break;
            case 'expenses':
                dispatch(getCustomerExpenses());
                break;
        }
    }
}

// METODY CLEARUJACE
export const clearCustomer = () => dispatch => {
    dispatch({ type: CLEAR_CUSTOMER });
}
export const clearCustomers = () => dispatch => {
    dispatch({ type: CLEAR_CUSTOMERS });
}
export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearCustomerDevices = () => dispatch => {
    dispatch({ type: CLEAR_CUSTOMER_DEVICES });
}
export const clearCustomerServices = () => dispatch => {
    dispatch({ type: CLEAR_CUSTOMER_SERVICES });
}
export const clearCustomerContracts = () => dispatch => {
    dispatch({ type: CLEAR_CUSTOMER_CONTRACTS });
}
export const clearCustomerExpenses = () => dispatch => {
    dispatch({ type: CLEAR_CUSTOMER_EXPENSES });
}
export const clearActiveTab = () => dispatch => {
    dispatch({ type: CLEAR_ACTIVE_TAB });
}
export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie

    dispatch(setOffset(0));
    dispatch(setPage(1, reload));

    if (replaceUrl) {
        window.history.replaceState(null, '', '/customers');
    }

    window.scrollTo(0, 0);
}

export const clearTabPage = (replaceUrl = false, reload) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie

    dispatch(setOffset(0));
    dispatch(setTabPage(1, null, reload));

    if (replaceUrl) {
        window.history.replaceState(null, '', replaceUrl);
    }

    window.scrollTo(0, 0);
}

// POBIERANIE KLIENTÓW
export const getCustomers = () => async (dispatch, getState) => {

    dispatch(isLoading(true));

    const { customers: { params, filters, query, queryFields } } = getState();
    const tmpParams = Object.assign({}, params, filters);

    // FILTRY PO QUERY I QUERYFIELDS
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }

    const result = await Api.get("customer/customer", tmpParams);

    if (result && result.success) {
        dispatch(setCustomers(result.documents, result.total));
    }

    dispatch(isLoading(false));
}

// POBIERANIE KLIENTA POJEDYNCZEGO
export const getCustomer = (id) => async (dispatch, getState) => {

    dispatch(isLoading(true));
    const { customers: { params } } = getState();
    const result = await Api.get(`customer/customer/${id}`, params);

    if (result && result.success) {
        dispatch(setCustomer(result.document));
    }

    dispatch(isLoading(false));
}

// METODA ZAPISUJĄCA KLIENTA
export const saveCustomer = (customer) => async dispatch => {
    if (!customer._id) {
        return createCustomer(customer, dispatch); // dodanie klienta
    } else {
        return editCustomer(customer, dispatch); // edycja klienta
    }
}

// DODANIE KLIENTA
const createCustomer = async (customer, dispatch) => {

    dispatch(isLoading(true));
    const result = await Api.post('customer/customer', customer);
    dispatch(isLoading(false));

    return result;
}

// EDYCJA KLIENTA
const editCustomer = async (customer, dispatch) => {
    dispatch(isLoading(true));
    const result = await Api.put('customer/customer', customer, customer._id);
    dispatch(isLoading(false));

    return result;
}

// METODA USUWAJĄCA KLIENTA
export const deleteCustomer = (customerId) => async (dispatch) => {

    dispatch(isLoading(true));
    const result = await Api.delete('customer/customer', customerId);

    dispatch(getCustomers());
    dispatch(isLoading(false));

    return result;
}

// POBIERANIE URZĄDZEŃ KLIENTA
export const getCustomerDevices = (customerId) => async (dispatch, getState) => {

    dispatch(isLoading(true));
    const { customers: { customer, params } } = getState();

    const tmpParams = Object.assign({}, {
        customer: customerId || _.get(customer, '_id', 0),
        select: 'name,conservator,customer',
        joinValues: true
    }, params);

    const result = await Api.get("device/device", tmpParams);

    if (result && result.success) {
        dispatch(setCustomerDevices(result.documents, result.total));
    }
    dispatch(isLoading(false));
}

// POBIERANIE ZLECEŃ KLIENTA
export const getCustomerServices = () => async (dispatch, getState) => {

    dispatch(isLoading(true));

    const { customers: { customer, params } } = getState();

    const tmpParams = Object.assign({}, {
        'customer.customerId': _.get(customer, '_id', 0),
        // select: 'name,conservator',
        joinValues: true
    }, params);

    const result = await Api.get("service/service", tmpParams);

    if (result && result.success) {
        dispatch(setCustomerServices(result.documents, result.total));
    }
    dispatch(isLoading(false));
}

// POBIERANIE UMÓW KLIENTA
export const getCustomerContracts = () => async (dispatch, getState) => {

    dispatch(isLoading(true));

    const { customers: { customer, params } } = getState();

    const tmpParams = Object.assign({}, {
        'customer.customerId': _.get(customer, '_id', 0),
        select: 'number,conclusionDate,expiryDate,usedComponents,reactionTime,description'
    }, params);

    const result = await Api.get("customer/contract", tmpParams);

    if (result && result.success) {
        dispatch(setCustomerContracts(result.documents, result.total));
    }
    dispatch(isLoading(false));
}

// POBIERANIE KOSZTORYSÓW KLIENTA
export const getCustomerExpenses = () => async (dispatch, getState) => {

    dispatch(isLoading(true));

    const { customers: { customer, params } } = getState();

    const tmpParams = Object.assign({}, {
        'customer.customerId': _.get(customer, '_id', 0),
        select: 'number,name,createdAt,customer,usedComponents,executionTime,totalCost',
    }, params);

    const result = await Api.get("expense/expense", tmpParams);

    if (result && result.success) {
        dispatch(setCustomerExpenses(result.documents, result.total));
    }
    dispatch(isLoading(false));
}

// FUNKCJE DODATKOWE DO MODUŁU
export const getNipData = (nip) => async () => {
    const result = await Api.get(`customer/getNipData/${nip}`);
    return result;
}



