import React from 'react';
import { Link } from "react-router-dom";
import AclManager from '../../application/services/aclManager';
import edytujIcon from "../../assets/gfx/edytuj.svg";
import koszIcon from "../../assets/gfx/kosz.svg";
import powielIcon from "../../assets/gfx/powiel.svg";

const ComponentsListCard = ({ item, handleDelete, index }) => {
    const typeLabels = {
        "component": "Komponent",
        "subcomponent": "Część"
    };

    return (

        <tr>
            <td>{index + 1}</td>
            <td> <Link to={`/components/${item._id}`} >{item.name}</Link></td>
            <td>{typeLabels[item.type] || "Inny"}</td>
            <td>{item.componentValue} PLN</td>
            <td>
                {AclManager.isAllowedMethod('device/component', 'create') && <Link to={`/component/${item._id}/clone`} title="Powiel komponent"><img className="akcja powiel" src={powielIcon} /></Link>}
                {AclManager.isAllowedMethod('device/component', 'update') && <Link to={`/component/${item._id}`} title="Edytuj komponent"><img className="akcja edytuj" src={edytujIcon} /></Link>}
                {AclManager.isAllowedMethod('device/component', 'delete') && <span onClick={() => handleDelete(item)} data-toggle="modal" data-target="#confirmDelete" title="Usuń"><img className="akcja usun" src={koszIcon} /></span>}
            </td>
        </tr>
    )
}

export default ComponentsListCard;