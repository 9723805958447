import React from 'react';

const TextInput = ({
    id,
    label,
    type,
    onChange,
    value,
    errors,
    sideLabel,
    customClass,
    placeholder,
    disabled,
    isRequired
}) => {

    const renderErrors = () => {
        if (!errors) return;

        if (Array.isArray(errors)) {
            return errors.map((e, i) => {
                return <div key={i} className="errormsg">{e}</div>
            })
        }

        if (typeof errors === 'string') {
            return <div className="errormsg">{errors}</div>
        }
    }

    return (
        <React.Fragment>
            <div className={`${customClass ? customClass : 'form-group d-flex flex-wrap w-100 flex-column justify-content-end'} ${errors ? 'error' : ''}`}>
                {label && <label>{label} {isRequired && <span className="is-required">*</span>}</label>}
                {sideLabel && <div className="input-group">
                    <input className="form-control"
                        disabled={disabled}
                        type={type}
                        placeholder={placeholder || ""}
                        autoComplete="off"
                        onChange={(e) => (id) ? onChange(id, e.target.value) : onChange(e.target.value)}
                        value={value}
                    /><div className="input-group-append">
                        <span className="input-group-text">{sideLabel}</span>
                    </div>
                </div>}
                {!sideLabel && <input className="form-control"
                    disabled={disabled}
                    type={type}
                    placeholder={placeholder || ""}
                    onChange={(e) => (id) ? onChange(id, e.target.value) : onChange(e.target.value)}
                    value={value}
                />}
                {renderErrors()}
            </div>
        </React.Fragment>
    )
}

export default TextInput;