import _ from "lodash";
import React from 'react';
import { Link } from "react-router-dom";
import edytujIcon from "../../assets/gfx/edytuj.svg";
import koszIcon from "../../assets/gfx/kosz.svg";
import powielIcon from "../../assets/gfx/powiel.svg";
import AclManager from '../../application/services/aclManager';

const ServiceRangesListCard = ({ item, handleDelete, index }) => {
    const intervalNames = {
        1: "1 miesiąc",
        3: "3 miesiące",
        6: "6 miesięcy",
        12: "12 miesięcy"
    }
    return (
        <tr>
            <td>{index + 1}</td>
            <td> <Link to={`/serviceRanges/${item._id}`} >{item.name}</Link></td>
            <td>{intervalNames[item.interval] || `${item.interval} miesięcy` }</td>
            <td>{_.size(item.activities)}</td>
            <td>
                {AclManager.isAllowedMethod('device/serviceRange', 'create') && <Link to={`/serviceRange/${item._id}/clone`} title="Powiel komponent"><img className="akcja powiel" src={powielIcon} /></Link>}
                {AclManager.isAllowedMethod('device/serviceRange', 'update') && <Link type="button" to={`/serviceRange/${item._id}`} title="Edytuj komponent" ><img className="akcja edytuj" src={edytujIcon} /></Link>}
                {AclManager.isAllowedMethod('device/serviceRange', 'delete') && <span onClick={() => handleDelete(item)} data-toggle="modal" data-target="#confirmDelete" title="Usuń"><img className="akcja usun" src={koszIcon} /></span>}
            </td>
        </tr>
    )
}

export default ServiceRangesListCard;