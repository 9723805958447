import { IS_LOADING } from '../actions/test.actions';

const defaultState = {
    isLoading: false,
    testowyProps: 'wyswietlam testowego propsa xd'
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        default:
            return state;

    }
}