import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getServiceRange, clearServiceRange } from '../actions/serviceRanges.actions';
import ServiceRangeDetails from '../components/serviceRangeDetails.component';

const ServiceRangeDetailsContainer = (props) => {
    const { getServiceRange, clearServiceRange, serviceRange } = props;
    let { id } = useParams();
    useEffect(() => {
        
        if (id) {
            getServiceRange(id);
        }
        return () => {
            clearServiceRange();
        }
    }, [getServiceRange, clearServiceRange])

    return (
        <Fragment>
            {serviceRange && <ServiceRangeDetails
                {...props}
            />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    serviceRange: store.serviceRanges.serviceRange
});

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceRange: (id) => dispatch(getServiceRange(id)),
        clearServiceRange: () => dispatch(clearServiceRange())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRangeDetailsContainer);
