import {
   SET_USER,
   SET_USERS,
   SET_USER_DEVICES,
   SET_USER_PERMISSIONS,
   SET_LAST_USER_ACTIVITIES,
   SET_QUERY,
   SET_OFFSET,
   SET_LIMIT,
   SET_FILTERS,
   SET_PAGE,
   CLEAR_USER,
   CLEAR_USERS,
   CLEAR_USER_DEVICES,
   CLEAR_USER_PERMISSIONS,
   CLEAR_LAST_USER_ACTIVITIES,
   CLEAR_QUERY,
   CLEAR_LIMIT,
   CLEAR_FILTERS
} from '../actions/users.actions';

const defaultState = {
   isLoading: false,
   user: {},
   users: [],
   userDevices: [],
   userPermissions: [],
   lastUserActivities: [],
   query: '',
   queryFields: [
      'name[or]',
   ],
   params: {
      limit: 15,
      offset: 0,
   },
   filters: {
      'sort[name]': 1
   },
   total: 0,
   page: 1
}

export default (state = defaultState, action) => {
   switch (action.type) {
      // USERS
      case SET_USERS:
         return {
            ...state,
            users: action.users,
            total: action.total
         };
      case CLEAR_USERS:
         return {
            ...state,
            users: [],
            total: 0
         }
      // USER
      case SET_USER:
         return {
            ...state,
            user: action.user
         };
      case CLEAR_USER:
         return {
            ...state,
            user: {},
         }
      // USER DEVICES
      case SET_USER_DEVICES:
         return {
            ...state,
            userDevices: action.userDevices
         }
      case CLEAR_USER_DEVICES:
         return {
            ...state,
            userDevices: []
         }
      // USER PERMISSIONS
      case SET_USER_PERMISSIONS:
         return {
            ...state,
            userPermissions: action.userPermissions
         }
      case CLEAR_USER_PERMISSIONS:
         return {
            ...state,
            userPermissions: []
         }
      // USER LAST ACTIVITIES
      case SET_LAST_USER_ACTIVITIES:
         return {
            ...state,
            lastUserActivities: action.lastUserActivities
         };
      case CLEAR_LAST_USER_ACTIVITIES:
         return {
            ...state,
            lastUserActivities: []
         }
      // QUERY
      case SET_QUERY:
         return {
            ...state,
            query: action.query
         }
      case CLEAR_QUERY:
         return {
            ...state,
            query: ''
         }
      // PAGE
      case SET_PAGE: // TU NIE MA CLEAR, robi to clearPage z akcji
         return {
            ...state,
            page: action.page
         }
      // OFFSET
      case SET_OFFSET: // TU NIE MA CLEAR, robi to clearPage z akcji
         return {
            ...state,
            params: {
               ...state.params,
               offset: action.offset
            }
         }
      // LIMIT
      case SET_LIMIT:
         return {
            ...state,
            params: {
               ...state.params,
               limit: action.limit
            }
         }
      case CLEAR_LIMIT:
         return {
            ...state,
            params: {
               ...defaultState.params,
               limit: defaultState.params.limit,
            }
         }
      // FILTERS
      case SET_FILTERS:
         return {
            ...state,
            filters: action.filters
         }
      case CLEAR_FILTERS:
         return {
            ...state,
            filters: {}
         }
      default:
         return state;
   }
}