import Api from '../../application/services/api';
import { isLoading } from "../../application/actions/application.actions";

const NAMESPACE = "COMPONENTS";

export const SET_COMPONENTS = `SET_COMPONENTS_${NAMESPACE}`;
export const SET_COMPONENT = `SET_COMPONENT_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;

export const CLEAR_COMPONENTS = `CLEAR_COMPONENTS_${NAMESPACE}`;
export const CLEAR_COMPONENT = `CLEAR_COMPONENT_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEARY_QUERY_${NAMESPACE}`;
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;

// metody setujące
export const setComponent = (component) => dispatch => {
    dispatch({ type: SET_COMPONENT, component });
}
export const setComponents = (components, total) => dispatch => {
    dispatch({ type: SET_COMPONENTS, components, total });
}
export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/components', true));
}
export const setOffset = (offset) => dispatch => {
    dispatch({ type: SET_OFFSET, offset: offset });
}

export const setLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setPage(1, true));
}
export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/components', true));
}
export const setPage = (page, reload = false) => (dispatch, getState) => {

    const { components: { params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    reload && dispatch(getComponents());

    window.scrollTo(0, 0);
}

// metody clearujące
export const clearComponent = () => dispatch => {
    dispatch({ type: CLEAR_COMPONENT });
}
export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setPage(1, reload));
    if (replaceUrl) {
        window.history.replaceState(null, '', replaceUrl);
    }
    window.scrollTo(0, 0);
}
export const clearComponents = () => dispatch => {
    dispatch({ type: CLEAR_COMPONENTS });
}

export const getComponent = (id) => async (dispatch, getState) => {
    dispatch(isLoading(true));
    let result = await Api.get(`device/component/${id}`);
    if (result && result.success) {
        dispatch(setComponent(result.document));
    }
    dispatch(isLoading(false));
}

export const getComponents = () => async (dispatch, getState) => {
    const { components: { params, filters, query, queryFields } } = getState();
    dispatch(isLoading(true));
    const tmpParams = Object.assign({}, params, filters);
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }

    const result = await Api.get("device/component", tmpParams);
    if (result && result.success) {
        dispatch(setComponents(result.documents, result.total));
    }
    dispatch(isLoading(false));
}

export const saveComponent = (data) => async (dispatch) => {
    if (data._id) {
        return editComponent(data, dispatch);
    } else {
        return createComponent(data, dispatch);
    }
}

export const editComponent = async (data, dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.put("device/component", data, data._id);
    dispatch(isLoading(false));
    return result;
}

export const createComponent = async (data, dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.post("device/component", data, true);
    dispatch(isLoading(false));
    return result;
}

export const deleteComponent = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.delete("device/component", id, true);
    dispatch(isLoading(false));
    dispatch(getComponents());
    return result;
}
