import React from 'react';
import PropTypes from 'prop-types';

const ConfirmModal = ({
    label,
    positionName,
    onConfirm,
    onCancel
}) => {
    return (
        <div className="modal fade" id="confirmDelete" tabIndex="-1" role="dialog" aria-labelledby="confirmDelete"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="confirmDelete">{label}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Anuluj" onClick={() => onCancel()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {positionName}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-dark" data-dismiss="modal" onClick={() => onCancel()}>Nie</button>
                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => onConfirm()}>Tak</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

ConfirmModal.propTypes = {
    label: PropTypes.string.isRequired,
    positionName: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ConfirmModal;

