import Api from '../../application/services/api';
import { isLoading } from '../../application/actions/application.actions';

export const NAMESPACE = "SETTINGS";
export const SAVE_SETTINGS = `SAVE_SETTINGS_${NAMESPACE}`;
export const SET_SETTINGS = `SET_SETTINGS_${NAMESPACE}`;

export const setSettings = (settings) => dispatch => {
    dispatch({
        type: SET_SETTINGS,
        settings
    })
}

export const getSettings = () => async dispatch => {
    dispatch(isLoading(true));
    const res = await Api.get('application/settings');

    if (res && res.success) {
        dispatch(setSettings(res.document));
    }

    dispatch(isLoading(false));
}

export const saveSettings = (form) => async dispatch => {
    dispatch(isLoading(true));
    const res = await Api.post('application/settings', form);
    dispatch(isLoading(false));

    if (res.success) {
        return { success: true }
    } else {
        if (res.errors) {
            return { success: false, errors: res.errors }
        }
    }
}
