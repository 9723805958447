import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import {
    getWarehouse,
    clearWarehouse,
    getWarehouseComponents,
    getWarehouseHistory,
    setTabPage,
    setTabLimit,
    setTabQuery,
    setFilters,
    clearTabPage,
    clearQuery,
    clearLimit,
    clearFilters,
    setActiveTab
} from '../actions/warehouses.actions';
import WarehouseDetails from '../components/WarehouseDetails.component';

const WarehouseDetailsContainer = (props) => {

    let { page, id } = useParams();

    useEffect(() => {
        props.setPage(page || 1);
        if (id) {
            props.getWarehouse(id);
        }
        return () => {
            props.clearWarehouse();
            props.clearPage();
            props.clearQuery();
            props.clearLimit();
            props.clearFilters();
        }
    }, [id])

    return (
        <Fragment>
            {props.warehouse && <WarehouseDetails
                {...props}
            />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    warehouse: store.warehouses.warehouse,
    components: store.warehouses.components,
    history: store.warehouses.history,
    total: store.warehouses.total,
    page: store.warehouses.page,
    limit: store.warehouses.params.limit
});

const mapDispatchToProps = (dispatch) => {
    return {
        getWarehouse: (id) => dispatch(getWarehouse(id)),
        clearWarehouse: () => dispatch(clearWarehouse()),
        getWarehouseComponents: (id) => dispatch(getWarehouseComponents(id)),
        getWarehouseHistory: (id) => dispatch(getWarehouseHistory(id)),
        setPage: (page) => dispatch(setTabPage(page)),
        setLimit: (limit) => dispatch(setTabLimit(limit)),
        setQuery: (query) => dispatch(setTabQuery(query)),
        setFilters: (filtersData) => dispatch(setFilters(filtersData)),
        clearPage: () => dispatch(clearTabPage()),
        clearQuery: () => dispatch(clearQuery()),
        clearLimit: () => dispatch(clearLimit()),
        clearFilters: () => dispatch(clearFilters()),
        setActiveTab: (tab) => dispatch(setActiveTab(tab))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseDetailsContainer);
