import Api from '../../application/services/api';
import { isLoading } from "../../application/actions/application.actions";

const NAMESPACE = "SERVICE_RANGES";
export const SET_SERVICE_RANGES = `SET_SERVICE_RANGES_${NAMESPACE}`;
export const CLEAR_SERVICE_RANGES = `CLEAR_SERVICE_RANGES_${NAMESPACE}`;
export const SET_SERVICE_RANGE = `SET_SERVICE_RANGE_${NAMESPACE}`;
export const CLEAR_SERVICE_RANGE = `CLEAR_SERVICE_RANGE_${NAMESPACE}`;

export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;

export const CLEAR_QUERY = `CLEARY_QUERY_${NAMESPACE}`;
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;

// metody setujące
export const setServiceRange = (serviceRange) => dispatch => {
    dispatch({ type: SET_SERVICE_RANGE, serviceRange });
}
export const setServiceRanges = (serviceRanges, total) => dispatch => {
    dispatch({ type: SET_SERVICE_RANGES, serviceRanges, total });
}
export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/serviceRanges', true));
}
export const setOffset = (offset) => dispatch => {
    dispatch({ type: SET_OFFSET, offset: offset });
}
export const setLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setPage(1, true));
}
export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/serviceRanges', true));
}
export const setPage = (page, reload = false) => (dispatch, getState) => {

    const { serviceRanges: { params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    reload && dispatch(getServiceRanges());
    window.scrollTo(0, 0);
}

// metody clearujace
export const clearServiceRange = () => dispatch => {
    dispatch({ type: CLEAR_SERVICE_RANGE });
}
export const clearServiceRanges = () => dispatch => {
    dispatch({ type: CLEAR_SERVICE_RANGES });
}
export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setPage(1, reload));
    if (replaceUrl) {
        window.history.replaceState(null, '', replaceUrl);
    }
    window.scrollTo(0, 0);
}

export const getServiceRange = (id) => async (dispatch, getState) => {
    dispatch(isLoading(true));
    let result = await Api.get(`device/serviceRange/${id}`);
    if (result && result.success) {
        dispatch(setServiceRange(result.document));
    }
    dispatch(isLoading(false));
}

export const getServiceRanges = () => async (dispatch, getState) => {
    const { serviceRanges: { params, filters, query, queryFields } } = getState();
    dispatch(isLoading(true));
    const tmpParams = Object.assign({}, params, filters);
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }

    const result = await Api.get("device/serviceRange", tmpParams);
    if (result && result.success) {
        dispatch(setServiceRanges(result.documents, result.total));
    }
    dispatch(isLoading(false));
}

export const saveServiceRange = (data) => async (dispatch) => {
    if (data._id) {
        return editServiceRange(data, dispatch);
    } else {
        return createServiceRange(data, dispatch);
    }
}

export const editServiceRange = async (data, dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.put("device/serviceRange", data, data._id);
    dispatch(isLoading(false));
    return result;
}

export const createServiceRange = async (data, dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.post("device/serviceRange", data);
    dispatch(isLoading(false));
    return result;
}

export const deleteServiceRange = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.delete("device/serviceRange", id);
    dispatch(isLoading(false));
    dispatch(getServiceRanges());
    return result;
}