import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { GithubPicker } from 'react-color';
import PlusIcon from "../../assets/gfx/plus-2.svg";

import { Formik } from 'formik';
import { userInitialState, userValidationSchema } from '../schemas/user.schema';

import TextInput from '../../assets/components/TextInput.component';
import Checkbox from '../../assets/components/Checkbox.component';
import Select from '../../assets/components/Select.component';
import Autocomplete from '../../assets/components/Autocomplete.component';
import Textarea from '../../assets/components//Textarea.component';

import AddIcon from '../../assets/gfx/dodaj.svg';

const User = ({ saveUser, user }) => {

   const [initialValues, setInitialValues] = useState(userInitialState);
   const [showColorPicker, setShowColorPicker] = useState(false)
   const history = useHistory();
   const userTypeOptions = [
      { name: "Klient", value: 'customer' },
      { name: "Pracownik", value: 'employee' },
      { name: "Administrator", value: 'admin' }
   ];

   useEffect(() => {
      _.get(user, "_id") && setInitialValues(user);
   }, [user])

   const handleAddNewCertificate = (formik) => {
      formik.setFieldValue("qualificationCertificates", _.concat(formik.values.qualificationCertificates, [{
         certificateNumber: '',
         description: ''
      }]));
   }

   const handleDeleteCertificate = (formik, index) => {
      formik.setFieldValue("qualificationCertificates", _.without(formik.values.qualificationCertificates, _.nth(formik.values.qualificationCertificates, index)));
   }

   const generatePassword = (formik) => {
      formik.setFieldValue('password', Math.random().toString(36).substring(4));
   }

   const renderUserForm = () => {
      return <Formik
         initialValues={initialValues}
         validationSchema={userValidationSchema}
         enableReinitialize={true}
         validateOnChange={false}
         validateOnBlur={false}
         onSubmit={(values, { setErrors }) => {
            if (values.customer && values.customer._id) {
               values.customer.customerId = values.customer._id
            }
            saveUser(_.get(user, '_id') ? _.get(user, '_id') : null, _.pickBy(values, _.identity)).then(res => {
               if (res.success) {
                  history.push(`/users/${res.document}`);
               } else {
                  if (res.errors) {
                     setErrors(res.errors);
                  }
               }
            });
         }}
      >
         {formik => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
               <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                     <TextInput
                        isRequired={true}
                        id="name"
                        label="Nazwa"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.name} />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                     <TextInput
                        isRequired={true}
                        id="password"
                        label="Hasło"
                        type="text"
                        value={formik.values.password || ''}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.password} />
                     <button type="button" className="btn btn-primary mb-3" onClick={() => generatePassword(formik)}>Generuj hasło</button>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-2 ">
                     <Checkbox
                        id="active"
                        label="Użytkownik aktywny"
                        value={formik.values.active}
                        onChange={(element, value) => formik.setFieldValue(element, value)}
                        errors={formik.errors.active}
                     />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                     <Select
                        isRequired={true}
                        id="type"
                        label="Typ"
                        options={userTypeOptions}
                        onChange={(element, val) => formik.setFieldValue(element, val)}
                        value={formik.values.type}
                        errors={formik.errors.type}
                     />
                  </div>
                  {formik.values.type == 'customer' && <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                     <Autocomplete
                        id="customer"
                        label="Klient"
                        url="customer/customer"
                        multi={false}
                        optionLabelField="name"
                        optionIdField="customerId"
                        onOptionSelect={formik.setFieldValue}
                        value={formik.values.customer}
                        errors={formik.errors.customer}
                        queryFields={['name']}
                        additionalParams={{ limit: 20 }}
                        prependText={null}
                        appendText={null}
                     />
                  </div>}
                  {formik.values.type != 'customer' && <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                     <Autocomplete
                        id="role"
                        label="Rola"
                        url="admin/role"
                        multi={false}
                        optionLabelField="name"
                        optionIdField="_id"
                        onOptionSelect={formik.setFieldValue}
                        value={formik.values.role}
                        errors={formik.errors.role}
                        queryFields={['name']}
                        additionalParams={{ limit: 20 }}
                     />
                  </div>}
                  <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                     <div className={`form-group d-flex flex-wrap w-100 flex-column justify-content-end ${formik.errors.color ? 'error' : ''}`}>
                        <label htmlFor="kolor-konta">Kolor konta <span className="is-required">*</span></label>
                        <div id="kolor-konta" className="usercolor-container rounded-circle" onClick={() => setShowColorPicker(true)}>
                           <div className="usercolor rounded-circle" style={{ cursor: 'pointer', backgroundColor: formik.values.color || "white" }}> </div>
                        </div>
                        {showColorPicker && <GithubPicker
                           width="212px"
                           onChangeComplete={(color) => {
                              setShowColorPicker(false)
                              formik.setFieldValue('color', color.hex);
                           }}
                        />}
                        <div className="errormsg">{formik.errors.color}</div>
                     </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                     <TextInput
                        isRequired={true}
                        id="email"
                        label="Email"
                        type="text"
                        value={formik.values.email || ''}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.email} />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                     <TextInput
                        isRequired={true}
                        id="phone"
                        label="Numer telefonu"
                        type="text"
                        value={formik.values.phone || ''}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.phone} />
                  </div>

                  <div className="col-12 col-md-4"></div>
                  {formik.values.type != 'customer' && <div className="col-12 col-md-4">
                     <Textarea
                        id="medicalExaminations"
                        rows="4"
                        label="Badania lekarskie"
                        cols="110"
                        placeholder="Badania lekarskie"
                        onChange={formik.setFieldValue}
                        value={formik.values.medicalExaminations}
                        errors={formik.errors.medicalExaminations}
                     />
                  </div>}
                  {formik.values.type != 'customer' && <div className="col-12 col-md-4">
                     <div className="form-group">
                        <label className="w-100">Świadectwa kwalifikacji</label>
                        {formik.values.qualificationCertificates && formik.values.qualificationCertificates.map((parameter, index) => {
                           return <React.Fragment key={`parameter-row-${index}`}>
                              <div className="d-flex flex-nowrap align-items-center">
                                 <TextInput
                                    isRequired={true}
                                    id={`qualificationCertificates.${index}.certificateNumber`}
                                    type="text"
                                    placeholder="Numer świadectwa"
                                    value={formik.values.qualificationCertificates[index].certificateNumber}
                                    onChange={(element, value) => formik.setFieldValue(element, value)}
                                    errors={_.get(formik, `errors.qualificationCertificates[${index}].certificateNumber`, null)}
                                 />
                                 <span className="pr-1 pl-1 mb-3">:</span>
                                 <TextInput
                                    isRequired={true}
                                    id={`qualificationCertificates.${index}.description`}
                                    type="text"
                                    placeholder="Opis"
                                    value={formik.values.qualificationCertificates[index].description}
                                    onChange={(element, value) => formik.setFieldValue(element, value)}
                                    errors={_.get(formik, `errors.qualificationCertificates[${index}].description`, null)}
                                 />
                                 <div className=" d-flex justify-content-center align-items-center pl-sm-0 mt-0 flex-fill" onClick={() => handleDeleteCertificate(formik, index)}>
                                    <div className="form-group flex-fill">
                                       <button data-toggle="modal" data-target="#confirmDelete" type="button" className="btn ml-2 btn-danger w-100">USUŃ</button>
                                    </div>
                                 </div>
                              </div>
                           </React.Fragment>
                        })}
                        <button type="button" className="btn btn-link p-0 mt-3" onClick={() => handleAddNewCertificate(formik)}>Dodaj parametr<div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5"><img className="mt-1 ml-0" src={PlusIcon} /></div></button>
                     </div>
                  </div>}
               </div>
               <button type="submit" className="btn btn-primary mb-3">Zapisz <img src={AddIcon} /></button>
            </form>
         )}
      </Formik >
   }

   return (
      <div className="dodawanie">
         <h3 className="mt-3 mb-3">{`${!user._id ? 'Dodaj nowego' : 'Edytuj'} użytkownika`}</h3>
         {renderUserForm()}
      </div>
   )
}

export default User;