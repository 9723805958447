import React from 'react';
import _ from 'lodash';

import GaleriaIcon from '../gfx/galeria.svg'
import DokumentIcon from '../gfx/dokument.svg';
import CopyToClipIcon from '../gfx/copytoclip.svg';

const SelectMediaModalCard = ({ onSelect, media: { name, src, mimetype } }) => {
    const fileType = () => {
        if (_.includes(mimetype, "image")) {
            return (
                <td><img src={GaleriaIcon} /></td>
            )
        }
        return <td><img src={DokumentIcon} /></td>
    }

    const cutFileName = (fileName, cutNumber) => {
        fileName = fileName.substring(fileName.lastIndexOf("/") + 1);

        if (fileName.length > cutNumber) {
            const sliceCount = fileName.length - cutNumber;
            fileName = `...${fileName.slice(sliceCount)}`;
        }
        return fileName;
    }


    return (
        <tr>
            {fileType()}
            <td>{cutFileName(name, 25)}</td>
            <td>{cutFileName(src, 25)}</td>
            <td className="text-right">
                <button
                    onClick={() => onSelect(src)}
                    data-dismiss="modal" className="btn btn-light mt-3 mb-1 centruj"><img src={CopyToClipIcon} /> Wstaw</button>
            </td>
        </tr>

    )
}

export default SelectMediaModalCard;