import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';

import AclManager from '../../application/services/aclManager';

import Select from '../../assets/components/Select.component';
import TextInput from '../../assets/components/TextInput.component';
import Checkbox from '../../assets/components/Checkbox.component';
import Time from '../../assets/components/Time.component';

import { settingsInitialValues, settingsValidationSchema } from '../schemas/Settings.schema';

import HolidaySettings from './HolidaySettings.component';

const VAT_VALUES = [
    { value: 23, name: "23%" },
    { value: 8, name: "8%" },
    { value: 5, name: "5%" },
    { value: 0, name: "0%" },
];

const Settings = ({ settings, saveSettings }) => {

    const [initialValues, setInitialValues] = useState(settingsInitialValues);

    useEffect(() => {
        settings && setInitialValues(settings);
    }, [settings])

    const prepareForm = (values) => {
        const form = _.cloneDeep(values);

        if (form.holidays && form.holidays.length > 0) {
            form.holidays.forEach(h => {
                h.date = moment(h.date).format("X")
            })
        }

        return form;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={settingsValidationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            onSubmit={(values, { setErrors }) => {
                const form = prepareForm(values);

                saveSettings(form).then(res => {
                    if (res.success) {
                    } else {
                        if (res.errors) {
                            setErrors(res.errors);
                        }
                    }
                });
            }}
        >
            {formik => (
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <h3 className="mt-3 mb-3 w-100">Ustawienia</h3>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <Select
                                isRequired={true}
                                id="vatRate"
                                label="Stawka VAT"
                                options={VAT_VALUES}
                                onChange={formik.setFieldValue}
                                value={_.get(formik, 'values.vatRate')}
                                errors={formik.errors.vatRate}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <TextInput
                                isRequired={true}
                                id="deliveryRate"
                                label="Stawka za dojazd"
                                type="number"
                                onChange={formik.setFieldValue}
                                value={formik.values.deliveryRate}
                                errors={formik.errors.deliveryRate}
                            />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <TextInput
                                isRequired={true}
                                id="serviceTime"
                                label="Średni czas zlecenia"
                                type="number"
                                onChange={formik.setFieldValue}
                                value={formik.values.serviceTime}
                                errors={formik.errors.serviceTime}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <Time
                                isRequired={true}
                                id="workingHours.from"
                                label="Godziny pracy (od)"
                                onChange={formik.setFieldValue}
                                value={_.get(formik, 'values.workingHours.from')}
                                errors={_.get(formik, 'errors.workingHours.from')}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <Time
                                isRequired={true}
                                id="workingHours.to"
                                label="Godziny pracy (do)"
                                onChange={formik.setFieldValue}
                                value={_.get(formik, 'values.workingHours.to')}
                                errors={_.get(formik, 'errors.workingHours.to')}
                            />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <Checkbox
                                id="addEventsToSaturday"
                                label="Wydarzenia w sobotę"
                                option="Tak"
                                onChange={formik.setFieldValue}
                                value={formik.values.addEventsToSaturday}
                                errors={formik.errors.addEventsToSaturday}
                            />
                        </div>

                        <div className="col-md-3 col-sm-12">
                            <Checkbox
                                id="addEventsToSunday"
                                label="Wydarzenia w niedzielę"
                                option="Tak"
                                onChange={formik.setFieldValue}
                                value={formik.values.addEventsToSunday}
                                errors={formik.errors.addEventsToSunday}
                            />
                        </div>

                        <HolidaySettings
                            id="holidays"
                            onChange={formik.setFieldValue}
                            holidays={formik.values.holidays}
                        />

                    </div>
                    {
                        AclManager.isAllowedMethod('application/settings', 'update') && <button type="submit" className="btn btn-primary ">Zapisz</button>
                    }
                </form>
            )}
        </Formik>

    )
}

export default Settings;