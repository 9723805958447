
import * as Yup from 'yup';

export const contractInitialState = {
    number: '',
    customer: {},
    devices: [],
    conclusionDate: '',
    expiryDate: '',
    reactionTime: '',
    indefiniteExpiryDate: true,
    description: [],
    files: [],
    uploadedFiles: []
}

export const contractValidationSchema = Yup.object().shape({
    number: Yup.string().required('Nazwa jest wymagana'),
    customer: Yup.object().shape({
        customerId: Yup.string(),
        name: Yup.string()
    }),
    devices: Yup.array().of(
        Yup.object().shape({
            deviceId: Yup.string(),
            name: Yup.string()
        })
    ),
    indefiniteExpiryDate: Yup.boolean(),
    conclusionDate: Yup.string().required('Data zawarcia jest wymagana'),
    expiryDate: Yup.string().when('indefiniteExpiryDate', {
        is: true,
        then: Yup.string(),
        otherwise: Yup.string().required('Data zakończenia jest wymagana')
    }),
    reactionTime: Yup.string().required('Czas reakcji jest wymagany'),
    description: Yup.array(),
    description: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Nazwa zakresu jest wymagana'),
            value: Yup.string().required('Opis zakresu jest wymagany')
        })
    ),
    files: Yup.array(),
    uploadedFiles: Yup.array()
});

