import {
    SET_COMPONENTS,
    CLEAR_COMPONENTS,
    SET_COMPONENT,
    CLEAR_COMPONENT,
    SET_QUERY,
    SET_OFFSET,
    SET_LIMIT,
    SET_FILTERS,
    SET_PAGE,
    CLEAR_QUERY,
    CLEAR_LIMIT,
    CLEAR_FILTERS
} from '../actions/components.actions';

const defaultState = {
    components: [],
    component: null,
    total: 0,
    page: 1,
    query: '',
    queryFields: [
        'name[or]',
        'serviceRanges.name[or]',
        'description[or]',
        'type[or]'
    ],
    params: {
        limit: 15,
        offset: 0,
    },
    filters: {
        'sort[name]': 1
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_COMPONENTS:
            return {
                ...state,
                components: action.components,
                total: action.total
            }
        case CLEAR_COMPONENTS:
            return {
                ...state,
                components: [],
                total: 0
            }
        case SET_COMPONENT:
            return {
                ...state,
                component: action.component
            }
        case CLEAR_COMPONENT:
            return {
                ...state,
                component: null
            }
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            }
        case SET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.offset
                }
            }
        case SET_LIMIT:
            return {
                ...state,
                params: {
                    ...state.params,
                    limit: action.limit
                }
            }
        case CLEAR_LIMIT:
            return {
                ...state,
                params: {
                    ...defaultState.params,
                    limit: defaultState.params.limit,
                }
            }
        case SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        case CLEAR_QUERY:
            return {
                ...state,
                query: ''
            }
        case SET_FILTERS:
            return {
                ...state,
                filters: action.filters
            }
        case CLEAR_FILTERS:
            return {
                ...state,
                filters: {}
            }
        default:
            return state;

    }
}