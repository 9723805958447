import React, { useState } from "react";
import _ from "lodash";
import placeholderImage from "../gfx/fixlifter-1.jpg"

const Carousel = ({ images }) => {

    let [activeIndex, setActiveIndex] = useState(0);

    function renderImages() {
        if (!_.isEmpty(images)) {
            return images.map((link, index) => {
                return (
                    <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={`image${index}`}>
                        <img className="d-block w-100" src={link} data-src={link} alt={`Zdjęcie ${index + 1}`} />
                    </div>
                )
            });
        } else {
            return (<div className="carousel-item active">
                <img className="d-block w-100" src={placeholderImage} alt="Zdjęcie" />
            </div>);
        }
    }

    return (
        <div className="col-sm-12 col-md-12 p-0">
            <div id="galeriaurzadzenia" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner" role="listbox">
                    {renderImages()}
                </div>
                <a className="carousel-control-prev" role="button" onClick={() => { activeIndex > 0 ? setActiveIndex(activeIndex - 1) : setActiveIndex(_.size(images) - 1) }}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Poprzedni</span>
                </a>
                <a className="carousel-control-next" role="button" onClick={() => { activeIndex < _.size(images) - 1 ? setActiveIndex(activeIndex + 1) : setActiveIndex(0) }}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Następny</span>
                </a>
            </div>
        </div>
    )
}

export default Carousel;