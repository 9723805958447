import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import AclManager from '../../application/services/aclManager';

import PropTypes from 'prop-types';
import EditIcon from "../../assets/gfx/edytuj.svg";
import DeleteIcon from "../../assets/gfx/kosz.svg";
import _ from "lodash";

const CustomersListCardComponent = ({ customer, handleDelete, index }) => {

    // const getContanctInfo = () => {

    //     const email = _.get(customer, 'contacts[0].email', null);
    //     const phone = _.get(customer, 'contacts[0].phone', null);

    //     return (email || phone) && <Fragment>
    //         {email && <a href={`mailto:${email}`}>{email}</a>}
    //         &nbsp;
    //         {phone && <a href="tel:+48600500400">{phone}</a>}
    //     </Fragment>
    // }

    return (
        <tr>
            <th scope="row">{index}</th>
            <td>
                <Link to={`/customers/${customer._id}`}>
                    {customer.name || "brak"}
                </Link>
            </td>
            <td>{customer.NIP || "brak"}</td>
            <td>{_.get(customer, 'address.formattedAddress', 'brak')}</td>
            {/* <td>{getContanctInfo()}</td> */}
            <td>
                {AclManager.isAllowedMethod('customer/customer', 'update') && <Link to={`/customer/${customer._id}`}>
                    <img className="akcja edytuj" src={EditIcon} />
                </Link>}

                {AclManager.isAllowedMethod('customer/customer', 'delete') && <span onClick={() => handleDelete(customer)} data-toggle="modal" data-target="#confirmDelete" title="Usuń klienta">
                    <img className="akcja usun" src={DeleteIcon} />
                </span>}
            </td>
        </tr>
    )
}

CustomersListCardComponent.propTypes = {
    customer: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default CustomersListCardComponent;