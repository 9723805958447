import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import UserComponent from '../components/User.component';

import { saveUser, getUser, clearUser } from '../actions/users.actions';

const UserContainer = ({ saveUser, getUser, user, clearUser }) => {
   
   const { id } = useParams();
   useEffect(() => {
      if (id) {
         getUser(id)
      }
      return () => {
         clearUser();
      }
   }, [])

   return (
      <UserComponent saveUser={saveUser} user={user} />
   );
}

const mapStateToProps = (store) => ({
   user: store.users.user
});

const mapDispatchToProps = (dispatch) => {
   return {
      saveUser: (userId, data) => saveUser(userId, data, dispatch),
      getUser: (userId) => dispatch(getUser(userId)),
      clearUser: () => dispatch(clearUser())
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
