import Api from '../../application/services/api';
import { isLoading } from '../../application/actions/application.actions';
import _ from 'lodash';

export const NAMESPACE = "INSPECTOR";

// AKCJE SETUJACE
export const SET_DEVICE_INFO = `SET_DEVICE_INFO_${NAMESPACE}`;

// AKCJE CLEARUJACE
export const CLEAR_DEVICE_INFO = `CLEAR_DEVICE_INFO${NAMESPACE}`;

// METODY SETUJACE
export const setDeviceInfo = (deviceInfo) => dispatch => {
    dispatch({ type: SET_DEVICE_INFO, deviceInfo });
}
// METODY CLEARUJACE
export const clearDeviceInfo = () => dispatch => {
    dispatch({ type: CLEAR_DEVICE_INFO });
}

// POBIERANIE INFORMACJI O URZĄDZENIU
export const getDeviceInfo = (deviceId) => async dispatch => {

    dispatch(isLoading(true));
    const result = await Api.get(`device/getInspectionData/${deviceId}`);

    if (result && result.success) {
        dispatch(setDeviceInfo(result.document));
    }

    dispatch(isLoading(false));

    return true;
}
