import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

const NoFoundComponent = ({ user, isLoading }) => {
  const history = useHistory();

  useEffect(() => {
    if (!user && !isLoading) {
      history.push('/login');
    }
  }, [user, isLoading]);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column mt-5 text-center">
      <h1>404</h1>
      <h2>STRONA NIE ISTNIEJE</h2>
    </div>
  )
}

export default NoFoundComponent;