import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import {
    getComponents,
    clearComponents,
    setPage,
    setLimit,
    setQuery,
    setFilters,
    clearPage,
    clearQuery,
    clearLimit,
    clearFilters,
    deleteComponent
} from '../actions/components.actions';
import Components from '../components/Components.component';

const ComponentsContainer = (props) => {

    let { page } = useParams();

    useEffect(() => {
        props.setPage(page || 1);
        props.getComponents();
        return () => {
            props.clearComponents();
            props.clearPage();
            props.clearQuery();
            props.clearLimit();
            props.clearFilters();
        }
    }, [])

    return (
        <Components
            {...props}
        />
    );
}

const mapStateToProps = (store) => ({
    components: store.components.components,
    total: store.components.total,
    query: store.components.query,
    page: store.components.page,
    limit: store.components.params.limit
});

const mapDispatchToProps = (dispatch) => {
    return {
        getComponents: () => dispatch(getComponents()),
        clearComponents: () => dispatch(clearComponents()),
        deleteComponent: (id) => dispatch(deleteComponent(id)),
        setPage: (page, reload) => dispatch(setPage(page, reload)),
        setLimit: (limit) => dispatch(setLimit(limit)),
        setQuery: (query) => dispatch(setQuery(query)),
        setFilters: (filtersData) => dispatch(setFilters(filtersData)),
        clearPage: () => dispatch(clearPage()),
        clearQuery: () => dispatch(clearQuery()),
        clearLimit: () => dispatch(clearLimit()),
        clearFilters: () => dispatch(clearFilters())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsContainer);
