import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash'
import PropTypes from 'prop-types';
import FiltrujIcon from '../../assets/gfx/filtruj.svg';
import TextInput from '../../assets/components/TextInput.component';
import Select from '../../assets/components/Select.component';

const mapFields = {
    'name': 'name',
    'serviceRangeNames': 'serviceRanges.name',
    'description': 'description',
    'type': 'type',
    'componentValueGte': 'componentValue[gte]',
    'componentValueLte': 'componentValue[lte]'
}

const typeOptions = [{ name: "Wszystkie", value: "" }, { name: "Komponent", value: "component" }, { name: "Część", value: "subcomponent" }];

const ComponentFiltersComponent = ({
    setFilters
}) => {

    const handleResetFilters = (resetForm) => {
        resetForm();
        setFilters({});
    }

    return (
        <React.Fragment>
            <button type="submit" className="btn btn-link text-dark" data-toggle="modal" data-target="#modal-filtruj">Filtruj <img src={FiltrujIcon} /></button>
            <div className="modal fade" id="modal-filtruj" tabIndex="-1" role="dialog" aria-labelledby="filtrowanie"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="filtrowanie">Filtry komponentu</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={{
                                    'name': '',
                                    'activitiesDescription': '',
                                    'description': '',
                                    'type': '',
                                    'componentValueGte': '',
                                    'componentValueLte': ''
                                }}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={(values) => {
                                    const filters = {};
                                    Object.keys(values).map(key => {
                                        if (values[key] !== '') {
                                            filters[mapFields[key]] = values[key];
                                        }
                                    });

                                    setFilters(filters);
                                }}
                            >
                                {formik => (
                                    <form className="input-group-login flex-column" onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <TextInput
                                                        id="name"
                                                        label="Nazwa"
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.name}
                                                        errors={formik.errors.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <TextInput
                                                        id="serviceRangeNames"
                                                        label="Nazwa zakresu czynności"
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.serviceRangeNames}
                                                        errors={formik.errors.serviceRangeNames}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <TextInput
                                                        id="description"
                                                        label="Opis"
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.description}
                                                        errors={formik.errors.description}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <Select
                                                        id="type"
                                                        label="Typ"
                                                        options={typeOptions}
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.type}
                                                        errors={formik.errors.type}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <TextInput
                                                        id="componentValueGte"
                                                        label="Wartość od"
                                                        type="number"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.componentValueGte}
                                                        errors={formik.errors.componentValueGte}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <TextInput
                                                        id="componentValueLte"
                                                        label="Wartość do"
                                                        type="number"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.componentValueLte}
                                                        errors={formik.errors.componentValueLte}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer pr-0">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                                            <button type="button" className="btn btn-secondary" onClick={() => handleResetFilters(formik.resetForm)}>Wyczyść</button>
                                            <button type="button" type="submit" className="btn btn-primary mr-0">Filtruj</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

ComponentFiltersComponent.propTypes = {
    setFilters: PropTypes.func.isRequired
};

export default ComponentFiltersComponent;