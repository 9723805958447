import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import UsersListCardComponent from './UsersListCard.component';
import UserFilters from './UserFilters.component';

import Pagination from '../../assets/components/Pagination.component';
import SearchComponent from '../../assets/components/Search.component'
import ConfirmModal from '../../assets/components/ConfirmModal.component';

import SortListComponent from '../../assets/components/SortList.component';

import AddIcon from '../../assets/gfx/dodaj.svg';

const UsersComponent = ({ users, deleteUser, page, total, limit, setPage, setLimit, setQuery, setFilters }) => {

   const [parsedUsers, setParsedUsers] = useState([]);
   const [userToDelete, setUserToDelete] = useState(null)
   const userTypes = [
      { name: 'Pracownik', value: 'employee' },
      { name: 'Klient', value: 'customer' },
      { name: 'Administrator', value: 'admin' }
   ];
   const fields = [
      { id: 'name', name: 'Nazwa' },
      { id: 'type', name: 'Typ' },
      { id: '', name: 'Rola' },
      { id: 'color', name: 'Kolor' },
      { id: '', name: 'Nr kwalifikacyjny' },
      { id: '', name: 'Kontakt' },
   ]

   useEffect(() => {
      if (!_.isEmpty(users)) {
         users.map(user => {
            if (_.get(user, 'type')) {
               let index = _.findIndex(userTypes, userType => userType.value == user.type);

               if (index > -1) {
                  user.userType = userTypes[index].name;
               }
            }

            return user
         })

         setParsedUsers(users);
      }
   }, [users]);

   const handleDelete = () => {
      deleteUser(_.get(userToDelete, '_id'));
      setUserToDelete(null);
   }

   const handleCancelConfirm = () => {
      setUserToDelete(null);
   }

   const renderUsersList = () => {
      if (parsedUsers && parsedUsers.length > 0) {
         return users.map((user, index) => <UsersListCardComponent key={index} index={index} user={user} handleDelete={(user) => setUserToDelete(user)} />)
      }
   }

   return (
      <Fragment>
         {userToDelete && <ConfirmModal
            id="confirmDelete"
            label="Czy na pewno chcesz usunąć tego użytkownika?"
            positionName={_.get(userToDelete, 'name')}
            onConfirm={handleDelete}
            onCancel={handleCancelConfirm}
         />}
         <div className="tab-content pt-3">
            <div className="tab-pane fade show active" id="users" role="tabpanel" aria-labelledby="users-tab">
               <h3 className="mt-3 mb-3">Użytkownicy</h3>
               <div className="container-fluid p-0">
                  <div className="row p-0">
                     <div className="col-12 col-md-6">
                        <Link to="/user">
                           <button type="submit" className="btn btn-primary mb-2 float-md-left mr-3">
                              Dodaj <img src={AddIcon} />
                           </button>
                        </Link>
                     </div>
                     <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                        <SearchComponent setQuery={setQuery} />
                        <UserFilters setFilters={setFilters} />
                     </div>
                  </div>
               </div>
               <div className="table-responsive tabela-left ramkatabeli ikonki mb-2">
                  <table id="uzytkownicy" className="table  table-hover rounded-10">
                     <thead>
                        <SortListComponent fields={fields} defaultSortBy='name' setFilters={setFilters} defaultSortType={1} />
                     </thead>
                     <tbody>
                        {/* users list */}
                        {renderUsersList()}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         <Pagination totalCount={total || 0} route={"/users"} page={parseInt(page)} setPage={(page) => setPage(page, true)} limit={limit} setLimit={setLimit} />
      </Fragment>
   )
}

UsersComponent.propTypes = {
   users: PropTypes.array.isRequired,
   page: PropTypes.number.isRequired,
   total: PropTypes.number.isRequired,
   limit: PropTypes.number.isRequired,
   setPage: PropTypes.func.isRequired,
   setLimit: PropTypes.func.isRequired,
   setQuery: PropTypes.func.isRequired,
   setFilters: PropTypes.func.isRequired,
   deleteUser: PropTypes.func.isRequired,
   setFilters: PropTypes.func.isRequired
};

export default UsersComponent;