import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import {
    getServiceRanges,
    clearServiceRanges,
    deleteServiceRange,
    setPage,
    setLimit,
    setQuery,
    setFilters,
    clearPage,
    clearQuery,
    clearLimit,
    clearFilters,
} from '../actions/serviceRanges.actions';
import ServiceRanges from '../components/serviceRanges.component';

const ServiceRangesContainer = (props) => {

    let { page } = useParams();

    useEffect(() => {
        props.setPage(page || 1);
        props.getServiceRanges();
        return () => {
            props.clearServiceRanges();
            props.clearPage();
            props.clearQuery();
            props.clearLimit();
            props.clearFilters();
        }
    }, [])

    return (
        <ServiceRanges
            {...props}
        />
    );
}

const mapStateToProps = (store) => ({
    serviceRanges: store.serviceRanges.serviceRanges,
    total: store.serviceRanges.total,
    query: store.serviceRanges.query,
    page: store.serviceRanges.page,
    limit: store.serviceRanges.params.limit
});

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceRanges: () => dispatch(getServiceRanges()),
        clearServiceRanges: () => dispatch(clearServiceRanges()),
        deleteServiceRange: (id) => dispatch(deleteServiceRange(id)),
        setPage: (page, reload) => dispatch(setPage(page, reload)),
        setLimit: (limit) => dispatch(setLimit(limit)),
        setQuery: (query) => dispatch(setQuery(query)),
        setFilters: (filtersData) => dispatch(setFilters(filtersData)),
        clearPage: () => dispatch(clearPage()),
        clearQuery: () => dispatch(clearQuery()),
        clearLimit: () => dispatch(clearLimit()),
        clearFilters: () => dispatch(clearFilters())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRangesContainer);
