import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import {
    getExpenses,
    deleteExpense,
    setPage,
    setLimit,
    setQuery,
    setFilters,
    clearPage,
    clearExpenses,
    clearQuery,
    clearLimit,
    clearFilters
} from '../actions/expenses.actions';
import ExpensesComponent from '../components/Expenses.component';

const ExpensesContainer = (props) => {

    let { page } = useParams();

    useEffect(() => {
        props.setPage(page && parseInt(page) || 1);
        props.getExpenses();
        return () => {
            // CZYŚCIMY WSZYSTKO PRZED WYJŚCIEM
            props.clearPage();
            props.clearExpenses();
            props.clearQuery();
            props.clearLimit();
            props.clearFilters();
        };
    }, []);

    return (
        <ExpensesComponent {...props} />
    );
}

const mapStateToProps = (store) => ({
    expenses: store.expenses.expenses,
    total: store.expenses.total,
    query: store.expenses.query,
    page: store.expenses.page,
    limit: store.expenses.params.limit
});

const mapDispatchToProps = (dispatch) => {
    return {
        setPage: (page) => dispatch(setPage(page)),
        setLimit: (limit) => dispatch(setLimit(limit)),
        setQuery: (query) => dispatch(setQuery(query)),
        setFilters: (filtersData) => dispatch(setFilters(filtersData)),
        getExpenses: () => dispatch(getExpenses()),
        deleteExpense: (expenseId) => dispatch(deleteExpense(expenseId)),
        clearPage: () => dispatch(clearPage()),
        clearExpenses: () => dispatch(clearExpenses()),
        clearQuery: () => dispatch(clearQuery()),
        clearLimit: () => dispatch(clearLimit()),
        clearFilters: () => dispatch(clearFilters())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesContainer);
