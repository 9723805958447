import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import DevicesComponent from '../components/Devices.components';
import {
    getDevices,
    setPage,
    setLimit,
    setQuery,
    setFilters,
    clearPage,
    clearDevices,
    deleteDevice,
    clearQuery,
    clearLimit,
    clearFilters
} from '../actions/devices.actions';


const DevicesContainer = (props) => {

    let { page } = useParams();

    useEffect(() => {
        props.setPage(page && parseInt(page) || 1);
        props.getDevices();
        return () => {
            props.clearDevices();
            props.clearQuery();
            props.clearLimit();
            props.clearPage();
            props.clearFilters();
        }
    }, []);

    return (
        <DevicesComponent {...props} />
    );
}

const mapStateToProps = (store) => ({
    devices: store.devices.devices,
    total: store.devices.total,
    query: store.devices.query,
    page: store.devices.page,
    limit: store.devices.params.limit,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getDevices: () => dispatch(getDevices()),
        clearDevices: () => dispatch(clearDevices()),
        deleteDevice: (id) => dispatch(deleteDevice(id)),
        setQuery: (query) => dispatch(setQuery(query)),
        clearQuery: () => dispatch(clearQuery()),
        clearLimit: () => dispatch(clearLimit()),
        clearPage: () => dispatch(clearPage()),
        clearFilters: () => dispatch(clearFilters()),
        setPage: (page, reload) => dispatch(setPage(page, reload)),
        setLimit: (limit) => dispatch(setLimit(limit)),
        setFilters: (filtersData) => dispatch(setFilters(filtersData)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesContainer);
