import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import AclManager from '../../application/services/aclManager';

import PropTypes from 'prop-types';
import EditIcon from "../../assets/gfx/edytuj.svg";
import DeleteIcon from "../../assets/gfx/kosz.svg";
import _ from "lodash";

const ContractsListCardComponent = ({ contract, handleDelete }) => {


    const getCurrentStatus = () => {

        const exipryDate = moment(contract.expiryDate).format('X');
        const exipryDateMonth = moment(contract.expiryDate).format('M');
        const exipryDateYear = moment(contract.expiryDate).format('YYYY');

        const currentDate = moment().format('X');
        const currentDateMonth = moment().format('M');
        const currentDateYear = moment().format('YYYY');

        if (currentDate > exipryDate) {
            return <div className="usercolor-container rounded-circle">
                <div className="usercolor bg-alert rounded-circle" title="Umowa się zakończyła">
                </div>
            </div>;
        } else if (currentDate < exipryDate && exipryDateYear == currentDateYear && exipryDateMonth == currentDateMonth) {
            return <div className="usercolor-container rounded-circle">
                <div className="usercolor bg-warning rounded-circle" title="Umowa kończy się w tym miesiącu">
                </div>
            </div>;
        } else if (currentDate < exipryDate) {
            return <div className="usercolor-container rounded-circle">
                <div className="usercolor bg-success rounded-circle" title="Umowa kończy się w tym miesiącu">
                </div>
            </div>;
        }
    }

    return (
        <tr>
        <td>
            {getCurrentStatus()}
        </td>
        <td>
            <Link to={`/contracts/${contract._id}`}>
                {contract.number || "brak"}
            </Link>
        </td>
        <td>{(contract.conclusionDate ? moment(contract.conclusionDate).format("DD.MM.YYYY") : null) || "brak"}</td>
        <td>{_.get(contract, 'indefiniteExpiryDate', false) ? "na czas nieokreślony" : (contract.expiryDate ? moment(contract.expiryDate).format("DD.MM.YYYY") : null) || "brak"}</td>
        <td>{contract.reactionTime + ' min' || 'brak'}</td>
        <td>{_.get(contract, 'customer.name') || 'brak'}</td>
        <td>
            {AclManager.isAllowedMethod('customer/contract', 'update') && <Link to={`/contract/${contract._id}`}>
                <img className="akcja edytuj" src={EditIcon} />
            </Link>}

            {AclManager.isAllowedMethod('customer/contract', 'delete') && <span onClick={() => handleDelete(contract)} data-toggle="modal" data-target="#confirmDelete" title="Usuń kontrakt">
                <img className="akcja usun" src={DeleteIcon} />
            </span>}
        </td>
    </tr>
    )
}

ContractsListCardComponent.propTypes = {
    contract: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired
};

export default ContractsListCardComponent;