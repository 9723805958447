import { toast } from 'react-toastify';
import _ from 'lodash';

import Api from '../../application/services/api';
import { isLoading } from '../../application/actions/application.actions';

export const NAMESPACE = "MEDIAS";
export const SET_MEDIAS = `SET_MEDIAS_${NAMESPACE}`;
export const CLEAR_MEDIAS = `CLEAR_MEDIAS_${NAMESPACE}`;
export const SET_PARAMS = `SET_PARAMS_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEAR_QUERY_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;


// METODY SETUJACE
export const setMedias = (medias, total) => dispatch => {
    dispatch({
        type: SET_MEDIAS,
        medias,
        total
    })
}

export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/medias', true));
}

export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/medias', true));
}

export const setMediaFilters = (values) => (dispatch, getState) => {
    const params = _.cloneDeep(getState().medias.params);

    (values.mimetype !== "") ? params["mimetype[in]"] = values.mimetype : delete params["mimetype[in]"];
    (values.from !== "") ? params["createdAt[gte]"] = new Date(values.from) : delete params["createdAt[gte]"];
    (values.to !== "") ? params["createdAt[lte]"] = new Date(values.to) : delete params["createdAt[lte]"];


    dispatch({
        type: SET_PARAMS,
        params
    })

    dispatch(getMedias());
}

export const setOffset = (offset) => dispatch => {
    dispatch({
        type: SET_OFFSET,
        offset: offset
    });
}

export const setPage = (page, reload = false) => (dispatch, getState) => {
    const limit = getState().medias.params.limit;

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    reload && dispatch(getMedias());
    window.scrollTo(0, 0);
}

export const setLimit = (limit) => dispatch => {
    dispatch({
        type: SET_LIMIT,
        limit: parseInt(limit)
    });
    dispatch(setPage(1));
}

// METODY CLEARUJACE
export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearMedias = () => dispatch => {
    dispatch({ type: CLEAR_MEDIAS });
}

export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setPage(1, reload));

    if (replaceUrl) {
        window.history.replaceState(null, '', replaceUrl);
    }

    window.scrollTo(0, 0);
}

// Pozostałe

export const getMedias = () => async (dispatch, getState) => {
    dispatch(isLoading(true));

    const { medias: { params, filters, query, queryFields } } = getState();
    const tmpParams = Object.assign({}, params, filters);

    // FILTRY PO QUERY I QUERYFIELDS
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }

    const res = await Api.get('media/media', tmpParams);

    if (res && res.success) {
        dispatch(setMedias(res.documents, res.total));
    }

    dispatch(isLoading(false));
}

export const saveMedia = (form) => async dispatch => {
    dispatch(isLoading(true));
    const res = await Api.post('media/media', form, true);
    dispatch(isLoading(false));

    if (res.success) {
        return { success: true }
    } else {
        if (res.errors) {
            return { success: false, errors: res.errors }
        }
    }
}

export const deleteMedia = (id) => async dispatch => {
    dispatch(isLoading(true));
    const res = await Api.delete(`media/media/${id}`);
    dispatch(isLoading(false));
    if (res.success) {
        dispatch(clearPage());
        dispatch(getMedias());
    }
}


