import React, { Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'

import { getContract, saveContract, clearContract } from '../actions/contracts.actions';
import ContractComponent from '../components/Contract.component';

const ContractContainer = (props) => {

    let { id } = useParams();

    useEffect(() => {
        id && props.getContract(id);
        return () => {
            props.clearContract();
        };
    }, []);

    return (
        <Fragment>
            {(!id || (id && props.contract)) && <ContractComponent {...props} />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    contract: store.contracts.contract
});

const mapDispatchToProps = (dispatch) => {
    return {
        getContract: (id) => dispatch(getContract(id)),
        saveContract: (contractData) => dispatch(saveContract(contractData)),
        clearContract: () => dispatch(clearContract())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractContainer);
