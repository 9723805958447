import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import permissionsList from '../../scripts/permissionsList';
import PermissionsModuleList from '../../scripts/permissionsModuleList';

import TextInput from '../../assets/components/TextInput.component';
import ConfirmModal from '../../assets/components/ConfirmModal.component';

import AddIcon from '../../assets/gfx/dodaj.svg';
import CloneIcon from '../../assets/gfx/powiel.svg';
import RemoveIcon from '../../assets/gfx/kosz.svg';
import SaveIcon from '../../assets/gfx/save.svg';

const RolesComponent = ({ roles, saveRole, errors, deleteRole }) => {

   // sparsowane permissiony z pliku z tymi pobranymi z bazy, używane do widoku
   const [parsedPermissions, setParsedPermissions] = useState([]);
   // aktualnie dodawana rola
   const [tmpRole, setTmpRole] = useState(null);
   const [tmpRoleName, setTmpRoleName] = useState('');
   const [roleToDelete, setRoleToDelete] = useState(null)

   // na wejściu łączymy role pobrane z bazy z permissionami pobranymi z pliku
   // pobieramy z roli akcje, które są aktywne i zaznaczamy je w permissionach pobranych z pliku,
   // na podstawie których tworzymy widok
   useEffect(() => {
      if (tmpRole) setTmpRole(null);
      if (tmpRoleName) setTmpRoleName('');

      let tmpPermissions = [];

      roles && roles.length > 0 && roles.map(role => {
         let tmpPermissionsList = JSON.parse(JSON.stringify(permissionsList));

         tmpPermissionsList.roleName = role.name;
         tmpPermissionsList.roleId = role._id;

         tmpPermissionsList.permissions.map(permission => {
            let index = _.findIndex(role.permissions, p => p.controller == permission.controller);

            if (index > -1) {
               let rolePermission = role.permissions[index];

               permission.actions.map(action => {
                  if (rolePermission.controller == permission.controller && _.findIndex(rolePermission.actions, el => el == action.action) > -1) {
                     action.value = true;
                  }
                  return action;
               })
            }

            return permission;
         })

         tmpPermissions.push(tmpPermissionsList);
      });

      if (tmpPermissions && tmpPermissions.length > 0) setParsedPermissions(tmpPermissions);
   }, [roles]);

   const handleDelete = () => {
      deleteRole(_.get(roleToDelete, 'roleId'));
      setRoleToDelete(null);
   }

   const handleCancelConfirm = () => {
      setRoleToDelete(null);
   }

   // podczas edycji roli (czyli zaznaczając checkbox akcji na widoku), szukamy roli na podstawie nazwy controllera,
   // robimy update value (zaznaczonego checkboxa) i wykonujemy zapis do bazy
   const onChangeRole = (module, controller, action, roleId) => {
      let roleIndex = null;
      if (roleId) {
         roleIndex = _.findIndex(roles, role => role._id == roleId);
      }

      let role = roleId && roleIndex > -1 ? roles[roleIndex] : tmpRole;
      let permissionIndex = _.findIndex(role.permissions, perm => perm.controller == controller);
      if (permissionIndex > -1) {
         role.permissions.map(permission => {
            if (permission.controller == controller) {
               let actionIndex = _.findIndex(permission.actions, act => act == action);

               if (actionIndex > -1) {
                  permission.actions = _.remove(permission.actions, act => act != action);
                  if (permission.actions.length == 0) {
                     role.permissions.splice(permissionIndex, 1);
                  }
               } else {
                  permission.actions.push(action);
               }
            }

            return permission;
         })
         // jeśli dana rola nie ma jeszcze żadnej akcji z danego controllera, to pushujemy cały 'permission'
      } else {
         role.permissions.push({
            module: module,
            controller: controller,
            actions: [action]
         })
      }

      if (role._id) {
         saveRole(role._id, role);
      } else {
         // jeśli zaznaczony checkbox jest z aktualnie dodawanej roli, to musimy nie tylko przeparsować role do zapisu,
         // ale również dane wyświetlane na widoku
         let tmpParsedPermissions = JSON.parse(JSON.stringify(parsedPermissions));

         tmpParsedPermissions && tmpParsedPermissions.map(role => {
            // w jednym czasie może być tylko jedna dodawana rola, więc updatujemy pierwszą napotkaną, która nie ma roleId
            if (!role.roleId) {
               let permissionIndex = _.findIndex(role.permissions, p => p.controller == controller);

               if (permissionIndex > -1) {
                  let permission = role.permissions[permissionIndex];
                  let actionIndex = _.findIndex(permission.actions, act => act.action == action);

                  if (actionIndex > -1) {
                     permission.actions[actionIndex].value = !permission.actions[actionIndex].value;
                  }
               }
            }
            return role;
         })

         setParsedPermissions(tmpParsedPermissions);
      }
   }

   // podczas dodawania roli robimy update tmpRole oraz pushujemy do parsedPermission nowe permissiony do wyklikania
   const onAddRole = () => {
      setParsedPermissions(prevState => [JSON.parse(JSON.stringify(permissionsList)), ...prevState]);
      setTmpRole({ name: '', permissions: [] });
   }

   // podczas klonowania roli szukamy po roleId roli, którą chcemy sklonować i pushujemy ją do parsedPermissions
   // oraz przypisujemy klonowaną role do tmpRole (czyli aktualnie dodawanej)
   const onCloneRole = (roleId) => {
      let parsedPermissionIndex = _.findIndex(parsedPermissions, perm => perm.roleId == roleId);
      if (parsedPermissionIndex > -1) {
         let permissionToClone = JSON.parse(JSON.stringify(parsedPermissions[parsedPermissionIndex]));
         delete permissionToClone.roleId;

         // jeśli już mamy wyklikaną role do dodania, to musimy ją zastąpić klonowaną rolą, jeśli nie to puszujemy jako pierwszy element tablicy
         if (!tmpRole) {
            setParsedPermissions(prevState => [permissionToClone, ...prevState]);
         } else {
            setParsedPermissions(parsedPermissions.map((perm, permIndex) => {
               if (permIndex === 0) return permissionToClone;
               return perm
            }))
         }
      }

      let roleIndex = _.findIndex(roles, role => role._id == roleId);
      if (roleIndex > -1) {
         let role = JSON.parse(JSON.stringify(roles[roleIndex]));
         delete role._id;

         setTmpRole(role);
         setTmpRoleName(role.name);
      }
   }

   const renderRoles = () => {
      // TODO CHECK IS EMPTY
      return (
         parsedPermissions && parsedPermissions.length > 0 ? parsedPermissions.map((role) => {
            return <tr className={!role.roleId ? 'table-primary text-dark' : ''} key={`${role.roleName}-${role.roleId}`}>
               {role.roleId ? <td style={{ minWidth: '200px' }}>{role.roleName}</td> : <td style={{ minWidth: '200px' }}>
                  <TextInput value={tmpRoleName} onChange={(e) => setTmpRoleName(e)} errors={errors && errors.name} />
               </td>}
               {role.permissions.map((perm, index) => {
                  return <td className="crud-controls" key={`${perm.controller}-${index}`}>
                     <div className="custom-control custom-checkbox">
                        {perm.actions && perm.actions.map((action, i) => {
                           return <div key={`${action.action}-${i}`}>
                              <input
                                 type="checkbox"
                                 className="custom-control-input"
                                 id={`${role.roleId}-${perm.controller}-${action.action}`}
                                 onChange={() => onChangeRole(perm.module, perm.controller, action.action, role.roleId)}
                                 checked={action.value} />
                              <label className="custom-control-label" htmlFor={`${role.roleId}-${perm.controller}-${action.action}`}>{action.name}</label><br />
                           </div>
                        })}
                     </div>
                  </td>
               })}
               {role.roleId ? <td>
                  {/* klonowanie roli */}
                  <Link to={window.location.pathname} onClick={() => onCloneRole(role.roleId)}>
                     <img className="akcja powiel" src={CloneIcon} />
                  </Link>
                  {/* usuwanie roli */}
                  <Link to={window.location.pathname} onClick={() => setRoleToDelete(role)} data-toggle="modal" data-target="#confirmDelete" title="Dezaktywuj użytkownika">
                     <img className="akcja usun" src={RemoveIcon} />
                  </Link>
               </td> : <td>
                     <button type="submit" onClick={() => saveRole(null, { ...tmpRole, name: tmpRoleName })} className="btn btn-primary mb-3 mt-3">Zapisz <img src={SaveIcon} /></button>
                  </td>}
            </tr>
         }) : <tr><td colSpan="12" className="text-center">Brak roli</td></tr>
      )
   }

   const renderColumnName = () => {
      return <tr>
         {PermissionsModuleList && PermissionsModuleList.map((perm, index) => {
            return <th key={`perm-name-${index}`}>{perm.name}</th>
         })}
         <th></th>
      </tr>
   }

   return (
      <Fragment>
         {roleToDelete && <ConfirmModal
            id="confirmDelete"
            label="Czy na pewno chcesz usunąć tę rolę?"
            positionName={_.get(roleToDelete, 'roleName')}
            onConfirm={handleDelete}
            onCancel={handleCancelConfirm}
         />}
         {/* roles list */}
         <div className="tab-content pt-3">
            <h3 className="mt-3 mb-3">Role</h3>
            {!tmpRole && <button type="submit" className="btn btn-primary mb-3" onClick={() => onAddRole()}>Dodaj <img src={AddIcon} /></button>}

            <div className="table-responsive tabela-left ramkatabeli ikonki">
               <table className="table  table-hover rounded-10 mb-5">
                  <thead>
                     {renderColumnName()}
                  </thead>
                  <tbody>
                     {renderRoles()}
                  </tbody>
               </table>
            </div>
         </div>

      </Fragment>
   )
}

RolesComponent.propTypes = {
   roles: PropTypes.array.isRequired,
   saveRole: PropTypes.func.isRequired,
   deleteRole: PropTypes.func.isRequired,
};

export default RolesComponent;