import React from 'react';
import koszIcon from "../../assets/gfx/kosz.svg";
import edytujIcon from "../../assets/gfx/edytuj.svg";
import { Link } from "react-router-dom";
import _ from "lodash";
import AclManager from '../../application/services/aclManager';

const WarehouseListCard = ({ item, handleDelete, index }) => {
    return (
        <tr>
            <td>{index + 1}</td>
            <td> <Link to={`/warehouses/${item._id}`} >{item.name}</Link></td>
            <td>{_.get(item, "owner.name", "-")}</td>
            <td>{item.itemsAmount} szt.</td>
            <td>{item.itemsValue} PLN</td>
            <td>
                {AclManager.isAllowedMethod('warehouse/warehouse', 'update') && <Link to={`/warehouse/${item._id}`} title="Edytuj komponent"><img className="akcja edytuj" src={edytujIcon} /></Link>}
                {AclManager.isAllowedMethod('warehouse/warehouse', 'delete') && <span onClick={() => handleDelete(item)} data-toggle="modal" data-target="#confirmDelete" title="Usuń"><img className="akcja usun" src={koszIcon} /></span>}
            </td>
        </tr>
    )
}

export default WarehouseListCard;