import React, { Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect } from 'react-redux'

import { getContract, clearContract } from '../actions/contracts.actions';
import { downloadFile } from '../../application/actions/application.actions';
import ContractDetailsComponent from '../components/ContractDetails.component';

const ContractDetailsContainer = (props) => {

    let { id } = useParams();

    useEffect(() => {
        id && props.getContract(id, { joinCustomer: true });
        return () => {
            props.clearContract();
        };
    }, []);

    return (
        <Fragment>
            {(!id || (id && props.contract)) && <ContractDetailsComponent {...props} />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    contract: store.contracts.contract
});

const mapDispatchToProps = (dispatch) => {
    return {
        getContract: (id, additional) => dispatch(getContract(id, additional)),
        clearContract: () => dispatch(clearContract()),
        downloadFile: (pathName) => dispatch(downloadFile(pathName))

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetailsContainer);
