import React from 'react';
import PropTypes from 'prop-types';

const ModalImage = ({
    fileName,
    fileSrc
}) => {
    return (
        <div className="modal fade" id="photoGallery" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenteredLabel">{fileName}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="input-group">
                            <div className="col-12">
                                <img src={`${fileSrc}`} className="alertPhotoimg w-100" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalImage;

ModalImage.propTypes = {
    fileName: PropTypes.string.isRequired,
    fileSrc: PropTypes.string.isRequired
}