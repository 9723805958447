import React from 'react';
import {
    Link
} from "react-router-dom";

import AclManager from '../services/aclManager';

import HamburgerIcon from '../../assets/gfx/hamburger.svg';
import MediaIcon from '../../assets/gfx/media.svg';
import PodgladIcon from '../../assets/gfx/podglad.svg';
import Ustawienia from '../../assets/gfx/ustawienia.svg';
import WylogujIcon from '../../assets/gfx/wyloguj.svg';
import MagazynIcon from '../../assets/gfx/magazyn.svg';
import KomponentyIcon from '../../assets/gfx/komponenty.svg';
import ZestawyIcon from "../../assets/gfx/zestawy.svg";
import UzytkownicyIcon from '../../assets/gfx/uzytkownicy.svg';
import CustomerIcon from '../../assets/gfx/klienci.svg';
import ExpensesIcon from '../../assets/gfx/kosztorysy.svg';
import ContractsIcon from '../../assets/gfx/umowy.svg';
import ZleceniaIcon from '../../assets/gfx/zlecenia.svg';
import RoleIcon from '../../assets/gfx/role.svg';

const Sidebar = ({ setShowHamburger, showHamburger, logout }) => {

    const MODULES = [
        { name: "Zlecenia serwisowe", logo: ZleceniaIcon, path: '/services', apiRoute: 'service/service' },
        { name: "Urządzenia", logo: PodgladIcon, path: '/devices', apiRoute: 'device/device' },
        { name: "Ustawienia", logo: Ustawienia, path: '/settings', apiRoute: 'application/settings' },
        { name: "Klienci", logo: CustomerIcon, path: '/customers', apiRoute: 'customer/customer' },
        { name: "Umowy", logo: ContractsIcon, path: '/contracts', apiRoute: 'customer/contract' },
        { name: "Kosztorysy", logo: ExpensesIcon, path: '/expenses', apiRoute: 'expense/expense' },
        { name: "Komponenty", logo: KomponentyIcon, path: '/components', apiRoute: 'device/component' },
        { name: "Zestawy czynności", logo: ZestawyIcon, path: '/serviceRanges', apiRoute: 'device/serviceRange' },
        { name: "Magazyny", logo: MagazynIcon, path: '/warehouses', apiRoute: 'warehouse/warehouse' },
        { name: "Użytkownicy", logo: UzytkownicyIcon, path: '/users', apiRoute: 'admin/user' },
        { name: "Role", logo: RoleIcon, path: '/roles', apiRoute: 'admin/role' },
        { name: "Profil", logo: UzytkownicyIcon, path: '/profile', apiRoute: 'application/profile' },
        { name: "Biblioteka mediów", logo: MediaIcon, path: '/medias', apiRoute: 'media/media' },
    ]

    const handleHideMenu = () => {
        if (window.screen.width <= 1280) {
            setShowHamburger(false);
        }
    }

    const renderModulesLinks = () => {
        return MODULES.map((m, i) => {
            return (
                AclManager.isAllowedMethod(m.apiRoute, 'get') && <Link key={i} to={m.path} onClick={() => handleHideMenu()} className="list-group-item list-group-item-action bg-dark">
                    <img src={m.logo} /> {m.name}
                </Link>
            )
        })
    }

    return (
        <div className="bg-dark elemencikprzed no-print" id="sidebar-wrapper">
            <button className="btn menu-burger" id="menu-toggle"
                onClick={() => setShowHamburger(!showHamburger)}
            ><img src={HamburgerIcon} /></button>
            <div className="fixedmenu bg-dark">
                <div className="sidebar-heading  font-weight-bold text-center">MENU </div>
                <div className="list-group list-group-flush">
                    {renderModulesLinks()}
                    <span className="list-group-item list-group-item-action bg-dark menu-wyloguj" onClick={() => logout()}>
                        <img src={WylogujIcon} />
                        Wyloguj
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;