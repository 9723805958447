import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import _ from 'lodash';

import PlusIcon from '../../assets/gfx/plus-2.svg';
import AddIcon from '../../assets/gfx/dodaj.svg';
import TextInput from '../../assets/components/TextInput.component';
import AddressComponent from '../../assets/components/Address.component';
import NIPSearchComponent from './NIPSearch.component';

import { customerInitialState, customerValidationSchema } from '../schemas/customer.schema';

const CustomerComponent = ({ customer, saveCustomer }) => {

    // defaultowe initialValues ze schemy
    const [initialValues, setInitialValues] = useState(customerInitialState);
    let history = useHistory();

    // aktualizujemy initialValues jak przyjdzie z API
    useEffect(() => {
        _.get(customer, '_id') && setInitialValues(customer);
    }, [customer]);

    const handleAddNewContact = (values) => {
        setInitialValues({
            ...values, contacts: [...values.contacts, {
                description: '',
                email: '',
                phone: ''
            }]
        });
    }

    const handleRemoveContact = (elementIndex, values) => {
        setInitialValues({
            ...values, contacts: [...values.contacts.filter((item, index) => index !== elementIndex)]
        });
    }

    return (
        <Fragment>
            <h3 className="mt-3 mb-3">{`${customer && customer._id ? "Edytuj klienta" : "Dodaj nowego klienta"}`} </h3>
            <Formik
                initialValues={initialValues}
                validationSchema={customerValidationSchema}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setErrors }) => {
                    saveCustomer(values).then(res => {
                        if (res.success) {
                            history.push(`/customers/${res.document}`);
                        } else {
                            if (res.errors) {
                                setErrors(res.errors);
                            }
                        }
                    });
                }}
            >
                {formik => (
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <NIPSearchComponent isRequired={true} value={formik.values.NIP} setFieldValue={formik.setFieldValue} errors={formik.errors.NIP} onChange={(value) => formik.setFieldValue('NIP', value)} />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6"></div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <TextInput
                                    id="name"
                                    label="Nazwa skrócona"
                                    type="text"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.name}
                                    errors={formik.errors.name}
                                    isRequired={true}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <TextInput
                                    id="gusName"
                                    label="Nazwa GUS"
                                    type="text"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.gusName}
                                    errors={formik.errors.gusName}
                                    isRequired={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <AddressComponent isRequired={true} value={formik.values.address} errors={formik.errors.address} onChange={(value) => formik.setFieldValue('address', value)} />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <hr />
                            </div>
                            {formik.values.contacts && formik.values.contacts.map((contact, index) => {
                                return <Fragment key={`contact-row-${index}`}>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <TextInput
                                            isRequired={true}
                                            id={`contacts.${index}.description`}
                                            label="Osoba kontaktowa"
                                            type="text"
                                            onChange={formik.setFieldValue}
                                            value={formik.values.contacts[index].description}
                                            errors={_.get(formik, `errors.contacts[${index}].description`) ? _.get(formik, `errors.contacts[${index}].description`) : null}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-2">
                                        <TextInput
                                            id={`contacts.${index}.email`}
                                            label="Adres e-mail"
                                            type="text"
                                            onChange={formik.setFieldValue}
                                            value={formik.values.contacts[index].email}
                                            errors={_.get(formik, `errors.contacts[${index}].email`) ? _.get(formik, `errors.contacts[${index}].email`) : null}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-2">
                                        <TextInput
                                            id={`contacts.${index}.phone`}
                                            label="Numer telefonu"
                                            type="text"
                                            onChange={formik.setFieldValue}
                                            value={formik.values.contacts[index].phone}
                                            errors={_.get(formik, `errors.contacts[${index}].phone`) ? _.get(formik, `errors.contacts[${index}].phone`) : null}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <TextInput
                                            id={`contacts.${index}.position`}
                                            label="Stanowisko"
                                            type="text"
                                            onChange={formik.setFieldValue}
                                            value={formik.values.contacts[index].position}
                                            errors={_.get(formik, `errors.contacts[${index}].position`) ? _.get(formik, `errors.contacts[${index}].position`) : null}
                                        />
                                    </div>
                                    {index !== 0 && <div className="col-sm-12 col-md-6 col-lg-1 d-flex justify-content-center align-items-center pl-sm-0" onClick={() => handleRemoveContact(index, formik.values)}>
                                        <button type="button" className="btn btn-danger flex-fill">USUŃ</button>
                                    </div>}
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </Fragment>
                            })}
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="btn btn-link p-0 mt-2" onClick={() => handleAddNewContact(formik.values)}>
                                        <span>Dodaj kolejną osobę kontaktową</span>
                                        <div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5">
                                            <img className="mt-1 ml-0" src={PlusIcon} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <TextInput
                                    isRequired={true}
                                    id="hourlyRate"
                                    label="Koszt roboczogodziny (zł)"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.hourlyRate}
                                    errors={formik.errors.hourlyRate}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <TextInput
                                    isRequired={true}
                                    id="overhead"
                                    label="Narzut (%)"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.overhead}
                                    errors={formik.errors.overhead}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mb-3">{`${customer && customer._id ? "Zapisz klienta" : "Dodaj klienta"}`} <img src={AddIcon} /></button>
                    </form>
                )}
            </Formik>
        </Fragment>
    )
}

CustomerComponent.propTypes = {
    customer: PropTypes.object,
    saveCustomer: PropTypes.func.isRequired
};

export default CustomerComponent;