import Api from '../services/api';
import AclManager from '../services/aclManager';
import _ from 'lodash';

import { toast } from 'react-toastify';

export const NAMESPACE = "APPLICATION";
export const IS_LOADING = `IS_LOADING_${NAMESPACE}`;
export const SAVE_HISTORY = `SAVE_HISTORY_${NAMESPACE}`;
export const INCREASE_LOADING_ITEMS = `INCREASE_LOADING_ITEMS_${NAMESPACE}`;
export const DECREASE_LOADING_ITEMS = `DECREASE_LOADING_ITEMS_${NAMESPACE}`;
export const SET_USER = `SET_USER_${NAMESPACE}`;
export const CLEAR_USER = `CLEAR_USER_${NAMESPACE}`;

export const setUser = (user) => dispatch => {
    dispatch({
        type: SET_USER,
        user
    });
}

export const clearUser = () => dispatch => {
    dispatch({
        type: CLEAR_USER
    });
}

//Dzięki temu możemy mieć kilka loadingow naraz i loader nie mruga i wyświetla się dopóki wszystkie rzeczy się nie załadują
export const isLoading = (isLoading) => (dispatch, getState) => {
    const loadingItems = getState().app.loadingItems;

    if (isLoading && loadingItems === 0) {
        dispatch({
            type: IS_LOADING,
            isLoading
        })
        dispatch({
            type: INCREASE_LOADING_ITEMS
        })
    } else if (isLoading && loadingItems > 0) {
        dispatch({
            type: INCREASE_LOADING_ITEMS
        })
    } else if (!isLoading && loadingItems === 1) {
        dispatch({
            type: IS_LOADING,
            isLoading
        })
        dispatch({
            type: DECREASE_LOADING_ITEMS
        })
    } else {
        dispatch({
            type: DECREASE_LOADING_ITEMS
        })
    }
}

export const displayErrors = (errors) => {
    Object.keys(errors).forEach(key => {
        if (typeof (errors[key]) === 'object') {
            displayErrors(errors[key])
        } else {
            toast.error(errors[key]);
        }
    });
}

export const saveHistoryToReducer = (history) => dispatch => {
    dispatch({
        type: SAVE_HISTORY,
        history
    })
}

export const isAuth = () => (dispatch) => {
    // dla inspektora nie robimy isAuth
    if (window.location.href.indexOf('/inspector/') == -1) {
        dispatch(isLoading(true));
        Api.get('application/isAuth').then(res => {
            if (res.success) {
                AclManager.setPermissions(_.get(res, 'user.role.permissions', []));
                AclManager.setUserType(_.get(res, 'user.type'));
                dispatch(setUser(res.user))
            } else {
                if (res.errors) {
                    displayErrors(res.errors);
                }
            }
            dispatch(isLoading(false));
        });
    }
}

export const login = (email, password) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        return Api.post('application/login', { email: email, password: password }).then(res => {

            if (res.success) {
                AclManager.setPermissions(_.get(res, 'user.role.permissions', []));
                AclManager.setUserType(_.get(res, 'user.type'));
                localStorage.setItem('token', res.token);
                dispatch(setUser(res.user));
                dispatch(isLoading(false));
                // ustawiamy uprawnienia dla użytkownika
                return { success: true };
            } else {
                if (res.errors) {
                    displayErrors(res.errors);
                    dispatch(isLoading(false));
                    return { success: false, errors: res.errors };
                }
            }
        }).catch(err => {
            displayErrors(err);
            dispatch(isLoading(false));
        })
    }

}

export const saveUser = (user) => dispatch => {
    dispatch({
        type: SET_USER,
        user
    });
}

export const logout = () => dispatch => {
    dispatch(clearUser());
    localStorage.removeItem('token');
    AclManager.clearPermissions();
}
export const getPlaces = (query, token) => async () => {
    const result = await Api.get('application/getLocations', {
        query: query,
        token: token
    });

    if (result && result.success) {
        return result.data;
    }
}

export const getPlaceDetails = (placeId, token) => async dispatch => {

    dispatch(isLoading(true));
    const result = await Api.get('application/getLocationDetails', { place_id: placeId });
    dispatch(isLoading(false));

    if (result && result.success) {
        return result.data;
    }
}

export const downloadFile = (fileName) => async dispatch => {
    dispatch(isLoading(true));
    await Api.get(fileName, null, null, true).then((result) => {
        dispatch(isLoading(false));

        if (result.success && result.file) {
            let blob = new Blob([new Uint8Array(result.file.data)]);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        } else if ('errors' in result) {
            toast.error('Błąd podczas pobierania pliku');
        }
    });
}
