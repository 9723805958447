import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from "lodash";

const CustomerContractListCardComponent = ({ contract, index }) => {

    return (
        <tr>
            <th scope="row">{index}</th>
            <td>
                <Link to={`/contracts/${_.get(contract, '_id')}`}>
                    {contract.number || ''}
                </Link>
            </td>
            <td>{contract.conclusionDate ? moment(contract.conclusionDate).format('DD.MM.YYYY') : '-'}</td>
            <td>{contract.expiryDate ? moment(contract.expiryDate).format('DD.MM.YYYY') : '-'}</td>
            <td>{_.get(contract, 'usedComponents', []).length}</td>
            <td>{_.get(contract, 'reactionTime', '-')}</td>
        </tr>
    )
}

CustomerContractListCardComponent.propTypes = {
    contract: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default CustomerContractListCardComponent;