import Api from '../../application/services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

export const NAMESPACE = "ROLES";
// AKCJE SETUJACE
export const SET_ROLES = `SET_ROLES_${NAMESPACE}`;
export const SET_ERRORS = `SET_ERRORS_${NAMESPACE}`;

// AKCJE CLEARUJACE
export const CLEAR_ERRORS = `CLEAR_ERRORS_${NAMESPACE}`;
export const CLEAR_ROLES = `CLEAR_ROLES_${NAMESPACE}`;

// METODY SETUJACE
export const setRoles = (roles, total) => ({
   type: SET_ROLES,
   roles,
   total
});

export const setErrors = (errors) => dispatch => {
   dispatch({
      type: SET_ERRORS,
      errors,
   });
}

// METODY CLEARUJACE
export const clearRoles = () => dispatch => {
   dispatch({
      type: CLEAR_ROLES
   });
}

export const clearErrors = () => dispatch => {
   dispatch({
      type: CLEAR_ERRORS
   });
}

export const getRoles = () => async dispatch => {
   dispatch(isLoading(true));

   const result = await Api.get('admin/role');

   if (result && result.success) {
      dispatch(setRoles(result.documents, result.total));
   }

   dispatch(isLoading(false));
}

export const saveRole = (roleId, data) => dispatch => {
   if (roleId) {
      return dispatch(editRole(roleId, data));
   } else {
      return dispatch(createRole(data));
   }
}

export const editRole = (roleId, data) => async dispatch => {
   dispatch(isLoading(true));

   const result = await Api.put(`admin/role/${roleId}`, data);

   if (result && result.success) {
      dispatch(getRoles());
   } else {
      dispatch(setErrors(result.errors));
   }

   dispatch(isLoading(false));
}

export const createRole = (data) => async dispatch => {
   dispatch(isLoading(true));
   dispatch(clearErrors());

   const result = await Api.post(`admin/role`, data);

   if (result && result.success) {
      dispatch(getRoles());
   } else {
      dispatch(setErrors(result.errors));
   }

   dispatch(isLoading(false));
}

export const deleteRole = (roleId) => async dispatch => {
   dispatch(isLoading(true));

   const result = await Api.delete(`admin/role/${roleId}`);

   if (result && result.success) {
      dispatch(getRoles());
   }

   dispatch(isLoading(false));
}

