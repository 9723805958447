import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash'

import Select from '../../assets/components/Select.component';
import Date from '../../assets/components/Date.component';

const MEDIA_TYPES = [
    { value: 'application', name: "Dokumenty" },
    { value: 'image', name: "Zdjęcia" },
    { value: '', name: "Wszystkie" },

];

// metoda mapująca pola do setowania filtrów 
const mapFields = {
    'mimetype': 'mimetype',
    'from': 'createdAt[gte]',
    'to': 'createdAt[lte]'
  }
  

const MediasFilter = ({
    setFilters
}) => {

    const handleResetFilters = (resetForm) => {
        resetForm();
        setFilters({});
      }

    return (
        <div className="modal fade" id="modal-filtruj" tabIndex="-1" role="dialog" aria-labelledby="filtrowanie"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="filtrowanie">Filtry</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h3 className="mt-4 mb-3 w-100">Pokaż media, które mają:</h3>

                        <Formik
                            initialValues={{ mimetype: '', from: '', to: '' }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values) => {
                                const filters = {};
                                Object.keys(values).map(key => {
                                  if (values[key] !== '') {
                                    filters[mapFields[key]] = values[key];
                                  }
                                });
              
                                setFilters(filters);
                            }}
                        >
                            {formik => (
                                <form className="input-group-login flex-column" onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <Select
                                                id="mimetype"
                                                label="Typ mediów"
                                                options={MEDIA_TYPES}
                                                onChange={formik.setFieldValue}
                                                value={_.get(formik, 'values.mimetype')}
                                                errors={formik.errors.mimetype}
                                            />
                                        </div>


                                        <div className="col-6">
                                            <div className="filtr">
                                                <Date
                                                    id='from'
                                                    label="Dodane od"
                                                    onChange={formik.setFieldValue}
                                                    value={_.get(formik, 'values.from')}
                                                    errors={_.get(formik, 'errors.from')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="filtr">
                                                <Date
                                                    id="to"
                                                    label="Dodane do"
                                                    onChange={formik.setFieldValue}
                                                    value={_.get(formik, 'values.to')}
                                                    errors={_.get(formik, 'errors.to')}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer pr-0">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                                        <button type="button" className="btn btn-secondary" onClick={() => handleResetFilters(formik.resetForm)}>Wyczyść</button>
                                        <button type="button" type="submit" className="btn btn-primary mr-0">Zapisz filtry</button>
                                    </div>
                                </form>
                            )}
                        </Formik>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default MediasFilter;