import React, { Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { connect } from 'react-redux'

import { getExpense, clearExpense } from '../actions/expenses.actions';
import ExpenseDetailsComponent from '../components/ExpenseDetails.component';

const ExpenseDetailsContainer = (props) => {

    let { id } = useParams();

    useEffect(() => {
        id && props.getExpense(id);
        return () => {
            props.clearExpense();
        };
    }, []);

    return (
        <Fragment>
            {(!id || (id && props.expense)) && <ExpenseDetailsComponent {...props} />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    expense: store.expenses.expense
});

const mapDispatchToProps = (dispatch) => {
    return {
        getExpense: (id) => dispatch(getExpense(id)),
        clearExpense: () => dispatch(clearExpense())

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetailsContainer);
