import * as Yup from 'yup';

export const componentInitialState = {
    name: '',
    manufacturer: '',
    kind: '',
    description: '',
    gallery: [],
    parameters: [],
    componentValue: 0,
    type: "component",
    instructions: [],
    serviceRanges: [],
    durabilitySource: "1",
    durabilityUnit: "1",
    estimatedDurability: '1',
    estimatedDurabilityYears: '1'
}

export const componentValidationSchema = Yup.object().shape({
    name: Yup.string().required('Nazwa jest wymagana'),
    manufacturer: Yup.string().required('Producent komponentu jest wymagany'),
    kind: Yup.string().required('Rodzaj komponentu jest wymagany'),
    description: Yup.string(),
    gallery: Yup.array().of(Yup.string()),
    parameters: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required("Nazwa parametru jest wymagana"),
            value: Yup.string().required("Wartość parametru jest wymagana")
        })
    ).min(1, 'Parametry są wymagane'),
    componentValue: Yup.number().required("Wartość komponentu jest wymagana"),
    type: Yup.string().oneOf(["component", "subcomponent"], "Nieprawidłowy typ komponentu").required("Typ komponentu jest wymagany"),
    instructions: Yup.array().of(Yup.string()),
    serviceRanges: Yup.array().when('type', {
        is: 'component',
        then: Yup.array().of(
            Yup.object().shape({
                serviceRangeId: Yup.string().required("Id zakresu jest wymagane"),
                name: Yup.string().required('Nazwa jest wymagana'),
                interval: Yup.number().oneOf([1, 3, 6, 12]).required("Interwał jest wymagany"),
                activities: Yup.array().of(
                    Yup.object().shape({
                        description: Yup.string().required("Opis czynności jest wymagany"),
                        instruction: Yup.string()
                    }))
            })
        ),
        otherWise: Yup.array()
    }),
    durabilitySource: Yup.string().required('Nazwa jest wymagana'),
    durabilityUnit: Yup.string().required('Nazwa jest wymagana'),
    estimatedDurability: Yup.string().required('Nazwa jest wymagana'),
    estimatedDurabilityYears: Yup.number().required('Nazwa jest wymagana')
});
//.min(1, "Nie dodano żadnego zestawu czynności"),