import Api from '../../application/services/api';
import { isLoading } from '../../application/actions/application.actions';

export const NAMESPACE = 'PROFILE';

export const changePassword = async (data, dispatch) => {
   dispatch(isLoading(true));
   let result = await Api.put(`application/profile`, data);
   dispatch(isLoading(false));
   return result;
}
