import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({
    id,
    label,
    onChange,
    value,
    errors,
    rows,
    cols,
    customClass,
    placeholder,
    disabled,
    isRequired
}) => {

    const renderErrors = () => {
        if (!errors) return;

        if (Array.isArray(errors)) {
            return errors.map((e, i) => {
                return <div key={i} className="errormsg">{e}</div>
            })
        }

        if (typeof errors === 'string') {
            return <div className="errormsg">{errors}</div>
        }
    }

    return (
        <React.Fragment>
            <div className={`${customClass ? customClass : 'form-group d-flex flex-wrap w-100 flex-column justify-content-end'} ${errors ? 'error' : null}`}>
                <label>{label} {isRequired && <span className="is-required">*</span>}</label>
                <textarea
                    id={id}
                    className="form-control"
                    rows={rows}
                    cols={cols}
                    autoComplete="off"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChange(id, e.target.value)}
                    disabled={disabled || false}
                />
                {renderErrors()}
            </div>
        </React.Fragment>
    )
}

Textarea.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    errors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]),
    rows: PropTypes.string,
    cols: PropTypes.string,
    placeholder: PropTypes.string
};

export default Textarea;