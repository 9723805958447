import React, { Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom";
import AclManager from '../../application/services/aclManager';
import { connect } from 'react-redux'

import {
    getCustomer,
    getCustomerDevices,
    getCustomerServices,
    getCustomerContracts,
    getCustomerExpenses,
    setTabPage,
    setActiveTab,
    setLimit,
    clearCustomer,
    clearCustomerDevices,
    clearCustomerServices,
    clearCustomerContracts,
    clearCustomerExpenses,
    clearTabPage,
    clearLimit,
    clearActiveTab
} from '../actions/customers.actions';
import CustomerDetailsComponent from '../components/CustomerDetails.component';

const CustomerDetailsContainer = (props) => {

    let { id, page } = useParams();

    useEffect(() => {
        
        // pobieramy na start ze szczegółami informacje o urządzeniach użytkownika
        id && props.getCustomer(id);

        // setPage już pobierze dla tabsa aktywnego dane - sprwadź funkcje setPpage()
        page && props.setTabPage(page && parseInt(page) || 1, id, true);

        return () => {
            props.clearCustomer();
            props.clearTabPage();
            props.clearLimit(); 
            props.clearCustomerDevices();
            props.clearCustomerServices();
            props.clearCustomerContracts();
            props.clearCustomerExpenses();
            props.clearActiveTab();
        };
    }, []);

    return (
        <Fragment>
            {(!id || (id && props.customer)) && <CustomerDetailsComponent {...props} />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    customer: store.customers.customer,
    customerDevices: store.customers.customerDevices,
    customerServices: store.customers.customerServices,
    customerContracts: store.customers.customerContracts,
    customerExpenses: store.customers.customerExpenses,
    total: store.customers.total,
    page: store.customers.page,
    limit: store.customers.params.limit
});

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomer: (customerId) => dispatch(getCustomer(customerId)),
        getCustomerDevices: (customerId) => dispatch(getCustomerDevices(customerId)),
        getCustomerServices: () => dispatch(getCustomerServices()),
        getCustomerContracts: () => dispatch(getCustomerContracts()),
        getCustomerExpenses: () => dispatch(getCustomerExpenses()),
        setTabPage: (page, customerId) => dispatch(setTabPage(page, customerId, true)),
        setActiveTab: (activeTabName) => dispatch(setActiveTab(activeTabName)),
        setLimit: (limit) => dispatch(setLimit(limit)),
        clearCustomer: () => dispatch(clearCustomer()),
        clearCustomerDevices: () => dispatch(clearCustomerDevices()),
        clearCustomerServices: () => dispatch(clearCustomerServices()),
        clearCustomerContracts: () => dispatch(clearCustomerContracts()),
        clearCustomerExpenses: () => dispatch(clearCustomerExpenses()),
        clearTabPage: () => dispatch(clearTabPage()),
        clearLimit: () => dispatch(clearLimit()),
        clearActiveTab: () => dispatch(clearActiveTab())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsContainer);
