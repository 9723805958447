import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from "lodash";

const CustomerExpenseListCardComponent = ({ expense, index }) => {
    return (
        <tr>
            <th scope="row">{index}</th>
            <td>
                <Link to={`/expenses/${_.get(expense, '_id')}`}>
                    {expense.name || ''}
                </Link>
            </td>
            <td>{expense.createdAt ? moment(expense.createdAt).format('DD.MM.YYYY') : '-'}</td>
            <td>{_.get(expense, 'customer.name', '-')}</td>
            <td>{_.get(expense, 'usedComponents', []).length}</td>
            <td>{_.get(expense, 'executionTime', '-')}</td>
            <td>{_.get(expense, 'totalCost', '-')}</td>
        </tr>
    )
}

CustomerExpenseListCardComponent.propTypes = {
    expense: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default CustomerExpenseListCardComponent;