import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux'

import UserDetailsComponent from '../components/UserDetails.component';

import {
   getUser,
   getLastUserActivities,
   getUserDevices,
   getUserPermissions,
   clearUser,
   clearLastUserActivities,
   clearUserDevices,
   clearUserPermissions,
} from '../actions/users.actions';

const UserDetailsContainer = ({
   getUser,
   user,
   getLastUserActivities,
   lastUserActivities,
   getUserDevices,
   userDevices,
   getUserPermissions,
   userPermissions,
   clearUser,
   clearLastUserActivities,
   clearUserDevices,
   clearUserPermissions,
}) => {
   const { id } = useParams();

   useEffect(() => {
      getUser(id);

      return () => {
         clearUser()
         clearLastUserActivities()
         clearUserDevices()
         clearUserPermissions()
      }
   }, [id])

   return (
      <UserDetailsComponent
         user={user}
         lastUserActivities={lastUserActivities}
         getUserDevices={getUserDevices}
         userDevices={userDevices}
         getUserPermissions={getUserPermissions}
         userPermissions={userPermissions}
         getLastUserActivities={getLastUserActivities}
      />
   );
}

const mapStateToProps = (store) => ({
   user: store.users.user,
   lastUserActivities: store.users.lastUserActivities,
   userDevices: store.users.userDevices,
   userPermissions: store.users.userPermissions
});

const mapDispatchToProps = (dispatch) => {
   return {
      getUser: (id) => dispatch(getUser(id)),
      getLastUserActivities: (id) => dispatch(getLastUserActivities(id)),
      getUserDevices: (id) => dispatch(getUserDevices(id)),
      getUserPermissions: (id) => dispatch(getUserPermissions(id)),
      clearUser: () => dispatch(clearUser()),
      clearUserDevices: () => dispatch(clearUserDevices()),
      clearUserPermissions: () => dispatch(clearUserPermissions()),
      clearLastUserActivities: () => dispatch(clearLastUserActivities()),
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer);
