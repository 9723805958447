import React, { Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { connect } from 'react-redux'

import { getDeviceInfo, clearDeviceInfo } from '../actions/inspector.actions';
import InspectorComponent from '../components/Inspector.component';

const InspectorContainer = (props) => {

    let { id } = useParams();

    useEffect(() => {
        id && props.getDeviceInfo(id);
        return () => {
            props.clearDeviceInfo();
        };
    }, []);

    return (
        <Fragment>
            {(!id || (id && props.deviceInfo)) && <InspectorComponent {...props} />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    deviceInfo: store.inspector.deviceInfo
});

const mapDispatchToProps = (dispatch) => {
    return {
        getDeviceInfo: (id) => dispatch(getDeviceInfo(id)),
        clearDeviceInfo: () => dispatch(clearDeviceInfo())

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectorContainer);
