import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getComponent, clearComponent, saveComponent } from '../actions/components.actions';
import Component from '../components/Component.component';

const ComponentContainer = (props) => {
    const { getComponent, clearComponent } = props;
    let { id } = useParams();
    useEffect(() => {

        if (id) {
            getComponent(id);
        }
        return () => {
            clearComponent();
        }
    }, [getComponent, clearComponent])

    return (
        <Fragment>
            <Component
                {...props}
            />
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    component: store.components.component
});

const mapDispatchToProps = (dispatch) => {
    return {
        getComponent: (id) => dispatch(getComponent(id)),
        clearComponent: () => dispatch(clearComponent()),
        saveComponent: (data) => dispatch(saveComponent(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentContainer);
