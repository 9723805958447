import { Formik } from 'formik';
import _ from "lodash";
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Autocomplete from "../../assets/components//Autocomplete.component";
import Select from "../../assets/components//Select.component";
import Textarea from "../../assets/components//Textarea.component";
import TextInput from "../../assets/components//TextInput.component";
import TextSelectInput from "../../assets/components//TextSelect.component";
import SelectMediaModal from "../../assets/components/SelectMediaModal.component";
import dodajIcon from "../../assets/gfx/dodaj.svg";
import plusIcon from "../../assets/gfx/plus-2.svg";
import koszIcon from "../../assets/gfx/kosz.svg";
import { componentInitialState, componentValidationSchema } from "../schemas/component.schema";
import UploadFilesComponent from '../../assets/components/UploadFiles.component';

const Component = ({ component, saveComponent }) => {

    const { id, action } = useParams();
    const typeOptions = [{ name: "Komponent", value: "component" }, { name: "Część", value: "subcomponent" }];
    const durabilitySourceOptions = [
        { name: "Instrukcja eksploatacji", value: "1" },
        { name: "Producent/katalogi", value: "2" },
        { name: "Osoba kompetentna", value: "3" }
    ];
    const durabilityUnitOptions = [
        { name: "Ilość jazd", value: "1" },
        { name: "Cykle pracy", value: "2" },
        { name: "Godziny pracy", value: "3" }
    ];
    const [initialValues, setInitialValues] = useState(componentInitialState);
    const history = useHistory();

    useEffect(() => {
        _.get(component, "_id") && setInitialValues(component);
    }, [component]);

    const handleAddNewParameter = (formik) => {
        formik.setFieldValue("parameters", _.concat(formik.values.parameters, [{
            name: '',
            value: ''
        }]));
    }

    const handleDeleteParameter = (formik, index) => {
        formik.setFieldValue("parameters", _.without(formik.values.parameters, _.nth(formik.values.parameters, index)));
    }

    const handleDeleteImage = (formik, index) => {
        formik.setFieldValue("gallery", _.without(formik.values.gallery, _.nth(formik.values.gallery, index)));
    }

    const handleDeleteInstruction = (formik, index) => {
        formik.setFieldValue("instructions", _.without(formik.values.instructions, _.nth(formik.values.instructions, index)));
    }

    const cutFileName = (fileName, cutNumber) => {
        fileName = fileName.substring(fileName.lastIndexOf("/") + 1);

        if (fileName.length > cutNumber) {
            const sliceCount = fileName.length - cutNumber;
            fileName = `...${fileName.slice(sliceCount)}`;
        }
        return fileName;
    }

    return (<div className="dodawanie">
        <h3 className="mt-3 mb-3">{id ? "Edytuj" : "Dodaj nowy"} komponent</h3>
        <Formik
            initialValues={initialValues}
            validationSchema={componentValidationSchema}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setErrors }) => {
                if (action && action === "clone") {
                    delete values._id;
                }
                let res = await saveComponent(values);
                if (res.success) {
                    history.push(`/components/${res.document}`);
                } else {
                    if (res.errors) {
                        setErrors(res.errors);
                    }
                }
            }}
        >
            {formik => (<form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                        <TextInput
                            isRequired={true}
                            id="name"
                            label="Nazwa"
                            type="text"
                            value={formik.values.name}
                            onChange={formik.setFieldValue}
                            errors={formik.errors.name}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                        <TextInput
                            isRequired={true}
                            id="manufacturer"
                            label="Producent"
                            type="text"
                            value={formik.values.manufacturer}
                            onChange={formik.setFieldValue}
                            errors={formik.errors.manufacturer}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                        <TextInput
                            isRequired={true}
                            id="kind"
                            label="Rodzaj"
                            type="text"
                            value={formik.values.kind}
                            onChange={formik.setFieldValue}
                            errors={formik.errors.kind}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                        <div className="form-group w-100 d-flex flex-column justify-content-end ">
                            <label>Galeria</label>
                            {formik.values.gallery && formik.values.gallery.map((image, i) => {
                                return (
                                    <div key={`image${i}`} className="param-komp mb-1">
                                        <img className="akcja usun mr-3" src={koszIcon}
                                            onClick={() => handleDeleteImage(formik, i)}
                                        />
                                        {cutFileName(image, 25)}
                                    </div>
                                )
                            })}
                            <div className="input-group">
                                <input readOnly={true} type="text" className="form-control" placeholder="Wybierz zdjęcia z biblioteki" aria-label="" aria-describedby="basic-addon1" />
                                <div className="input-group-append">
                                    <SelectMediaModal
                                        modalId={'gallery'}
                                        buttonName="Wybierz"
                                        onSelect={(src) => formik.setFieldValue('gallery', _.concat(formik.values.gallery, [src]))}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                        <div className="input-group">
                            <UploadFilesComponent
                                multiple={true}
                                files={[]}
                                uploadedFiles={formik.values.uploadedFiles}
                                handleChangeFiles={(values) => null}
                                handleChangeUploadedFiles={(values) => formik.setFieldValue('uploadedFiles', values)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Select
                            id="type"
                            label="Typ"
                            options={typeOptions}
                            onChange={formik.setFieldValue}
                            value={formik.values.type}
                            errors={formik.errors.type}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                        <div className="form-group w-100 d-flex flex-column justify-content-end ">
                            <label>Instrukcje</label>
                            {formik.values.instructions && formik.values.instructions.map((instruction, i) => {
                                return (
                                    <div key={`instruction${i}`} className="param-komp mb-1">
                                        <img className="akcja usun mr-3" src={koszIcon}
                                            onClick={() => handleDeleteInstruction(formik, i)}
                                        />
                                        {instruction}
                                    </div>
                                )
                            })}
                            <div className="input-group">
                                <input type="text" id='instructions-link-input' onChange={(e) => {
                                    if (e.target.value.indexOf("http") >= 0) {
                                        e.target.value.trim() != "" && formik.setFieldValue('instructions', [...formik.values.instructions, e.target.value]);
                                        document.getElementById('instructions-link-input').value = "";
                                    }
                                }} className="form-control" placeholder="Wklej link lub wybierz z biblioteki" aria-label="" aria-describedby="basic-addon1" />
                                <div className="input-group-append">
                                    <SelectMediaModal
                                        modalId={'instructions'}
                                        buttonName="biblioteka"
                                        onSelect={(src) => formik.setFieldValue('instructions', _.concat(formik.values.instructions, [src]))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {formik.values.type === "component" && <div className="row">
                    <div className="col-12">
                        <hr />
                    </div>
                    {/* <div className="col-sm-6 col-md-4 col-lg-4">
                        <TextSelectInput
                            inputId="estimatedDurability"
                            inputError={formik.errors.estimatedDurability}
                            inputValue={formik.values.estimatedDurability}
                            onInputChange={formik.setFieldValue}
                            selectId="durabilityUnit"
                            onSelectChange={formik.setFieldValue}
                            selectError={formik.errors.durabilityUnit}
                            placeholder=""
                            selectValue={formik.values.durabilityUnit}
                            label="Założona trwałość eksploatacyjna"
                            type="text"
                            options={durabilityUnitOptions}
                        />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 d-flex flex-wrap">
                        <TextInput
                            id="estimatedDurabilityYears"
                            label="Założony okres trwałości (lata)"
                            type="number"
                            value={formik.values.estimatedDurabilityYears}
                            onChange={formik.setFieldValue}
                            errors={formik.errors.estimatedDurabilityYears}
                        />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap">
                        <Select
                            id="durabilitySource"
                            label="Wybierz źródło danych"
                            options={durabilitySourceOptions}
                            onChange={formik.setFieldValue}
                            value={formik.values.durabilitySource}
                            errors={formik.errors.durabilitySource}
                        />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <TextInput
                            id="componentValue"
                            label="Wartość"
                            type="number"
                            sideLabel="PLN"
                            value={formik.values.componentValue}
                            onChange={formik.setFieldValue}
                            errors={formik.errors.componentValue}
                        />
                    </div> */}
                    <div className="col-sm-6 col-md-4 col-lg-4">
                        <Autocomplete
                            id="subcomponents"
                            url="device/component"
                            multi={true}
                            label="Części składowe"
                            optionLabelField="fullName"
                            optionIdField="componentId"
                            prependText={null}
                            appendText={null}
                            onOptionSelect={formik.setFieldValue}
                            value={formik.values.subcomponents}
                            errors={formik.errors.subcomponents}
                            queryFields={["name", "manufacturer", "kind"]}
                            additionalParams={{ limit: 20, select: "name,manufacturer,kind" }}
                            parseOption={(val) => {
                                val.fullName = `[${val.manufacturer}] ${val.name} (${val.kind})`;
                                val.componentId = val._id;
                                return val;
                            }}
                        />
                    </div>
                    <div className="col-12">
                        <hr />
                    </div>
                </div>}
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="form-group d-flex flex-wrap w-100 flex-column align-items-start justify-content-end">
                            <label className="w-100">Parametry komponentu</label>
                            {formik.values.parameters && formik.values.parameters.map((parameter, index) => {
                                return <Fragment key={`parameter-row-${index}`}>
                                    <div className="d-flex flex-nowrap align-items-center">
                                        <TextInput
                                            id={`parameters.${index}.name`}
                                            type="text"
                                            placeholder="Etykieta parametru"
                                            value={formik.values.parameters[index].name}
                                            onChange={(element, value) => formik.setFieldValue(element, value)}
                                            errors={_.get(formik, `errors.parameters[${index}].name`, null)}
                                        />
                                        <span className="pr-1 pl-1 mb-3">:</span>
                                        <TextInput
                                            id={`parameters.${index}.value`}
                                            type="text"
                                            placeholder="Wartość komponentu"
                                            value={formik.values.parameters[index].value}
                                            onChange={(element, value) => formik.setFieldValue(element, value)}
                                            errors={_.get(formik, `errors.parameters[${index}].value`, null)}
                                        />
                                        <div className=" d-flex justify-content-center align-items-center pl-sm-0 mt-0 flex-fill" onClick={() => handleDeleteParameter(formik, index)}>
                                            <div className="form-group flex-fill">
                                                <button data-toggle="modal" data-target="#confirmDelete" type="button" className="btn ml-2 btn-danger w-100">USUŃ</button>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            })}
                            <button type="button" className="btn btn-link p-0 mt-3" onClick={() => handleAddNewParameter(formik)}>Dodaj parametr<div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5"><img className="mt-1 ml-0" src={plusIcon} /></div></button>
                        </div>
                        {_.isString(formik.errors.parameters) && <div className="errormsg">{formik.errors.parameters}</div>}
                    </div>
                    {formik.values.type === "component" && <div className="col-12 col-md-6">
                        <Autocomplete
                            id="serviceRanges"
                            url="device/serviceRange"
                            multi={true}
                            label="Czynności konserwacyjne (zestawy czynności)"
                            optionLabelField="name"
                            optionIdField="serviceRangeId"
                            prependText={null}
                            appendText={null}
                            onOptionSelect={formik.setFieldValue}
                            value={formik.values.serviceRanges}
                            errors={formik.errors.serviceRanges}
                            queryFields={["name"]}
                            additionalParams={{ limit: 20 }}
                            parseOption={(val) => {
                                val.serviceRangeId = val._id;
                                return val;
                            }}
                        />
                    </div>}
                    <div className="col-12 col-md-8">
                        <Textarea
                            id="description"
                            rows="4"
                            label="Opis"
                            cols="100"
                            placeholder="Opis komponentu"
                            onChange={formik.setFieldValue}
                            value={formik.values.description}
                            errors={formik.errors.description}
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary mb-3 mt-3">Zapisz <img src={dodajIcon} /></button>
            </form>)}
        </Formik>
    </div>
    )
}

export default Component;