import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import {
    getContracts,
    deleteContract,
    setPage,
    setLimit,
    setQuery,
    setFilters,
    clearPage,
    clearContracts,
    clearQuery,
    clearLimit,
    clearFilters
} from '../actions/contracts.actions';
import ContractsComponent from '../components/Contracts.component';

const ContractsContainer = (props) => {

    let { page } = useParams();

    useEffect(() => {
        props.setPage(page && parseInt(page) || 1);
        props.getContracts();
        return () => {
            // CZYŚCIMY WSZYSTKO PRZED WYJŚCIEM
            props.clearPage();
            props.clearContracts();
            props.clearQuery();
            props.clearLimit();
            props.clearFilters();
        };
    }, []);

    return (
        <ContractsComponent {...props} />
    );
}

const mapStateToProps = (store) => ({
    contracts: store.contracts.contracts,
    total: store.contracts.total,
    query: store.contracts.query,
    page: store.contracts.page,
    limit: store.contracts.params.limit
});

const mapDispatchToProps = (dispatch) => {
    return {
        setPage: (page, reload) => dispatch(setPage(page, reload)),
        setLimit: (limit) => dispatch(setLimit(limit)),
        setQuery: (query) => dispatch(setQuery(query)),
        setFilters: (filtersData) => dispatch(setFilters(filtersData)),
        getContracts: () => dispatch(getContracts()),
        deleteContract: (contractId) => dispatch(deleteContract(contractId)),
        clearPage: () => dispatch(clearPage()),
        clearContracts: () => dispatch(clearContracts()),
        clearQuery: () => dispatch(clearQuery()),
        clearLimit: () => dispatch(clearLimit()),
        clearFilters: () => dispatch(clearFilters())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsContainer);
