import React, { Fragment, useEffect } from 'react';
import { useParams } from "react-router-dom";
import _ from 'lodash';
import { connect } from 'react-redux'

import { getCustomer, clearCustomer, saveCustomer } from '../actions/customers.actions';
import CustomerComponent from '../components/Customer.component';

const CustomerContainer = (props) => {

    let { id } = useParams();

    useEffect(() => {

        id && props.getCustomer(id);
        return () => {
            props.clearCustomer();
        };
    }, []);

    return (
        <Fragment>
            {(!id || (id && props.customer)) && <CustomerComponent {...props} />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    customer: store.customers.customer
});

const mapDispatchToProps = (dispatch) => {
    return {
        getCustomer: (id) => dispatch(getCustomer(id)),
        saveCustomer: (customerData) => dispatch(saveCustomer(customerData)),
        clearCustomer: () => dispatch(clearCustomer())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer);
