import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { clearDevice, getDevice } from '../actions/devices.actions';
import ServiceLife from '../components/ServiceLife.component';

const ServiceLifeContainer = (props) => {
    const { id } = useParams();

    useEffect(() => {
        id && props.getDevice(id);
        return () => {
            props.clearDevice();
        };
    }, []);

    return (
        <Fragment>
            {props.device && <ServiceLife {...props} />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    device: store.devices.device,
    user: store.app.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        getDevice: (id) => dispatch(getDevice(id)),
        clearDevice: () => dispatch(clearDevice())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLifeContainer);
