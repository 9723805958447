import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'lodash';

const SearchComponent = ({ setQuery, time }) => {

  const debounced = useRef(debounce((value) => setQuery(value), time || 400))

  const updateSearchValue = useCallback(({ target: { value } }) => {
    debounced.current(value)
  }, []);

  return (
    <input type="text" onChange={updateSearchValue} className="form-control col-md-6 col-sm-6" id="wyszukiwarka" placeholder="Szukaj" />
  )
}

SearchComponent.propTypes = {
  time: PropTypes.number,
  setQuery: PropTypes.func.isRequired
}

export default SearchComponent;