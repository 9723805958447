import React, { useState, useEffect, Fragment } from 'react';
import { Formik } from 'formik';
import { useHistory, useParams } from "react-router-dom";
import _ from 'lodash';
import moment from 'moment';

import PlusIcon from "../../assets/gfx/plus-2.svg";
import KoszIcon from "../../assets/gfx/kosz.svg";
import DodajIcon from "../../assets/gfx/dodaj.svg";

import SelectMediaModal from '../../assets/components/SelectMediaModal.component';
import TextInput from '../../assets/components/TextInput.component';
import Checkbox from '../../assets/components/Checkbox.component';
import Autocomplete from '../../assets/components/Autocomplete.component';
import Select from '../../assets/components/Select.component';
import Address from '../../assets/components/Address.component';
import Textarea from '../../assets/components/Textarea.component';
import TextSelectInput from "../../assets/components/TextSelect.component";
import Date from '../../assets/components/Date.component';

import { deviceInitialValues, deviceValidationSchema } from '../schemas/device.schema';

const BUILDING_TYPES = [
	{ value: 1, name: 'Budynek mieszkalny do 6 mieszkań, małe biura z kilkoma pracownikami (1)' },
	{ value: 2, name: 'Budynek mieszkalny do 20 mieszkań, małe biura z 2-5 kondygnacji (2)' },
	{ value: 3, name: 'Budynek mieszkalny do 50 mieszkań, małe biura 10 kondygnacji (3)' },
	{ value: 4, name: 'Budynek mieszkalny ponad 50 mieszkań, duże biura ponad 10 kondygnacji (4)' },
	{ value: 5, name: 'Małe hotele (2)' },
	{ value: 6, name: 'Średnie hotele (3)' },
	{ value: 7, name: 'Duże hotele, małe i średnie szpitale (4)' },
	{ value: 8, name: 'Budynki biurowe powyżej 100m wysokości, duże szpitale (5)' },
	{ value: 9, name: 'Dźwigi towarowe o niewielkiej ilości jazd (2)' },
	{ value: 10, name: 'Dźwigi towarowe o średniej ilości jazd (3)' },
	{ value: 11, name: 'Dźwigi towarowe mocno obciążone (5)' },
]

const durabilitySourceOptions = [
	{ name: "Instrukcja eksploatacji", value: "1" },
	{ name: "Producent/katalogi", value: "2" },
	{ name: "Osoba kompetentna", value: "3" }
];

const durabilityUnitOptions = [
	{ name: "Ilość jazd", value: "1" },
	{ name: "Cykle pracy", value: "2" },
	{ name: "Godziny pracy", value: "3" }
];

const Device = ({
	saveDevice,
	device,
	customers
}) => {

	const history = useHistory();
	const { id, action } = useParams();

	const [initialValues, setInitialValues] = useState(deviceInitialValues);

	useEffect(() => {
		if (_.get(device, "_id")) {
			device.electricalMeasurmentsExpirationDate = moment(device.electricalMeasurmentsExpirationDate).format('X');
			device.udtExaminationDate = moment(device.udtExaminationDate).format('X');
			if (action === 'clone') {
				delete device.udtNumber;
				delete device.serialNumber;
				delete device.electricalMeasurmentsExpirationDate;
			}
			setInitialValues(device);
		}
	}, [device]);

	const handleAddNewParameter = (formik) => {
		formik.setFieldValue("parameters", _.concat(formik.values.parameters, [{
			name: '',
			value: ''
		}]));
	}

	const handleDeleteParameter = (formik, index) => {
		formik.setFieldValue("parameters", _.without(formik.values.parameters, _.nth(formik.values.parameters, index)));
	}
	const handleDeleteImage = (formik, index) => {
		formik.setFieldValue("gallery", _.without(formik.values.gallery, _.nth(formik.values.gallery, index)));
	}

	const cutFileName = (fileName, cutNumber) => {
		fileName = fileName.substring(fileName.lastIndexOf("/") + 1);

		if (fileName.length > cutNumber) {
			const sliceCount = fileName.length - cutNumber;
			fileName = `...${fileName.slice(sliceCount)}`;
		}
		return fileName;
	}

	const handleAddNewComponent = (values) => {
		setInitialValues({
			...values, components: [...values.components, {}]
		});
	}

	const handleRemoveComponent = (elementIndex, values) => {
		setInitialValues({
			...values, components: [...values.components.filter((item, index) => index !== elementIndex)]
		});
	}

	const handleDeleteComponentParameter = (formik, componentIndex, paramIndex) => {
		formik.setFieldValue("components", formik.values.components.map((component, index) => {
			if (componentIndex == index) {
				component.parameters = _.without(component.parameters, _.nth(component.parameters, paramIndex));
			}
			return component;
		}));
	}

	const renderTitle = () => {
		if (action) return 'Sklonuj urządzenie';
		if (id) return 'Edytuj urządzenie';
		return 'Dodaj nowe urządzenie';
	}

	return (
		<React.Fragment>
			<div className="row mb-2">
				<div className="col-12 col-md-12 pt-3 d-flex btns-filtry">
					<h3 className="mt-0 mb-3 w-100">{renderTitle()}</h3>
				</div>
			</div>
			<Formik
				initialValues={initialValues}
				validationSchema={deviceValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
				enableReinitialize={true}
				onSubmit={async (values, { setErrors }) => {
					if (action && action === "clone") {
						delete values._id;
					}
					const res = await saveDevice(values);

					if (res.success) {
						history.push(`/devices/${res.document}`);
					} else {
						if (res.errors) {
							setErrors(res.errors);
						}
					}
				}}
			>
				{formik => (
					<form onSubmit={formik.handleSubmit}>
						<div className="row">
							<div className="col-12 col-md-6 col-lg-4">
								<TextInput
									isRequired={true}
									id="name"
									label="Nazwa"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.name}
									errors={formik.errors.name}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-4">
								<TextInput
									id="udtNumber"
									label="Numer UDT"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.udtNumber}
									errors={formik.errors.udtNumber}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-4">
								<TextInput
									id="serialNumber"
									label="Numer fabryczny"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.serialNumber}
									errors={formik.errors.serialNumber}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 col-md-6 col-lg-4">
								<TextInput
									isRequired={true}
									id="type"
									label="Typ"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.type}
									errors={formik.errors.type}
								/>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4">
								<TextInput
									isRequired={true}
									id="manufacturer"
									label="Producent"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.manufacturer}
									errors={formik.errors.manufacturer}
								/>
							</div>
							<div className="col-sm-6 col-md-6 col-lg-2 ">
								<Checkbox
									id="ceMarking"
									label="Oznakowanie CE"
									option="Tak"
									onChange={formik.setFieldValue}
									value={formik.values.ceMarking}
									errors={formik.errors.ceMarking}
								/>
							</div>
						</div>
						<div className="row pb-2">
							<div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<Autocomplete
									isRequired={true}
									id="customer"
									url="customer/customer"
									multi={false}
									label="Przypisz klienta"
									optionLabelField="name"
									optionIdField="_id"
									prependText={null}
									appendText={null}
									onOptionSelect={formik.setFieldValue}
									value={formik.values.customer}
									errors={formik.errors.customer}
									queryFields={["name"]}
									preload={true}
									additionalParams={{ limit: 20, select: "name" }}
								/>
								{/* <Select
									isRequired={true}
									id="customer"
									label="Klient"
									options={customers}
									onChange={(element, val) => { formik.setFieldValue(element, val) }}
									returnWholeObject={true}
									value={formik.values.customer}
									errors={formik.errors.customer}
								/> */}
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<Select
									isRequired={true}
									id="buildingType"
									label="Rodzaj budynku wg VDI 4707"
									options={BUILDING_TYPES}
									onChange={formik.setFieldValue}
									value={_.get(formik, 'values.buildingType')}
									errors={formik.errors.buildingType}
								/>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<TextInput
									isRequired={true}
									id="estimatedNumberOfCycles"
									label="Przewidywana ilośc cykli"
									type="number"
									onChange={formik.setFieldValue}
									value={formik.values.estimatedNumberOfCycles}
									errors={formik.errors.estimatedNumberOfCycles}
									disabled={(id && !action) ? true : false}
								/>
							</div>
							<div className="col-sm-12">
								<hr />
							</div>
						</div>

						<div className="row pb-2">
							<Address
								isRequired={true}
								value={formik.values.address}
								errors={formik.errors.address}
								onChange={(value) => formik.setFieldValue('address', value)}
							/>
							<div className="col-12 col-md-6 col-lg-4 d-flex">
								<Textarea
									id="accessTips"
									rows="10"
									label="Wskazówki dostępu do urządzenia"
									cols="110"
									placeholder="Kod do klatki, wskazówki dojścia itp..."
									onChange={formik.setFieldValue}
									value={formik.values.accessTips}
									errors={formik.errors.accessTips}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-4">
								<TextInput
									id="administrationName"
									label="Imię i nazwisko administratora"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.administrationName}
									errors={formik.errors.administrationName}
								/>
								<TextInput
									id="administrationPhone"
									label="Numer telefonu do administracji"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.administrationPhone}
									errors={formik.errors.administrationPhone}
								/>
							</div>
							<div className="col-12">
								<hr />
							</div>
						</div>
						<div className="row">
							<div className="col-12 mb-3">
								<label htmlFor='component-row-0'>
									<h3>Komponenty</h3>
								</label>
							</div>
							{formik.values.components && formik.values.components.map((component, componentIndex) => {
								return <Fragment key={`component-row-${componentIndex}`}>
									<div className="col-sm-12 col-md-6 col-lg-6 d-flex align-content-stretch">
										<Autocomplete
											isRequired={true}
											id="usedComponents"
											url="device/component"
											multi={false}
											label="Komponent"
											optionLabelField="fullName"
											optionIdField="componentId"
											prependText={null}
											appendText={null}
											onOptionSelect={(key, val) => {
												formik.setFieldValue(`components[${componentIndex}]`, val || {});
											}}
											value={component}
											errors={_.get(formik, `errors.components[${componentIndex}].componentId`) ? _.get(formik, `errors.components[${componentIndex}].componentId`) : null}
											queryFields={["name", "manufacturer", "kind"]}
											additionalParams={{ limit: 20, type: 'component' }}
											parseOption={(val) => {
												val.fullName = `[${val.manufacturer}] ${val.name} (${val.kind})`;
												val.componentId = val._id;
												return val;
											}}
										/>
									</div>
									{<div className="col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center align-items-end mb-4 pb-2" onClick={() => handleRemoveComponent(componentIndex, formik.values)}>
										<button type="button" className="btn btn-danger flex-fill">USUŃ</button>
									</div>}
									<div className="col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center align-items-end mb-4 pb-2"></div>
									{component.componentId && <div className="col-sm-6 col-md-4 col-lg-4">
										<label htmlFor='component-row-0'>
											<h4>Parametry trwałości</h4>
										</label><br />
										<TextSelectInput
											inputId={`components[${componentIndex}].estimatedDurability`}
											inputValue={formik.values.components[componentIndex].estimatedDurability}
											inputError={_.get(formik, `errors.components[${componentIndex}].estimatedDurability`) ? _.get(formik, `errors.components[${componentIndex}].estimatedDurability`) : null}
											onInputChange={formik.setFieldValue}
											selectId={`components[${componentIndex}].durabilityUnit`}
											onSelectChange={formik.setFieldValue}
											selectValue={formik.values.components[componentIndex].durabilityUnit}
											inputError={_.get(formik, `errors.components[${componentIndex}].durabilityUnit`) ? _.get(formik, `errors.components[${componentIndex}].durabilityUnit`) : null}
											placeholder=""
											label="Założona trwałość eksploatacyjna"
											type="text"
											options={durabilityUnitOptions}
										/>
									</div>}
									{component.componentId && <div className="col-sm-6 col-md-4 col-lg-4 d-flex flex-wrap">
										<TextInput
											id={`components[${componentIndex}].estimatedDurabilityYears`}
											label="Założony okres trwałości (lata)"
											type="number"
											value={formik.values.components[componentIndex].estimatedDurabilityYears}
											onChange={formik.setFieldValue}
											errors={_.get(formik, `errors.components[${componentIndex}].estimatedDurabilityYears`) ? _.get(formik, `errors.components[${componentIndex}].estimatedDurabilityYears`) : null}
										/>
									</div>}
									{component.componentId && <div className="col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap">
										<Select
											id={`components[${componentIndex}].durabilitySource`}
											label="Wybierz źródło danych"
											options={durabilitySourceOptions}
											onChange={formik.setFieldValue}
											value={formik.values.components[componentIndex].durabilitySource}
											errors={_.get(formik, `errors.components[${componentIndex}].durabilitySource`) ? _.get(formik, `errors.components[${componentIndex}].durabilitySource`) : null}
										/>
									</div>}
									{component.componentId && <div className="col-12 col-md-6 col-lg-4">
										<Date
											isRequired={true}
											id={`components[${componentIndex}].installationDate`}
											label="Data montażu"
											type="number"
											onChange={formik.setFieldValue}
											value={formik.values.components[componentIndex].installationDate}
											errors={_.get(formik, `errors.components[${componentIndex}].installationDate`) ? _.get(formik, `errors.components[${componentIndex}].installationDate`) : null}
										/>
									</div>
									}
									<div className="col-sm-12 col-md-12 col-lg-12"></div>
									<div className="col-sm-12 col-md-8 col-lg-6">
										<label htmlFor='component-row-0'>
											<h4>Parametry komponentu</h4>
										</label>
										{component.parameters && component.parameters.length > 0 && component.parameters.map((parameter, pramIndex) => {
											return <Fragment key={`parameter-row-${componentIndex}-${pramIndex}`}>
												<div className="d-flex flex-nowrap align-items-center">
													<TextInput
														id={`components[${componentIndex}].parameters.${pramIndex}.name`}
														type="text"
														placeholder="Etykieta parametru"
														value={parameter.name}
														onChange={(element, value) => formik.setFieldValue(element, value)}
														errors={_.get(formik, `errors.components[${componentIndex}].parameters[${pramIndex}].name`, null)}
													/>
													<span className="pr-1 pl-1 mb-3">:</span>
													<TextInput
														id={`components[${componentIndex}].parameters.${pramIndex}.value`}
														type="text"
														placeholder="Wartość"
														value={parameter.value}
														onChange={(element, value) => formik.setFieldValue(element, value)}
														errors={_.get(formik, `errors.components[${componentIndex}].parameters[${pramIndex}].value`, null)}
													/>
													<div className=" d-flex justify-content-center align-items-center pl-sm-0 mt-0 flex-fill" onClick={() => handleDeleteComponentParameter(formik, componentIndex, pramIndex)}>
														<div className="form-group flex-fill">
															<button data-toggle="modal" data-target="#confirmDelete" type="button" className="btn ml-2 btn-danger w-100">USUŃ</button>
														</div>
													</div>
												</div>
											</Fragment>
										})}
									</div>
									{formik.values.components.length != (componentIndex + 1) && <div className="col-12">
										<hr />
									</div>}
								</Fragment>
							})}


							<div className="col-12 mb-3">
								<div className="btn btn-link p-0 mt-2" onClick={() => handleAddNewComponent(formik.values)}>
									<span>Dodaj komponent</span>
									<div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5">
										<img className="mt-1 ml-0" src={PlusIcon} />
									</div>
									{/* {formik.errors.components && <div className="errormsg">{_.get(formik, 'errors.components')}</div>} */}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<hr />
							</div>
							<div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<Autocomplete
									preload={true}
									isRequired={true}
									id="conservator"
									url="admin/user"
									multi={false}
									label="Przydziel konserwatora"
									optionLabelField="name"
									optionIdField="_id"
									prependText={null}
									appendText={null}
									onOptionSelect={formik.setFieldValue}
									value={formik.values.conservator}
									errors={formik.errors.conservator}
									queryFields={["name"]}
									additionalParams={{ limit: 20, select: "name", type: "employee" }}
								/>
							</div>
							{!formik.values._id && <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<Date
									isRequired={true}
									id="firstServiceDate"
									label="Data pierwszej konserwacji"
									type="number"
									onChange={formik.setFieldValue}
									value={formik.values.firstServiceDate}
									errors={formik.errors.firstServiceDate}
									disabled={(id && !action) ? true : false}
								/>
							</div>}
							<div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<Date
									isRequired={true}
									id="electricalMeasurmentsExpirationDate"
									label="Ważność pomiarów"
									type="number"
									onChange={formik.setFieldValue}
									value={formik.values.electricalMeasurmentsExpirationDate}
									errors={formik.errors.electricalMeasurmentsExpirationDate}
									disabled={(id && !action) ? true : false}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<Date
									isRequired={true}
									id="udtExaminationDate"
									label="Ważność badania UDT"
									type="number"
									onChange={formik.setFieldValue}
									value={formik.values.udtExaminationDate}
									errors={formik.errors.udtExaminationDate}
									disabled={(id && !action) ? true : false}
								/>
							</div>
							<div className="col-sm-12">
								<hr />
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12 col-md-4">
								<div className="form-group">
									<label className="w-100">Parametry urządzenia</label>
									{formik.values.parameters && formik.values.parameters.map((parameter, index) => {
										return <React.Fragment key={`parameter-row-${index}`}>
											<div className="d-flex flex-nowrap align-items-center">
												<TextInput
													isRequired={true}
													id={`parameters.${index}.name`}
													type="text"
													placeholder="Etykieta parametru"
													value={formik.values.parameters[index].name}
													onChange={(element, value) => formik.setFieldValue(element, value)}
													errors={_.get(formik, `errors.parameters[${index}].name`, null)}
												/>
												<span className="pr-1 pl-1 mb-3">:</span>
												<TextInput
													isRequired={true}
													id={`parameters.${index}.value`}
													type="text"
													placeholder="Wartość"
													value={formik.values.parameters[index].value}
													onChange={(element, value) => formik.setFieldValue(element, value)}
													errors={_.get(formik, `errors.parameters[${index}].value`, null)}
												/>
												<div className=" d-flex justify-content-center align-items-center pl-sm-0 mt-0 flex-fill" onClick={() => handleDeleteParameter(formik, index)}>
													<div className="form-group flex-fill">
														<button data-toggle="modal" data-target="#confirmDelete" type="button" className="btn ml-2 btn-danger w-100">USUŃ</button>
													</div>
												</div>
											</div>
										</React.Fragment>
									})}
									<button type="button" className="btn btn-link p-0 mt-3" onClick={() => handleAddNewParameter(formik)}>Dodaj parametr<div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5"><img className="mt-1 ml-0" src={PlusIcon} /></div></button>
								</div>
							</div>
							<div className="col-sm-12 col-md-4">
								<div className="form-group ">
									<label>Galeria</label><br />

									{formik.values.gallery && formik.values.gallery.map((image, i) => {
										return (
											<div key={i} className="param-komp mb-1">
												<img className="akcja usun mr-3" src={KoszIcon}
													onClick={() => handleDeleteImage(formik, i)}
												/>
												{cutFileName(image, 25)}
											</div>
										)
									})}
									<SelectMediaModal
										modalId="1"
										buttonName="Wybierz zdjecia"
										onSelect={(src) => formik.setFieldValue('gallery', _.concat(formik.values.gallery, [src]))}
									/>
								</div>
							</div>
							<div className="col-12 col-md-8">
								<Textarea
									id="description"
									rows="4"
									label="Opis"
									cols="110"
									placeholder="Opis urządzenia"
									onChange={formik.setFieldValue}
									value={formik.values.description}
									errors={formik.errors.description}
								/>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4">
								<TextInput
									id="maintenanceCompany"
									label="Firma serwisująca"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.maintenanceCompany}
									errors={formik.errors.maintenanceCompany}
									isRequired={true}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 col-md-6 col-lg-4">
								<TextInput
									id="udtInspector"
									label="Opiekun ze strony UDT"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.udtInspector}
									errors={formik.errors.udtInspector}
								/>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-4">
								<TextInput
									id="installer"
									label="Instalator"
									type="text"
									onChange={formik.setFieldValue}
									value={formik.values.installer}
									errors={formik.errors.installer}
									isRequired={true}
								/>
							</div>
							<div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<Date
									isRequired={true}
									id="installationDate"
									label="Rok montażu"
									type="number"
									onChange={formik.setFieldValue}
									value={formik.values.installationDate}
									errors={formik.errors.installationDate}
									disabled={(id && !action) ? true : false}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-12 col-md-6 col-lg-4 d-flex align-content-stretch">
								<TextInput
									isRequired={true}
									id="numberOfCycles"
									label="Aktualna liczba jazd"
									type="number"
									onChange={formik.setFieldValue}
									value={formik.values.numberOfCycles}
									errors={formik.errors.numberOfCycles}
								/>
							</div>
						</div>
						<button type="submit" className="btn btn-primary mb-5 mt-2">Zapisz <img
							src={DodajIcon} /></button>
					</form>
				)}
			</Formik>

		</React.Fragment>
	)
}

export default Device;