import React, { useState } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import _ from 'lodash';

import { getNipData } from '../actions/customers.actions';

const NIPSearchComponent = ({ onChange, value, errors, getNipData, setFieldValue, isRequired }) => {

    const [showLoader, setShowLoader] = useState(false);
    const [nipValue, setNipValue] = useState("");
    const [formError, setFormError] = useState(null);

    const setNIpDataValues = (nipData) => {
        if (Object.keys(nipData).length > 0) {

            setFieldValue('address', {
                area_1: _.get(nipData, "address.area_1", ""),
                area_2: _.get(nipData, "address.area_2", ""),
                area_3: _.get(nipData, "address.area_3", ""),
                locality: _.get(nipData, "address.locality", ""),
                postCode: _.get(nipData, "address.postCode", ""),
                street: _.get(nipData, "address.street", "").replace("ul. ", ""),
                streetNumber: _.get(nipData, "address.streetNumber", ""),
                coordinates: _.get(nipData, "address.coordinates", [52.237049, 21.017532])
            });

            setFieldValue('name', _.get(nipData, "name", ""));
            setFieldValue('gusName', _.get(nipData, "name", ""));

            document.getElementById('adres-autocomplete').value = `${_.get(nipData, "address.street", "").replace("ul. ", "")} ${_.get(nipData, "address.streetNumber", "")}, ${_.get(nipData, "address.locality", "")}, Polska`;
        }
    }

    const handleChangeNipValue = (nip) => {
        setNipValue(nip);

        // setujemy funkcją od rodzica wartość wybranego miejsca
        if (_.isFunction(onChange)) {
            onChange(nip);
        }
    }

    const handleSearchNipData = () => {

        setFormError(null);

        if (nipValue && nipValue.length == "10") {
            setShowLoader(true);

            getNipData(nipValue).then(res => {
                if (res.success) {
                    setNIpDataValues(res.document);
                } else {
                    setFormError(_.get(res, 'errors.NIP', null));
                }
                setShowLoader(false);
            });
        } else {
            setFormError("NIP musi zawierać 10 znaków");
        }
    }

    return (
        <div className={`form-group ${(errors || formError) ? 'error' : ''}`}>
            <label htmlFor="nip-klienta">NIP {isRequired && <span className="is-required">*</span>}</label>
            <div className="input-group">
                {showLoader && <div className="nip-mask-loader">
                    <Loader
                        className=""
                        type="Circles"
                        color="#00BFFF"
                        height={30}
                        width={30}
                        visible={true}
                    />
                </div>}
                <input type="text" defaultValue={value || ''} className="form-control" onChange={(e) => handleChangeNipValue(e.target.value)} placeholder="" aria-label="" aria-describedby="basic-addon1" />
                <div className="input-group-append">
                    <button onClick={() => handleSearchNipData()} className="btn btn-info gusbtn bgkolor2" type="button">Szukaj w GUS</button>
                </div>
            </div>
            {(errors || formError) && <div className="errormsg">{errors || formError}</div>}
        </div>
    )
}

NIPSearchComponent.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    errors: PropTypes.string,
    getNipData: PropTypes.func,
    setFieldValue: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNipData: (nip) => dispatch(getNipData(nip))
    }
};

export default connect(null, mapDispatchToProps)(NIPSearchComponent);

