import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import RolesComponent from '../components/Roles.component';

import { getRoles, saveRole, deleteRole, clearRoles, clearErrors } from '../actions/roles.actions';

const RolesContainer = ({ getRoles, roles, saveRole, errors, deleteRole, clearRoles, clearErrors }) => {

   useEffect(() => {
      getRoles();

      // cleanup
      return () => {
         clearRoles();
         clearErrors();
      }
   }, [])

   return (
      <RolesComponent roles={roles} saveRole={saveRole} errors={errors} deleteRole={deleteRole} />
   );
}

const mapStateToProps = (store) => ({
   roles: store.roles.roles,
   errors: store.roles.errors
});

const mapDispatchToProps = (dispatch) => {
   return {
      getRoles: () => dispatch(getRoles()),
      saveRole: (roleId, data) => dispatch(saveRole(roleId, data)),
      deleteRole: (roleId) => dispatch(deleteRole(roleId)),
      clearRoles: () => dispatch(clearRoles()),
      clearErrors: () => dispatch(clearErrors())
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer);
