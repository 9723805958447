import React from 'react';

const Checkbox = ({ id, label, onChange, errors, option, value, isRequired }) => {

    const renderErrors = () => {
        if (!errors) return;

        if (Array.isArray(errors)) {
            return errors.map((e, i) => {
                return <div key={i} className="errormsg">{e}</div>
            })
        }

        if (typeof errors === 'string') {
            return <div className="errormsg">{errors}</div>
        }
    }

    return (
        <div className={`form-group ${errors ? 'error' : null}`}>
            <label >{label} {isRequired && <span className="is-required">*</span>}</label>
            <div className="custom-control custom-checkbox">
                <input
                    id={id}
                    type="checkbox"
                    className="custom-control-input"
                    onClick={(e) => onChange(id, e.target.checked)}
                    onChange={() => { }}
                    checked={value}
                />
                <label className="custom-control-label mt-2" htmlFor={id}>{option}</label>
            </div>
            {renderErrors()}
        </div>
    )
}

export default Checkbox;