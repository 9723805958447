import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import Media from '../components/Media.component';

import { saveMedia } from '../actions/medias.actions';

const MediaContainer = ({
    saveMedia,
    history
}) => {

    return (
        <Media
            saveMedia={saveMedia}
            history={history}
        />
    );
}

const mapStateToProps = (store) => ({
    history: store.app.history
});

const mapDispatchToProps = (dispatch) => {
    return {
        saveMedia: (form) => dispatch(saveMedia(form)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaContainer);
