import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getServiceRange, clearServiceRange, saveServiceRange } from '../actions/serviceRanges.actions';
import ServiceRange from '../components/serviceRange.component';

const ServiceRangeContainer = (props) => {
    const { getServiceRange, clearServiceRange } = props;
    let { id } = useParams();
    useEffect(() => {

        if (id) {
            getServiceRange(id);
        }
        return () => {
            clearServiceRange();
        }
    }, [getServiceRange, clearServiceRange])

    return (
        <Fragment>
            <ServiceRange
                {...props}
            />
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    serviceRange: store.serviceRanges.serviceRange
});

const mapDispatchToProps = (dispatch) => {
    return {
        getServiceRange: (id) => dispatch(getServiceRange(id)),
        clearServiceRange: () => dispatch(clearServiceRange()),
        saveServiceRange: (data) => dispatch(saveServiceRange(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRangeContainer);
