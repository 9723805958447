import React from 'react';
import { Link } from 'react-router-dom';
import AclManager from '../../application/services/aclManager';
import _ from 'lodash';

import ResursIcon from '../../assets/gfx/resurs.svg';
import EdytujIcon from '../../assets/gfx/edytuj.svg';
import PowielIcon from '../../assets/gfx/powiel.svg';
import KoszIcon from '../../assets/gfx/kosz.svg';


const MediasListCard = ({
    index,
    device, device: { _id, udtNumber, name, customer, conservator },
    setDeviceToDelete,
    getResurs
}) => {

    return (

        <tr>
            <td>{index + 1}.</td>
            <td><Link to={`/devices/${_id}`}>{name}</Link></td>
            <td>{udtNumber}</td>
            <td>{_.get(customer, 'name', '-')}</td>
            <td>
                <div className="usercolor-container rounded-circle float-left mr-2">
                    <div className="usercolor rounded-circle" style={{ backgroundColor: _.get(conservator, 'color', 'white') }}></div>
                </div> {_.get(conservator, 'name', '-')}
            </td>
            <td><Link to={`/serviceLife/${_id}`} style={{ cursor: 'pointer' }} title="Generuj RESURS" className="text-secondary"><img
                src={ResursIcon} /></Link></td>
            <td>
                {
                    AclManager.isAllowedMethod('device/device', 'update') &&
                    <Link to={`/device/${_id}`} title="Edytuj urządzenie">
                        <img className="akcja edytuj" src={EdytujIcon} />
                    </Link>
                }
                {
                    AclManager.isAllowedMethod('device/device', 'create') &&
                    <Link to={`/device/${_id}/clone`} title="Powiel urządzenie">
                        <img className="akcja powiel" src={PowielIcon} />
                    </Link>
                }
                {
                    AclManager.isAllowedMethod('device/device', 'delete') &&
                    <span onClick={() => setDeviceToDelete(device)} data-toggle="modal" data-target="#confirmDelete" title="Usuń"
                    ><img className="akcja usun" src={KoszIcon} />
                    </span>
                }

            </td>
        </tr>
    )
}

export default MediasListCard;


// MediasListCard.propTypes = {
//     media: PropTypes.object,
//     media: PropTypes.shape({
//         mimetype: PropTypes.string.isRequired,
//         src: PropTypes.string.isRequired,
//         name: PropTypes.string.isRequired,
//         createdAt: PropTypes.string.isRequired,
//     })
// }