import _ from "lodash";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import WarehousesListCard from "./WarehouseListCard.component";
import TransferModal from "../containers/TransferModal.container";
import dodajIcon from "../../assets/gfx/dodaj.svg";
import arrowsIcon from "../../assets/gfx/arrows.svg";
import Pagination from "../../assets/components/Pagination.component";
import SearchComponent from '../../assets/components/Search.component';
import WarehouseFilters from './WarehouseFilters.component';
import AclManager from '../../application/services/aclManager';
import ConfirmModal from '../../assets/components/ConfirmModal.component';
import SortListComponent from '../../assets/components/SortList.component';

const Warehouses = ({ warehouses, page, total, limit, setPage, setLimit, setQuery, setFilters, deleteWarehouse }) => {

    const [warehouseToDelete, setWarehouseToDelete] = useState(null);

    const fields = [
        { id: 'name', name: 'Magazyn' },
        { id: '', name: 'Właściciel' },
        { id: '', name: 'Stan magazynu' },
        { id: '', name: 'Wartość' },
    ]

    const handleDelete = () => {
        deleteWarehouse(_.get(warehouseToDelete, '_id'));
        setWarehouseToDelete(null);
    }

    const renderItems = () => {
        if (!_.isEmpty(warehouses)) {
            return warehouses.map((warehouse, index) => <WarehousesListCard key={index} item={warehouse} handleDelete={setWarehouseToDelete} index={index} />);
        }
        return <tr><td colSpan="5">Brak elementów</td></tr>;
    }

    return (<div>
        <div className="row mb-2">
            <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                <h3 className="w-50 mt-3">Magazyny</h3>
            </div>
        </div>
        <div className="container-fluid p-0">
            <div className="row p-0">
                <div className="col-12 col-md-6 ">
                    {AclManager.isAllowedMethod('warehouse/warehouse', 'create') && <Link to="warehouse" className="btn btn-primary mb-3 float-md-left mr-3">Dodaj magazyn <img src={dodajIcon} /></Link>}
                    {AclManager.getIsAdmin() && <button type="submit" className="btn btn-info mb-3 float-md-left" data-toggle="modal" data-target="#transfer-mm">Generuj MM <img src={arrowsIcon} /></button>}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                    <SearchComponent setQuery={setQuery} />
                    <WarehouseFilters setFilters={setFilters} />
                </div>
            </div>
        </div>
        <div className="table-responsive ramkatabeli ikonki mb-2">
            <table className="table table-hover rounded-10 tabela-left">
                <thead>
                    <SortListComponent fields={fields} defaultSortBy='name' setFilters={setFilters} defaultSortType={1} />
                </thead>
                <tbody>
                    {renderItems()}
                </tbody>
            </table>
        </div>
        <Pagination totalCount={total} route={"/warehouses"} page={parseInt(page)} setPage={(page) => setPage(page, true)} limit={limit} setLimit={setLimit} />
        <TransferModal />
        {warehouseToDelete && <ConfirmModal
            id="confirmDelete"
            label="Czy na pewno chcesz usunąć ten magazyn?"
            positionName={_.get(warehouseToDelete, 'name')}
            onConfirm={handleDelete}
            onCancel={() => setWarehouseToDelete(null)}
        />}
    </div>
    )
}

export default Warehouses;