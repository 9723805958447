import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getWarehouse, clearWarehouse, saveWarehouse } from '../actions/warehouses.actions';
import Warehouse from '../components/Warehouse.component';

const WarehouseContainer = (props) => {
    const { getWarehouse, clearWarehouse } = props;
    let { id } = useParams();
    useEffect(() => {

        if (id) {
            getWarehouse(id);
        }
        return () => {
            clearWarehouse();
        }
    }, [getWarehouse, clearWarehouse])

    return (
        <Fragment>
            <Warehouse
                {...props}
            />
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    warehouse: store.warehouses.warehouse
});

const mapDispatchToProps = (dispatch) => {
    return {
        getWarehouse: (id) => dispatch(getWarehouse(id)),
        clearWarehouse: () => dispatch(clearWarehouse()),
        saveWarehouse: (data) => dispatch(saveWarehouse(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseContainer);
