import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import AclManager from '../../application/services/aclManager';

import TickIcon from '../../assets/gfx/tick.svg'
import WykrzykIcon from '../../assets/gfx/wykrzyk.svg'
import TrashIcon from '../../assets/gfx/kosz.svg';
import EditIcon from '../../assets/gfx/edytuj.svg';

const SERVICE_TYPES = {
   'udt_examination': 'Badanie UDT',
   'fault': 'Awaria',
   'conservation': 'Konserwacja',
   'measurement': 'Pomiary',
   'other': 'Inne',
   'repair': 'Naprawa'
};

const ServicesListCardComponent = ({ index, service, handleDelete }) => {

   const getStatus = (service) => {
      let { dateTo, startDate, completionDate } = service;

      if (completionDate) return 'success';
      if (!completionDate && !startDate && moment(dateTo).isBefore(moment())) return 'warning';
      if (startDate && !completionDate) return 'primary';
      return ''
   }

   const getStatusByColor = (service) => {
      const colorStatus = getStatus(service);

      switch (colorStatus) {
         case 'success':
            return <div className="service-status status-complete" >
               <img src={TickIcon} className="m--1" />
            </div >;
         case 'warning':
            return <div className="service-status status-warning">
               <img src={WykrzykIcon} className="m--1" />
            </div>;
         case 'primary':
            return <div className="service-status status-in-progress">
               <div className="spinner-border text-primary ml-1 mr-4 mt-1" role="status"></div>
            </div>;
         default:
            return <div className="service-status status-new"></div>;
      }
   }

   const getActivityDuration = () => {
      let { startDate, completionDate } = service;
      let duration;

      if (!completionDate) {
         return '-';
      }

      if (completionDate) {
         duration = moment(completionDate).diff(startDate);
      } else {
         duration = moment().diff(startDate);
      }

      return `${moment.utc(duration).format("H:mm").split(':')[0]}h ${moment.utc(duration).format("H:mm").split(':')[1]}m`;
   }

   return <tr key={index} className={`table-${getStatus(service)}`}>
      <td>{`${index}.`}</td>
      <td>
         <Link to={`/services/${service._id}`}>
            {_.get(service, 'number', '-')}
         </Link>
      </td>
      <td>
         <Link to={`/services/${service._id}`}>
            {SERVICE_TYPES[service.type] || '-'}
         </Link>
      </td>
      <td>{_.get(service, 'device.name', '-')}</td>
      <td>{moment(service.dateFrom).format("DD.MM.YYYY HH:mm")}</td>
      <td>{moment(service.dateTo).format("DD.MM.YYYY HH:mm")}</td>
      <td>{getActivityDuration()}</td>
      <td> <Link to={`/users/${service.conservator.conservatorId}`}> {_.get(service, 'conservator.name', '-')} </Link></td>
      <td>
         {getStatusByColor(service)}
      </td>
      <td>
         {AclManager.isAllowedMethod('service/service', 'update') && !_.get(service, 'completionDate') && !_.get(service, 'startDate') && _.get(service, 'type') != 'planned_conservation' &&
            <Link to={`/service/${_.get(service, '_id')}`}>
               <img className="akcja edytuj" src={EditIcon} />
            </Link>}
         {AclManager.isAllowedMethod('service/service', 'delete') && service.type !== "conservation" && !_.get(service, 'completionDate') && !_.get(service, 'startDate') &&
            <span onClick={() => handleDelete(service)} data-toggle="modal" data-target="#confirmDelete" title="Dezaktywuj zlecenie">
               <img className="akcja usun" src={TrashIcon} />
            </span>}
      </td>
   </tr>
}

export default ServicesListCardComponent;