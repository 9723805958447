import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux'

import UsersComponent from '../components/Users.component';

import {
   getUsers,
   deleteUser,
   setPage,
   setLimit,
   setQuery,
   setFilters,
   clearPage,
   clearUsers,
   clearQuery,
   clearLimit,
   clearFilters
} from '../actions/users.actions';

const UsersContainer = (props) => {

   let { page } = useParams();

   useEffect(() => {
      props.setPage(page && parseInt(page) || 1);
      props.getUsers();

      return () => {
         props.clearPage();
         props.clearUsers();
         props.clearQuery();
         props.clearLimit();
         props.clearFilters();
      }
   }, [])

   return (
      <UsersComponent {...props} />
   );
}

const mapStateToProps = (store) => ({
   users: store.users.users,
   query: store.users.query,
   page: store.users.page,
   limit: store.users.params.limit,
   total: store.users.total,
});

const mapDispatchToProps = (dispatch) => {
   return {
      setPage: (page, reload) => dispatch(setPage(page, reload)),
      setLimit: (limit) => dispatch(setLimit(limit)),
      setQuery: (query) => dispatch(setQuery(query)),
      setFilters: (filtersData) => dispatch(setFilters(filtersData)),
      getUsers: () => dispatch(getUsers()),
      deleteUser: (userId) => dispatch(deleteUser(userId)),
      clearPage: () => dispatch(clearPage()),
      clearUsers: () => dispatch(clearUsers()),
      clearQuery: () => dispatch(clearQuery()),
      clearLimit: () => dispatch(clearLimit()),
      clearFilters: () => dispatch(clearFilters()),
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
