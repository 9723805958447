import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import _ from "lodash";
import AclManager from '../../application/services/aclManager';
import AddIcon from "../../assets/gfx/dodaj.svg";
import ConfirmModal from '../../assets/components/ConfirmModal.component';
import CustomersListCard from "./CustomersListCard.component";
import Pagination from '../../assets/components/Pagination.component';
import SearchComponent from '../../assets/components/Search.component';
import CustomerFilters from './CustomerFilters.component';
import SortListComponent from '../../assets/components/SortList.component';

const CustomersComponent = ({ customers, page, total, limit, setPage, setLimit, setQuery, setFilters, deleteCustomer }) => {

    const [customerToDelete, setCustomerToDelete] = useState(null);

    const fields = [
        { id: 'name', name: 'Nazwa' },
        { id: 'NIP', name: 'NIP' },
        { id: 'address.formattedAddress', name: 'Adres rozliczeniow' }
    ]

    const handleDelete = () => {
        deleteCustomer(_.get(customerToDelete, '_id'));
        setCustomerToDelete(null);
    }

    const handleCancelConfirm = () => {
        setCustomerToDelete(null);
    }

    const renderCustomers = () => {
        if (!_.isEmpty(customers)) {
            return customers.map((customer, index) => <CustomersListCard key={index} customer={customer} handleDelete={setCustomerToDelete} index={index + 1} />);
        }
        return <tr><td colSpan="6">Brak elementów</td></tr>;
    }

    return (
        <Fragment>
            {customerToDelete && <ConfirmModal
                id="confirmDelete"
                label="Czy na pewno chcesz usunąć tego klienta?"
                positionName={_.get(customerToDelete, 'name')}
                onConfirm={handleDelete}
                onCancel={handleCancelConfirm}
            />}
            <div className="row mb-2">
                <div className="col-12 col-md-12 pt-3 d-flex btns-filtry">
                    <h3 className="mt-0 mb-2 w-100">Klienci</h3>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="row p-0">
                    <div className="col-12 col-md-6 ">
                        {AclManager.isAllowedMethod('customer/customer', 'create') && <Link type="submit" className="btn btn-primary mb-3 float-md-left mr-3" to="/customer">Dodaj <img src={AddIcon} /></Link>}
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                        <SearchComponent setQuery={setQuery} />
                        <CustomerFilters setFilters={setFilters} />
                    </div>
                </div>
            </div>
            <div className="table-responsive ikonki ramkatabeli tabela-left mb-3">
                <table id="klienci" className="table table-hover rounded-10">
                    <thead>
                        <SortListComponent fields={fields} defaultSortBy='name' setFilters={setFilters} defaultSortType={1} />
                    </thead>
                    <tbody>
                        {renderCustomers()}
                    </tbody>
                </table>
            </div>
            <Pagination totalCount={total || 0} route={"/customers"} page={parseInt(page)} setPage={(page) => setPage(page, true)} limit={limit} setLimit={setLimit} />
        </Fragment>
    )
}

CustomersComponent.propTypes = {
    customers: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
    setQuery: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    deleteCustomer: PropTypes.func.isRequired
};

export default CustomersComponent;