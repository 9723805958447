import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import Logo from '../../assets/gfx/logo.svg';
import Zaloguj from '../../assets/gfx/zaloguj.svg';

import TextInput from '../../assets/components/TextInput.component';

const Login = ({
    login
}) => {


    const history = useHistory();

    return (
        <div className="d-flex" id="wrapper-login">
            <div id="page-content-wrapper">
                <div className="h-100 container container-fluid">
                    <div className="row h-100">
                        <div className="col-12 d-flex justify-content-center align-content-center flex-wrap">
                            <div className="col-sm-12 col-md-12">
                                <img className="logoLogin" src={Logo} />
                            </div>
                            <div className="col-sm-12 col-md-12 ">
                                <div className="container-login elemencikprzed elemencikpo">
                                    <div className="dekor"></div>

                                    <Formik
                                        initialValues={{ email: '', password: '' }}
                                        validationSchema={Yup.object({
                                            email: Yup.string()
                                                .required(`Login jest wymagany`)
                                                .email(`Niepoprawny adres e-mail`),
                                            password: Yup.string()
                                                .required(`Podaj hasło`)
                                        })}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        onSubmit={(values, { setErrors }) => {
                                            login(values.email, values.password).then(res => {
                                                if (res && res.success) {
                                                    history.push('/')
                                                } else {
                                                    if (res && res.errors) {
                                                        setErrors(res.errors);
                                                    }
                                                }
                                            })
                                        }}
                                    >
                                        {formik => (
                                            <form className="input-group-login flex-column ml-4 mr-4" onSubmit={formik.handleSubmit}>
                                                <TextInput
                                                    id="email"
                                                    label="Adres e-mail"
                                                    type="text"
                                                    onChange={formik.setFieldValue}
                                                    value={formik.values.email}
                                                    errors={formik.errors.email}
                                                />
                                                <TextInput
                                                    id="password"
                                                    label="Hasło"
                                                    type="password"
                                                    onChange={formik.setFieldValue}
                                                    value={formik.values.password}
                                                    errors={formik.errors.password}
                                                />
                                                <button type="submit" className="bgkolor borderkolor zaloguj-button btn btn-primary center">Zaloguj <img src={Zaloguj} /></button>
                                            </form>
                                        )}
                                    </Formik>


                                </div>
                            </div>
                            <div className="kontakthead mt-1 col-sm-12 col-md-4 d-flex justify-content-center flex-wrap align-content-center align-self-end text-center">
                                <h4 className="w-100">Biuro</h4>
                                <a href="tel:123456789"><span className="icon icon-telefon"></span></a>
                                <a href="mailto:biuro@fixlift.pl"><span className="icon icon-mail"></span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Login;