import * as Yup from 'yup';


export const settingsInitialValues = {
    vatRate: '',
    deliveryRate: '',
    serviceTime: '',
    addEventsToSaturday: true,
    addEventsToSunday: false,
    workingHours: {
        from: '',
        to: ''
    },
    holidays: []
}

const holidaySchema = Yup.object().shape({
    name: Yup.string().required('name required'),
    date: Yup.string().required('date required')
});

export const settingsValidationSchema = Yup.object({
    holidays: Yup.array().of(holidaySchema),
    vatRate: Yup.number()
        .required(`Stawka VAT jest wymagana`),
    deliveryRate: Yup.number()
        .required(`Stawka za dojazd jest wymagana`)
        .min(0, 'Stawka za dojazd nie może być mniejsza niż 0'),
    serviceTime: Yup.number()
        .required(`Czas realizacji zlecenia (w minutach) jest wymagany`)
        .min(1, 'Czas realizacji nie może być mniejszy niż minuta'),
    workingHours: Yup.object().shape({
        from: Yup.string().required('Godzina rozpoczęcia pracy jest wymagana'),
        to: Yup.string().required('Godzina zakończenia pracy jest wymagana')
    })
})
