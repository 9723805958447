import _ from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { clearDevice, clearDevicesServices, getDevice, getDevicesServices, deactivateDevice, activateDevice } from '../actions/devices.actions';
import DeviceDetails from '../components/DeviceDetails.component';
import DeviceDetailsCustomer from '../components/DeviceDetailsCustomer.component';

const DeviceDetailsContainer = (props) => {
    const { id } = useParams();

    useEffect(() => {
        id && props.getDevice(id);
        return () => {
            props.clearDevice();
            props.clearDevicesServices();
        };
    }, []);

    useEffect(() => {
        id && props.userType === "customer" && props.getDevicesServices(id);
    }, [props.userType])

    const renderComponent = () => {
        if (props.userType === "customer") return <DeviceDetailsCustomer {...props} />;
        return <DeviceDetails {...props} />;
    }

    return (
        <Fragment>
            {(!id || (id && props.device)) && renderComponent()}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    device: store.devices.device,
    services: store.devices.services,
    userType: _.get(store, 'app.user.type')
});

const mapDispatchToProps = (dispatch) => {
    return {
        getDevice: (id) => dispatch(getDevice(id)),
        getDevicesServices: (id) => dispatch(getDevicesServices(id)),
        clearDevice: () => dispatch(clearDevice()),
        clearDevicesServices: () => dispatch(clearDevicesServices()),
        deactivateDevice: (id) => dispatch(deactivateDevice(id)),
        activateDevice: (id, nextServiceDate) => dispatch(activateDevice(id, nextServiceDate)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsContainer);
