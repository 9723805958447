import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getMedias, clearMedias, setQuery, clearQuery } from '../../medias/actions/medias.actions';
import SelectMediaModalCard from '../../assets/components/SelectMediaModalCard.component';
import Search from '../../assets/components/Search.component';

const SelectMediaModal = ({ getMedias, clearMedias, medias, onSelect, setQuery, modalId, buttonName }) => {

    useEffect(() => {
        getMedias();

        return () => {
            clearQuery();
            clearMedias();
        }
    }, [])

    const renderMedias = () => {
        if (_.isEmpty(medias)) {
            return <tr><td colSpan="4">Brak mediów</td></tr>
        }

        return medias.map((media, i) => {
            return (
                <SelectMediaModalCard
                    key={i}
                    media={media}
                    onSelect={onSelect}
                />
            )
        })
    }

    return (
        <React.Fragment>
            <button className="btn btn-primary bgkolor2" data-toggle="modal" data-target={`#modal-select-media-${modalId}`} type="button">{buttonName || 'Wybierz z biblioteki'}</button>
            <div className="modal fade" id={`modal-select-media-${modalId}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenteredLabel">Wybierz plik z biblioteki mediów</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex flex-wrap align-items-center ">
                                <div>Przeszukaj bibliotekę mediów:</div>
                                <div className="w-100 mb-3 mt-1">
                                    <Search setQuery={setQuery} />
                                </div>
                                <div className="table-responsive ramkatabeli ikonki tabela-left mb-2">
                                    <table className="table table-hover rounded-10 tabela-biblioteka">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Nazwa</th>
                                                <th>Plik</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderMedias()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" data-dismiss="modal" className="btn btn-light mt-3 mb-1 centruj">Zamknij</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        medias: store.medias.medias,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMedias: () => dispatch(getMedias()),
        clearMedias: () => dispatch(clearMedias()),
        setQuery: (query) => dispatch(setQuery(query)),
        clearQuery: () => dispatch(clearQuery()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectMediaModal);