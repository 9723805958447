import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash'
import PropTypes from 'prop-types';
import FiltrujIcon from '../../assets/gfx/filtruj.svg';
import TextInput from '../../assets/components/TextInput.component';
import Autocomplete from '../../assets/components/Autocomplete.component';
import Select from '../../assets/components/Select.component';

const mapFields = {
    'name': 'name',
    'type': 'type',
    'owner': 'owner'
}

const warehouseTypeOptions = [{ name: "Magazyn Główny", value: "main" }, { name: "Magazyn Konserwatora", value: "secondary" }];

const WarehouseFiltersComponent = ({
    setFilters
}) => {

    const handleResetFilters = (resetForm) => {
        resetForm();
        setFilters({});
    }

    return (
        <React.Fragment>
            <button type="submit" className="btn btn-link text-dark" data-toggle="modal" data-target="#modal-filtruj">Filtruj <img src={FiltrujIcon} /></button>
            <div className="modal fade" id="modal-filtruj" tabIndex="-1" role="dialog" aria-labelledby="filtrowanie"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="filtrowanie">Filtry magazynu</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={{
                                    'name': '',
                                    'owner': '',
                                    'type': ''
                                }}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={(values) => {
                                    const filters = {};
                                    Object.keys(values).map(key => {
                                        if (values[key] !== '') {
                                            filters[mapFields[key]] = values[key];
                                        }
                                    });

                                    setFilters(filters);
                                }}
                            >
                                {formik => (
                                    <form className="input-group-login flex-column" onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <TextInput
                                                        id="name"
                                                        label="Nazwa"
                                                        type="text"
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.name}
                                                        errors={formik.errors.name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <Select
                                                        id="type"
                                                        label="Typ magazynu"
                                                        options={warehouseTypeOptions}
                                                        onChange={formik.setFieldValue}
                                                        value={formik.values.type}
                                                        errors={formik.errors.type}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="filtr">
                                                    <Autocomplete
                                                        id="owner"
                                                        url="admin/user"
                                                        multi={false}
                                                        label="Właściciel"
                                                        optionLabelField="name"
                                                        optionIdField="_id"
                                                        prependText={null}
                                                        appendText={null}
                                                        onOptionSelect={(id, value) => formik.setFieldValue(id, _.get(value, "_id", ''))}
                                                        value={formik.values.owner}
                                                        errors={formik.errors.owner}
                                                        queryFields={["name"]}
                                                        additionalParams={{ limit: 20, select: "name" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer pr-0">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                                            <button type="button" className="btn btn-secondary" onClick={() => handleResetFilters(formik.resetForm)}>Wyczyść</button>
                                            <button type="button" type="submit" className="btn btn-primary mr-0">Filtruj</button>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

WarehouseFiltersComponent.propTypes = {
    setFilters: PropTypes.func.isRequired
};

export default WarehouseFiltersComponent;