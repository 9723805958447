import { SET_SETTINGS } from '../actions/settings.actions';

const defaultState = {
    settings: null,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_SETTINGS:
            return {
                ...state,
                settings: action.settings
            }
        default:
            return state;

    }
}