import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash'
import PropTypes from 'prop-types';

import FiltrujIcon from '../../assets/gfx/filtruj.svg';
import TextInput from '../../assets/components/TextInput.component';
import Select from '../../assets/components/Select.component';
import Autocomplete from '../../assets/components/Autocomplete.component';

// metoda mapująca pola do setowania filtrów 
const mapFields = {
   'name': 'name[or]',
   'type': 'type[or]',
   'role': 'role[or]',
   'qualificationCertificate': 'qualificationCertificate[or]'
}

const UserFiltersComponent = ({
   setFilters
}) => {

   const handleResetFilters = (resetForm) => {
      resetForm();
      setFilters({});
   }

   const userTypeOptions = [
      { name: "Klient", value: 'customer' },
      { name: "Pracownik", value: 'employee' },
      { name: "Administrator", value: 'admin' }
   ];

   return (
      <React.Fragment>
         <button type="submit" className="btn btn-link text-dark" data-toggle="modal" data-target="#modal-filtruj">Filtruj <img src={FiltrujIcon} /></button>
         <div className="modal fade" id="modal-filtruj" tabIndex="-1" role="dialog" aria-labelledby="filtrowanie"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
               <div className="modal-content">
                  <div className="modal-header">
                     <h5 className="modal-title" id="filtrowanie">Filtry klienta</h5>
                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div className="modal-body">
                     <Formik
                        initialValues={{
                           'name': '',
                           'type': '',
                           'role': null,
                           'qualificationCertificate': ''
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={(values) => {
                           if (_.get(values, 'role')) values.role = _.get(values, 'role._id');

                           const filters = {};
                           Object.keys(_.pickBy(values, _.identity)).map(key => {
                              if (values[key] !== '') {
                                 filters[mapFields[key]] = values[key];
                              }
                           });

                           setFilters(filters);
                        }}
                     >
                        {formik => (
                           <form className="input-group-login flex-column" onSubmit={formik.handleSubmit}>
                              <div className="row">
                                 <div className="col-6">
                                    <div className="filtr">
                                       <TextInput
                                          id="name"
                                          label="Nazwa"
                                          type="text"
                                          onChange={formik.setFieldValue}
                                          value={formik.values.name}
                                          errors={formik.errors.name}
                                       />
                                    </div>
                                 </div>
                                 <div className="col-6">
                                    <div className="filtr">
                                       <Select
                                          id="type"
                                          label="Typ"
                                          options={userTypeOptions}
                                          onChange={(element, val) => formik.setFieldValue(element, val)}
                                          value={formik.values.type}
                                          errors={formik.errors.type}
                                       />
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-6">
                                    <div className="filtr">
                                       <Autocomplete
                                          id="role"
                                          label="Rola"
                                          url="admin/role"
                                          multi={false}
                                          optionLabelField="name"
                                          optionIdField="_id"
                                          onOptionSelect={formik.setFieldValue}
                                          value={formik.values.role}
                                          errors={formik.errors.role}
                                          queryFields={['name']}
                                          additionalParams={{ limit: 20 }}
                                       />
                                    </div>
                                 </div>
                                 <div className="col-6">
                                    <div className="filtr">
                                       <TextInput
                                          id="qualificationCertificate"
                                          label="Numer świadectwa kwalifikacji"
                                          type="text"
                                          value={formik.values.qualificationCertificate}
                                          onChange={formik.setFieldValue}
                                          errors={formik.errors.qualificationCertificate} />
                                    </div>
                                 </div>
                              </div>
                              <div className="modal-footer">
                                 <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                                 <button type="button" className="btn btn-secondary" onClick={() => handleResetFilters(formik.resetForm)}>Wyczyść</button>
                                 <button type="button" type="submit" className="btn btn-primary">Filtruj</button>
                              </div>
                           </form>
                        )}
                     </Formik>
                  </div>
               </div>
            </div>
         </div>
      </React.Fragment >
   )
}

UserFiltersComponent.propTypes = {
   setFilters: PropTypes.func.isRequired
};

export default UserFiltersComponent;