import React, { useState } from 'react';
import { connect } from 'react-redux'
import {
    Route,
    Redirect,
    Switch
} from "react-router-dom";
import _ from 'lodash';

import Header from '../components/Header.component';
import Sidebar from '../components/Sidebar.component';

import SettingsContainer from '../../settings/containers/Settings.container';

import MediasContainer from '../../medias/containers/Medias.container';
import MediaContainer from '../../medias/containers/Media.container';

import DevicesContainer from '../../devices/containers/Devices.container';
import DeviceContainer from '../../devices/containers/Device.container';
import DeviceDetailsContainer from '../../devices/containers/DeviceDetails.container';
import ServiceLifeContainer from '../../devices/containers/ServiceLife.container';

import CustomersContainer from '../../customers/containers/Customers.container';
import CustomerContainer from '../../customers/containers/Customer.container';
import CustomerDetailsContainer from '../../customers/containers/CustomerDetails.container';

import ContractsContainer from '../../contracts/containers/Contracts.container';
import ContractContainer from '../../contracts/containers/Contract.container';
import ContractDetailsContainer from '../../contracts/containers/ContractDetails.container';

import ExpensesContainer from '../../expenses/containers/Expenses.container';
import ExpenseContainer from '../../expenses/containers/Expense.container';
import ExpenseDetailsContainer from '../../expenses/containers/ExpenseDetails.container';

import UsersContainer from '../../users/containers/Users.container';
import UserDetailsContainer from '../../users/containers/UserDetails.container';
import UserContainer from '../../users/containers/User.container';
import RolesContainer from '../../roles/containers/Roles.container';

import ComponentsContainer from "../../components/containers/Components.container";
import ComponentDetailsContainer from "../../components/containers/ComponentDetails.container";
import ComponentContainer from "../../components/containers/Component.container";

import ServiceRangesContainer from "../../serviceRanges/containers/serviceRanges.container";
import ServiceRangeDetailsContainer from "../../serviceRanges/containers/serviceRangeDetails.container";
import ServiceRangeContainer from "../../serviceRanges/containers/serviceRange.container";

import WarehousesContainer from "../../warehouses/containers/Warehouses.container";
import WarehouseDetailsContainer from "../../warehouses/containers/WarehouseDetails.container";
import WarehouseContainer from "../../warehouses/containers/Warehouse.container";

import ServicesContainer from '../../services/containers/Services.container';
import ServiceContainer from '../../services/containers/Service.container';
import ServiceDetailsContainer from '../../services/containers/ServiceDetails.container';

import ProfileContainer from '../../profile/containers/Profile.container';

import WelcomeComponent from "../components/Welcome.component";
import NoFoundComponent from '../components/NoFound.component';

const MainModulesContainer = (props) => {

    const [showHamburger, setShowHamburger] = useState(false);
    return (
        <React.Fragment>
            <Header />
            <div className={`d-flex panel ${showHamburger ? 'toggled' : ''} `} id="wrapper" >
                <Sidebar
                    logout={props.logout}
                    showHamburger={showHamburger}
                    setShowHamburger={setShowHamburger}
                />
                {/* wydzielony element "Witaj user", ponieważ znajduję się on na kazdym widoku */}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="witaj d-flex align-items-center justify-content-end no-print">
                            <Route path="/serviceLife/:id" exact>
                                <div className="d-flex align-items-center justify-content-start">
                                    <button onClick={() => window.print()} className="text-left mt-2 mb-2 mr-2 btn btn-secondary no-print">Drukuj</button>
                                </div>
                            </Route>
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="witaj-txt">Witaj {_.get(props, 'user.name')} </div>
                                <div className="usercolor-container rounded-circle">
                                    <div style={{ backgroundColor: _.get(props, 'user.color', 'white') }} className="usercolor rounded-circle"></div>
                                </div>
                            </div>
                        </div>
                        <Switch>
                            {/* Welcome page */}
                            <PrivateRoute {...props} path={["/"]} exact>
                                <WelcomeComponent />
                            </PrivateRoute>

                            {/* Settings */}
                            <PrivateRoute {...props} path="/settings" exact>
                                <SettingsContainer />
                            </PrivateRoute>

                            {/* Media */}
                            <PrivateRoute {...props} path={["/medias", "/medias/page/:page"]} exact>
                                <MediasContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/media/:id", "/media"]} exact>
                                <MediaContainer />
                            </PrivateRoute>

                            {/* Urzadzenia */}
                            <PrivateRoute {...props} path={["/devices", "/devices/page/:page"]} exact>
                                <DevicesContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/device/:id", "/device/:id/:action", "/device"]} exact>
                                <DeviceContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path="/serviceLife/:id" exact>
                                <ServiceLifeContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path="/devices/:id" exact >
                                <DeviceDetailsContainer />
                            </PrivateRoute>

                            {/* Clients */}
                            <PrivateRoute  {...props} path={["/customers", "/customers/page/:page"]} exact>
                                <CustomersContainer />
                            </PrivateRoute>
                            <PrivateRoute  {...props} path={["/customer/:id", "/customer"]} exact>
                                <CustomerContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/customers/:id", "/customers/:id/page/:page"]} exact>
                                <CustomerDetailsContainer />
                            </PrivateRoute>
                            {/* Contracts */}
                            <PrivateRoute {...props} path={["/contracts", "/contracts/page/:page"]} exact>
                                <ContractsContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/contract/:id", "/contract"]} exact>
                                <ContractContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path="/contracts/:id" exact>
                                <ContractDetailsContainer />
                            </PrivateRoute>
                            {/* Expenses */}
                            <PrivateRoute {...props} path={["/expenses", "/expenses/page/:page"]} exact>
                                <ExpensesContainer />
                            </PrivateRoute>
                            <PrivateRoute  {...props} path={["/expense/:id", "/expense"]} exact>
                                <ExpenseContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path="/expenses/:id" exact>
                                <ExpenseDetailsContainer />
                            </PrivateRoute>
                            {/* Users */}
                            <PrivateRoute {...props} path={["/users", "/users/page/:page"]} exact>
                                <UsersContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path="/users/:id" exact>
                                <UserDetailsContainer />
                            </PrivateRoute>
                            <PrivateRoute  {...props} path={["/user", "/user/:id"]} exact>
                                <UserContainer />
                            </PrivateRoute>
                            {/* Roles */}
                            <PrivateRoute {...props} path="/roles">
                                <RolesContainer />
                            </PrivateRoute>
                            {/* Components */}
                            <PrivateRoute {...props} path={["/components", "/components/page/:page"]} exact>
                                <ComponentsContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path="/components/:id" exact>
                                <ComponentDetailsContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/component/:id", "/component", , "/component/:id/:action"]} exact>
                                <ComponentContainer />
                            </PrivateRoute>
                            {/* ServiceRanges */}
                            <PrivateRoute {...props} path={["/serviceRanges", "/serviceRanges/page/:page"]} exact>
                                <ServiceRangesContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path="/serviceRanges/:id?" exact>
                                <ServiceRangeDetailsContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/serviceRange", "/serviceRange/:id", "/serviceRange/:id/:action"]} exact>
                                <ServiceRangeContainer />
                            </PrivateRoute>
                            {/* Warehouses */}
                            <PrivateRoute {...props} path={["/warehouses", "/warehouses/page/:page"]} exact>
                                <WarehousesContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/warehouses/:id", "/warehouses/:id/page/:page"]} exact>
                                <WarehouseDetailsContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/warehouse", "/warehouse/:id"]} exact>
                                <WarehouseContainer />
                            </PrivateRoute>
                            {/* Services */}
                            <PrivateRoute {...props} path={["/services", "/services/page/:page"]} exact>
                                <ServicesContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path={["/service", "/service/:id"]} exact>
                                <ServiceContainer />
                            </PrivateRoute>
                            <PrivateRoute {...props} path="/services/:id" exact>
                                <ServiceDetailsContainer />
                            </PrivateRoute>
                            {/* Profile */}
                            <PrivateRoute {...props} path={"/profile"} exact>
                                <ProfileContainer />
                            </PrivateRoute>

                            {/* route no found */}
                            <Route path="*">
                                <NoFoundComponent {...props} />
                            </Route>

                        </Switch>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

function PrivateRoute({ children, user, isLoading, ...rest }) {
    const isAllowed = isLoading || user;
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAllowed ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps = (store) => ({
    user: store.app.user
});

export default connect(mapStateToProps)(MainModulesContainer);
