import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isAuth } from './application/actions/application.actions';

import configureStore from './config/configureStore';
import App from './application/containers/App';

import './assets/css/logowanie.css';
import './assets/css/simple-sidebar.css';

export const store = configureStore();

store.dispatch(isAuth());

ReactDOM.render(
    <Provider store={store}>
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
        <App />
    </Provider>
    ,
    document.getElementById('root')
);
