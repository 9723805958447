import * as Yup from 'yup';

export const serviceRangeInitialState = {
    name: '',
    interval: 1,
    activities: []
}

export const serviceRangeValidationSchema = Yup.object().shape({
    name: Yup.string().required('Nazwa jest wymagana'),
    interval: Yup.number().oneOf([1, 3, 6, 12]).required("Interwał jest wymagany"),
    activities: Yup.array().of(
        Yup.object().shape({
            description: Yup.string().required("Opis czynności jest wymagany"),
            instruction: Yup.string()
        })
    ).min(1, "Nie dodano żadnej czynności")
});