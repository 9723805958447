import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux'

import ServiceDetailsComponent from '../components/ServiceDetails.component';

import {
   getService,
   clearService,
   startService,
   updateServiceComponents,
   updateServiceVerified,
   saveService,
   updateServiceComponentPhoto,
   completeService,
   clearErrors
} from '../actions/services.actions';

const ServiceDetailsContainer = ({
   getService,
   service,
   clearService,
   startService,
   updateServiceComponents,
   updateServiceVerified,
   saveService,
   updateServiceComponentPhoto,
   completeService,
   errors,
   clearErrors,
   user
}) => {
   const { id } = useParams();

   useEffect(() => {
      getService(id);

      return () => {
         clearService();
         clearErrors();
      }
   }, [id])

   return (
      <ServiceDetailsComponent
         serviceData={service}
         startService={startService}
         updateServiceComponents={updateServiceComponents}
         user={user}
         updateServiceVerified={updateServiceVerified}
         saveService={saveService}
         updateServiceComponentPhoto={updateServiceComponentPhoto}
         completeService={completeService}
         errors={errors}
      />
   );
}

const mapStateToProps = (store) => ({
   user: store.app.user,
   service: store.services.service,
   errors: store.services.errors
});

const mapDispatchToProps = (dispatch) => {
   return {
      getService: (serviceId) => dispatch(getService(serviceId)),
      clearService: () => dispatch(clearService()),
      startService: (serviceId, data) => dispatch(startService(serviceId, data)),
      updateServiceComponents: (serviceId, data) => dispatch(updateServiceComponents(serviceId, data)),
      updateServiceVerified: (serviceId, data) => dispatch(updateServiceVerified(serviceId, data)),
      updateServiceComponentPhoto: (serviceId, data) => dispatch(updateServiceComponentPhoto(serviceId, data)),
      saveService: (serviceId, data) => saveService(serviceId, data, dispatch),
      completeService: (serviceId, data) => dispatch(completeService(serviceId, data)),
      clearErrors: () => dispatch(clearErrors())
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDetailsContainer);
