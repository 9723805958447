import React from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';
import AclManager from '../../application/services/aclManager';

import PropTypes from 'prop-types';
import EditIcon from '../../assets/gfx/edytuj.svg';

const ExpenseDetailsComponent = ({ expense }) => {

    const renderComponentsList = () => {
        return <React.Fragment>
            <hr className="mt-5" />
            {_.get(expense, 'usedComponents[0]') && <h3 className="text-right">
                <span className="font-weight-light float-left">Komponenty:</span>
                <ul className="clearListStyle">
                    {expense.usedComponents.map((component, index) => {
                        return <li key={`expense-component-${index}`} >{_.get(component, 'component.name')},  {_.get(component, 'component.amount')} szt.</li>
                    })}
                </ul>
            </h3>}
        </React.Fragment>;
    }

    const renderActivitiesList = () => {
        return <React.Fragment>
            <hr className="mt-5" />
            {_.get(expense, 'activities[0]') && <h3 className="text-right">
                <span className="font-weight-light float-left">Czynności:</span>
                <ul className="clearListStyle">
                    {expense.activities.map((activity, index) => {
                        return <li key={`expense-component-${index}`} >{_.get(activity, 'name')}</li>
                    })}
                </ul>
            </h3>}
        </React.Fragment>;
    }

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                    <h3 className="w-50 mt-3 mb-3">Szczegóły kosztorysu</h3>
                    {AclManager.isAllowedMethod('expense/expense', 'update') && <Link to={`/expense/${expense._id}`}>
                        <button type="submit" className="btn btn-outline-primary float-md-right">Edytuj <img src={EditIcon} /></button>
                    </Link>}
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 d-flex justify-content-center flex-wrap align-items-center pt-5">
                            <div className="w-100">
                                {expense.customer && <h2 id="klient" className="text-center">
                                    <Link to={`/customer/${expense.customer.customerId}`}>
                                        {expense.customer.name}
                                    </Link>
                                </h2>}
                                {/* <h4 className="w-100 text-center">
                                    <span className="font-weight-light ">Kosztorys nr:</span>
                                    44242/20
								</h4> */}
                                <h3 className="w-100 text-center">
                                    <span className="font-weight-light">{expense.name || 'brak nazwy'}</span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            {renderComponentsList()}
                            {renderActivitiesList()}
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <hr className="mt-5" />
                            <h3 className="text-right">
                                <span className="font-weight-light float-left">Dojazd:</span> {expense.deliveryRate + ' zł' || 'brak informacji'}
                            </h3>
                            <hr />
                            <h3 className=" text-right">
                                <span className="font-weight-light float-left">Ilość roboczogodzin:</span> {expense.executionTime + ' h' || 'brak informacji'}
                            </h3>
                            <h3 className="text-right">
                                <span className="font-weight-light float-left">Cena roboczogodziny:</span> {expense.hourlyRate + ' zł' || 'brak informacji'}
                            </h3>
                            <h3 className="text-right">
                                <span className="font-weight-light float-left">Wartość godzin:</span> {_.get(expense, 'executionTime', 0) * _.get(expense, 'hourlyRate', 0) + ' zł' || 'brak informacji'}
                            </h3>
                            <hr />
                            <h3 className="text-right">
                                <span className="font-weight-light float-left">Wartość komponentów:</span> {expense.componentsCost + ' zł' || 'brak informacji'}
                            </h3>
                            <hr />
                            <h3 className="text-right">
                                <span className="font-weight-light float-left">Narzut:</span>({expense.overHeadValue + ' zł'}) {expense.overhead + ' %' || 'brak informacji'}
                            </h3>
                            <hr />
                            <h3 className="text-right">
                                <span className="font-weight-light float-left">VAT:</span>{expense.vatValue + ' zł' || 'brak informacji'}
                            </h3>
                            <hr />
                            <h3 className="text-right mb-3">
                                <span className="font-weight-light float-left">Suma:</span>{expense.totalCost + ' zł' || 'brak informacji'}
                            </h3>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

ExpenseDetailsComponent.propTypes = {
    expense: PropTypes.object.isRequired
};

export default ExpenseDetailsComponent;