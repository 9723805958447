import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Autocomplete from '../../assets/components/Autocomplete.component';

const ReplaceModalComponent = ({ replacedComponent, onSelectNewComponent, cmpIndex }) => {

   const [newComponent, setNewComponent] = useState({});

   useEffect(() => {
      setNewComponent(replacedComponent);
   }, [replacedComponent])

   return (
      <div className="modal fade" id={`wymiana-komponentu${cmpIndex}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
         <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content">
               <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenteredLabel">Wybierz nowy komponent</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div className="modal-body">
                  <div className="row">
                     <div className="col-12">
                        <div className="form-group">
                           <Autocomplete
                              id="components"
                              url="device/component"
                              multi={false}
                              label="Nowy komponent"
                              optionLabelField="fullName"
                              optionIdField="_id"
                              prependText={null}
                              appendText={null}
                              onOptionSelect={(id, cmp) => {
                                 if (_.get(cmp, 'componentId')) {
                                    setNewComponent({ componentId: cmp.componentId, name: cmp.name })
                                 } else {
                                    setNewComponent({})
                                 }
                              }}
                              value={newComponent}
                              queryFields={["name", "manufacturer", "kind"]}
                              additionalParams={{ limit: 20 }}
                              parseOption={(value) => {
                                 value.fullName = `[${value.manufacturer}] ${value.name} (${value.kind})`;
                                 value.componentId = value._id;
                                 return value;
                              }}
                           />
                        </div>
                     </div>
                  </div>
                  <div className="row d-flex w-100 justify-content-center m-0">
                     <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={() => onSelectNewComponent(newComponent)}>Zapisz</button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}


export default ReplaceModalComponent;