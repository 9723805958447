import React from 'react';
import { Link } from "react-router-dom";
import AclManager from '../../application/services/aclManager';

import PropTypes from 'prop-types';
import EditIcon from "../../assets/gfx/edytuj.svg";
import DeleteIcon from "../../assets/gfx/kosz.svg";
import _ from "lodash";

const ExpensesListCardComponent = ({ expense, handleDelete, index }) => {

    return (
        <tr>
            <th scope="row">{index}</th>
            <td>
                <Link to={`/expenses/${expense._id}`}>
                    {expense.name || "brak"}
                </Link>
            </td>
            <td>{_.get(expense, 'customer.name') || "brak"}</td>
            <td>{_.get(expense, 'hourlyRate') || "brak"}</td>
            <td>{_.get(expense, 'totalCost') || "brak"}</td>
            <td>
                {AclManager.isAllowedMethod('expense/expense', 'update') && <Link to={`/expense/${expense._id}`}>
                    <img className="akcja edytuj" src={EditIcon} />
                </Link>}

                {AclManager.isAllowedMethod('expense/expense', 'delete') && <span onClick={() => handleDelete(expense)} data-toggle="modal" data-target="#confirmDelete" title="Usuń kontrakt">
                    <img className="akcja usun" src={DeleteIcon} />
                </span>}
            </td>
        </tr>
    )
}

ExpensesListCardComponent.propTypes = {
    expense: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

export default ExpensesListCardComponent;