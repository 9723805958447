import React, { useState, useEffect } from 'react';
import Api from "../../application/services/api";
import koszIcon from "../gfx/kosz.svg";
import PropTypes from 'prop-types';
import _ from "lodash";

const Autocomplete = ({
    id,
    url,
    multi,
    placeholder,
    label,
    optionLabelField,
    optionIdField,
    prependText,
    appendText,
    onOptionSelect,
    value,
    errors,
    queryFields,
    additionalParams,
    parseOption,
    hideChips,
    readonly,
    isRequired,
    preload
}) => {

    let [isLoading, setIsLoading] = useState(false);
    let [options, setOptions] = useState([]);
    let [selected, setSelected] = useState([]);
    let [showOptions, setShowOptions] = useState(false);
    let [optionHovered, setOptionHovered] = useState(false);
    let [changeBlocked, setChangeBlocked] = useState(false);
    let [noResults, setNoResults] = useState(false);
    const defaultParams = {
        limit: 5
    }

    useEffect(() => {
        if (preload) {
            getOptions(null, true);
        }
    }, [preload, additionalParams]);

    useEffect(() => {
        if (!_.isEmpty(value) && !changeBlocked) {
            setSelected(_.castArray(value));
            setChangeBlocked(false);
        } else if (_.isEmpty(value)) {
            setSelected([]);
        }
    }, [value])

    const getRequestParams = (query, preload = false) => {
        let reqParams = {};
        if (_.isArray(queryFields) && !preload) {
            _.each(queryFields, (qField) => {
                reqParams[`${qField}[or]`] = query;
            });
        }
        return Object.assign({}, defaultParams, additionalParams, reqParams);
    }

    const getOptions = async (query, preload = false) => {
        setNoResults(false);
        if (_.size(query) > 2 || preload) {
            setIsLoading(true);
            let res = await Api.get(url, getRequestParams(query, preload));
            if (res && res.success) {
                if (_.isEmpty(res.documents)) {
                    setNoResults(true);
                }
                setOptions(res.documents);
            }
            setIsLoading(false);
        } else if (_.size(query) < 3 && !_.isEmpty(options)) {
            setOptions([]);
        }
    }

    const renderOptions = () => {
        if (!showOptions) return null;
        if (isLoading) {
            <li className="list-group-item autocomplete-item" onMouseOver={() => setOptionHovered(true)} onMouseOut={() => setOptionHovered(false)}>Ładowanie...</li>
        } else {
            if (noResults) {
                return <li className="list-group-item autocomplete-item" onMouseOver={() => setOptionHovered(true)} onMouseOut={() => setOptionHovered(false)}>Brak wyników</li>
            } else {
                return options.map((option, index) => {
                    option = _.isFunction(parseOption) ? parseOption(option) : option;
                    return <li
                        className="list-group-item autocomplete-item"
                        style={{ cursor: "pointer" }}
                        key={`${id}option${index}`}
                        onClick={() => handleSelect(index)}
                        onMouseOver={() => setOptionHovered(true)}
                        onMouseOut={() => setOptionHovered(false)}
                    >{_.get(option, optionLabelField, _.get(option, "name", `Opcja ${index + 1}`))}</li>
                });
            }
        }
    }

    const handleSelect = (index) => {
        setChangeBlocked(true);
        if (!!multi) {
            let newSelected = _.uniqBy(_.concat(selected, _.nth(options, index)), (o) => o[optionIdField]);
            if (_.isFunction(onOptionSelect)) {
                onOptionSelect(id, newSelected);
            }
            setSelected(newSelected);
        } else {
            setSelected([options[index]]);
            if (_.isFunction(onOptionSelect)) {
                onOptionSelect(id, options[index]);
            }
        }
        if (id) {
            document.getElementById(id).value = "";
        }
        setShowOptions(false);
    }

    const handleDeleteSelection = (index) => {
        setChangeBlocked(true);
        if (!!multi) {
            let newSelected = _.without(selected, _.nth(selected, index));
            if (_.isFunction(onOptionSelect)) {
                onOptionSelect(id, newSelected);
            }
            setSelected(newSelected);
        } else {
            if (_.isFunction(onOptionSelect)) {
                onOptionSelect(id, null);
            }
            setSelected([]);
        }
    }

    const renderErrors = () => {
        if (!errors) return;
        if (_.isArray(errors)) {
            return errors.map((e, i) => {
                return <div key={i} className="errormsg">{e}</div>
            })
        } else if (_.isObject(errors)) {
            let messages = [];
            _.each(errors, (val, k) => {
                messages.push(<div className="errormsg" key={k}>{_.isObject(val) ? JSON.stringify(val) : val}</div>)
            });
            return messages;
        } else if (typeof errors === 'string') {
            return <div className="errormsg">{errors}</div>
        }
    }

    const renderChips = () => {
        if (hideChips) return null;
        if (!_.isEmpty(selected)) {
            return selected.map((sel, index) => {
                return (<div className="czynnosc-komp mb-1" key={`${id}chip${index}`}>
                    <img className="akcja usun mr-3" src={koszIcon} onClick={() => handleDeleteSelection(index)} />
                    {_.get(sel, optionLabelField, _.get(sel, "name", `Opcja ${index + 1}`))}
                </div>);
            });
        }
        return null;
    }

    return (
        <div className={`form-group d-flex flex-wrap w-100 flex-column justify-content-end ${errors ? 'error' : ''}`}>
            {label && <label>{label} {isRequired && <span className="is-required">*</span>}</label>}
            {renderChips()}
            <div className="input-group">
                {prependText && <div className="input-group-prepend">
                    <span className="input-group-text">{prependText}</span>
                </div>}
                <input
                    type="text"
                    className="form-control"
                    onChange={(e) => getOptions(e.target.value)}
                    id={id}
                    autoComplete="off"
                    placeholder={placeholder || "Szukaj"}
                    onFocus={() => setShowOptions(true)}
                    readOnly={!!readonly}
                    onBlur={() => optionHovered ? null : setShowOptions(false)}
                />
                {appendText && <div className="input-group-append">
                    <button className="btn btn-success" type="button">{appendText}</button>
                </div>}
                <button className="btn btn-success d-none" type="button" id={`${id}-reset`} onClick={() => {
                    document.getElementById(id).value = "";
                    setSelected([]);
                }}>Wyczyść</button>
            </div>
            <div className="pos-relative">
                <ul className="list-group pos-absolute w-100">
                    {renderOptions()}
                </ul>
            </div>

            {renderErrors()}
        </div>
    )
}

Autocomplete.propTypes = {
    id: PropTypes.string,
    url: PropTypes.string,
    multi: PropTypes.bool,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    optionLabelField: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    optionIdField: PropTypes.string,
    prependText: PropTypes.string,
    appendText: PropTypes.string,
    onOptionSelect: PropTypes.func,
    value: PropTypes.any,
    errors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]),
    queryFields: PropTypes.array,
    additionalParams: PropTypes.object,
    parseOption: PropTypes.func,
    hideChips: PropTypes.bool,
    readonly: PropTypes.bool
};

export default Autocomplete;