import {
    SET_DEVICE,
    SET_DEVICES,
    SET_DEVICES_SERVICES,
    SET_QUERY,
    SET_OFFSET,
    SET_LIMIT,
    SET_FILTERS,
    SET_PAGE,
    CLEAR_DEVICE,
    CLEAR_DEVICES,
    CLEAR_QUERY,
    CLEAR_LIMIT,
    CLEAR_FILTERS,
    CLEAR_DEVICES_SERVICES
} from '../actions/devices.actions';

const defaultState = {
    device: null,
    devices: [],
    total: 0,
    page: 1,
    query: '',
    queryFields: [
        'name[or]',
        'udtNumber[or]',
        'conservator.name[or]',
        'client.name[or]',
    ],
    params: {
        limit: 15,
        offset: 0,
    },
    filters: {
        'sort[name]': 1
    }
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_DEVICE:
            return {
                ...state,
                device: action.device
            }
        case CLEAR_DEVICE:
            return {
                ...state,
                device: null
            }
        case SET_DEVICES:
            return {
                ...state,
                devices: action.devices,
                total: action.total
            }
        case SET_DEVICES_SERVICES:
            return {
                ...state,
                services: action.services
            }
        case CLEAR_DEVICES_SERVICES:
            return {
                ...state,
                services: []
            }
        case CLEAR_DEVICES:
            return {
                ...state,
                devices: []
            }
        case SET_PAGE: // TU NIE MA CLEAR, robi to clearPage z akcji
            return {
                ...state,
                page: action.page
            }
        case SET_OFFSET: // TU NIE MA CLEAR, robi to clearPage z akcji
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.offset
                }
            }
        case SET_LIMIT:
            return {
                ...state,
                params: {
                    ...state.params,
                    limit: action.limit
                }
            }
        case CLEAR_LIMIT:
            return {
                ...state,
                params: {
                    ...defaultState.params,
                    limit: defaultState.params.limit,
                }
            }
        case SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        case CLEAR_QUERY:
            return {
                ...state,
                query: ''
            }
        case SET_FILTERS:
            return {
                ...state,
                filters: action.filters
            }
        case CLEAR_FILTERS:
            return {
                ...state,
                filters: {}
            }
        default:
            return state;

    }
}