import { Formik } from 'formik';
import _ from "lodash";
import React from 'react';
import { useParams } from "react-router-dom";
import TextInput from "../../assets/components//TextInput.component";
import Checkbox from "../../assets/components//Checkbox.component";
import koszIcon from "../../assets/gfx/kosz.svg";
import Autocomplete from "../../assets/components//Autocomplete.component";
import { transferInitialValues, transferValidationSchema } from "../schemas/transfer.schema";

const TransferModal = ({ saveTransfer, getWarehouseComponents, getWarehouses, getComponentsToEdit }) => {

    const { id } = useParams();

    const renderComponents = (formik) => {
        if (!_.isEmpty(formik.values.components)) {
            return formik.values.components.map((component, index) => {
                return (<tr key={`component${index}`}>
                    <td className="pb-3">{component.name}</td>
                    <td>
                        <TextInput
                            customClass='d-flex flex-wrap w-100 flex-column justify-content-end '
                            id={`components[${index}].amount`}
                            type="number"
                            placeholder="Wpisz ilość"
                            value={formik.values.components[index].amount}
                            onChange={formik.setFieldValue}
                            errors={_.get(formik, `errors.components[${index}]`, null)}
                        />
                    </td>
                    <td className="pb-3">
                        <span onClick={() => deleteComponent(formik, index)} title="Usuń"><img className="akcja usun" src={koszIcon} /></span>
                    </td>
                </tr>);
            });
        } else {
            return (<tr>
                <td colSpan="3" className="text-center">Brak elementów</td>
            </tr>)
        }
    }

    const handleNewComponentsChange = async (formik, value) => {
        if (value === true && formik.values.warehouseTo) {
            let documents = await getComponentsToEdit(formik.values.warehouseTo);
            if (_.isArray(documents)) {
                documents = documents.map((doc) => {
                    doc.componentId = doc.component;
                    return doc;
                });
            }
            formik.setFieldValue("components", documents);
        } else {
            formik.setFieldValue("components", []);
            // document.getElementById("components-reset").click();
        }
        formik.setFieldValue("newComponents", value);
    }

    const handleWarehouseToChange = async (formik, value) => {
        formik.setFieldValue("components", []);
        document.getElementById("components-reset").click();
        if (formik.values.newComponents) {
            let documents = await getComponentsToEdit(value);
            if (_.isArray(documents)) {
                documents = documents.map((doc) => {
                    doc.componentId = doc.component;
                    return doc;
                });
            }
            formik.setFieldValue("components", documents);
        }
        formik.setFieldValue("warehouseTo", value);
    }

    const addComponent = (formik, components) => {
        let difference = _.differenceBy(components, formik.values.components, (val) => val.componentId);
        formik.setFieldValue("components", _.concat(formik.values.components, difference));
    }

    const deleteComponent = (formik, index) => {
        formik.setFieldValue("components", _.without(formik.values.components, _.nth(formik.values.components, index)));
    }

    const changeWarehouseFrom = (formik, warehouse) => {
        formik.setFieldValue("components", []);
        document.getElementById("components-reset").click();
        formik.setFieldValue("warehouseFrom", _.get(warehouse, "_id"));
    }

    const resetAll = (resetForm) => {
        document.getElementById("warehouseFrom-reset").click();
        document.getElementById("warehouseTo-reset").click();
        document.getElementById("components-reset").click();
        resetForm();
    }

    return (<div className="modal fade" id="transfer-mm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalCenteredLabel">Transfer między magazynami</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Formik
                        initialValues={transferInitialValues}
                        validationSchema={transferValidationSchema}
                        enableReinitialize={true}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={(values, { setErrors, resetForm }) => {
                            saveTransfer(values).then(res => {
                                if (res.success) {
                                    resetAll(resetForm);
                                    document.getElementById("modalCloseBtn").click();
                                    if (id) {
                                        getWarehouseComponents(id);
                                    } else {
                                        getWarehouses();
                                    }
                                } else {
                                    if (res.errors) {
                                        setErrors(res.errors);
                                    }
                                }
                            });
                        }}
                    >
                        {formik => (<form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-6 d-flex align-content-stretch">
                                    <Autocomplete
                                        isRequired={true}
                                        id="warehouseFrom"
                                        url="warehouse/warehouse"
                                        multi={false}
                                        label="Magazyn źródłowy"
                                        optionLabelField="name"
                                        optionIdField="_id"
                                        prependText={null}
                                        appendText={null}
                                        onOptionSelect={(id, value) => changeWarehouseFrom(formik, value)}
                                        value={formik.values.warehouseFrom}
                                        errors={formik.errors.warehouseFrom}
                                        queryFields={["name"]}
                                        additionalParams={{ limit: 5, select: "name" }}
                                        readonly={formik.values.newComponents}
                                    />
                                </div>
                                <div className="col-6 d-flex align-content-stretch">
                                    <Autocomplete
                                        isRequired={true}
                                        id="warehouseTo"
                                        url="warehouse/warehouse"
                                        multi={false}
                                        label="Magazyn docelowy"
                                        optionLabelField="name"
                                        optionIdField="_id"
                                        prependText={null}
                                        appendText={null}
                                        onOptionSelect={(id, value) => handleWarehouseToChange(formik, _.get(value, "_id"))}
                                        value={formik.values.warehouseTo}
                                        errors={formik.errors.warehouseTo}
                                        queryFields={["name"]}
                                        additionalParams={{ limit: 5, select: "name" }}
                                    />
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-6">
                                    <Checkbox
                                        id="newComponents"
                                        label="Edytuj stan magazynu docelowego"
                                        option="Tak"
                                        onChange={(id, value) => handleNewComponentsChange(formik, value)}
                                        value={formik.values.newComponents}
                                        errors={formik.errors.newComponents}
                                    />
                                </div>
                                <div className="col-6"></div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-12">
                                    <Autocomplete
                                        isRequired={true}
                                        id="components"
                                        url={formik.values.newComponents ? "device/component" : `warehouse/items/${formik.values.warehouseFrom}`}
                                        multi={true}
                                        label="Wybierz komponenty"
                                        optionLabelField="name"
                                        optionIdField="_id"
                                        prependText="Szukaj"
                                        appendText={null}
                                        placeholder="Wpisz nazwę komponentu"
                                        onOptionSelect={(id, components) => addComponent(formik, components)}
                                        value={formik.values.components}
                                        errors={formik.errors.components}
                                        queryFields={["name"]}
                                        additionalParams={{ limit: 5, select: "name,component,amount" }}
                                        parseOption={(val) => {
                                            if (_.has(val, "component")) {
                                                if (!val.amountAdded) {
                                                    val.name = `${val.name} (Dostępne: ${val.amount} szt.)`;
                                                    val.amountAdded = true;
                                                }
                                                val.componentId = val.component;
                                            } else {
                                                val.componentId = val._id;
                                            }
                                            val.amount = 1;
                                            return val;
                                        }}
                                        readonly={!formik.values.warehouseFrom && !formik.values.newComponents}
                                        hideChips={true}
                                    />
                                </div>
                                <div className="col-12">
                                    <div className="table-responsive ramkatabeli mb-2">
                                        <table className="table table-hover rounded-10 tabela-left">
                                            <thead>
                                                <tr>
                                                    <th className="w-50">Nazwa</th>
                                                    <th>Ilość<span className="is-required">*</span></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderComponents(formik)}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* {_.isString(formik.errors.components) && <div className="errormsg">{formik.errors.components}</div>} */}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" id="modalCloseBtn">Zamknij</button>
                                    <button type="submit" className="btn btn-primary">Zapisz</button>
                                </div>
                            </div>
                        </form>)}
                    </Formik>
                </div>
            </div>
        </div>
    </div>);
}

export default TransferModal;