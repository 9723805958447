import { Formik } from 'formik';
import _ from "lodash";
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Select from "../../assets/components//Select.component";
import TextInput from "../../assets/components//TextInput.component";
import SelectMediaModal from "../../assets/components/SelectMediaModal.component";
import dodajIcon from "../../assets/gfx/dodaj.svg";
import koszIcon from "../../assets/gfx/kosz.svg";
import plusIcon from "../../assets/gfx/plus-2.svg";
import { serviceRangeInitialState, serviceRangeValidationSchema } from "../schemas/serviceRange.schema";

const ServiceRange = ({ serviceRange, saveServiceRange }) => {

    const { id, action } = useParams();
    const intervalOptions = [{ name: "1 miesiąc", value: 1 }, { name: "3 miesiące", value: 3 }, { name: "6 miesięcy", value: 6 }, { name: "12 miesięcy", value: 12 }];
    const [initialValues, setInitialValues] = useState(serviceRangeInitialState);
    const history = useHistory();

    useEffect(() => {
        _.get(serviceRange, "_id") && setInitialValues(serviceRange);
    }, [serviceRange]);

    const handleAddNewActivity = (formik) => {
        formik.setFieldValue("activities", _.concat(formik.values.activities, [{
            description: '',
            instruction: ''
        }]));
    }

    const handleDeleteActivity = (formik, index) => {
        formik.setFieldValue("activities", _.without(formik.values.activities, _.nth(formik.values.activities, index)));
    }

    const handleDeleteInstruction = (formik, index) => {
        let newActivities = formik.values.activities;
        newActivities[index].instruction = null;
        formik.setFieldValue("activities", newActivities);
    }

    return (<div>
        <div className="row mb-3 mt-3">
            <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                <h3 className="mt-3 mb-3">{id ? "Edytuj" : "Dodaj nowy"} zestaw czynności konserwacyjnych</h3>
            </div>
        </div>
        <Formik
            initialValues={initialValues}
            validationSchema={serviceRangeValidationSchema}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setErrors }) => {
                if (action && action === "clone") {
                    delete values._id;
                }
                let res = await saveServiceRange(values);
                if (res.success) {
                    history.push(`/serviceRanges/${res.document}`);
                } else {
                    if (res.errors) {
                        setErrors(res.errors);
                    }
                }
            }}
        >
            {formik => (<form autoComplete="off" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-6">
                        <TextInput
                            isRequired={true}
                            id="name"
                            label="Nazwa"
                            type="text"
                            value={formik.values.name}
                            onChange={formik.setFieldValue}
                            errors={formik.errors.name}
                        />
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <Select
                            id="interval"
                            label="Interwał konserwacji"
                            options={intervalOptions}
                            onChange={formik.setFieldValue}
                            value={formik.values.interval}
                            errors={formik.errors.interval}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <label htmlFor="typ-komponentu">Dodaj czynności konserwacyjne <span className="is-required">*</span></label>
                        <div className="table-responsive ramkatabeli ikonki">
                            <table className="table table-hover rounded-10">
                                <thead>
                                    <tr>
                                        <th>Nazwa</th>
                                        <th className="w-50">Instrukcja</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formik.values.activities && formik.values.activities.map((activity, index) => {
                                        return <Fragment key={`activity-row-${index}`}>
                                            <tr>
                                                <td className="align-bottom">
                                                    <TextInput
                                                        customClass='d-flex flex-wrap w-100 flex-column justify-content-end'
                                                        id={`activities.${index}.description`}
                                                        label=""
                                                        placeholder="Wpisz nazwę czynności"
                                                        type="text"
                                                        onChange={(element, value) => formik.setFieldValue(element, value)}
                                                        value={formik.values.activities[index].description}
                                                        errors={_.get(formik, `errors.activities[${index}].description`, null)} />
                                                </td>
                                                <td className="align-bottom">
                                                    <div className="input-group">
                                                        {activity.instruction && <div key={`instruction${index}`} className="param-komp mb-1 col-12 text-left pl-0">
                                                            <img className="akcja usun mr-3" src={koszIcon}
                                                                onClick={() => {
                                                                    handleDeleteInstruction(formik, index);
                                                                    document.getElementById(`link-input-${index}`).value = "";
                                                                }}
                                                            />
                                                            {activity.instruction}
                                                        </div>}
                                                        <input type="text" id={`link-input-${index}`} className="form-control" onChange={(e) => {
                                                            if (e.target.value.indexOf("http") >= 0) {
                                                                e.target.value.trim() != "" && formik.setFieldValue(`activities.${index}.instruction`, e.target.value);
                                                                document.getElementById(`link-input-${index}`).value = "";
                                                            }
                                                        }} placeholder="Wklej link lub wybierz z biblioteki" aria-label="" aria-describedby="basic-addon1" />
                                                        <div className="input-group-append">
                                                            <SelectMediaModal
                                                                modalId={`instruction${index}`}
                                                                buttonName="biblioteka"
                                                                onSelect={(src) => formik.setFieldValue(`activities.${index}.instruction`, src)}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-bottom"><span onClick={() => handleDeleteActivity(formik, index)} title="Usuń"><img className="akcja usun pb-2" src={koszIcon} /></span> </td>
                                            </tr>
                                        </Fragment>
                                    })}
                                    <tr>
                                        <td colSpan="3" className="text-right"><button type="button" className="btn btn-link p-0 mt-2 mr-3" onClick={() => handleAddNewActivity(formik)}>Dodaj<div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5"><img className="mt-1 ml-0" src={plusIcon} /></div></button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {_.isString(formik.errors.activities) && <div className="errormsg">{formik.errors.activities}</div>}

                    </div>
                </div>
                <button type="submit" className="btn btn-primary mb-3 mt-5">Zapisz<img src={dodajIcon} /></button>
            </form>)}
        </Formik>
    </div>
    )
}

export default ServiceRange;