import * as Yup from 'yup';

export const transferInitialValues = {
    warehouseFrom: null,
    warehouseTo: null,
    components: [],
    newComponents: false
}

export const transferValidationSchema = Yup.object().shape({
    warehouseFrom: Yup.string().nullable()
        .when('newComponents', {
            is: false,
            then: Yup.string().nullable().required('Magazyn źródłowy jest wymagany'),
            otherWise: Yup.string().nullable()
        }),
    warehouseTo: Yup.string().required("Magazyn docelowy jest wymagany").nullable(),
    components: Yup.array().of(Yup.object().shape({
        componentId: Yup.string().required("Id komponentu jest wymagane"),
        amount: Yup.number().required("Ilość komponentu jest wymagana")
    })).required("Nie wybrano komponentów"),
    newComponents: Yup.boolean()
});