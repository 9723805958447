import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash'
import PropTypes from 'prop-types';

import FiltrujIcon from '../../assets/gfx/filtruj.svg';
import Date from '../../assets/components/Date.component';

// metoda mapująca pola do setowania filtrów 
const mapFields = {
  'dateFrom': 'conclusionDate[gte]',
  'dateTo': 'expiryDate[lte]'
}

const ExpenseFiltersComponent = ({
  setFilters
}) => {

  const handleResetFilters = (resetForm) => {
    resetForm();
    setFilters({});
  }

  return (
    <React.Fragment>
      <button type="submit" className="btn btn-link text-dark" data-toggle="modal" data-target="#modal-filtruj">Filtruj <img src={FiltrujIcon} /></button>
      <div className="modal fade" id="modal-filtruj" tabIndex="-1" role="dialog" aria-labelledby="filtrowanie"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="filtrowanie">Filtry kosztorysu</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  'dateFrom': '',
                  'dateTo': ''
                }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {

                  const filters = {};
                  Object.keys(values).map(key => {
                    if (values[key] !== '') {
                      filters[mapFields[key]] = values[key];
                    }
                  });

                  setFilters(filters);
                }}
              >
                {formik => (
                  <form className="input-group-login flex-column" onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-6">
                        <div className="filtr">
                          <Date
                            id="dateFrom"
                            label="Termin od"
                            type="number"
                            onChange={formik.setFieldValue}
                            value={formik.values.dateFrom}
                            errors={formik.errors.dateFrom}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="filtr">
                          <Date
                            id="dateTo"
                            label="Termin do"
                            type="number"
                            onChange={formik.setFieldValue}
                            value={formik.values.dateTo}
                            errors={formik.errors.dateTo}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer pr-0">
                      <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                      <button type="button" className="btn btn-secondary" onClick={() => handleResetFilters(formik.resetForm)}>Wyczyść</button>
                      <button type="button" type="submit" className="btn btn-primary mr-0">Filtruj</button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

ExpenseFiltersComponent.propTypes = {
  setFilters: PropTypes.func.isRequired
};

export default ExpenseFiltersComponent;