import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import DodajIcon from '../../assets/gfx/dodaj.svg';
import FiltrujIcon from '../../assets/gfx/filtruj.svg';

import MediasListCard from './MediasListCard.component';
import MediasFilter from './MediasFilter.component';
import ModalImage from '../../assets/components/ModalImage.component';
import ConfirmModal from '../../assets/components/ConfirmModal.component';
import Pagination from '../../assets/components/Pagination.component';
import Search from '../../assets/components/Search.component';
import SortListComponent from '../../assets/components/SortList.component';

import AclManager from '../../application/services/aclManager';

const Medias = ({
    medias,
    deleteMedia,
    setFilters,
    setPage,
    setLimit,
    setQuery,
    page,
    total,
    limit
}) => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [mediaToDelete, setMediaToDelete] = useState(null);

    const fields = [
        { id: 'mimeType', name: 'Typ' },
        { id: 'name', name: 'Nazwa' },
        { id: '', name: 'Plik' },
        { id: 'createdAt', name: 'Data' },
    ]

    const renderMedias = () => {
        if (_.isEmpty(medias)) {
            return <tr><td colSpan="6">Brak mediów</td></tr>
        }

        return medias.map((media, i) => {
            return (
                <MediasListCard
                    key={i}
                    index={i}
                    media={media}
                    setSelectedImage={setSelectedImage}
                    setMediaToDelete={setMediaToDelete}
                />
            )
        })
    }

    const handleDeleteMedia = () => {
        deleteMedia(_.get(mediaToDelete, "_id"));
        setMediaToDelete(null);
    }
    const handleCancelModal = () => {
        setMediaToDelete(null);
    }

    return (
        <React.Fragment>
            {selectedImage && <ModalImage
                fileName={_.get(selectedImage, 'name')}
                fileSrc={_.get(selectedImage, 'src')}
            />}

            {mediaToDelete && <ConfirmModal
                id="confirmDelete"
                label="Czy na pewno chcesz usunąć?"
                positionName={_.get(mediaToDelete, 'name')}
                onConfirm={handleDeleteMedia}
                onCancel={handleCancelModal}
            />
            }


            <MediasFilter
                setFilters={setFilters}
            />

            <div className="row mb-2">
                <div className="col-12 col-md-12 pt-3 d-flex btns-filtry">
                    <h3 className="mt-0 mb-2 w-100">Biblioteka mediów</h3>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-wrap flex-md-nowrap justify-content-between justify-content-lg-start">
                    {
                        AclManager.isAllowedMethod('media/media', 'create') && <Link to="/media" className="btn btn-primary mr-2 mb-3">Dodaj plik <img src={DodajIcon} /></Link>
                    }
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                    <Search
                        setQuery={setQuery}
                    />
                    <button type="submit" className="btn btn-link text-dark" data-toggle="modal"
                        data-target="#modal-filtruj">Filtruj <img src={FiltrujIcon} /></button>
                </div>
            </div>

            <div className="table-responsive ramkatabeli ikonki tabela-left mb-2">
                <table className="table table-hover rounded-10 tabela-biblioteka">
                    <thead>
                        <SortListComponent fields={fields} defaultSortBy='name' setFilters={setFilters} defaultSortType={1} />
                    </thead>
                    <tbody>
                        {renderMedias()}
                    </tbody>
                </table>

            </div>

            <Pagination
                totalCount={total}
                page={parseInt(page)}
                setPage={(page) => setPage(page, true)}
                limit={limit}
                setLimit={setLimit}
                route={"/medias"}
            />

        </React.Fragment>
    )
}

export default Medias;