import { SET_MEDIAS, CLEAR_MEDIAS, SET_PARAMS, SET_OFFSET, SET_PAGE, SET_LIMIT, SET_QUERY, SET_FILTERS, CLEAR_FILTERS, CLEAR_LIMIT, CLEAR_QUERY } from '../actions/medias.actions';

const defaultState = {
    medias: null,
    total: 0,
    page: 1,
    query: '',
    queryFields: [
        'name[or]',
        'src[or]'
    ],
    params: {
        limit: 15,
        offset: 0,
    },
    filters: {
        'sort[name]': 1
    }
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_MEDIAS:
            return {
                ...state,
                medias: action.medias,
                total: action.total
            }
        case CLEAR_MEDIAS:
            return {
                ...state,
                medias: null,
                total: 0
            }
        case SET_PARAMS:
            return {
                ...state,
                params: action.params
            }
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            }
        case SET_OFFSET:
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.offset
                }
            }
        case SET_LIMIT:
            return {
                ...state,
                params: {
                    ...state.params,
                    limit: action.limit
                }
            }
        case CLEAR_LIMIT:
            return {
                ...state,
                params: {
                    ...state.params,
                    limit: state.params.limit,
                }
            }
        case SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        case CLEAR_QUERY:
            return {
                ...state,
                query: ''
            }
        case SET_FILTERS:
            return {
                ...state,
                filters: action.filters
            }
        case CLEAR_FILTERS:
            return {
                ...state,
                filters: {}
            }
        default:
            return state;

    }
}