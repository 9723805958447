import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import moment from 'moment';

import { Formik } from 'formik';
import _ from 'lodash';

import UploadFilesComponent from '../../assets/components/UploadFiles.component';
import Textarea from "../../assets/components/Textarea.component";
import Checkbox from '../../assets/components/Checkbox.component';
import TextInput from "../../assets/components/TextInput.component";
import Date from "../../assets/components/Date.component";
import Autocomplete from "../../assets/components/Autocomplete.component";
import AddIcon from '../../assets/gfx/dodaj.svg';
import PlusIcon from "../../assets/gfx/plus-2.svg";

import { contractInitialState, contractValidationSchema } from '../schemas/contract.schema';

const ContractComponent = ({ contract, saveContract }) => {
    
    // defaultowe initialValues ze schemy
    const [initialValues, setInitialValues] = useState(contractInitialState);
    let history = useHistory();

    // aktualizujemy initialValues jak przyjdzie z API
    useEffect(() => {
        if (_.get(contract, '_id')) {
            contract.conclusionDate = moment(contract.conclusionDate).format('X');
            contract.expiryDate = moment(contract.expiryDate).format('X');
            setInitialValues(contract);
        }
    }, [contract]);

    const handleAddNewDescription = (formik) => {
        formik.setFieldValue("description", [...formik.values.description, { name: '', value: '' }]);
    }

    const handleDeleteDescription = (formik, index) => {
        formik.setFieldValue("description", _.without(formik.values.description, _.nth(formik.values.description, index)));
    }

    return (
        <Fragment>
            <h3 className="mt-3 mb-3">{`${contract && contract._id ? "Edytuj kontrakt" : "Dodaj nowy kontrakt"}`} </h3>
            <Formik
                initialValues={initialValues}
                validationSchema={contractValidationSchema}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setErrors }) => {
                    saveContract(values).then(res => {
                        if (res.success) {
                            history.push(`/contracts/${res.document}`);
                        } else {
                            if (res.errors) {
                                setErrors(res.errors);
                            }
                        }
                    });
                }}
            >
                {formik => (
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <TextInput
                                    isRequired={true}
                                    id="number"
                                    label="Numer umowy"
                                    type="text"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.number}
                                    errors={formik.errors.number}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <Autocomplete
                                    preload={true}
                                    id="customer"
                                    url="customer/customer"
                                    multi={false}
                                    label="Klient"
                                    optionLabelField="name"
                                    optionIdField="customerId"
                                    prependText={null}
                                    appendText={null}
                                    onOptionSelect={(key, value) => formik.setFieldValue("customer", value ? { customerId: value.customerId, name: value.name } : {})}
                                    value={formik.values.customer}
                                    errors={formik.errors.customer}
                                    queryFields={["name"]}
                                    additionalParams={{ limit: 100, select: "name" }}
                                    parseOption={(val) => {
                                        val.customerId = val._id;
                                        return val;
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <Autocomplete
                                    preload={true}
                                    id="devices"
                                    url="device/device"
                                    multi={true}
                                    label="Urządzenia"
                                    optionLabelField="name"
                                    optionIdField="deviceId"
                                    prependText={null}
                                    appendText={null}
                                    onOptionSelect={(id, val) => {
                                        formik.setFieldValue(id, val.map((v) => { return { deviceId: v.deviceId, name: v.name } }))
                                    }}
                                    value={formik.values.devices}
                                    errors={formik.errors.devices}
                                    queryFields={["name"]}
                                    additionalParams={{ limit: 20, select: "name" }}
                                    parseOption={(val) => {
                                        val.deviceId = val._id;
                                        return val;
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <Date
                                    isRequired={true}
                                    id="conclusionDate"
                                    label="Data zawarcia"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.conclusionDate}
                                    errors={formik.errors.conclusionDate}
                                />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <Checkbox
                                    id="indefiniteExpiryDate"
                                    label="Zawarta na czas nieokreślony?"
                                    option="Tak"
                                    onChange={(field, value) => {
                                        formik.setFieldValue("indefiniteExpiryDate", value);
                                        if (value) {
                                            formik.setFieldValue("expiryDate", '');
                                        }
                                    }}
                                    value={formik.values.indefiniteExpiryDate}
                                    errors={formik.errors.indefiniteExpiryDate}
                                />
                            </div>
                            {!formik.values.indefiniteExpiryDate && <div className="col-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <Date
                                    isRequired={true}
                                    id="expiryDate"
                                    label="Data zakończenia"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.expiryDate}
                                    errors={formik.errors.expiryDate}
                                />
                            </div>}
                            <div className="col-12 col-md-6 col-lg-4">
                                <TextInput
                                    isRequired={true}
                                    id="reactionTime"
                                    label="Czas reakcji (w minutach)"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.reactionTime}
                                    errors={formik.errors.reactionTime}
                                />
                            </div>
                            {formik.values.type != 'customer' && <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="w-100">Zakres umowy</label>
                                    {formik.values.description && formik.values.description.map((singleDescription, index) => {
                                        return <React.Fragment key={`desciption-row-${index}`}>
                                            <div className="d-flex flex-wrap align-items-center">
                                                <label className="w-100"><b>{`Zakres nr ${index + 1}`}</b></label>
                                                <TextInput
                                                    id={`description.${index}.name`}
                                                    type="text"
                                                    placeholder="Nazwa zakresu"
                                                    value={singleDescription.name}
                                                    onChange={(element, value) => {
                                                        formik.setFieldValue(element, value)
                                                    }}
                                                    errors={_.get(formik, `errors.description[${index}].name`, null)}
                                                />
                                                <Textarea
                                                    id={`description.${index}.value`}
                                                    type="text"
                                                    placeholder="Opis zakresu"
                                                    value={singleDescription.value}
                                                    onChange={(element, value) => {
                                                        formik.setFieldValue(element, value)
                                                    }}
                                                    errors={_.get(formik, `errors.description[${index}].value`, null)}
                                                />
                                                <div className=" d-flex justify-content-center align-items-center pl-sm-0 mt-0 flex-fill" onClick={() => handleDeleteDescription(formik, index)}>
                                                    <div className="form-group flex-fill">
                                                        <button data-toggle="modal" data-target="#confirmDelete" type="button" className="btn btn-danger w-100">{`USUŃ ZAKRES ${index + 1}`}</button>
                                                    </div>
                                                </div>
                                                {formik.values.description.length !== (index + 1) && <div className="col-12 p-0 pb-3">
                                                    <hr />
                                                </div>}
                                            </div>
                                        </React.Fragment>
                                    })}
                                    <button type="button" className="btn btn-link p-0 mt-3" onClick={() => handleAddNewDescription(formik)}>Dodaj zakres<div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5"><img className="mt-1 ml-0" src={PlusIcon} /></div></button>
                                </div>
                            </div>}
                            <div className="col-12 col-md-4">
                                <UploadFilesComponent
                                    multiple={true}
                                    files={formik.values.files}
                                    uploadedFiles={formik.values.uploadedFiles}
                                    handleChangeFiles={(values) => formik.setFieldValue('files', values)}
                                    handleChangeUploadedFiles={(values) => formik.setFieldValue('uploadedFiles', values)}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mb-3">{`${contract && contract._id ? "Zapisz umowę" : "Dodaj umowę"}`} <img src={AddIcon} /></button>
                    </form>
                )}
            </Formik>
        </Fragment>
    )
}

ContractComponent.propTypes = {
    contract: PropTypes.object,
    saveContract: PropTypes.func.isRequired
};

export default ContractComponent;