import * as Yup from 'yup';

export const serviceInitialState = {
   type: '',
   customer: null,
   device: null,
   conservator: null,
   dateFrom: '',
   timeFrom: '',
   dateTo: '',
   timeTo: '',
   componentsMaintenance: false,
   components: [],
   filingDate: '',
   reportingPerson: '',
   inspectorName: '',
   electricalMeasurmentsExpirationDate: '',
   udtExaminationDate: '',
   udtExaminationType: '',
   protocolNumber: ''
}

export const serviceValidationSchema = Yup.object().shape({
   type: Yup.string()
      .required('Typ zlecenia jest wymagany')
      .oneOf(["udt_examination", "measurement", "fault", "conservation", "other", "repair"], 'Nieprawidłowy typ zlecenia'),
   customer: Yup.object().shape({
      customerId: Yup.string(),
      name: Yup.string()
   }).nullable().required('Klient jest wymagany'),
   device: Yup.object().shape({
      deviceId: Yup.string(),
      name: Yup.string(),
      components: Yup.array()
   }).nullable().required('Urządzenie jest wymagane'),
   conservator: Yup.object().shape({
      conservatorId: Yup.string(),
      name: Yup.string()
   }).nullable().required('Konserwator jest wymagany'),
   dateFrom: Yup.string()
      .required('Data realizacji (od) jest wymagana'),
   timeFrom: Yup.string()
      .required('Godzina realizacji (od) jest wymagany'),
   dateTo: Yup.string()
      .required('Data realizacji (do) jest wymagana'),
   timeTo: Yup.string()
      .required('Godzina realizacji (do) jest wymagany'),
   components: Yup.array().of(
      Yup.object().shape({
         componentId: Yup.string(),
         componentName: Yup.string(),
         activities: Yup.array().of(
            Yup.object().shape({
               description: Yup.string().required('Opis czynności jest wymagany'),
               instruction: Yup.string()
            })
         ),
         checked: false,
      })
   ),
   filingDate: Yup.string(),
   reportingPerson: Yup.string(),
   inspectorName: Yup.string(),
   electricalMeasurmentsExpirationDate: Yup.string().when(['type', 'startDate'], {
      is: (type, startDate) => type === "measurement" && startDate,
      then: Yup.string().required("Data ważności pomiarów elektrycznych jest wymagana"),
      otherWise: Yup.string()
   }),
   udtExaminationDate: Yup.string().when(['type', 'startDate'], {
      is: (type, startDate) => type === "udt_examination" && startDate,
      then: Yup.string().required("Data ważności badania udt jest wymagana"),
      otherWise: Yup.string()
   }),
   udtExaminationType: Yup.string().when(['type'], {
      is: (type) => type === "udt_examination",
      then: Yup.string().oneOf(['periodic', 'afterRepair', 'afterFault', 'control'], 'Nieprawidłowy typ badania UDT').required("Typ badania UDT jest wymagany"),
      otherWise: Yup.string()
   })
});