import _ from "lodash";
import React from 'react';
import { Link, useParams } from "react-router-dom";
import AclManager from '../../application/services/aclManager';
import Api from "../../application/services/api";
import Carousel from "../../assets/components/Carousel.component";
import edytujIcon from "../../assets/gfx/edytuj.svg";
import fileIcon from "../../assets/gfx/file.svg";

const ComponentDetails = ({ component }) => {
    const { id } = useParams();
    const typeLabels = {
        "component": "Komponent",
        "subcomponent": "Część"
    };
    const durabilityUnitLabels = {
        1: "(Ilość jazd)",
        2: "(Cykle pracy)",
        3: "(Godziny pracy)",
        4: "(Lata pracy)",
    };

    function renderInstructions() {
        if (!_.isEmpty(component.instructions)) {
            return component.instructions.map((instruction, index) => {
                let isExternal = _.startsWith(instruction, "http");
                let name = isExternal ? instruction : _.last(_.split(instruction, "/"));
                return <div className="plik-instrukcji mb-1" key={`instruction${index}`}><img className="akcja usun mr-3" src={fileIcon} /><a href={isExternal ? instruction : Api.getImageUrl() + instruction} className="card-link mt-2" target="_blank">{name}</a></div>;
            });
        } else {
            return "-";
        }
    }

    function renderServiceRanges() {
        if (!_.isEmpty(component.serviceRanges)) {
            return component.serviceRanges.map((range, index) => <li key={`range${index}`}><Link to={`/serviceRanges/${range.serviceRangeId}`} className="card-link mt-2">{range.name}</Link></li>);
        } else {
            return "-";
        }
    }

    function renderSubcomponents() {
        if (!_.isEmpty(component.subcomponents)) {
            return component.subcomponents.map((subcomponent, index) => <li key={`subcomponent${index}`}><Link to={`/components/${subcomponent.componentId}`} className="card-link mt-2">{subcomponent.name}</Link></li>);
        } else {
            return "-";
        }
    }

    function renderParameters() {
        if (!_.isEmpty(component.parameters)) {
            return component.parameters.map((param, index) => <h4 className="text-right" key={`param${index}`}><span className="font-weight-light float-left">{param.name}:</span> {param.value}</h4>)
        } else {
            return "-";
        }
    }

    return (
        <div>
            <div className="row mb-3">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                    <h3 className="w-50 mt-3 mb-3">Szczegóły komponentu</h3>
                    {AclManager.isAllowedMethod('device/component', 'update') && <Link className="btn btn-outline-primary float-md-right" to={`/component/${id}`}>Edytuj <img src={edytujIcon} /></Link>}
                </div>
            </div>		<div className="row">
                <div className="col-md-6 col-sm-12 metryka">
                    <h2 id="nazwa-urzadzenia" className="w-100">{component.name}</h2>
                    <hr />
                    <h3 className="text-right"><span className="font-weight-light float-left">Producent:</span> {component.manufacturer|| "-"}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Rodzaj:</span> {component.kind|| "-"}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Typ:</span> {typeLabels[component.type] || "Inny"}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Stan magazynowy:</span> {component.warehouseAmount || "-"}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Wartość:</span> {component.componentValue || "-"} PLN</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Założona trwałość eksploatacyjna:</span> 	{component.estimatedDurability || "-"} {durabilityUnitLabels[component.durabilityUnit]}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Założony okres trwałości (lata):</span> 	{component.estimatedDurabilityYears || "-"}</h3><hr />
                    <h3 className=" w-100"><span className="font-weight-light">Parametry</span> </h3>
                    {renderParameters()}
                    <h3 className=" w-100"><span className="font-weight-light">Opis</span> </h3>{component.description || "-"}<hr />

                    <h3 className=" w-100"><span className="font-weight-light">Zestaw czynności konserwacyjnych</span> </h3>
                    <div className="mb-3">
                        <ul className="clearListStyle">
                            {renderServiceRanges()}
                        </ul>
                    </div>
                    {component.type === "component" && <div>
                        <hr />
                        <h3 className=" w-100"><span className="font-weight-light">Części składowe</span> </h3>
                        <div className="mb-3">
                            <ul className="clearListStyle">
                                {renderSubcomponents()}
                            </ul>
                        </div>
                    </div>}
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-center flex-wrap">
                    <Carousel images={component.gallery} />
                    <div className="text-left w-100 mt-3" >
                        <h3 className=" w-100"><span className="font-weight-light">Instrukcje</span> </h3>
                        <div className="mb-3">
                            {renderInstructions()}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <hr />
                </div>
            </div>
        </div>
    )
}

export default ComponentDetails;