import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";

import { login } from '../actions/application.actions';
import Login from '../components/Login.component';

const LoginContainer = ({
    login,
    user
}) => {

    const history = useHistory();

    useEffect(() => {
        if (user) {
            history.push('/');
        }
    }, [user]);

    return (
        <Login login={login} />
    );
}

const mapStateToProps = (store) => ({
    user: store.app.user
});

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password) => dispatch(login(email, password))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
