import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import _ from "lodash";
import AclManager from '../../application/services/aclManager';
import AddIcon from "../../assets/gfx/dodaj.svg";
import ConfirmModal from '../../assets/components/ConfirmModal.component';
import ExpensesListCard from "./ExpensesListCard.component";
import Pagination from '../../assets/components/Pagination.component';
import SearchComponent from '../../assets/components/Search.component';
import ExpenseFilters from './ExpenseFilters.component';
import SortListComponent from '../../assets/components/SortList.component';

const ExpensesComponent = ({ expenses, page, total, limit, setPage, setLimit, setQuery, setFilters, deleteExpense }) => {

    const [expenseToDelete, setExpenseToDelete] = useState(null);

    const fields = [
        { id: 'name', name: 'Nazwa' },
        { id: 'customer.name', name: 'Klient' },
        { id: 'hourlyRate', name: 'Ilość roboczogodzin' },
        { id: 'totalCost', name: 'Wartość' },
    ]

    const handleDelete = () => {
        deleteExpense(_.get(expenseToDelete, '_id'));
        setExpenseToDelete(null);
    }

    const handleCancelConfirm = () => {
        setExpenseToDelete(null);
    }

    const renderExpenses = () => {
        if (!_.isEmpty(expenses)) {
            return expenses.map((expense, index) => <ExpensesListCard key={index} expense={expense} handleDelete={setExpenseToDelete} index={index + 1} />);
        }
        return <tr><td colSpan="5">Brak elementów</td></tr>;
    }

    return (
        <Fragment>
            {expenseToDelete && <ConfirmModal
                id="confirmDelete"
                label="Czy na pewno chcesz usunąć ten kosztorys?"
                positionName={_.get(expenseToDelete, 'name')}
                onConfirm={handleDelete}
                onCancel={handleCancelConfirm}
            />}
            <div className="row mb-2">
                <div className="col-12 col-md-12 pt-3 d-flex btns-filtry">
                    <h3 className="mt-0 mb-2 w-100">Kosztorysy</h3>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="row p-0">
                    <div className="col-12 col-md-6 ">
                        {AclManager.isAllowedMethod('expense/expense', 'create') && <Link type="submit" className="btn btn-primary mb-3 float-md-left mr-3" to="/expense">Dodaj <img src={AddIcon} /></Link>}
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex btns-filtry mb-2 flex-nowrap justify-content-between justify-content-lg-end">
                        <SearchComponent setQuery={setQuery} />
                        <ExpenseFilters setFilters={setFilters} />
                    </div>
                </div>
            </div>
            <div className="table-responsive ikonki ramkatabeli tabela-left mb-3">
                <table id="klienci" className="table table-hover rounded-10">
                    <thead>
                        <SortListComponent fields={fields} defaultSortBy='name' setFilters={setFilters} defaultSortType={1} />
                    </thead>
                    <tbody>
                        {renderExpenses()}
                    </tbody>
                </table>
            </div>
            <Pagination totalCount={total || 0} route={"/expenses"} page={parseInt(page)} setPage={setPage} limit={limit} setLimit={setLimit} />
        </Fragment>
    )
}

ExpensesComponent.propTypes = {
    expenses: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
    setQuery: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    deleteExpense: PropTypes.func.isRequired
};

export default ExpensesComponent;