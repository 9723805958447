import * as Yup from 'yup';

export const warehouseInitialState = {
    name: '',
    owner: null,
    type: 'secondary'
}

export const warehouseValidationSchema = Yup.object().shape({
    name: Yup.string().required('Nazwa jest wymagana'),
    type: Yup.string().oneOf(["main", "secondary"]).required("Typ jest wymagany"),
    owner: Yup.object().shape({
        _id: Yup.string(),
        name: Yup.string()
    }).nullable().required("Właściciel jest wymagany")
});