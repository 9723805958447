import * as Yup from 'yup';

export const userInitialState = {
   _id: null,
   name: '',
   password: '',
   active: true,
   color: '',
   type: '',
   role: null,
   email: '',
   phone: '',
   customer: null,
   qualificationCertificates: [],
   medicalExaminations: '',
}

export const userValidationSchema = Yup.object().shape({
   name: Yup.string()
      .required('Nazwa jest wymagana')
      .min(1, 'Wartość jest za krótka'),
   password: Yup.string()
      .when('_id', {
         is: null,
         then: Yup.string().required('Hasło jest wymagane').min(1, 'Wartość jest za krótka'),
         otherwise: Yup.string().nullable()
      }),
   type: Yup.string()
      .oneOf(["customer", "employee", "admin"], 'Nieprawidłowy typ użytkownika')
      .required('Typ jest wymagany'),
   color: Yup.string().required('Kolor jest wymagany'),
   active: Yup.bool()
      .required('Wartość jest wymagana'),
   email: Yup.string()
      .required('Email jest wymagany')
      .min(1, 'Nieprawidłowy adres email'),
   phone: Yup.string().required('Telefon jest wymagany'),
   qualificationCertificates: Yup.array().of(
      Yup.object().shape({
         certificateNumber: Yup.string().min(1, 'Wartość jest za krótka').required("Numer świadectwa jest wymagany"),
         description: Yup.string().required("Opis jest wymagany")
      })
   ),
   medicalExaminations: Yup.string(),
   role: Yup.object()
      .nullable()
      .when('type', {
         is: 'employee',
         then: Yup.object().nullable().required('Rola jest wymagana dla użytkownika typu pracownik'),
         otherWise: Yup.object().nullable()
      }),
   customer: Yup.object()
      .nullable()
      .when('type', {
         is: 'customer',
         then: Yup.object().nullable().required('Klient powiązany z kontem typu klient jest wymagany'),
         otherWise: Yup.object().nullable()
      })
});