import * as Yup from 'yup';

export const expenseInitialState = {
    name: '',
    customer: {},
    executionTime: 0,
    overhead: 0,
    vatRate: 0,
    hourlyRate: 0,
    deliveryRate: 0,
    usedComponents: [],
    activities: [{ name: '' }]
}

export const expenseValidationSchema = Yup.object().shape({
    name: Yup.string().required('Nazwa jest wymagana'),
    customer: Yup.object().shape({
        customerId: Yup.string().required('Klient jest wymagany'),
        amount: Yup.string()
    }).required('Klient jest wymagany'),
    executionTime: Yup.number().required('Ilość roboczogodzin jest wymagana'),
    overhead: Yup.number().required('Narzut jest wymagany'),
    vatRate: Yup.number().required('Wartość VAT jest wymagana'),
    hourlyRate: Yup.number().required('Stawka za godzine jest wymagana'),
    deliveryRate: Yup.number().required('Stawka za dojazd jest wymagana'),
    usedComponents: Yup.array().of(
        Yup.object().shape({
            component: Yup.object().shape({
                componentId: Yup.string().required('Komponent jest wymagany'),
                name: Yup.string(),
            }),
            amount: Yup.number().required('Ilość jest wymagana')
        })
    ),
    activities: Yup.array().of(Yup.object().shape({
        name: Yup.string().required('Nazwa aktywności jest wymagana')
    }).required('Aktywności są wymagane')
    )
});