import Api from '../../application/services/api';
import { isLoading } from "../../application/actions/application.actions";
import _ from "lodash";

const NAMESPACE = "WAREHOUSES";

export const SET_WAREHOUSES = `SET_WAREHOUSES_${NAMESPACE}`;
export const SET_WAREHOUSE = `SET_WAREHOUSE_${NAMESPACE}`;
export const SET_WAREHOUSE_COMPONENTS = `SET_WAREHOUSE_COMPONENTS_${NAMESPACE}`;
export const SET_WAREHOUSE_HISTORY = `SET_WAREHOUSE_HISTORY_${NAMESPACE}`;
export const SET_ACTIVE_TAB = `SET_ACTIVE_TAB_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;

export const CLEAR_WAREHOUSES = `CLEAR_WAREHOUSES_${NAMESPACE}`;
export const CLEAR_WAREHOUSE = `CLEAR_WAREHOUSE_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEARY_QUERY_${NAMESPACE}`;
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;


// metody setujace
export const setWarehouse = (warehouse) => dispatch => {
    dispatch({ type: SET_WAREHOUSE, warehouse });
}
export function setWarehouseComponents(components, total) {
    return (dispatch) => {
        dispatch({
            type: SET_WAREHOUSE_COMPONENTS,
            components: components,
            total: total
        });
    }
}
export const setWarehouses = (warehouses, total) => dispatch => {
    dispatch({
        type: SET_WAREHOUSES,
        warehouses,
        total
    });
}
export const setActiveTab = (tab) => dispatch => {
    dispatch({ type: SET_ACTIVE_TAB, tab: tab });
    dispatch(clearTabPage(true));
}
export const setTabQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearTabPage(true));
}
export const setTabLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setTabPage(1));
}
export const setTabFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearTabPage());
}
export const setTabPage = (page) => (dispatch, getState) => {
    const { warehouses: { warehouse, params: { limit }, activeTab } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });
    if (_.get(warehouse, "_id")) {
        if (activeTab === "components") {
            dispatch(getWarehouseComponents(_.get(warehouse, "_id")));
        } else {
            dispatch(getWarehouseHistory(_.get(warehouse, "_id")));
        }
        window.scrollTo(0, 0);
    }
}
export const setQuery = (query) => dispatch => {
    dispatch({ type: SET_QUERY, query: query });
    dispatch(clearPage('/warehouses', true));
}
export const setOffset = (offset) => dispatch => {
    dispatch({ type: SET_OFFSET, offset: offset });
}
export const setLimit = (limit) => dispatch => {
    dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
    dispatch(setPage(1, true));
}
export const setFilters = (filters) => (dispatch) => {
    dispatch({ type: SET_FILTERS, filters });
    dispatch(clearPage('/warehouses', true));
}
export const setPage = (page, reload = false) => (dispatch, getState) => {

    const { warehouses: { params: { limit } } } = getState();

    dispatch(setOffset(limit * ((page || 1) - 1)));
    dispatch({
        type: SET_PAGE,
        page: page || 1
    });

    reload && dispatch(getWarehouses());
    window.scrollTo(0, 0);
}

// metody clearujace
export const clearWarehouse = () => dispatch => {
    dispatch({ type: CLEAR_WAREHOUSE });
}
export const clearWarehouses = () => dispatch => {
    dispatch({
        type: CLEAR_WAREHOUSES
    });
}
export const clearQuery = () => dispatch => {
    dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
    dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
    dispatch({ type: CLEAR_FILTERS });
}
export const clearPage = (replaceUrl = false, reload = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setPage(1, reload));
    if (replaceUrl) {
        window.history.replaceState(null, '', replaceUrl);
    }
    window.scrollTo(0, 0);
}
export const clearTabPage = (replaceUrl = false) => dispatch => {
    // resetujemy tutaj offset i page jednocześnie
    dispatch(setOffset(0));
    dispatch(setTabPage(1));
    if (replaceUrl) {
        let splitUrl = _.head(_.split(window.location.pathname, "/page"));
        window.history.replaceState(null, '', splitUrl);
    }
    window.scrollTo(0, 0);
}

export const getWarehouse = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.get(`warehouse/warehouse/${id}`);
    if (result && result.success) {
        dispatch(setWarehouse(result.document));
    }
    dispatch(isLoading(false));
}

export const getComponentsToEdit = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    const result = await Api.get(`warehouse/items/${id}`, { limit: 1000, select: "name,amount,component" });
    dispatch(isLoading(false));
    if (result && result.success) {
        return result.documents;
    } else {
        return [];
    }
}

export const getWarehouseComponents = (id) => async (dispatch, getState) => {
    const { warehouses: { params, filters, query, queryFields } } = getState();
    dispatch(isLoading(true));
    const tmpParams = Object.assign({}, params, filters);
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }
    const result = await Api.get(`warehouse/items/${id}`, tmpParams);
    if (result && result.success) {
        dispatch(setWarehouseComponents(result.documents, result.total));
    }
    dispatch(isLoading(false));
}


export const getWarehouseHistory = (id) => async (dispatch, getState) => {
    const { warehouses: { params, filters, query, queryFields } } = getState();
    dispatch(isLoading(true));
    const tmpParams = Object.assign({}, params, filters);
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }
    tmpParams["sort[createdAt]"] = -1;
    const result = await Api.get(`warehouse/transfers/${id}`, tmpParams);
    if (result && result.success) {
        dispatch(setWarehouseHistory(result.documents, result.total));
    }
    dispatch(isLoading(false));
}

export function setWarehouseHistory(history, total) {
    return (dispatch) => {
        dispatch({
            type: SET_WAREHOUSE_HISTORY,
            history: history,
            total: total
        });
    }
}

export const getWarehouses = () => async (dispatch, getState) => {
    const { warehouses: { params, filters, query, queryFields } } = getState();
    dispatch(isLoading(true));
    const tmpParams = Object.assign({}, params, filters);
    if (query != "") {
        queryFields.map((field) => {
            tmpParams[field] = query;
        });
    }
    const result = await Api.get("warehouse/warehouse", tmpParams);
    if (result && result.success) {
        dispatch(setWarehouses(result.documents, result.total));
    }
    dispatch(isLoading(false));
}

export const saveWarehouse = (data) => async (dispatch) => {
    data = parseData(data);
    if (data._id) {
        return editWarehouse(data, dispatch);
    } else {
        return createWarehouse(data, dispatch);
    }
}

export const editWarehouse = async (data, dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.put("warehouse/warehouse", data, data._id);
    dispatch(isLoading(false));
    return result;
}

export const createWarehouse = async (data, dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.post("warehouse/warehouse", data);
    dispatch(isLoading(false));
    return result;
}

export const saveTransfer = (data) => async (dispatch) => {
    dispatch(isLoading(true));
    let result = null;
    if (data.newComponents) {
        result = await Api.post("warehouse/changeWarehouseStock", data);
    } else {
        result = await Api.post("warehouse/transfers", data);
    }
    dispatch(isLoading(false));
    return result;
}

export const deleteWarehouse = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    let result = await Api.delete("warehouse/warehouse", id);
    dispatch(isLoading(false));
    dispatch(getWarehouses());
    return result;
}

const parseData = (data) => {
    if (_.get(data, "owner._id")) {
        data.owner = data.owner._id;
    }
    return data;
}
