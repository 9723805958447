import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from "lodash";

import TickIcon from '../../assets/gfx/tick.svg'
import WykrzykIcon from '../../assets/gfx/wykrzyk.svg'
import serviceTypes from '../../scripts/serviceTypes';

const CustomerServiceListCardComponent = ({ service, index }) => {

    const [colorStatus, setColorStatus] = useState('');

    useEffect(() => {
        if (Object.keys(service).length > 0) {
            let { dateTo, startDate, completionDate } = service;

            if (completionDate) {
                setColorStatus('success');
            } else if (!completionDate && !startDate && moment(dateTo).isBefore(moment())) {
                setColorStatus('warning');
            } else if (startDate && !completionDate) {
                setColorStatus('primary');
            }
        }
    }, [service]);

    const getActivityDuration = () => {
        let { startDate, completionDate } = service;
        let duration;

        if (!completionDate) {
            return '-';
        }

        if (completionDate) {
            duration = moment(completionDate).diff(startDate);
        } else {
            duration = moment().diff(startDate);
        }

        return `${moment.utc(duration).format("H:mm").split(':')[0]}h ${moment.utc(duration).format("H:mm").split(':')[1]}m`;
    }


    const getStatusByColor = () => {

        switch (colorStatus) {
            case 'success':
                return <div className="service-status status-complete" >
                    <img src={TickIcon} className="m--1" />
                </div >;
            case 'warning':
                return <div className="service-status status-warning">
                    <img src={WykrzykIcon} className="m--1" />
                </div>;
            case 'primary':
                return <div className="service-status status-in-progress">
                    <div className="spinner-border text-primary ml-1 mr-4 mt-1" role="status"></div>
                </div>;
            default:
                return <div className="service-status status-new"></div>;
        }
    }

    return (
        <tr className={`table-${colorStatus}`}>
            <th scope="row">{index}</th>
            <td>
                <Link to={`/services/${_.get(service, '_id')}`}>
                    {service.number || ''}
                </Link>
            </td>
            <td>{_.get(service, 'type') ? _.find(serviceTypes, { value: service.type }).name : '-'}</td>
            <td>{service.startDate ? moment(service.startDate).format('DD.MM.YYYY HH:MM') : '-'}</td>
            <td>{service.completionDate ? moment(service.completionDate).format('DD.MM.YYYY HH:MM') : '-'}</td>
            <td>{getActivityDuration()}</td>
            <td>{_.get(service, 'conservator.name', '-')}</td>
            <td>
                {getStatusByColor()}
            </td>
        </tr>
    )
}

CustomerServiceListCardComponent.propTypes = {
    service: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default CustomerServiceListCardComponent;