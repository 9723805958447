import _ from "lodash";
import React from 'react';
import { Link, useParams } from "react-router-dom";
import Pagination from "../../assets/components/Pagination.component";
import arrowsIcon from "../../assets/gfx/arrows.svg";
import edytujIcon from "../../assets/gfx/edytuj.svg";
import autaIcon from "../../assets/gfx/mag-auta.svg";
import mmIcon from "../../assets/gfx/mm.svg";
import TransferModal from "../containers/TransferModal.container";
import WarehouseComponentListCard from "./WarehouseComponentListCard.component";
import WarehouseHistoryListCard from "./WarehouseHistoryListCard.component";
import SearchComponent from '../../assets/components//Search.component';
import AclManager from '../../application/services/aclManager';

const WarehouseDetails = ({ warehouse, components, history, page, total, limit, setPage, setLimit, setQuery, setActiveTab }) => {

    const { id } = useParams();

    function renderComponents() {
        if (!_.isEmpty(components)) {
            return components.map((component, index) => <WarehouseComponentListCard key={`component${index}`} component={component} index={index} />);
        }
        return <tr><td colSpan="6" className="text-center">Brak elementów</td></tr>;

    }

    function renderHistory() {
        if (!_.isEmpty(history)) {
            return history.map((item, index) => <WarehouseHistoryListCard key={`historycard${index}`} history={item} index={index} />);
        }
        return <tr><td colSpan="8" className="text-center">Brak elementów</td></tr>;

    }

    return (
        <div>
            <div className="row mb-2">
                <div className="col-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                    <h3 className="w-50 mt-3 mb-3">
                        {warehouse.type === "secondary" && <img className="magazyn-prywatny" src={autaIcon} />}
                        {warehouse.name || "-"}
                    </h3>
                </div>
            </div>
            <div className="row p-0">
                <div className="col-12 col-md-6 justify-content-start ">
                    {AclManager.getIsAdmin() && <button type="submit" className="btn btn-info mb-3 float-md-left mr-2" data-toggle="modal" data-target="#transfer-mm">Generuj MM <img src={arrowsIcon} /></button>}
                    {AclManager.isAllowedMethod('warehouse/warehouse', 'update') && <Link to={`/warehouse/${id}`} className="btn btn-outline-primary float-md-left">Edytuj <img src={edytujIcon} /></Link>}
                </div>
                <div className="col-12 col-md-6 p-0 d-flex btns-filtry">
                    <SearchComponent setQuery={setQuery} />
                    {/* <button type="submit" className="btn btn-link text-dark" data-toggle="modal" data-target="#modal-filtruj">Filtruj <img src="gfx/filtruj.svg" /></button> */}
                </div>
            </div>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {AclManager.isAllowedMethod('warehouse/item', 'get') && <li className="nav-item" onClick={() => setActiveTab("components")}>
                    <span className="nav-link" id="lista-tab" data-toggle="tab" href="#lista" role="tab" aria-controls="lista" aria-selected="false" >Lista</span>
                </li>}
                <li className="nav-item" onClick={() => setActiveTab("history")}>
                    <span className="nav-link" id="historia-tab" data-toggle="tab" href="#historia" role="tab" aria-controls="historia" aria-selected="false">Historia magazynu</span>
                </li>
            </ul>

            <div className="tab-content pt-3" id="myTabContent">

                {AclManager.isAllowedMethod('warehouse/item', 'get') && <div className="tab-pane fade" id="lista" role="tabpanel" aria-labelledby="lista-tab">
                    <h3 className="w-50 mt-3 mb-4"> Stan magazynowy</h3>
                    <div className="table-responsive ramkatabeli ikonki mb-2">
                        <table className="table table-hover rounded-10 tabela-left">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Komponent</th>
                                    <th>Typ</th>
                                    <th>Ilość</th>
                                    <th>Wartość /szt.</th>
                                    <th>SUMA</th>
                                    {/* <th></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {renderComponents()}
                            </tbody>
                        </table>
                    </div>
                    <Pagination totalCount={total} route={`/warehouses/${id}`} page={parseInt(page)} setPage={setPage} limit={limit} setLimit={setLimit} />
                </div>}
                <div className="tab-pane fade" id="historia" role="tabpanel" aria-labelledby="historia-tab">
                    <h3 className="w-50 mt-3 mb-3">Historia magazynu <img className="magazyn-prywatny" src={mmIcon} /></h3>
                    <div className="table-responsive ramkatabeli mb-2">
                        <table className="table table-hover rounded-10 tabela-left tabela-mm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Z</th>
                                    <th>Do</th>
                                    <th>Pozycja</th>
                                    <th>Ilość</th>
                                    <th>Data</th>
                                    <th>Wystawiający</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderHistory()}
                            </tbody>
                        </table>
                    </div>
                    <Pagination totalCount={total} route={`/warehouses/${id}`} page={parseInt(page)} setPage={setPage} limit={limit} setLimit={setLimit} />
                </div>
            </div>
            <TransferModal />
        </div >
    )
}

export default WarehouseDetails;