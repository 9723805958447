import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import QrReader from 'react-qr-reader'
import { toast } from 'react-toastify';


import moment from 'moment';
import _, { findLastIndex } from 'lodash';

import Api from '../../application/services/api';
import AclManager from '../../application/services/aclManager';

import ServiceTypes from '../../scripts/serviceTypes';

import Select from '../../assets/components/Select.component';
import ReplaceModalComponent from './ReplaceModal.component';
import UsedModalComponent from './UsedModal.component';
import UploadFilesComponent from '../../assets/components/UploadFiles.component';
import Textarea from '../../assets/components/Textarea.component';
import TextInput from '../../assets/components/TextInput.component';
import DebouncedTextarea from './DebouncedTextarea.component';
import Date from '../../assets/components//Date.component';

import QRIcon from '../../assets/gfx/qrcode.svg';
import MoreIcon from '../../assets/gfx/more.svg';
import StartZleceniaIcon from '../../assets/gfx/start-zlecenia.svg';
import TickIcon from '../../assets/gfx/tick.svg';
import InstructionIcon from '../../assets/gfx/instrukcja.svg';
import Tick2Icon from '../../assets/gfx/tick-2.svg';
import WymianaIcon from '../../assets/gfx/wymiana.svg';
import AddIcon from '../../assets/gfx/dodaj.svg';
import WykrzykIcon from '../../assets/gfx/wykrzyk.svg'
import EditIcon from '../../assets/gfx/edytuj.svg'

const ServiceDetails = ({ serviceData, startService, updateServiceComponents, updateServiceVerified, updateServiceComponentPhoto, completeService, user, errors }) => {

   const { id } = useParams();
   const [service, setService] = useState({});
   const [showQR, setShowQR] = useState(false);
   const componentChooseTypeTypes = [{ name: 'Brak', value: "none" }, { name: 'Wymień komponent', value: "replace" }, { name: 'Zużyte części', value: "use" }];
   const componentStatusTypes = [
      { name: 'Komponent sprawny, bez uwag', value: "working" },
      { name: 'Sprawny z zaleceniem naprawy', value: "needs_repair" },
      { name: 'Sprawny z zaleceniem regulacji', value: "needs_adjustment" },
      { name: 'Niesprawny z zaleceniem wymiany', value: "needs_replace" },
   ]
   const udtExaminationTypes = [
      { value: 'periodic', name: 'Badanie okresowe' },
      { value: 'afterRepair', name: 'Po naprawie' },
      { value: 'afterFault', name: 'Po awarii' },
      { value: 'control', name: 'Badanie kontrolne' }
   ]

   useEffect(() => {
      if (_.get(serviceData, '_id')) {
         setService(Object.assign({}, service, serviceData)); // aktualizujemy state nowo pobranymi danymi nie zapominając od wartościach nie zapisanych w zleceniu (komentarz, ilosc jazd, status)
      }
   }, [serviceData])

   //SKANER QR
   const confirmDeviceWithQR = (idFromQr) => {
      if (idFromQr === _.get(service, 'device.deviceId')) {
         updateServiceVerified(id, { qrVerified: true });
      } else {
         toast.error("Złe urządzenie");
      }
      setShowQR(false);
   }

   const shouldDisplayQr = () => {
      if (_.get(serviceData, 'conservator.conservatorId') === _.get(user, '_id') && serviceData.startDate && !serviceData.completionDate && !serviceData.qrVerified) {
         return true;
      }

      return false;
   }

   const handleScan = (data) => {
      if (data) {
         //wycinamy id z urla
         const parts = data.split('/');
         const id = parts.pop();
         console.log(id);
         confirmDeviceWithQR(id);
      }
   }
   const handleError = (error) => {
      console.log({ error })
   }

   // CZAS WYKONYWANIA ZLECENIA
   const getServiceDuration = (service) => {
      let { startDate, completionDate } = service;
      let ms = moment(completionDate).diff(moment(startDate));
      let duration = moment.duration(ms);
      return `${Math.floor(Math.abs(duration.asHours())) + ' h ' + moment.utc(ms).format("mm [m] ss [s]")}`;
   }

   // KOLOR TABELI
   const getComponentStatus = (component) => {
      if (_.get(component.replacedComponent, 'componentId')) return 'bg-warning';

      if (!_.isEmpty(component.activities)) {
         let isCompleted = true;

         component.activities.forEach(activity => {
            if (!activity.completed) isCompleted = false;
         })

         if (isCompleted && (!_.get(component, 'componentId') || _.get(component, 'status') != 'none')) {
            return 'bg-success'
         }
         return ''
      }
   }

   // ZMIANA STANU AKTYWNOŚCI AKTYWNOŚCI
   const handleActivityCompleted = (cmpIndex, actIndex) => {
      let components = _.cloneDeep(service.components);

      if (components[cmpIndex] && components[cmpIndex].activities && components[cmpIndex].activities[actIndex]) {
         components[cmpIndex].activities[actIndex].completed = !components[cmpIndex].activities[actIndex].completed;
      }
      updateServiceComponents(id, components);
   }

   // SELECT POLA CHOOSETYPE (CZY ZUŻYTE KOMPONENTY CZY WYMIENIONY)
   const handleComponentChooseType = (cmpIndex, val) => {
      let tmpService = _.cloneDeep(service);

      if (tmpService.components[cmpIndex]) {
         tmpService.components[cmpIndex].chooseType = val;
         tmpService.components[cmpIndex].usedComponents = [];
         tmpService.components[cmpIndex].replacedComponent = {};
      }

      updateServiceComponents(id, tmpService.components);
   }

   // REPLACED COMPONENT
   const setReplacedComponent = (newComponent, cmpIndex) => {
      let components = _.cloneDeep(service.components);

      if (components[cmpIndex]) {
         components[cmpIndex].replacedComponent = newComponent;
      }
      updateServiceComponents(id, components);
   }

   // USED COMPONENTS
   const setUsedComponents = (usedComponents, cmpIndex) => {
      let components = _.cloneDeep(service.components);

      if (components[cmpIndex]) {
         components[cmpIndex].usedComponents = usedComponents;
      }
      updateServiceComponents(id, components);
   }

   // STATUS KOMPONENTU
   const changeComponentStatus = (cmpIndex, val) => {
      let components = _.cloneDeep(service.components);
      if (components[cmpIndex]) {
         components[cmpIndex].status = val;
      }

      updateServiceComponents(id, components);
   }

   // KOMENTARZ DO KOMPONENTU
   const setComponentFeedbackComment = (cmpIndex, val, components) => {
      if (components[cmpIndex]) {
         if (components[cmpIndex].feedback) {
            components[cmpIndex].feedback.comment = val;
         } else {
            components[cmpIndex].feedback = {
               comment: val
            }
         }
      }

      updateServiceComponents(id, components);
   }

   // ZDJĘCIE DO KOMPONENTU
   const setComponentFeedbackPhoto = (val, cmpIndex) => {
      if (!_.isEmpty(val) && val[0]) {
         updateServiceComponentPhoto(id, { file: val[0], componentIndex: cmpIndex })
      } else {
         let components = _.cloneDeep(service.components);

         if (_.get(components[cmpIndex], 'feedback.photo')) {
            delete components[cmpIndex].feedback.photo;
         }
         updateServiceComponents(id, components);
      }
   }

   // KOMENTARZ DO ZLECENIA
   const setCompletionComment = (val) => {
      let tmpService = _.cloneDeep(service);

      tmpService.completionComment = val;
      setService(tmpService)
   }

   // ILOŚĆ CYKLI KOMPONENTU
   const setNumberOfCycles = (val) => {
      let tmpService = _.cloneDeep(service);

      tmpService.numberOfCycles = val;
      setService(tmpService)
   }

   const setInspectorName = (val) => {
      let tmpService = _.cloneDeep(service);

      tmpService.inspectorName = val;
      setService(tmpService)
   }

   const setElectricalMeasurmentsExpirationDate = (val) => {
      let tmpService = _.cloneDeep(service);
      tmpService.electricalMeasurmentsExpirationDate = val;
      setService(tmpService)
   }

   const setUdtExaminationDate = (val) => {
      let tmpService = _.cloneDeep(service);
      tmpService.udtExaminationDate = val;
      setService(tmpService)
   }

   const setFinalStatus = (val) => {
      console.log(val);
      let tmpService = _.cloneDeep(service);

      tmpService.finalStatus = val;
      setService(tmpService)
   }

   const handleCompleteService = () => {
      let data = { completionComment: service.completionComment || '' };
      if ('numberOfCycles' in service) {
         data.numberOfCycles = service.numberOfCycles;
      }
      if ('electricalMeasurmentsExpirationDate' in service) {
         data.electricalMeasurmentsExpirationDate = service.electricalMeasurmentsExpirationDate;
      }
      if ('udtExaminationDate' in service) {
         data.udtExaminationDate = service.udtExaminationDate;
      }
      if ('inspectorName' in service) {
         data.inspectorName = service.inspectorName;
      }
      if ('finalStatus' in service) {
         data.finalStatus = service.finalStatus;
      }
      completeService(id, data);
   }

   const renderComponentActivities = () => {
      return !_.isEmpty(service.components) && service.components.map((component, cmpIndex) => {
         return <div className="card" key={`component-activities-${component.componentId}`}>
            <div className={`card-header ${getComponentStatus(component)}`} role="tab" id="headingOne" href={`#collapseOne${cmpIndex}`} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
               <h5 className="mb-0 d-flex justify-content-between align-items-center">
                  <span href={`#collapseOne${cmpIndex}`} className="d-flex align-items-center float-left">
                     {_.get(component, 'componentName', 'Inne')}
                     {_.get(errors, `components[${cmpIndex}]`) && <div className="usercolor-container rounded-circle ml-2 mr-2 ml-0 border-0">
                        <div className="usercolor text-center rounded-circle">
                           <img src={WykrzykIcon} className="m-2" />
                        </div>
                     </div>}
                  </span>

                  {_.get(component, 'replacedComponent.componentId') &&
                     <div className="finishedTask float-right">
                        Komponent wymieniony. Zainstalowano <strong>{_.get(component, 'replacedComponent.name')}</strong>
                     </div>}
               </h5>
            </div>

            <div id={`collapseOne${cmpIndex}`} className="collapse" role="tabpanel" aria-labelledby="headingOne">
               <div className="card-body p-0">
                  {_.get(component, 'lastServiceInfo') && <div className="has-float-label error m-3">
                     {_.get(component, 'lastServiceInfo.comment') && <div className="form-control powiadomienie d-flex justify-content-between p-3" id="first"> {_.get(component, 'lastServiceInfo.comment')}
                        {_.get(component, 'lastServiceInfo.photo') && <a href="#!" className="card-link kolor" data-toggle="modal" data-target="#alertPhoto" style={{ whiteSpace: 'nowrap' }}>Pokaż zdjęcie</a>}
                     </div>}
                     <label htmlFor="first" className="kolor"><strong>UWAGA!</strong> Komentarz z ostatniej konserwacji</label>
                  </div>}

                  {/* MODAL */}
                  {_.get(component, 'lastServiceInfo.photo') && <div className="modal fade" id="alertPhoto" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
                     <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                           <div className="modal-header">
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                 <span aria-hidden="true">&times;</span>
                              </button>
                           </div>
                           <div className="modal-body">
                              <div className="input-group">
                                 <div className="col-sm-12">
                                    <img src={_.get(component, 'lastServiceInfo.photo')} className="alertPhotoimg w-100" />
                                 </div>
                              </div>
                           </div>
                           <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                           </div>
                        </div>
                     </div>
                  </div>}

                  <div className="table-responsive">
                     <table id="zlecenieszczegol" className="table table-hover rounded-10 tabela-left">
                        <thead>
                           <tr>
                              <th className="w-50">Działanie</th>
                              <th>Zestaw</th>
                              <th>Instrukcja</th>
                              <th>Stan</th>
                           </tr>
                        </thead>
                        <tbody>
                           {!_.isEmpty(component, 'activities') && component.activities.map((activity, actIndex) => {
                              return <tr key={`activity-${actIndex}`} className={activity.completed ? 'table-success' : ''}>
                                 <td>{_.get(activity, 'description', 'Brak')}</td>
                                 <td>{_.get(activity, 'serviceRangeName', 'Brak')}</td>
                                 <td>
                                    <button
                                       type="submit"
                                       onClick={() => window.open(_.get(activity, 'instruction'), "_blank")}
                                       className="btn btn-sm btn-primary m-0 rounded-circle kolko-40">
                                       <img src={InstructionIcon} />
                                    </button>
                                 </td>
                                 <td>
                                    <button
                                       type="submit"
                                       onClick={() => handleActivityCompleted(cmpIndex, actIndex)}
                                       className={`btn btn-sm ${activity.completed ? 'btn-success' : 'btn-outline-success'} m-0 rounded-circle kolko-40`}
                                       disabled={!service.startDate || service.completionDate}>
                                       <img src={TickIcon} className="m--1" />
                                    </button>
                                    {_.get(errors, `components[${cmpIndex}].activities[${actIndex}].completed`) &&
                                       <div className="errormsg-activity-completed">{_.get(errors, `components[${cmpIndex}].activities[${actIndex}].completed`)}</div>
                                    }
                                 </td>
                              </tr>
                           })}
                        </tbody>
                     </table>
                  </div>

                  <hr />
                  {_.get(component, 'componentId') && <div className="container-fluid pb-3 pl-3 pr-3">
                     <div className="row pl-2 pr-2 d-flex justify-content-between">
                        <div className="col-sm-12 col-md-6 d-flex align-content-start flex-wrap p-1">
                           <label className="label" htmlFor="finishComponentConservation">Wybierz czynność</label>
                           <Select
                              id="chooseType"
                              dontShowDefaultOption={true}
                              options={componentChooseTypeTypes}
                              onChange={(element, val) => handleComponentChooseType(cmpIndex, val)}
                              value={service.components[cmpIndex] && service.components[cmpIndex].chooseType}
                              disabled={!service.startDate || service.completionDate}
                              errors={_.get(errors, `components[${cmpIndex}].chooseType`)}
                           />
                           {_.get(service.components[cmpIndex], 'chooseType') == 'replace' && !service.completionDate &&
                              <button
                                 className="btn btn-danger flex-fill mt-2"
                                 data-toggle="modal"
                                 data-target={`#wymiana-komponentu${cmpIndex}`}
                                 type="button"
                                 disabled={!service.startDate || service.completionDate}>
                                 <img src={WymianaIcon} className="mr-2" />Wybierz komponent do wymiany
                              </button>
                           }
                           {_.get(service.components[cmpIndex], 'chooseType') == 'use' && !service.completionDate &&
                              <button
                                 className="btn btn-info flex-fill mt-2"
                                 data-toggle="modal"
                                 data-target={`#modalPodzespoly${cmpIndex}`}
                                 type="button"
                                 disabled={!service.startDate && service.completionDate}>
                                 <img src={AddIcon} className="mr-2" />Wybierz zużyte części
                              </button>
                           }
                        </div>
                        <div className="col-sm-12 col-md-6 d-flex align-items-stretch flex-wrap p-1">
                           {_.get(service.components[cmpIndex], 'chooseType') == 'replace' && <div className="mt-2 w-100 d-flex flex-column" id="komponent">
                              <div className="card card-body mb-0 flex-fill">
                                 <label className="label" htmlFor="komponent-urzadzenia">Nowy komponent</label>
                                 <ul className="clearListStyle">
                                    {_.get(component, 'replacedComponent.componentId') ?
                                       <li className="param-komp mb-1"> {_.get(component.replacedComponent, 'name', 'brak')}
                                          <div className="errormsg">{_.get(errors, `components[${cmpIndex}].replacedComponent`)}</div>
                                       </li>
                                       : <li className="param-komp mb-1">Brak komponentu
                                          <div className="errormsg">{_.get(errors, `components[${cmpIndex}].replacedComponent`)}</div>
                                       </li>}
                                 </ul>
                              </div>
                           </div>}

                           {_.get(service.components[cmpIndex], 'chooseType') == 'use' && <div className="mt-2 d-flex flex-column w-100" id="podzespoly">
                              <div className="card card-body mb-0 flex-fill">
                                 <label className="label" htmlFor="podzespoly-urzadzenia">Zużyte części</label>
                                 <ul className="clearListStyle">
                                    {!_.isEmpty(component.usedComponents) ? component.usedComponents.map((usedComponent, usedCmpIndex) => {
                                       return <li className="param-komp mb-1" key={`usedComponent${usedCmpIndex}`}>
                                          {_.get(usedComponent, 'name', 'brak')}
                                          <div className="float-right">
                                             <span className="badge badge-primary badge-pill float-right">{_.get(usedComponent, 'amount', 'brak')}</span>
                                          </div>
                                          {_.get(errors, `components[${cmpIndex}].usedComponents[${usedCmpIndex}]`) &&
                                             <div className="errormsg">{_.get(errors, `components[${cmpIndex}].usedComponents[${usedCmpIndex}]`)}</div>
                                          }
                                       </li>
                                    }) : <li className="param-komp mb-1">
                                       Brak elementów
                                          {_.get(errors, `components[${cmpIndex}].usedComponents`) &&
                                          <div className="errormsg">{_.get(errors, `components[${cmpIndex}].usedComponents`)}</div>
                                       }
                                    </li>}
                                 </ul>
                              </div>
                           </div>}
                        </div>

                        <div className="col-sm-12 d-flex align-items-start flex-wrap p-1">
                           <label className="label" htmlFor="finishComponentConservation">Wybierz status komponentu</label>
                           <div className="input-group align-items-start w-100">
                              <Select
                                 id="status"
                                 options={componentStatusTypes}
                                 onChange={(element, val) => changeComponentStatus(cmpIndex, val)}
                                 value={service.components[cmpIndex] && service.components[cmpIndex].status}
                                 errors={_.get(errors, `components[${cmpIndex}].status`)}
                                 disabled={!service.startDate || service.completionDate} />
                           </div>
                        </div>
                     </div>

                     <div className="row mt-4">
                        <div className="col-sm-12 col-lg-12">
                           <span className="has-float-label">
                              <label htmlFor="first" className="kolor">Dodaj komentarz do kolejnej konserwacji</label>
                           </span>
                           <div className="form-control powiadomienie d-flex p-3" id="first">
                              <div className="col-sm-12 col-md-6 col-lg-6">
                                 <DebouncedTextarea
                                    id="comment"
                                    rows="2"
                                    label="Opis"
                                    cols="100"
                                    placeholder="Opis komponentu"
                                    setQuery={(val, components) => setComponentFeedbackComment(cmpIndex, val, components)}
                                    time={1000}
                                    comment={component.feedback && component.feedback.comment || ''}
                                    disabled={!service.startDate || service.completionDate}
                                    components={service.components} />
                              </div>
                              {!service.completionDate && <div className="col-sm-12 col-md-6 col-lg-4">
                                 <UploadFilesComponent
                                    multiple={true}
                                    index={cmpIndex}
                                    files={component.feedback && component.feedback.photo && [component.feedback.photo] || []}
                                    uploadedFiles={[]}
                                    handleChangeFiles={() => setComponentFeedbackPhoto(null, cmpIndex)}
                                    handleChangeUploadedFiles={(values) => setComponentFeedbackPhoto(values, cmpIndex)}
                                    disabled={!service.startDate || service.completionDate} />
                              </div>}
                           </div>
                        </div>
                     </div>

                  </div>}

               </div>
            </div>

            <ReplaceModalComponent
               cmpIndex={cmpIndex}
               replacedComponent={component.replacedComponent || {}}
               onSelectNewComponent={(newComponent) => setReplacedComponent(newComponent, cmpIndex)} />
            <UsedModalComponent
               cmpIndex={cmpIndex}
               usedComponents={component.usedComponents || {}}
               onSetUsedComponents={(usedComponents) => setUsedComponents(usedComponents, cmpIndex)} />
         </div >
      })
   }

   return (
      <div>

         {
            showQR &&
            <div style={{ width: '50%', position: 'fixed', left: 'auto', top: 'auto', zIndex: 9999 }}>
               <QrReader
                  delay={1000}
                  onError={handleError}
                  onScan={handleScan}
               />
               <button
                  onClick={() => setShowQR(false)}
               >Zamknij skaner</button>
            </div>

         }
         <div className="container-fluid p-0 mb-5">
            {AclManager.isAllowedMethod('service/service', 'update') && !_.get(service, 'startDate') && _.get(service, 'type') != 'planned_conservation' &&
               <Link className="btn btn-outline-primary float-md-right mt-2" to={`/service/${id}`}>Edytuj <img src={EditIcon} /></Link>
            }
            <div className="row">
               <div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-wrap justify-content-between flex-column">
                  <h3 className="mt-3 mb-3 w-100">Szczegóły zlecenia</h3>
                  <h3 id="typ-zlecenia" className="font-weight-light w-100">{_.get(service, 'type') ? _.find(ServiceTypes, { value: service.type }).name : '-'}</h3>
                  {_.get(service, 'udtExaminationType') &&
                     <h4 id="typ-badania-udt" className="w-100"><span>Typ badania:</span> {_.find(udtExaminationTypes, { value: service.udtExaminationType }).name}</h4>
                  }
                  <Link to={`/devices/${_.get(service, 'device.deviceId')}`}>
                     <h3 id="nazwa-urzadzenia" className="w-100">{_.get(service, 'device.name')} <img src={MoreIcon} className="ml-2" /></h3>
                  </Link>
                  {/* TODO JOIN UDT NUMBER */}
                  <h4 id="id-urzadzenia" className="w-100"><span>ID:</span> {_.get(service.device, 'udtNumber')}</h4>
                  {_.get(service, 'filingDate') && <h4 id="filing-date" className="w-100 mb-1"><span>Data zgłoszenia:</span> {moment(service.filingDate).format("DD.MM.YYYY")}</h4>}
                  {_.get(service, 'reportingPerson') && <h4 id="reportingPerson" className="w-100 mb-1"><span>Osoba zgłaszająca:</span> {service.reportingPerson}</h4>}
                  {_.get(service, 'startDate') && <h4 id="czas-zlecenia" className="w-100 mb-1">
                     <span>Czas pracy:</span> {getServiceDuration(service)}

                  </h4>}
                  {_.get(service, 'completionDate') && <h4>{moment(service.startDate).format("DD.MM.YYYY HH:mm") + ' - ' + moment(service.completionDate).format("DD.MM.YYYY HH:mm")}</h4>}
                  {_.get(service, 'protocolNumber') && <h3>Numer protokołu: {service.protocolNumber}</h3>}
                  {_.get(service, 'protocol') && <a href={service.protocol} target="_blank">Kliknij aby wyświetlić protokół</a>}
               </div>

               <div className="zlecenie-przyciski col-sm-12 col-md-6 col-lg-3 d-flex flex-wrap justify-content-center align-content-end mt-5 border  border-top-0 border-bottom-0">
                  <a
                     href={`${Api.getImageUrl()}/device/getQrCode/${_.get(service, 'device.deviceId')}`}
                     target="blank"
                     type="submit"
                     className={`btn btn-outline-secondary ${_.get(service, 'startDate') ? 'w-100' : 'w-75'} m-2`}>
                     <img src={QRIcon} /> Pobierz kod QR
                  </a>

                  {
                     shouldDisplayQr() &&
                     <button
                        target="blank"
                        className={`btn btn-outline-secondary ${_.get(service, 'startDate') ? 'w-100' : 'w-75'}   m-2`}
                        onClick={() => setShowQR(true)}
                     >
                        <img src={QRIcon} /> Zweryfikuj kodem QR
                  </button>
                  }

                  {!_.get(service, 'startDate') && !AclManager.getIsAdmin() && AclManager.isAllowedMethod('service/service', 'completeService') ?
                     <button
                        type="submit"
                        className="btn btn-outline-success w-75 mt-1"
                        onClick={() => startService(id, { startDate: moment().format('X') })}>
                        <img src={StartZleceniaIcon} /> Rozpocznij
                  </button> : _.get(service, 'startDate') &&
                     <div className="border border-success text-center text-success pt-3 pb-3 w-100 ml-md-2 mr-md-2 mt-1">
                        <h4 id="czas-zlecenia" className="w-100 mb-0"><span>Czas pracy:</span> {getServiceDuration(service)}</h4>
                     </div>}
               </div>

               <div className="col-sm-12 col-md-12 col-lg-5 d-flex flex-wrap align-content-end">
                  <h3 className="mt-3 mb-3 w-100">Dane</h3>
                  <div className="row">
                     <div className="col-6 d-flex flex-wrap align-content-start">
                        <h4 className="w-100 align-self-start">Czas zlecenia</h4>
                        <p id="data-zlecenia" className="w-100 m-0">{moment(_.get(service, 'dateFrom')).format('DD.MM.YYYY')}</p>
                        <p id="godzina-zlecenia" className="w-100 mb-0">
                           {moment(_.get(service, 'dateFrom')).format('HH:mm')} - {moment(_.get(service, 'dateTo')).format('HH:mm')}
                        </p>
                     </div>
                     <div className="col-6 d-flex flex-wrap align-content-end">
                        <h4 className="w-100">Adres</h4>
                        <p className="w-100 mb-1">{_.get(service, 'device.address', 'brak')}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {_.get(service, 'description') && <div className="row">
            <div className="col-sm-12 col-lg-12 ">
               <h4 className="w-100 align-self-start">Dodatkowe informacje</h4>
               <div id="informacjeDodatkoweZlecenia" className="mb-4">{_.get(service, 'description')}</div>
            </div>
         </div>}
         <hr />
         <h3 className="mt-3 mb-3">Czynności do wykonania</h3>

         <div id="accordion" role="tablist" className="mb-5">
            {renderComponentActivities()}
         </div>

         <h3 className="mb-3">Podsumowanie</h3>
         <div className={`row ${service.completionDate && 'mb-5'}`}>
            <div className="col-sm-12 col-md-7 col-lg-6">
               <div className="input-group mb-3">
                  <TextInput
                     id="service.numberOfCycles"
                     type="number"
                     label="Aktualna ilość cykli urządzenia"
                     value={service.numberOfCycles || ''}
                     onChange={(id, val) => setNumberOfCycles(val)}
                     errors={_.get(errors, `numberOfCycles`)}
                     disabled={!service.startDate || service.completionDate} />
               </div>
               {service.type === "measurement" && <div className="input-group mb-3">
                  <Date
                     id="service.electricalMeasurmentsExpirationDate"
                     label="Data ważności pomiarów elektrycznych"
                     onChange={(id, val) => setElectricalMeasurmentsExpirationDate(val)}
                     value={service.electricalMeasurmentsExpirationDate}
                     errors={errors.electricalMeasurmentsExpirationDate}
                     disabled={!service.startDate || service.completionDate}
                  />
               </div>}
               {service.type === "udt_examination" && <div className="input-group mb-3">
                  <Date
                     id="service.udtExaminationDate"
                     label="Data ważności badania udt"
                     onChange={(id, val) => setUdtExaminationDate(val)}
                     value={service.udtExaminationDate}
                     errors={errors.udtExaminationDate}
                     disabled={!service.startDate || service.completionDate}
                  />
               </div>}
               {service.type === "udt_examination" && <div className="input-group mb-3">
                  <TextInput
                     id="service.inspectorName"
                     type="text"
                     label="Inspektor obecny na badaniu"
                     value={service.inspectorName}
                     onChange={(id, val) => setInspectorName(val)}
                     errors={_.get(errors, `inspectorName`)}
                     disabled={!service.startDate || service.completionDate} />
               </div>}
               <Textarea
                  id="completionComment"
                  rows="4"
                  cols="100"
                  label="Komentarz ogólny do zlecenia"
                  placeholder="Wpisz komentarz..."
                  onChange={(id, val) => setCompletionComment(val)}
                  value={service.completionComment || ''}
                  disabled={!service.startDate || service.completionDate} />

               <Select
                  id="finalStatus"
                  label="Status urządzenia"
                  dontShowDefaultOption={false}
                  options={[{ name: 'Urządzenie sprawne technicznie', value: 'functional' }, { name: 'Urządzenie niesprawne technicznie', value: 'faulty' }]}
                  onChange={(element, val) => setFinalStatus(val)}
                  value={service.finalStatus}
                  disabled={!service.startDate || service.completionDate}
                  errors={_.get(errors, `finalStatus`)} />

            </div>
            {service.startDate && !service.completionDate && !AclManager.getIsAdmin() && AclManager.isAllowedMethod('service/service', 'completeService')
               && <div className="col-sm-12 d-flex justify-items-center">
                  <button type="submit" className="btn btn-outline-success btn-lg w-100 mt-1 mb-5" onClick={() => handleCompleteService()}>
                     <img src={Tick2Icon} /> Zakończ zlecenie
                  </button>
               </div>}
         </div>

      </div>
   )
}

export default ServiceDetails;