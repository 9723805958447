import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Api from "../../application/services/api";
import DownloadIcon from '../../assets/gfx/pobierz.svg';
import TickIcon from '../../assets/gfx/tick.svg';
import WykrzykIcon from '../../assets/gfx/wykrzyk.svg';

const SERVICE_TYPES = {
    'udt_examination': 'Badanie UDT',
    'fault': 'Awaria',
    'conservation': 'Konserwacja',
    'measurement': 'Pomiary',
    'other': 'Inne'
};


const DeviceDetailsServices = ({ services }) => {

    const getStatus = (service) => {
        let { dateTo, startDate, completionDate } = service;

        if (completionDate) return 'success';
        if (!completionDate && !startDate && moment(dateTo).isBefore(moment())) return 'warning';
        if (startDate && !completionDate) return 'primary';
        return ''
    }

    const getStatusByColor = (service) => {
        const colorStatus = getStatus(service);

        switch (colorStatus) {
            case 'success':
                return <div className="service-status status-complete" >
                    <img src={TickIcon} className="m--1" />
                </div >;
            case 'warning':
                return <div className="service-status status-warning">
                    <img src={WykrzykIcon} className="m--1" />
                </div>;
            case 'primary':
                return <div className="service-status status-in-progress">
                    <div className="spinner-border text-primary ml-1 mr-4 mt-1" role="status"></div>
                </div>;
            default:
                return <div className="service-status status-new"></div>;
        }
    }

    const renderNextServices = () => {
        if (!services || services.length === 0) return <tr><td className="text-center" colSpan="8">Brak zaplanowanych zleceń</td></tr>;

        return services.map((service, i) => {
            if (!service.completionDate) {
                return (
                    <tr key={i}>
                        <td>{i + 1.}</td>
                        <td>
                            <Link to={`/services/${service._id}`}>
                                {_.get(service, 'number', '-')}
                            </Link>
                        </td>
                        <td>
                            <Link to={`/services/${service._id}`}>
                                {SERVICE_TYPES[service.type] || '-'}
                            </Link>
                        </td>
                        <td>{moment(service.dateFrom).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(service.dateTo).format("DD.MM.YYYY HH:mm")}</td>
                        <td>-</td>
                        <td> <Link to={`/users/${service.conservator._id}`}> {_.get(service, 'conservator.name', '-')} </Link></td>
                        <td>
                            {getStatusByColor(service)}
                        </td>
                    </tr>
                )
            }
        })
    }
    const renderServicesHistory = () => {
        if (!services || services.length === 0) return <tr><td className="text-center" colSpan="8">Brak zleceń</td></tr>;


        const calculateBetweenTime = (from, to) => {
            const duration = moment.duration(to.diff(from)).asMinutes();
            return `${Math.floor((duration / 60))}h ${Math.floor(duration % 60)}min`;
        }

        return services.map((service, i) => {
            if (service.completionDate) {
                return (
                    <tr className="table-success" key={i}>
                        <td>{i + 1.}</td>
                        <td>
                            <Link to={`/services/${service._id}`}>
                                {_.get(service, 'number', '-')}
                            </Link>
                        </td>
                        <td>
                            <Link to={`/services/${service._id}`}>
                                {SERVICE_TYPES[service.type] || '-'}
                            </Link>
                        </td>
                        <td>{moment(service.dateFrom).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(service.dateTo).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{calculateBetweenTime(moment(service.dateFrom), moment(service.dateTo))}</td>
                        <td> <Link to={`/users/${service.conservator._id}`}> {_.get(service, 'conservator.name', '-')} </Link></td>
                        <td>
                            {getStatusByColor(service)}
                        </td>
                        <td>
                            <a target="_blank" href={`${Api.getHost()}service/service/getServiceReport/${service._id}`}><img src={DownloadIcon} /></a>
                        </td>
                    </tr>
                )
            }
        });

    }

    return (
        <div className="tab-pane fade show" id="zlecenia" role="tabpanel" aria-labelledby="zlecenia-tab">

            <h3 className="mt-3 mb-3">Następne zlecenia</h3>
            <div className="table-responsive ramkatabeli tabela-left ikonki">
                <table className="table table-hover rounded-10">
                    <thead>
                        <tr>
                            <th>Lp.</th>
                            <th>Nr zlecenia</th>
                            <th>Typ zlecenia</th>
                            <th>Data rozpoczęcia</th>
                            <th>Data zakończenia</th>
                            <th>Czas realizacji</th>
                            <th>Konserwator</th>
                            <th>Stan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderNextServices()}
                    </tbody>
                </table>
            </div>

            <h3 className="mt-3 mb-3">Historia zleceń</h3>
            <div className="table-responsive ramkatabeli mb-5">
                <table className="table tabela-left table-hover rounded-10">
                    <thead>
                        <tr>
                            <th>Lp.</th>
                            <th>Nr zlecenia</th>
                            <th>Typ zlecenia</th>
                            <th>Data rozpoczęcia</th>
                            <th>Data zakończenia</th>
                            <th>Czas realizacji</th>
                            <th>Konserwator</th>
                            <th>Stan</th>
                            <th>Raport</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderServicesHistory()}
                    </tbody>
                </table>
            </div>



        </div>
    )
}

export default DeviceDetailsServices;