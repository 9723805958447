import React from 'react';

import Kosz from '../gfx/kosz.svg'

const Chip = ({ name, onRemove }) => {
    return (
        <div className="param-komp mb-1">
            <img className="akcja usun mr-3" src={Kosz}
                onClick={onRemove}
            />
            {name}
        </div>
    )
}

export default Chip;