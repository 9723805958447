import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import placeholderImage from "../../assets/gfx/fixlifter-1.jpg";
import tickIcon from "../../assets/gfx/tick-2.svg";
import iksRedIcon from "../../assets/gfx/x-r.svg";
import helpIcon from "../../assets/gfx/help.svg";

const ServiceLife = ({
    device,
    user
}) => {

    const buildingTypes = {
        1: "Budynek mieszkalny do 6 mieszkań, małe biura z kilkoma pracownikami (1)",
        2: "Budynek mieszkalny do 20 mieszkań, małe biura z 2-5 kondygnacji (2)",
        3: "Budynek mieszkalny do 50 mieszkań, małe biura 10 kondygnacji (3)",
        4: "Budynek mieszkalny ponad 50 mieszkań, duże biura ponad 10 kondygnacji (4)",
        5: "Małe hotele (2)",
        6: "Średnie hotele (3)",
        7: "Duże hotele, małe i średnie szpitale (4)",
        8: "Budynki biurowe powyżej 100m wysokości, duże szpitale (5)",
        9: "Dźwigi towarowe o niewielkiej ilości jazd (2)",
        10: "Dźwigi towarowe o średniej ilości jazd (3)",
        11: "Dźwigi towarowe mocno obciążone (5)"
    };

    const getParametersTable = () => {
        let tableRows = [];
        if (!_.isEmpty(device.parameters)) {
            _.each(device.parameters, (parameter, index) => {
                tableRows.push(<tr key={`parameter${index}`}>
                    <th>{parameter.name}</th>
                    <td>
                        <div className="paramvalue">{parameter.value}</div>
                    </td>
                </tr>);
            });
        } else {
            tableRows.push(<tr>
                <th colSpan='2'>Brak parametrów</th>
            </tr>);
        }
        return tableRows;
    }

    const getParsedImage = () => {
        if (!_.isEmpty(device.gallery)) {
            let src = device.gallery[0];
            return <img className=" ml-5 mr-5" src={src} style={{ "maxWidth": "90%", objectFit: "contain" }} />
        }
        return <img className="ml-5 mr-5" src={placeholderImage} style={{ "maxWidth": "90%", objectFit: "contain" }} />
    }

    const getComponentsTable = () => {
        if (!_.isEmpty(device.components)) {
            let componentRows = [];
            const durabilityUnits = {
                1: "jazd",
                2: "cykli",
                3: "godzin"
            };

            const durabilitySources = {
                1: "Instrukcja eksploatacji",
                2: "Producent/ katalogi",
                3: "Osoba kompetentna"
            };
            _.each(device.components, (component, index) => {
                let installationDate = component.installationDate ? moment(component.installationDate).format("YYYY") : moment(device.createdAt).format("YYYY");
                let serviceLifeEndYear = parseInt(installationDate) + _.get(component, "estimatedDurabilityYears", 0);
                let durabilityLeft = component.estimatedDurability - component.numberOfCycles;
                let yearsLeft = serviceLifeEndYear - parseInt(moment().format("YYYY"));
                componentRows.push(<tr key={`component${index}`}>
                    <th>{index + 1}.</th>
                    <th>{component.name}</th>
                    <td>{component.installationDate ? moment(component.installationDate).format("YYYY") : moment(device.createdAt).format("YYYY")}</td>
                    <td>{component.estimatedDurability} {durabilityUnits[component.durabilityUnit] || "jazd"}</td>
                    <td>{component.estimatedDurabilityYears || "brak danych"}</td>
                    <td>{durabilitySources[component.durabilitySource] || "brak danych"}</td>
                    <td>{component.numberOfCycles} cykli</td>
                    <td>{durabilityLeft} cykli</td>
                    <td>{yearsLeft}</td>
                    <td>{serviceLifeEndYear}</td>
                </tr>)
            });
            return componentRows;
        }
        return <tr><th colSpan='10' className="text-center">Brak komponentów</th></tr>
    }

    const getCeMarkingIcon = () => {
        if (device.ceMarking) {
            return <img style={{ position: "absolute", top: "-12px", right: "-35px" }} src={tickIcon} />
        } else {
            return <img style={{ position: "absolute", top: "-12px", right: "-35px" }} src={iksRedIcon} />
        }
    }

    const getStatementText = () => {
        let passed = true;
        _.each(device.components, (component) => {
            let installationDate = component.installationDate ? moment(component.installationDate).format("YYYY") : moment(device.createdAt).format("YYYY");
            let serviceLifeEndYear = parseInt(installationDate) + _.get(component, "estimatedDurabilityYears", 0);
            let durabilityLeft = component.estimatedDurability - component.numberOfCycles;
            let yearsLeft = serviceLifeEndYear - parseInt(moment().format("YYYY"));
            if (yearsLeft <= 0 || durabilityLeft <= 0) {
                passed = false;
            }
        });
        if (passed) {
            return <h3 className="text-center mb-3 mt-1"><span className="kolor">nie został osiągnięty </span>i dźwig nadaje się do dalszej bezpiecznej eksploatacji</h3>
        } else {
            return <h3 className="text-center mb-3 mt-1"><span className="kolor">został osiągnięty </span>i dźwig nie nadaje się do dalszej bezpiecznej eksploatacji</h3>
        }
    }

    return (
        <React.Fragment>
            <div className="d-flex panel" id="wrapper">
                <div id="page-content-wrapper" className="pl-5 pr-5">

                    <div className="container-fluid">
                        <div className="row mb-3 mt-3">
                            <h3 className="w-100 text-right mb-0"><span className="font-weight-light ">Data sporządzenia:</span> {moment().format("DD.MM.YYYY")}</h3>
                            <h3 className="w-100 text-right mt-0"><span className="font-weight-light ">Opracował:</span> {user.name || "-"}
                            </h3>
                            <h1 className="mt-3 mb-0 text-center w-100 font-weight-bold">PROTOKÓŁ</h1>
                            <h3 className="text-center w-100 mb-5"> Z WYZNACZENIA RESURSU DŹWIGU ELEKTRYCZNEGO</h3>
                        </div>
                        <div className="row flex-fix">
                            <div className="col-6">
                                <h1 id="nazwa-urzadzenia" className="w-100 kolor text-center mb-3 mt-5 font-weight-bold">{device.name || "-"}</h1>
                                <div className="d-flex justify-content-center mb-5">
                                    <h4 className="mr-4"><span className="font-weight-light">UDT:</span> {device.udtNumber || "-"}</h4>
                                    <h4 className="mr-4"><span className="font-weight-light">Nr fabryczny:</span> {device.serialNumber || "-"}</h4>
                                    <h4 className="mr-4" style={{ position: "relative" }}>
                                        <span className="font-weight-light">Oznakowanie CE:</span> {getCeMarkingIcon()}
                                    </h4>
                                </div>
                                <hr />
                                <h3 className="text-right"><span className="font-weight-light float-left">Typ:</span> {_.get(device, "type", "-")}</h3>
                                <h3 className="text-right"><span className="font-weight-light float-left">Producent:</span> {_.get(device, "manufacturer", "-")}</h3>
                                <h3 className="text-right"><span className="font-weight-light float-left">Rok instalacji:</span> {moment(device.installationDate).format("YYYY")}</h3>
                                <h3 className="text-right"><span className="font-weight-light float-left">Instalator:</span>{_.get(device, "installer", "-")}</h3>
                                <h3 className="text-right"><span className="font-weight-light float-left">Eksploatujący:</span> {_.get(device, "customer.name", "-")}</h3>
                                <hr />
                                <h3 className="mt-3 mb-3 font-weight-light">Miejsce zainstalowania</h3>
                                <div>{_.get(device, "address.formattedAddress", "-")}</div>
                                <div>
                                    <hr />
                                    <h3 className="mt-1 mb-3 font-weight-light">Rodzaj budynku wg VDI 4707</h3>
                                </div>
                                <div>{buildingTypes[_.get(device, "buildingType")] || "-"}</div>
                                <div>
                                    <hr />
                                </div>

                            </div>
                            <div className="col-6 text-center">
                                {getParsedImage()}
                            </div>
                        </div>
                        <br /><div style={{ pageBreakAfter: "always" }}></div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <h1 className="mt-1 mb-3  font-weight-light">Parametry</h1>
                                <table className="table table-hover table-bordered" style={{ maxWidth: "80%", margin: "0 auto" }}>
                                    <tbody>
                                        {getParametersTable()}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <br /><div style={{ pageBreakAfter: "always" }}></div>
                        <div className="row mt-5">
                            <div className="col-12 mt-5 mb-5">

                                <h1 className="mt-1 mb-3  font-weight-light">RESURS</h1>
                                <div className="resursdesc">
                                    <p>Jak wynika z różnego rodzaju publikacji oraz instrukcji producentów dźwigów, precyzyjne
                                    określenie "kresu życia" dźwigu jest bardzo trudne. Podczas określania "czasu życia"
                                    całej instalacji dźwigowej zwykle bazuje się na kondycji elementów składowych dźwigu,
                                    założeniach projektowych oraz metodach statystycznych. W ten sposób, znając "żywotność"
                                    poszczególnych komponentów dźwigu możemy oszacować zasób eksploatacyjny całej instalacji
                                dźwigowej - "RESURS".</p>
                                    <p>Do określenia stopnia wykorzystania resursu dźwigu (wyeksploatowania jego elementów)
                                    niezbędne jest oprócz określenia wieku komponentów składowych, określenie intensywności
                                    uzytkowania dźwigu. Intensywność użytkownaia najprościej oszacować poprzez określenie
                                    ilości jazd dźwigu w ciągu roku. Zarówno instrukcje producentów dźwigów, jak i dokumenty
                                    normatywne wskazują metody na podstawie których można oszacować ilość jazd dla urządzeń
                                nie posiadających wbudowanych liczników.</p>
                                    <p>Można wyznaczyć tę wartość na podstawie norm np. VDI 4707, ISO 25745-2, określając ilość
                                    jazd należy uwzględnić rodzaj budynku, ilości przystanków, liczbę mieszkańców, prędkość
                                dźwigu.</p>
                                    <p>Inną metodą do określenia rocznej ilości jazd jest pomiar średniotygodniowy. W trakcie
                                    obserwacji zlicza się ilość jazd w ciągu tygodnia (wyłączając tygodnie wakacyjne,
                                świąteczne itp.) oraz mnoży otrzymaną wartośc przez ilość tygodni w roku.</p>
                                    <p>Dwie ostatnie metody można stosować pod warunkiem, że w trakcie eksploatacji nie
                                    zmieniają się warunki eksploatacji dźwigu (np. w budynku pojawia się biuro, kawiarnia,
                                    itp. co znacząco może wpłynąć na ilość jazd w roku). Po zmianie przeznaczenia budynku
                                należy skorygować szacowaną ilość jazd rocznych.</p>
                                    <p> W celu określenia stopnia wykorzystania resursu komponentu [A] w tabeli "resurs" należy
                                uzupełnić informacje dotyczące zainstalowanych komponentów:</p>
                                    <p> <strong>[C]</strong> - rok zamontowania/ modernizacji/ wymiany komponentu</p>
                                    <p>Jeżeli w kolumnie [C] wpisujesz rok inny niż rok zainstalowania dźwigu ("DANE DŹWIGU"),
                                    to w polu "UWAGI" na końcu wpisz jaka czynność została wykonana i skąd posiadasz dane
                                dotyczące tej czynności. </p>
                                    <p>Przykład:</p>
                                    <p><strong>A1, A3</strong> - modernizacja - wpis w protokole z badania UDT z dnia 14.10.2020
                            </p>
                                    <p><strong>A25</strong> - wymiana - wpis w dzienniku konserwacji z dnia 10.05.2020</p>
                                    <p><strong>[D]</strong> - założoną liczbę jazd, ilość cykli, ilość godzin pracy,
                                przewidzianą dla komponentu
                                i/ lub</p>
                                    <p><strong>[E]</strong> - założoną ilość lat eksploatacji komponentu</p>
                                    <p><strong>[F]</strong> - źródło informacji, na bazie którego przyjęto założenia [D] i/lub
                                [E]</p>
                                    <a href="https://www.udt.gov.pl/wazne/przewodnik-dla-utb" style={{ color: "#212529", textDecoration: "none" }} target="_blank">
                                        <div className="float-right d-flex align-items-center ">Materiały pomocnicze znajdziesz tutaj.
                                            <img className="float-right pl-3" src={helpIcon} />
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <br /><div style={{ pageBreakAfter: "always", orientation: "landscape" }}></div>
                        <div className="row mt-5">
                            <div className="col-12 landscape-rotate">
                                <h1 className="mt-1 mb-3 float-left font-weight-light">RESURS</h1>
                                <div className="float-right">
                                    <h3 className="text-right mb-0 "><span className="font-weight-light">Rok zainstalowania dźwigu:</span> {moment(device.installationDate).format("YYYY")}</h3>
                                    <h3 className="text-right mt-0 "><span className="font-weight-light">Ilość jazd rocznych dźwigu:</span> {_.get(device, "estimatedNumberOfCycles", "-")}</h3>
                                </div>
                                <table className="table table-bordered table-hover w-100 mb-5">
                                    <thead className="thead-light">
                                        <tr>
                                            <th colSpan="2" rowSpan="2">Oceniany komponent</th>
                                            <th rowSpan="2">Montaż</th>
                                            <th colSpan="2">Założona trwałość</th>
                                            <th rowSpan="2">Źródło danych</th>
                                            <th rowSpan="2">Odczytana<br />z&nbsp;licznika <br />lub oszacowana</th>
                                            <th colSpan="3">Pozostały zasób
                                            eksploatacyjny
                                    </th>
                                        </tr>
                                        <tr>
                                            <th>Jazdy/ Cykle/ Godziny</th>
                                            <th>Lata</th>
                                            <th>Jazdy/ Cykle/ Godziny</th>
                                            <th>Lata</th>
                                            <th>Rok osiągnięcia resursu</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getComponentsTable()}
                                    </tbody>
                                </table>
                            </div>
                            <br /><div style={{ pageBreakAfter: "always" }}></div>
                            <div className="col-12 mt-5 mb-5">
                                <h1 className="mt-1 mb-3 text-center font-weight-light">Uwagi</h1>
                                <div className="mt-2 mb-5"><textarea rows="7" cols="100" className="form-control" /></div>
                            </div>
                            <div className="col-12 mb-5"><hr />
                                <h1 className="mt-5 mb-3 text-center font-weight-light">Orzeczenie</h1>
                                <h3 className="text-center mb-1">Uwzględniając powyższe ustalenia stwierdza się, że resurs</h3>
                                {getStatementText()}
                                <div className="mt-2 mb-5"><textarea rows="7" cols="100" className="form-control" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default ServiceLife;