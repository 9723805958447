import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { getComponent, clearComponent } from '../actions/components.actions';
import ComponentDetails from '../components/ComponentDetails.component';

const ComponentDetailsContainer = (props) => {
    const { getComponent, clearComponent, component } = props;
    let { id } = useParams();
    useEffect(() => {
        if (id) {
            getComponent(id);
        }
        return () => {
            clearComponent();
        }
    }, [getComponent, clearComponent, id])


    return (
        <Fragment>
            {component && <ComponentDetails
                {...props}
            />}
        </Fragment>
    );
}

const mapStateToProps = (store) => ({
    component: store.components.component
});

const mapDispatchToProps = (dispatch) => {
    return {
        getComponent: (id) => dispatch(getComponent(id)),
        clearComponent: () => dispatch(clearComponent())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentDetailsContainer);
