import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import MediasComponent from '../components/Medias.component';

import { getMedias, clearMedias, deleteMedia, setFilters, clearFilters, setPage, setLimit, setQuery, clearQuery } from '../actions/medias.actions';

const MediasContainer = (props) => {

    let { page } = useParams();

    useEffect(() => {
        props.setPage(page && parseInt(page) || 1);
        props.getMedias();

        return () => {
            props.clearMedias();
            props.clearFilters();
            props.clearQuery();
        }
    }, [])

    return (
        <MediasComponent {...props} />
    );
}

const mapStateToProps = (store) => ({
    medias: store.medias.medias,
    page: store.medias.page,
    total: store.medias.total,
    limit: store.medias.params.limit
});

const mapDispatchToProps = (dispatch) => {
    return {
        getMedias: () => dispatch(getMedias()),
        clearMedias: () => dispatch(clearMedias()),
        deleteMedia: (id) => dispatch(deleteMedia(id)),
        setFilters: (filters) => dispatch(setFilters(filters)),
        clearFilters: () => dispatch(clearFilters()),
        setPage: (page, reload) => dispatch(setPage(page, reload)),
        setLimit: (limit) => dispatch(setLimit(limit)),
        setQuery: (query) => dispatch(setQuery(query)),
        clearQuery: () => dispatch(clearQuery()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MediasContainer);
