import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import Settings from '../components/Settings.component';

import { getSettings, saveSettings } from '../actions/settings.actions';

const SettingsContainer = ({
    getSettings,
    saveSettings,
    settings
}) => {

    useEffect(() => {
        getSettings();
    }, [])

    return (
        <Settings
            settings={settings}
            saveSettings={saveSettings}
        />
    );
}

const mapStateToProps = (store) => ({
    settings: store.settings.settings
});

const mapDispatchToProps = (dispatch) => {
    return {
        getSettings: () => dispatch(getSettings()),
        saveSettings: (form) => dispatch(saveSettings(form)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
