import _ from 'lodash';

class aclManager {

    constructor() {
        this.permissions = null;
        this.isAdmin = false;
        this.userType = false;
    }

    setPermissions(permissions) {
        if (!this.permissions) {
            this.permissions = permissions;
        }
    }

    clearPermissions() {
        this.permissions = null;
        this.isAdmin = false;
    }

    setIsAdmin(isAdmin) {
        this.isAdmin = isAdmin;
    }

    setUserType(userType) {
        if (userType === "admin") {
            this.setIsAdmin(true);
        }
        this.userType = userType;
    }

    getUserType() {
        return this.userType;
    }

    getIsAdmin() {
        return this.isAdmin;
    }

    getPermissions() {
        return this.permissions;
    }

    isAllowedMethod(moduleName, methodName) {

        if (this.getUserType() == "admin") {
            return true;
        }

        if (moduleName === 'application/profile') {
            return true
        }

        if (this.getUserType() == "customer" && methodName !== "get") {
            return false;
        }

        if (this.allowForCustomer(moduleName)) {
            return true;
        }

        if (!moduleName || !methodName) {
            return false;
        }

        if (!this.permissions || _.isEmpty(this.permissions)) {
            return false;
        }

        let isAllowed = false;
        const modulePathInfo = moduleName.split("/");

        this.permissions.forEach(permission => {
            if (permission.module == _.get(modulePathInfo, '[0]') && permission.controller == _.get(modulePathInfo, '[1]')) {
                if (_.includes(_.get(permission, 'actions', []), methodName)) {
                    isAllowed = true;
                }
            }
        });

        return isAllowed;
    }

    allowForCustomer(moduleName) {

        if (this.getUserType() !== "customer") {
            return false;
        }

        switch (moduleName) {
            case 'device/device':
                return true;
            case 'customer/contract':
                return true;
            default:
                break;
        }
        return false;
    }

}

export default new aclManager();