import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import PropTypes from 'prop-types';
import EditIcon from '../../assets/gfx/edytuj.svg';
import DownloadIcon from '../../assets/gfx/pobierz.svg';
import AclManager from '../../application/services/aclManager';

const ContractDetailsComponent = ({ contract, downloadFile }) => {

    const renderDevicesList = () => {
        return <React.Fragment>
            <hr className="mt-5" />
            {_.get(contract, 'devices[0]') && <h3 className="text-right">
                <span className="font-weight-light float-left">Urządzenia:</span>
                <ul className="clearListStyle">
                    {contract.devices.map((device, index) => {
                        return <li key={`device-component-${index}`} >{_.get(device, 'name')}</li>
                    })}
                </ul>
            </h3>}
        </React.Fragment>;
    }

    const renderContactsList = () => {
        return <React.Fragment>
            {_.get(contract, 'customer.contacts[0]') && <React.Fragment>
                {contract.customer.contacts.map((contact, index) => {
                    return <React.Fragment key={`contact-component-${index}`}>
                        <hr className="mt-5" />
                        {_.get(contact, 'desctipion') !== "" && <h3 className="text-right">
                            <span className="font-weight-light float-left">Osoba kontaktowa:</span>{contact.description || 'brak informacji'}
                        </h3>}
                        {_.get(contact, 'phone') !== "" && <h3 className="text-right">
                            <span className="font-weight-light float-left">Telefon:</span> <a href={`tel:+48${contact.phone}`}>+48 {contact.phone.match(/.{1,3}/g).join(" ") || 'brak informacji'}</a>
                        </h3>}
                        {_.get(contact, 'email') !== "" && <h3 className="text-right">
                            <span className="font-weight-light float-left">Adres e-mail:</span> <a href={`mailto:${contact.email}`}>{contact.email || 'brak informacji'}</a>
                        </h3>}
                    </React.Fragment>
                })}
            </React.Fragment>}
        </React.Fragment>;
    }

    const renderFilesList = () => {
        return <React.Fragment>
            <hr className="mt-5" />
            {_.get(contract, 'files[0]') && <h3 className="text-right">
                <div className="font-weight-light float-left">Załączniki:</div>
                <ul className="clearListStyle">
                    {contract.files.map((file, index) => {
                        var fileNameParts = file.split('/');
                        return <li key={`file-component-${index}`} >
                            {fileNameParts[fileNameParts.length - 1]}
                            {AclManager.isAllowedMethod('customer/contract', 'downloadContract') && <span className="btn pr-0" onClick={() => downloadFile(file)} title="Pobierz plik">
                                <img className="mb-1" src={DownloadIcon} />
                            </span>}
                        </li>
                    })}
                </ul>
            </h3>}
        </React.Fragment>;
    }

    return (
        <React.Fragment>
            <div className="row mb-3">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                    <h3 className="w-50 mt-3 mb-3">Szczegóły umowy</h3>
                    {AclManager.isAllowedMethod('customer/contract', 'update') && <Link to={`/contract/${contract._id}`}>
                        <button type="submit" className="btn btn-outline-primary float-md-right">Edytuj <img src={EditIcon} /></button>
                    </Link>}
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 d-flex justify-content-center flex-wrap align-items-center pt-5">
                            <div className="w-100">
                                {contract.customer && <h2 id="klient" className="text-center">
                                    {AclManager.isAllowedMethod('customer/customer', 'get') ?
                                        <Link to={`/customer/${contract.customer._id}`}>
                                            {contract.customer.name}
                                        </Link> :
                                        <span>
                                            {contract.customer.name}
                                        </span>
                                    }
                                </h2>}
                                <h4 className="w-100 text-center">
                                    <span className="font-weight-light ">Nr umowy:</span> {contract.number || 'brak informacji'}
                                </h4>
                                <h3 className="w-100 text-center">
                                    <span className="font-weight-light ">OD:</span>{contract.conclusionDate ? moment(contract.conclusionDate).format('DD.MM.YYYY') : "brak daty"}
                                    &nbsp; &nbsp;
                                    <span className="font-weight-light ">DO:</span> {contract.expiryDate ? moment(contract.expiryDate).format('DD.MM.YYYY') : _.get(contract, 'indefiniteExpiryDate', false) == true ? "na czas nieokreślony" : "brak daty"}
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            {renderDevicesList()}
                            <h3 className="w-100">
                                <span className="font-weight-light">Zakres umowy</span>
                            </h3>
                            {contract.description && contract.description.length > 0 ? contract.description.map((singleDescription, index) => {
                                return <div key={`single-description-${index}`}>
                                    <p className="mb-1" key={`description-name-${index}`}><b>{singleDescription.name}</b></p>
                                    <p className="mb-1" key={`description-value-${index}`}>{singleDescription.value}</p>
                                </div>;
                            }) : 'brak informacji'}
                            {renderFilesList()}
                        </div>
                        <div className="col-md-6 col-sm-12">
                            {_.get(contract, 'customer.address') && <React.Fragment>
                                <hr className="mt-5" />
                                <h3 className="text-right">
                                    <span className="font-weight-light float-left">Adres rozliczeniowy:</span>{_.get(contract, 'customer.address.postCode')} {_.get(contract, 'customer.address.locality')} <br />ul. {_.get(contract, 'customer.address.street')} {_.get(contract, 'customer.address.streetNumber')}
                                </h3>
                            </React.Fragment>}
                            <hr className="mt-5" />
                            <h3 className="text-right">
                                <span className="font-weight-light float-left">Czas reakcji:</span> {contract.reactionTime} min
                            </h3>
                            {renderContactsList()}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

ContractDetailsComponent.propTypes = {
    contract: PropTypes.object.isRequired,
    downloadFile: PropTypes.func.isRequired
};

export default ContractDetailsComponent;