import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import _ from 'lodash';

import TextInput from "../../assets/components/TextInput.component";
import Autocomplete from "../../assets/components/Autocomplete.component";
import AddIcon from '../../assets/gfx/dodaj.svg';
import PlusIcon from '../../assets/gfx/plus-2.svg';
import { expenseInitialState, expenseValidationSchema } from '../schemas/expense.schema';

const ExpenseComponent = ({ expense, settings, saveExpense }) => {

    // defaultowe initialValues ze schemy
    const [initialValues, setInitialValues] = useState(expenseInitialState);
    let history = useHistory();

    // aktualizujemy initialValues jak przyjdzie z API
    useEffect(() => {
        if (_.get(expense, '_id')) {
            setInitialValues(expense);
        }
    }, [expense]);

    // aktualizujemy initialValues jak przyjdzie z API
    useEffect(() => {
        if (settings) {
            setInitialValues({ ...initialValues, ...settings });
        }
    }, [settings]);

    const handleAddNewActivity = (values) => {
        setInitialValues({
            ...values, activities: [...values.activities, {
                name: ''
            }]
        });
    }

    const handleRemoveActivity = (elementIndex, values) => {
        setInitialValues({
            ...values, activities: [...values.activities.filter((item, index) => index !== elementIndex)]
        });
    }

    const handleAddNewComponent = (values) => {
        setInitialValues({
            ...values, usedComponents: [...values.usedComponents, {
                component: {},
                amount: '',
            }]
        });
    }

    const handleRemoveComponent = (elementIndex, values) => {
        setInitialValues({
            ...values, usedComponents: [...values.usedComponents.filter((item, index) => index !== elementIndex)]
        });
    }

    return (
        <Fragment>
            <h3 className="mt-3 mb-3">{`${expense && expense._id ? "Edytuj kosztorys" : "Dodaj nowy kosztorys"}`} </h3>
            <Formik
                initialValues={initialValues}
                validationSchema={expenseValidationSchema}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setErrors }) => {
                    saveExpense(values).then(res => {
                        if (res.success) {
                            history.push(`/expenses/${res.document}`);
                        } else {
                            if (res.errors) {
                                setErrors(res.errors);
                            }
                        }
                    });
                }}
            >
                {formik => (
                    <form autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <TextInput
                                    isRequired={true}
                                    id="name"
                                    label="Nazwa"
                                    type="text"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.name}
                                    errors={formik.errors.name}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <Autocomplete
                                    isRequired={true}
                                    id="customer"
                                    url="customer/customer"
                                    multi={false}
                                    label="Wybierz klienta"
                                    optionLabelField="name"
                                    optionIdField="customerId"
                                    prependText={null}
                                    appendText={null}
                                    onOptionSelect={(key, value) => {
                                        formik.setFieldValue("customer", value ? { customerId: value.customerId, name: value.name } : {});
                                        formik.setFieldValue("overhead", value && value.overhead || 0);
                                        formik.setFieldValue("hourlyRate", value && value.hourlyRate || 0);
                                    }}
                                    value={formik.values.customer}
                                    errors={_.get(formik, 'errors.customer.customerId')}
                                    queryFields={["name"]}
                                    additionalParams={{ limit: 20, select: "name,overhead,hourlyRate" }}
                                    parseOption={(val) => {
                                        val.customerId = val._id;
                                        return val;
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                <TextInput
                                    isRequired={true}
                                    id="hourlyRate"
                                    label="Cena roboczogodziny"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.hourlyRate}
                                    errors={formik.errors.hourlyRate}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4 ">
                                <TextInput
                                    isRequired={true}
                                    id="executionTime"
                                    label="Ilość roboczogodzin"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.executionTime}
                                    errors={formik.errors.executionTime}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <TextInput
                                    isRequired={true}
                                    id="overhead"
                                    label="Narzut (%)"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.overhead}
                                    errors={formik.errors.overhead}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <TextInput
                                    isRequired={true}
                                    id="deliveryRate"
                                    label="Stawka za dojazd"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.deliveryRate}
                                    errors={formik.errors.deliveryRate}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                                <TextInput
                                    isRequired={true}
                                    id="vatRate"
                                    label="Stawka VAT"
                                    type="number"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.vatRate}
                                    errors={formik.errors.vatRate}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <hr />
                            </div>
                            <div className="col-12 mb-3">
                                <label htmlFor='component-row-0'>Lista komponentów</label>
                            </div>
                            {formik.values.usedComponents && formik.values.usedComponents.map((component, index) => {
                                return <Fragment key={`component-row-${index}`}>
                                    <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-content-stretch">
                                        <Autocomplete
                                            isRequired={true}
                                            id="usedComponents"
                                            url="device/component"
                                            multi={false}
                                            label="Komponent"
                                            optionLabelField="fullName"
                                            optionIdField="componentId"
                                            prependText={null}
                                            appendText={null}
                                            onOptionSelect={(key, val) => {
                                                formik.setFieldValue(`usedComponents[${index}].component`, val || {});
                                            }}
                                            value={component.component}
                                            errors={_.get(formik, `errors.usedComponents[${index}].component.componentId`) ? _.get(formik, `errors.usedComponents[${index}].component.componentId`) : null}
                                            queryFields={["name", "manufacturer", "kind"]}
                                            additionalParams={{ limit: 20,  select: "name,manufacturer,kind" }}
                                            parseOption={(val) => {
                                                val.fullName = `[${val.manufacturer}] ${val.name} (${val.kind})`;
                                                val.componentId = val._id;
                                                return val;
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-4 d-flex align-content-stretch">
                                        <TextInput
                                            isRequired={true}
                                            id={`usedComponents.${index}.amount`}
                                            label="Ilość"
                                            type="number"
                                            onChange={formik.setFieldValue}
                                            value={formik.values.usedComponents[index].amount}
                                            errors={_.get(formik, `errors.usedComponents[${index}].amount`) ? _.get(formik, `errors.usedComponents[${index}].amount`) : null}
                                        />
                                    </div>
                                    {<div className="col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center align-items-end mb-4 pb-2" onClick={() => handleRemoveComponent(index, formik.values)}>
                                        <button type="button" className="btn btn-danger flex-fill">USUŃ</button>
                                    </div>}
                                    {formik.values.usedComponents.length == index && <div className="col-12">
                                        <hr />
                                    </div>}
                                </Fragment>
                            })}
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="btn btn-link p-0 mt-2" onClick={() => handleAddNewComponent(formik.values)}>
                                        <span>Dodaj komponent</span>
                                        <div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5">
                                            <img className="mt-1 ml-0" src={PlusIcon} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pl-0 pr-0">
                            <hr />
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <label htmlFor='activity-row-0'>Lista czynności do wykonania <span className="is-required">*</span></label>
                            </div>
                            {formik.values.activities && formik.values.activities.map((activity, index) => {
                                return <Fragment key={`activity-row-${index}`}>
                                    <div className="col-8">
                                        <TextInput
                                            id={`activities.${index}.name`}
                                            type="text"
                                            placeholder="Nazwa czynności"
                                            onChange={formik.setFieldValue}
                                            value={activity.name}
                                            errors={_.get(formik, `errors.activities[${index}].name`) ? _.get(formik, `errors.activities[${index}].name`) : null}
                                        />
                                    </div>
                                    {index !== 0 && <div className="col-2 d-flex justify-content-center align-items-start p-0" onClick={() => handleRemoveActivity(index, formik.values)}>
                                        <button type="button" className="btn btn-danger flex-fill">USUŃ</button>
                                    </div>}
                                </Fragment>
                            })}
                            <div className="col-12">
                                <div className="form-group">
                                    <div className="btn btn-link p-0 mt-2" onClick={() => handleAddNewActivity(formik.values)}>
                                        <span>Dodaj kolejną czynność</span>
                                        <div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5">
                                            <img className="mt-1 ml-0" src={PlusIcon} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mb-3">{`${expense && expense._id ? "Zapisz kosztorys" : "Dodaj kosztorys"}`} <img src={AddIcon} /></button>
                    </form>
                )}
            </Formik>
        </Fragment>
    )
}

ExpenseComponent.propTypes = {
    expense: PropTypes.object,
    settings: PropTypes.object,
    saveExpense: PropTypes.func.isRequired
};

export default ExpenseComponent;