import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment'

import PlusIcon from '../../assets/gfx/plus-2.svg';

import Chip from '../../assets/components/Chip.component';

const HolidaySettings = ({
    id,
    onChange,
    holidays
}) => {

    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [nameError, setNameError] = useState(null);
    const [dateError, setDateError] = useState(null);

    const clearErrors = () => {
        setNameError(null);
        setDateError(null);
    }

    const parseDateToString = (date) => {
        return moment(date).format('DD-MM-YYYY');
    }

    const validateName = () => {
        if (name.length > 0) return true;
        return false;
    }
    const validateDate = () => {
        if (date.length > 0) return true;
        return false;
    }

    const createHoliday = () => {
        clearErrors();

        const nameValid = validateName();
        const dateValid = validateDate();

        if (!nameValid) setNameError('Podaj nazwę święta')
        if (!dateValid) setDateError('Wybierz datę')

        if (nameValid && dateValid) {
            const path = `${id}[${holidays.length}]`
            onChange(path, { name, date });
            setName('');
            setDate('');
        }
    }

    const removeHoliday = (h) => {
        const newHolidays = _.without(holidays, h);
        onChange(id, newHolidays);
    }

    const renderHolidays = () => {
        if (!holidays) return;

        return holidays.map((h, i) => {
            return (
                <Chip
                    key={i}
                    name={`${h.name} ${parseDateToString(h.date)}`}
                    onRemove={() => removeHoliday(h)}
                />
            )
        })
    }

    return (
        <div className="col-sm-12 col-md-6">
            <div className="form-group ">
                <label >Parametry</label>
                {renderHolidays()}

                <div className="d-flex flex-nowrap ">
                    <div className="mr-2">
                        <input type="text" value={name} className={`form-control ${nameError ? 'error' : null}`} placeholder="Nazwa święta" onChange={e => setName(e.target.value)} />
                        <div className="errormsg">{nameError}</div>
                    </div>
                    <div>
                        <input type="date" value={date} className={`form-control ${dateError ? 'error' : null}`} onChange={e => setDate(e.target.value)} />
                        <div className="errormsg">{dateError}</div>
                    </div>
                </div>
            </div>
            <button
                type="button"
                className="btn btn-link p-0 mt-1 mb-5"
                onClick={() => createHoliday()}
            >
                Dodaj
                    <div className="float-left rounded-circle bgkolor2 kolko-30 mr-2 mt--5">
                    <img className="mt-1 ml-0" src={PlusIcon} />
                </div>
            </button>
        </div>
    )
}

export default HolidaySettings;