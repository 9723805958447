import * as Yup from 'yup';


export const mediaInitialValues = {
    name: '',
    file: ''
}



export const mediaValidationSchema = Yup.object({
    name: Yup.string()
        .required(`Nazwa pliku jest wymagana`),
    file: Yup.mixed()
        .required("Plik jest wymagany")
})
