import React from 'react';
import { Formik } from 'formik';
import _ from 'lodash'
import PropTypes from 'prop-types';
import moment from 'moment';

import ServiceTypes from '../../scripts/serviceTypes';

import FiltrujIcon from '../../assets/gfx/filtruj.svg';
import TextInput from '../../assets/components/TextInput.component';
import Select from '../../assets/components/Select.component';
import Autocomplete from '../../assets/components/Autocomplete.component';
import Date from '../../assets/components/Date.component'

// metoda mapująca pola do setowania filtrów 
const mapFields = {
   'number': 'number[or]',
   'type': 'type[or]',
   'conservator': 'conservator[or]',
   'dateFrom': 'dateFrom[gte]',
   'dateTo': 'dateTo[lte]'
}

const ServiceFiltersComponent = ({
   setFilters
}) => {

   const handleResetFilters = (resetForm) => {
      resetForm();
      setFilters({});
   }

   return (
      <React.Fragment>
         <button type="submit" className="btn btn-link text-dark" data-toggle="modal" data-target="#modal-filtruj">Filtruj <img src={FiltrujIcon} /></button>
         <div className="modal fade" id="modal-filtruj" tabIndex="-1" role="dialog" aria-labelledby="filtrowanie"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
               <div className="modal-content">
                  <div className="modal-header">
                     <h5 className="modal-title" id="filtrowanie">Filtry klienta</h5>
                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div className="modal-body">
                     <Formik
                        initialValues={{
                           'number': '',
                           'type': '',
                           'conservator': '',
                           'hours': '',
                           'dateFrom': moment().startOf('day').format('X'),
                           'dateTo': moment().endOf('day').format('X')
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={(values) => {
                           if (_.get(values, 'conservator')) values.role = _.get(values, 'conservator._id');

                           const filters = {};
                           Object.keys(_.pickBy(values, _.identity)).map(key => {
                              if (values[key] !== '') {
                                 filters[mapFields[key]] = values[key];
                              }
                           });
                           setFilters(filters);
                        }}
                     >
                        {formik => (
                           <form className="input-group-login flex-column" onSubmit={formik.handleSubmit}>
                              <div className="row">
                                 <div className="col-6">
                                    <div className="filtr">
                                       <TextInput
                                          id="number"
                                          label="Numer"
                                          type="text"
                                          onChange={formik.setFieldValue}
                                          value={formik.values.number}
                                          errors={formik.errors.number}
                                       />
                                    </div>
                                 </div>
                                 <div className="col-6">
                                    <div className="filtr">
                                       <Select
                                          id="type"
                                          label="Typ"
                                          options={ServiceTypes}
                                          onChange={(element, val) => formik.setFieldValue(element, val)}
                                          value={formik.values.type}
                                          errors={formik.errors.type}
                                       />
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-6">
                                    <div className="filtr">
                                       <Autocomplete
                                          id="conservator"
                                          label="Konserwator"
                                          url="admin/user"
                                          multi={false}
                                          optionLabelField="name"
                                          optionIdField="_id"
                                          onOptionSelect={formik.setFieldValue}
                                          value={formik.values.conservator}
                                          errors={formik.errors.conservator}
                                          queryFields={['name']}
                                          additionalParams={{ limit: 20 }}
                                       />
                                    </div>
                                 </div>
                                 <div className="col-6">
                                    <div className="filtr">
                                       <Date
                                          id="dateFrom"
                                          label="Data od"
                                          type="number"
                                          onChange={formik.setFieldValue}
                                          value={formik.values.dateFrom}
                                          errors={formik.errors.dateFrom}
                                       />
                                    </div>
                                 </div>
                                 <div className="col-6">
                                    <div className="filtr">
                                       <Date
                                          id="dateTo"
                                          label="Data do"
                                          type="number"
                                          onChange={formik.setFieldValue}
                                          value={formik.values.dateTo}
                                          errors={formik.errors.dateTo}
                                       />
                                    </div>
                                 </div>
                              </div>
                              <div className="modal-footer">
                                 <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                                 <button type="button" className="btn btn-secondary" onClick={() => handleResetFilters(formik.resetForm)}>Wyczyść</button>
                                 <button type="button" type="submit" className="btn btn-primary">Filtruj</button>
                              </div>
                           </form>
                        )}
                     </Formik>
                  </div>
               </div>
            </div>
         </div>
      </React.Fragment >
   )
}

ServiceFiltersComponent.propTypes = {
   setFilters: PropTypes.func.isRequired
};

export default ServiceFiltersComponent;