import React from 'react';
import { connect } from 'react-redux'

import ProfileComponent from '../components/Profile.component';

import {
   changePassword
} from '../actions/profile.actions';

const ProfileContainer = (props) => {
   return (
      <ProfileComponent {...props} />
   );
}

const mapDispatchToProps = (dispatch) => {
   return {
      changePassword: (data) => changePassword(data, dispatch),
   }
};

export default connect(null, mapDispatchToProps)(ProfileContainer);
