import React from 'react';
import { Link } from "react-router-dom";
import _ from 'lodash';
import moment from 'moment';
import Api from "../../application/services/api";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import mapStyles from '../../assets/js/mapStyle.json';
import Carousel from "../../assets/components/Carousel.component";
import DownloadIcon from '../../assets/gfx/pobierz.svg';
import TickIcon from '../../assets/gfx/tick.svg';
import WykrzykIcon from '../../assets/gfx/wykrzyk.svg';

const SERVICE_TYPES = {
    'udt_examination': 'Badanie UDT',
    'fault': 'Awaria',
    'conservation': 'Konserwacja',
    'measurement': 'Pomiary',
    'other': 'Inne'
};

const containerStyle = {
    width: '100%',
    height: '200px'
};


const DeviceDetailsCustomer = ({
    device,
    device: { name, _id, udtNumber, serialNumber, customer, manufacturer, administrationName, administrationPhone, address, gallery },
    services
}) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAKRv4pARQD586LVg3a1tfG22WDu4-XNqA" // klucz do wpięcia u klienta na jego koncie (aktualnie prywatny)
    });

    const getDateWithColor = (date) => {
        if (date) {
            date = moment(date);
            let style = {};
            let warningDate = moment().subtract(90, "days").startOf("day");
            if (moment().isAfter(date)) {
                style.color = "#BF1E2E";
            } else if (date.isAfter(warningDate)) {
                style.color = "rgb(252, 203, 0)";
            }
            return <span style={style}>{date.format("DD.MM.YYYY")}</span>;
        }
        return "-";
    }

    const getStatus = (service) => {
        let { dateTo, startDate, completionDate } = service;

        if (completionDate) return 'success';
        if (!completionDate && !startDate && moment(dateTo).isBefore(moment())) return 'warning';
        if (startDate && !completionDate) return 'primary';
        return ''
    }

    const getStatusByColor = (service) => {
        const colorStatus = getStatus(service);

        switch (colorStatus) {
            case 'success':
                return <div className="service-status status-complete" >
                    <img src={TickIcon} className="m--1" />
                </div >;
            case 'warning':
                return <div className="service-status status-warning">
                    <img src={WykrzykIcon} className="m--1" />
                </div>;
            case 'primary':
                return <div className="service-status status-in-progress">
                    <div className="spinner-border text-primary ml-1 mr-4 mt-1" role="status"></div>
                </div>;
            default:
                return <div className="service-status status-new"></div>;
        }
    }

    const renderServicesHistory = () => {
        if (!services || services.length === 0) return <tr><td className="text-center" colSpan="8">Brak zleceń</td></tr>;

        const calculateBetweenTime = (from, to) => {
            const duration = moment.duration(to.diff(from)).asMinutes();
            return `${Math.floor((duration / 60))}h ${Math.floor(duration % 60)}min`;
        }
        let i = 0;

        return services.map((service) => {
            if (service.completionDate) {
                i++;
                return (
                    <tr className="table-success" key={`service-` + i}>
                        <td>{i}</td>
                        <td>
                            {_.get(service, 'number', '-')}
                        </td>
                        <td>
                            {SERVICE_TYPES[service.type] || '-'}
                        </td>
                        <td>{moment(service.dateFrom).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{moment(service.dateTo).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{calculateBetweenTime(moment(service.dateFrom), moment(service.dateTo))}</td>
                        <td>{_.get(service, 'conservator.name', '-')}</td>
                        <td>
                            {getStatusByColor(service)}
                        </td>
                        <td>
                            <a target="_blank" href={`${Api.getHost()}service/service/getServiceReport/${service._id}`}><img src={DownloadIcon} /></a>
                        </td>
                    </tr>
                )
            }
        })
    }

    return (
        <React.Fragment>
            <div className="row mb-3 mt-3">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
                    <h3 className="mt-3 mb-3">Szczegóły urządzenia</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-sm-12 metryka">
                    <h2 id="nazwa-urzadzenia" className="w-100 kolor">{name}</h2>
                    <div className="d-flex justify-content-start">
                        <h4 className="mr-4"><span className="font-weight-light">ID:</span> {_id}</h4>
                        <h4 className="mr-4"><span className="font-weight-light">UDT:</span> {udtNumber}</h4>
                        <h4 className="mr-4"><span className="font-weight-light">Nr fabryczny:</span> {serialNumber}</h4>
                    </div>
                    <hr />
                    <h3 className="text-right"><span className="font-weight-light float-left">Klient:</span> <span to={`/customer/${_.get(customer, '_id')}`}>{_.get(customer, 'name')}</span></h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Producent:</span> {manufacturer}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Administrator:</span> {administrationName} <br />{administrationPhone}</h3><hr />
                    <h3 className="text-right"><span className="font-weight-light float-left">Data ostatniego przeglądu:</span> {device.lastServiceDate ? moment(device.lastServiceDate).format('DD.MM.YYYY') : "-"}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Data następnego przeglądu:</span> {device.nextServiceDate ? moment(device.nextServiceDate).format('DD.MM.YYYY') : "-"}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Data ważności pomiarów elektrycznych:</span> {getDateWithColor(device.electricalMeasurmentsExpirationDate)}</h3>
                    <h3 className="text-right"><span className="font-weight-light float-left">Data ważności badania udt:</span> {getDateWithColor(device.udtExaminationDate)}</h3>
                    <hr /><h3 className="mt-3 mb-3">Adres</h3>

                    <div className="row">
                        <div className="col-6 col-md-6 col-sm-12 border-top-0 border-bottom-0 border-left-0 border">
                            ul. {_.get(address, 'street')} {_.get(address, 'streetNumber')}<br />{_.get(address, 'postCode')} {_.get(address, 'locality')}</div>
                        <div className="col-6 col-md-6 col-sm-12 align-self-center">
                            {
                                isLoaded && address.coordinates && address.coordinates.length === 2 && <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{ lat: address.coordinates[0], lng: address.coordinates[1] }}
                                    zoom={15}
                                    options={{
                                        styles: mapStyles,
                                        disableAutoPan: true,
                                        disableDefaultUI: true
                                    }}
                                >
                                    <Marker
                                        position={{ lat: address.coordinates[0], lng: address.coordinates[1] }}
                                    />
                                </GoogleMap>
                            }
                        </div>
                        {_.get(device, 'accessTips', '-') && <div className="col-12"><hr /><h3 className="mt-1 mb-3">Wskazówki</h3></div>}
                        {_.get(device, 'accessTips', '-') && <div className="col-12">{_.get(device, 'accessTips', '-')}</div>}
                        {_.get(device, 'description', '-') && <div className="col-12 mt-3">
                            <h3 className="mt-0 text-left">Opis urządzenia</h3>
                            <div className="mb-3">{_.get(device, 'description', '-')}</div>
                        </div>}
                        <div className="col-12 col-sm-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-center flex-wrap ">
                    <div className="col-sm-12 col-md-12 p-0">
                        <Carousel images={gallery} />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">

                    <h3 className="mt-3 mb-3">Historia zleceń</h3>
                    <div className="table-responsive ramkatabeli mb-5">
                        <table className="table tabela-left table-hover rounded-10">
                            <thead>
                                <tr>
                                    <th>Lp.</th>
                                    <th>Nr zlecenia</th>
                                    <th>Typ zlecenia</th>
                                    <th>Data rozpoczęcia</th>
                                    <th>Data zakończenia</th>
                                    <th>Czas realizacji</th>
                                    <th>Konserwator</th>
                                    <th>Stan</th>
                                    <th>Raport</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderServicesHistory()}
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>

        </React.Fragment>
    )
}

export default DeviceDetailsCustomer;