import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import TrashIcon from '../../assets/gfx/kosz.svg';
import EditIcon from '../../assets/gfx/edytuj.svg';

const UsersListCardComponent = ({ index, user, handleDelete }) => {

   const [certificateNumbers, setCertificateNumbers] = useState('Brak');

   useEffect(() => {
      if (_.size(user.qualificationCertificates) > 0) {
         let certNumber = ''

         _.forEach(user.qualificationCertificates, (cert, i) => {
            if (i == _.size(user.qualificationCertificates) - 1) certNumber += ' ' + cert.certificateNumber
            else certNumber += ' ' + cert.certificateNumber + ','
         })

         setCertificateNumbers(certNumber);
      } else setCertificateNumbers('Brak')
   }, [user])

   return <tr>
      <th scope="row">{index + 1}</th>
      <td>
         <Link to={`/users/${user._id}`}>
            {user.name}
         </Link>
      </td>
      <td>{_.get(user, 'userType')}</td>
      <td>{_.get(user, 'role.name', 'Brak')}</td>
      <td>
         <div className="usercolor-container rounded-circle">
            <div className="usercolor rounded-circle" style={{ backgroundColor: _.get(user, 'color', 'white') }}></div>
         </div>
      </td>
      <td>{certificateNumbers}</td>
      <td>
         {_.get(user, 'email', null)} {_.get(user, 'phone', null)}
      </td>
      <td>
         <Link to={`/user/${user._id}`}>
            <img className="akcja edytuj" src={EditIcon} />
         </Link>
         <span onClick={() => handleDelete(user)} data-toggle="modal" data-target="#confirmDelete" title="Dezaktywuj użytkownika">
            <img className="akcja usun" src={TrashIcon} />
         </span>
      </td>
   </tr>
}

export default UsersListCardComponent;