import React, { useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import _ from 'lodash';
import moment from 'moment';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import AclManager from '../../application/services/aclManager';
import mapStyles from '../../assets/js/mapStyle.json';
import Api from '../../application/services/api';
import EdytujIcon from '../../assets/gfx/edytuj.svg';
import DodajIcon from '../../assets/gfx/dodaj.svg';
import ResursIcon from '../../assets/gfx/resurs.svg';
import QRCodeIcon from '../../assets/gfx/qrcode.svg';
import MoreIcon from '../../assets/gfx/more.svg';
import DeviceDetailsServices from './DeviceDetailsServices.component';
import Carousel from "../../assets/components/Carousel.component";
import ConfirmModal from '../../assets/components//ConfirmModal.component';
import Date from '../../assets/components//Date.component';

const SERVICE_TYPES = {
    'udt_examination': 'Badanie UDT',
    'fault': 'Awaria',
    'conservation': 'Konserwacja',
    'measurement': 'Pomiary',
    'other': 'Inne'
};

const durabilityUnitLabels = {
    1: "(Ilość jazd)",
    2: "(Cykle pracy)",
    3: "(Godziny pracy)",
    4: "(Lata pracy)",
};

const containerStyle = {
    width: '100%',
    height: '200px'
};

const DeviceDetails = ({
    device,
    device: { name, _id, udtNumber, serialNumber, customer, manufacturer, administrationName, administrationPhone, conservator, address, gallery },
    services,
    getDevicesServices,
    deactivateDevice,
    userType,
    activateDevice
}) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAKRv4pARQD586LVg3a1tfG22WDu4-XNqA" // klucz do wpięcia u klienta na jego koncie (aktualnie prywatny)
    });
    const history = useHistory();
    const { id } = useParams();

    const [deviceToDeactivate, setDeviceToDeactivate] = useState(null);
    const [nextServiceDate, setNextServiceDate] = useState(null);
    const [nextServiceDateErrors, setNextServiceDateErrors] = useState(null);

    const handleDeactivate = () => {
        deactivateDevice(_.get(deviceToDeactivate, '_id'));
        setDeviceToDeactivate(null);
    }

    const handleCancelConfirm = () => {
        setDeviceToDeactivate(null);
    }

    const handleActivateDevice = async () => {
        const res = await activateDevice(id, nextServiceDate);
        if (res.success) {
            const closeModalEl = document.getElementById('closeActivateModal');
            closeModalEl.click();
        } else {
            setNextServiceDateErrors(_.get(res, 'errors.nextServiceDate'))
        }
    }

    const handleGenerateService = () => {
        const data = {
            customer: {
                customerId: _.get(customer, '_id'),
                name: _.get(customer, 'name')
            },
            device: {
                deviceId: _.get(device, '_id'),
                name: _.get(device, 'name'),
                components: _.get(device, 'components'),
            },
            conservator: {
                conservatorId: _.get(conservator, 'conservatorId'),
                name: _.get(conservator, 'name')
            }
        }

        localStorage.setItem('deviceData', JSON.stringify(data));

        history.push(`/service`);
    }

    const scrollToElement = (el) => {
        setTimeout(() => {
            document.getElementById(el).scrollIntoView({ behavior: "smooth" })
        }, 200);
    }

    const getDateWithColor = (date) => {
        if (date) {
            date = moment(date);
            let style = {};
            let warningDate = moment().subtract(90, "days").startOf("day");
            if (moment().isAfter(date)) {
                style.color = "#BF1E2E";
            } else if (date.isAfter(warningDate)) {
                style.color = "rgb(252, 203, 0)";
            }
            return <span style={style}>{date.format("DD.MM.YYYY")}</span>;
        }
        return "-";
    }

    const getResursDate = (device) => {
        if (device && device.components && device.components.length > 0) {
            let lowestYear = 3000;
            device.components.forEach(comp => {
                if (comp.installationDate && comp.estimatedDurabilityYears) {
                    const year = comp.installationDate.substr(0, 4);
                    const resurs = parseInt(year) + parseInt(comp.estimatedDurabilityYears);
                    if (resurs < lowestYear) lowestYear = resurs;
                }
            })

            return lowestYear;
        }

        return '-';
    }

    return (
        <React.Fragment>
            {
                deviceToDeactivate && <ConfirmModal
                    id="confirmDelete"
                    label="Czy na pewno chcesz dezaktywować urządzenie? Dezaktywowanie urządzenia spowoduje usunięcie aktualnych, nierozpoczętych zleceń."
                    positionName={_.get(device, 'name')}
                    onConfirm={handleDeactivate}
                    onCancel={handleCancelConfirm}
                />
            }

            <div className="modal fade" id="activateDevice" tabIndex="-1" role="dialog" aria-labelledby="activateDevice"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="activateDevice">Aktywowanie urządzenia</h5>
                            <button id="closeActivateModal" type="button" className="close" data-dismiss="modal" aria-label="Anuluj" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Date
                                id="nextServiceDate"
                                label="Data następnego zlecenia"
                                type="number"
                                onChange={(id, value) => setNextServiceDate(value)}
                                value={nextServiceDate}
                                errors={nextServiceDateErrors}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark" data-dismiss="modal">Anuluj</button>
                            {
                                nextServiceDate && <button type="button" className="btn btn-success" onClick={() => handleActivateDevice()}>Aktywuj</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3 mt-3">
                <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">

                    <h3 className="mt-3 mb-3">Szczegóły urządzenia</h3>
                    <div>
                        {
                            (userType === 'admin') && device.active && <button onClick={() => setDeviceToDeactivate(device)} data-toggle="modal" data-target="#confirmDelete" className="btn btn-danger mr-2">Dezaktywuj</button>
                        }
                        {
                            (userType === 'admin') && !device.active && <button onClick={() => setDeviceToDeactivate(device)} data-toggle="modal" data-target="#activateDevice" className="btn btn-success mr-2">Aktywuj</button>
                        }

                        {
                            AclManager.isAllowedMethod('device/device', 'update') &&
                            <button onClick={() => history.push(`/device/${_id}`)} type="submit" className="btn btn-outline-primary float-md-right">Edytuj <img src={EdytujIcon} /></button>
                        }

                        {
                            AclManager.isAllowedMethod('service/service', 'create') &&
                            <button type="submit" onClick={() => handleGenerateService()} className="btn btn-info float-md-right mr-2">Generuj zlecenie <img src={DodajIcon} /></button>
                        }

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-sm-12 metryka">
                    <h2 id="nazwa-urzadzenia" className="w-100 kolor">{name}</h2>
                    <div className="d-flex justify-content-start">
                        <h4 className="mr-4"><span className="font-weight-light">ID:</span> {_id}</h4>
                    </div>
                    <div className="d-flex justify-content-start">
                        <h4 className="mr-4"><span className="font-weight-light">UDT:</span> {udtNumber}</h4>
                    </div>
                    <div className="d-flex justify-content-start">
                        <h4 className="mr-4"><span className="font-weight-light">Nr fabryczny:</span> {serialNumber}</h4>
                    </div>
                    <hr />
                    <div className="col-md-12">
                        <h3 className="text-right"><span className="font-weight-light float-left">Klient:</span> <Link to={`/customers/${_.get(customer, '_id')}`}>{_.get(customer, 'name')}</Link></h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Producent:</span> {manufacturer}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Administrator:</span> {administrationName} <br />{administrationPhone}</h3>
                        <hr />
                        <h3 className="text-right"><span className="font-weight-light float-left">Firma serwisująca:</span> {_.get(device, 'maintenanceCompany', '-')}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Inspektor UDT:</span> {_.get(device, 'udtInspector', '-')}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Instalator:</span> {_.get(device, 'installer', '-')}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Licznik jazd:</span> {_.get(device, 'numberOfCycles', '-')}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Konserwator:</span> <div className="usercolor-container rounded-circle float-right ml-2"><div className="usercolor rounded-circle" style={{ backgroundColor: _.get(conservator, 'color', 'white') }}></div></div> {_.get(conservator, 'name')}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Data ostatniego przeglądu:</span> {device.lastServiceDate ? moment(device.lastServiceDate).format('DD.MM.YYYY') : "-"}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Data następnego przeglądu:</span> {device.nextServiceDate ? moment(device.nextServiceDate).format('DD.MM.YYYY') : "-"}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Data ważności pomiarów elektrycznych:</span> {getDateWithColor(device.electricalMeasurmentsExpirationDate)}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Data ważności badania udt:</span> {getDateWithColor(device.udtExaminationDate)}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Data montażu:</span> {device.installationDate ? moment(device.installationDate).format('DD.MM.YYYY') : "-"}</h3>
                        <h3 className="text-right"><span className="font-weight-light float-left">Rok osiągnięcia resursu:</span> {getResursDate(device)}</h3>

                    </div>

                    <div className="row">

                    </div>
                    <hr />
                    <h3 className="mt-3 mb-3">Adres</h3>

                    <div className="row">
                        <div className="col-6 col-md-6 col-sm-12 border-top-0 border-bottom-0 border-left-0 border">
                            ul. {_.get(address, 'street')} {_.get(address, 'streetNumber')}<br />{_.get(address, 'postCode')} {_.get(address, 'locality')}</div>
                        <div className="col-6 col-md-6 col-sm-12 align-self-center">
                            {
                                isLoaded && address.coordinates && address.coordinates.length === 2 && <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{ lat: address.coordinates[0], lng: address.coordinates[1] }}
                                    zoom={15}
                                    options={{
                                        styles: mapStyles,
                                        disableAutoPan: true,
                                        disableDefaultUI: true
                                    }}
                                >
                                    <Marker
                                        position={{ lat: address.coordinates[0], lng: address.coordinates[1] }}
                                    />
                                </GoogleMap>
                            }
                        </div>
                        <div className="col-12"><hr /><h3 className="mt-1 mb-3">Wskazówki</h3></div>
                        <div className="col-12">{_.get(device, 'accessTips', '-')}</div>
                        <div className="col-12 col-sm-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-center flex-wrap ">
                    <div className="col-sm-12 col-md-12 p-0">
                        <Carousel images={gallery} />
                    </div>

                    <div className="col-md-12 col-sm-12 mt-3">
                        <div className="d-flex justify-content-between mt-2 mb-0">
                            <Link to={`/serviceLife/${id}`} className="btn btn-light w-50 mr-1"><img src={ResursIcon} /> Generuj RESURS</Link>
                            <a href={`${Api.getImageUrl()}/device/getQrCode/${id}`} target="blank" type="submit" className="btn btn-info w-50 ml-1"><img src={QRCodeIcon} /> Pobierz kod QR</a>
                        </div>
                    </div>

                    <div className="col-12 mt-3">
                        <h3 className="mt-0 mb-3 text-left">Opis urządzenia</h3>
                        <div className="mb-3">{_.get(device, 'description', '-')}</div>
                    </div>
                </div>


            </div>

            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {AclManager.isAllowedMethod('service/service', 'get') && < li className="nav-item" onClick={() => {
                            getDevicesServices(_id);
                            scrollToElement('zlecenia-tab');
                        }}>
                            <a className="nav-link" id="zlecenia-tab" data-toggle="tab" href="#zlecenia" role="tab" aria-controls="zlecenia" aria-selected="false">Zlecenia</a>
                        </li>}
                        <li className="nav-item" onClick={() => scrollToElement('komponenty-tab')}>
                            <a className="nav-link" id="komponenty-tab" data-toggle="tab" href="#komponenty" role="tab" aria-controls="komponenty" aria-selected="false">Komponenty</a>
                        </li>
                        <li className="nav-item" onClick={() => scrollToElement('parametry-tab')}>
                            <a className="nav-link" id="parametry-tab" data-toggle="tab" href="#parametry" role="tab" aria-controls="parametry" aria-selected="false">Parametry</a>
                        </li>
                    </ul>


                    <div className="tab-content pt-3" id="myTabContent">
                        {AclManager.isAllowedMethod('service/service', 'get') && <DeviceDetailsServices
                            services={services}
                        />}

                        <div className="tab-pane fade show" id="parametry" role="tabpanel" aria-labelledby="parametry-tab">
                            <div className="d-flex justify-content-start mb-5 mt-3">
                                <div className="col-md-6 col-sm-12 ">
                                    {(!device.parameters || device.parameters.length === 0) ? "Brak parametrów" : ''}
                                    {device.parameters && device.parameters.map((param, i) => {
                                        return <h3 key={i} className="text-right"><span className="font-weight-light float-left">{param.name}:</span> {param.value}</h3>
                                    })}

                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade show" id="komponenty" role="tabpanel" aria-labelledby="komponenty-tab">

                            <h3 className="mt-3 mb-3">Komponenty</h3>
                            <ul className="list-group mb-5">
                                {(!device.components || device.components.length === 0) ? "Brak komponentów" : ''}
                                {device.components && device.components.map((component, i) => {
                                    let params = _.isEmpty(component.parameters) ? null : component.parameters.map((param, pindex) => {
                                        return (<h4 className="text-right" key={`param${pindex}`}>
                                            <span className="font-weight-light float-left">{param.name}:</span> {param.value}
                                        </h4>);
                                    });
                                    let subcomponents = _.isEmpty(component.subcomponents) ? "-" : _.join(component.subcomponents.map((subcomponent) => subcomponent.name), ", ");
                                    return <li key={i} className="list-group-item pl-0 pr-0" >
                                        <a data-toggle="collapse" className="ml-3 pl-1 font-weight-bold" href={`#collapse${i}`} aria-expanded="false" aria-controls={`collapse${i}`}>{component.name}<img src={MoreIcon} className="ml-2" /></a>
                                        <div className="row ml-0 collapse" id={`collapse${i}`}>

                                            <ul className="col-sm-12 col-md-6 list-group text-right">
                                                <li className="list-group-item border-top-0 border-left-0 border-right-0">
                                                    <h4 className="m-0">
                                                        <span className="font-weight-light float-left">Założona trwałość eksploatacyjna:</span> {component.estimatedDurability || "-"} {durabilityUnitLabels[component.durabilityUnit]}
                                                    </h4>
                                                </li>
                                                <li className="list-group-item border-left-0 border-right-0">
                                                    <h4 className="m-0">
                                                        <span className="font-weight-light float-left">Założony okres trwałości (lata):</span> {component.estimatedDurabilityYears || "-"}
                                                    </h4>
                                                </li>
                                                <li className="list-group-item border-left-0 border-right-0">
                                                    <h4 className="m-0">
                                                        <span className="font-weight-light float-left">Typ:</span> {component.type || "-"}
                                                    </h4>
                                                </li>
                                                <li className="list-group-item border-left-0 border-right-0">
                                                    <h4 className="m-0">
                                                        <span className="font-weight-light float-left">Rodzaj:</span> {component.kind || "-"}
                                                    </h4>
                                                </li>
                                                <li className="list-group-item border-left-0 border-right-0 border-bottom-0">
                                                    {params}
                                                </li>
                                            </ul>

                                            <ul className="col-sm-12 col-md-6 list-group text-right">
                                                <h4 className="text-left" style={{ margin: '16px 8px 8px 16px' }}>
                                                    Części składowe:
                                                </h4>
                                                {
                                                    !_.isEmpty(component.subcomponents) ?
                                                        component.subcomponents.map((sub, i) => {
                                                            return (
                                                                <ul key={i}>
                                                                    <li className="flex flex-column list-group-item border-left-0 border-right-0" >
                                                                        <h4 className="m-0">
                                                                            <span className="font-weight-light float-left">{sub.name ? sub.name : '-'}</span> {"1 szt."}

                                                                        </h4>
                                                                        <h4 className="m-0">
                                                                            <span className="font-weight-light float-left">Typ: {sub.type ? sub.type : '-'}</span> <br />
                                                                        </h4>
                                                                        <h4 className="m-0">
                                                                            <span className="font-weight-light float-left">Rodzaj: {sub.kind ? sub.kind : '-'}</span><br />
                                                                        </h4>
                                                                        <h4 className="m-0">
                                                                            <span className="font-weight-light float-left">Producent: {sub.manufacturer ? sub.manufacturer : '-'}</span><br />
                                                                        </h4>
                                                                    </li>
                                                                    {/* <li  >
                                                                        <h4 className="m-0">
                                                                            <span className="font-weight-light float-left">Typ: {sub.type ? sub.type : '-'}</span>
                                                                        </h4>
                                                                    </li>
                                                                    <li className="list-group-item " >
                                                                        <h4 className="m-0">
                                                                            <span className="font-weight-light float-left">Rodzaj: {sub.kind ? sub.kind : '-'}</span>
                                                                        </h4>
                                                                    </li> */}
                                                                </ul>
                                                            )
                                                        })
                                                        : "-"
                                                }

                                            </ul>
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>


                </div>
            </div>

        </React.Fragment >
    )
}

export default DeviceDetails;