import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { saveTransfer, getWarehouseComponents, getWarehouses , getComponentsToEdit} from '../actions/warehouses.actions';
import TransferModal from '../components/TransferModal.component';

const TransferModalContainer = (props) => {
    return (
        <Fragment>
            <TransferModal
                {...props}
            />
        </Fragment>
    );
}

const mapStateToProps = (store) => ({

});

const mapDispatchToProps = (dispatch) => {
    return {
        saveTransfer: (data) => dispatch(saveTransfer(data)),
        getWarehouseComponents: (id) => dispatch(getWarehouseComponents(id)),
        getWarehouses: () => dispatch(getWarehouses()),
        getComponentsToEdit: (id) => dispatch(getComponentsToEdit(id))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferModalContainer);
