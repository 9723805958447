import Api from '../../application/services/api';
import { isLoading, displayErrors } from '../../application/actions/application.actions';

export const NAMESPACE = 'SERVICES';

// AKCJE SETUJACE
export const SET_SERVICE = `SET_SERVICE_${NAMESPACE}`;
export const SET_SERVICES = `SET_SERVICES_${NAMESPACE}`;
export const SET_QUERY = `SET_QUERY_${NAMESPACE}`;
export const SET_OFFSET = `SET_OFFSET_${NAMESPACE}`;
export const SET_LIMIT = `SET_LIMIT_${NAMESPACE}`;
export const SET_FILTERS = `SET_FILTERS_${NAMESPACE}`;
export const SET_PAGE = `SET_PAGE_${NAMESPACE}`;
export const SET_ERRORS = `SET_ERRORS_${NAMESPACE}`;

// AKCJE CLEARUJACE
export const CLEAR_SERVICE = `CLEAR_SERVICE_${NAMESPACE}`;
export const CLEAR_SERVICES = `CLEAR_SERVICES_${NAMESPACE}`;
export const CLEAR_QUERY = `CLEAR_QUERY_${NAMESPACE}`
export const CLEAR_LIMIT = `CLEAR_LIMIT_${NAMESPACE}`;
export const CLEAR_FILTERS = `CLEAR_FILTERS_${NAMESPACE}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${NAMESPACE}`;

// METODY SETUJACE
export const setService = (service) => ({
   type: SET_SERVICE,
   service,
});

export const setServices = (services, total) => ({
   type: SET_SERVICES,
   services,
   total
});

export const setQuery = (query) => dispatch => {
   dispatch({ type: SET_QUERY, query: query });
   dispatch(clearPage(true));
}

export const setOffset = (offset) => dispatch => {
   dispatch({ type: SET_OFFSET, offset: offset });
}

export const setLimit = (limit) => dispatch => {
   dispatch({ type: SET_LIMIT, limit: parseInt(limit) });
   dispatch(setPage(1));
}

export const setFilters = (filters) => (dispatch) => {
   dispatch({ type: SET_FILTERS, filters });
   dispatch(clearPage());
}

export const setPage = (page) => (dispatch, getState) => {
   const { services: { params: { limit } } } = getState();

   dispatch(setOffset(limit * ((page || 1) - 1)));
   dispatch({
      type: SET_PAGE,
      page: page || 1
   });

   dispatch(getServices());
   window.scrollTo(0, 0);
}

export const setErrors = (errors) => (dispatch) => {
   dispatch({ type: SET_ERRORS, errors })
}

// METODY CLEARUJACE
export const clearService = () => dispatch => {
   dispatch({
      type: CLEAR_SERVICE
   });
}

export const clearServices = () => dispatch => {
   dispatch({
      type: CLEAR_SERVICES
   });
}

export const clearQuery = () => dispatch => {
   dispatch({ type: CLEAR_QUERY });
}
export const clearLimit = () => dispatch => {
   dispatch({ type: CLEAR_LIMIT });
}
export const clearFilters = () => dispatch => {
   dispatch({ type: CLEAR_FILTERS });
}
export const clearPage = (replaceUrl = false) => dispatch => {
   // resetujemy tutaj offset i page jednocześnie
   dispatch(setOffset(0));
   dispatch(setPage(1));

   if (replaceUrl) {
      window.history.replaceState(null, '', '/services');
   }
   window.scrollTo(0, 0);
}

export const clearErrors = () => dispatch => {
   dispatch({ type: CLEAR_ERRORS });
}

// GETY
export const getServices = () => async (dispatch, getState) => {

   dispatch(isLoading(true));

   const { services: { params, filters, query, queryFields } } = getState();
   const tmpParams = Object.assign({}, params, filters);

   // FILTRY PO QUERY I QUERYFIELDS
   if (query != "") {
      queryFields.map((field) => {
         tmpParams[field] = query;
      });
   }

   const result = await Api.get(`service/service`, tmpParams);

   if (result && result.success) {
      dispatch(setServices(result.documents, result.total));
   }

   dispatch(isLoading(false));
}

export const getService = (serviceId, showLoader = true) => async (dispatch) => {
   if (showLoader) dispatch(isLoading(true));

   const result = await Api.get(`service/service/${serviceId}`);

   if (result && result.success) {
      dispatch(setService(result.document));
   }

   if (showLoader) dispatch(isLoading(false));
}

// CREATE
export const createService = async (data, dispatch) => {
   dispatch(isLoading(true));
   let result = await Api.post("service/service", data);
   dispatch(isLoading(false));
   return result;
}

export const editService = async (serviceId, data, dispatch) => {
   dispatch(isLoading(true));
   let result = await Api.put(`service/service`, data, serviceId);

   if (data.startDate) {
      dispatch(getService(serviceId));
      dispatch(isLoading(false));
   } else {
      dispatch(isLoading(false));
      return result;
   }
}

export const saveService = async (serviceId, data, dispatch) => {
   if (serviceId) {
      return editService(serviceId, data, dispatch);
   } else {
      return createService(data, dispatch);
   }
}

// REMOVE
export const deleteService = (serviceId) => async (dispatch) => {
   dispatch(isLoading(true));
   const result = await Api.delete(`service/service/${serviceId}`);

   dispatch(getServices());
   dispatch(isLoading(false));

   return result;
}

// INNE
export const startService = (serviceId, data) => async (dispatch) => {
   dispatch(isLoading(true));
   const result = await Api.put('service/updateCompletion', data, serviceId);

   dispatch(getService(serviceId));
   dispatch(isLoading(false));
}

export const updateServiceComponents = (serviceId, data) => async (dispatch) => {
   dispatch(clearErrors());

   const result = await Api.put('service/updateCompletion', { components: data }, serviceId);

   if (result && result.errors) {
      dispatch(setErrors(result.errors));
   } else {
      dispatch(getService(serviceId, false));
   }
}

export const updateServiceVerified = (serviceId, data) => async (dispatch) => {
   dispatch(clearErrors());

   const result = await Api.put('service/updateCompletion', data, serviceId);

   if (result && result.errors) {
      dispatch(setErrors(result.errors));
   } else {
      dispatch(getService(serviceId, false));
   }
}

export const updateServiceComponentPhoto = (serviceId, data) => async (dispatch) => {
   dispatch(clearErrors());

   const result = await Api.put('service/setCompletionPhoto', data, serviceId, true);

   dispatch(getService(serviceId, false));
}

export const completeService = (serviceId, data) => async (dispatch) => {
   dispatch(isLoading(true));
   dispatch(clearErrors());

   const result = await Api.put('service/completeService', data, serviceId);

   if (result && result.errors) {
      dispatch(setErrors(result.errors));
   } else {
      dispatch(getService(serviceId));
   }

   dispatch(isLoading(false))
}
