import React from 'react';

const Time = ({ id, label, onChange, value, errors, isRequired }) => {

    const renderErrors = () => {
        if (!errors) return;

        if (Array.isArray(errors)) {
            return errors.map((e, i) => {
                return <div key={i} className="errormsg">{e}</div>
            })
        }

        if (typeof errors === 'string') {
            return <div className="errormsg">{errors}</div>
        }
    }

    return (
        <div className={`form-group d-flex flex-wrap w-100 flex-column justify-content-end ${errors ? 'error' : null}`}>
            <label >{label} {isRequired && <span className="is-required">*</span>}</label>
            <input
                type="time"
                className="form-control"
                value={value}
                onChange={e => onChange(id, e.target.value)}
            />
            {renderErrors()}
        </div>
    )
}

export default Time;