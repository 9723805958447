import React from 'react';

import { Formik } from 'formik';
import { profileInitialState, profileValidationSchema } from '../schemas/profile.schema';

import TextInput from '../../assets/components/TextInput.component';

const ProfileComponent = ({ changePassword }) => {

   const renderUserForm = () => {
      return <Formik
         initialValues={profileInitialState}
         validationSchema={profileValidationSchema}
         enableReinitialize={true}
         validateOnChange={false}
         validateOnBlur={false}
         onSubmit={(values, { setErrors, resetForm }) => {
            changePassword(values).then(res => {
               if (res.success) {
                  resetForm()
               } else {
                  if (res.errors) {
                     setErrors(res.errors);
                  }
               }
            });
         }}
      >
         {formik => (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
               <div className="row">
                  <div className="col-12 col-md-6 col-lg-4">
                     <TextInput
                        isRequired={true}
                        id="oldPassword"
                        label="Stare hasło"
                        type="password"
                        value={formik.values.oldPassword}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.oldPassword} />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                     <TextInput
                        isRequired={true}
                        id="newPassword"
                        label="Nowe hasło"
                        type="password"
                        value={formik.values.newPassword}
                        onChange={formik.setFieldValue}
                        errors={formik.errors.newPassword} />
                  </div>

               </div>
               <button type="submit" className="btn btn-primary mb-3">Zmień hasło </button>
            </form>
         )}
      </Formik >
   }

   return (
      <div className="tab-content pt-3">
         <div className="tab-pane fade show active" id="users" role="tabpanel" aria-labelledby="users-tab">
            <h3 className="mt-3 mb-3">Profil</h3>
            {renderUserForm()}
         </div>
      </div>
   )
}

export default ProfileComponent;