import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import AclManager from '../../application/services/aclManager';

import PermissionsModuleList from '../../scripts/permissionsModuleList'
import permissionsList from '../../scripts/permissionsList';

import EditIcon from '../../assets/gfx/edytuj.svg'
import TickIcon from '../../assets/gfx/tick.svg'
import WykrzykIcon from '../../assets/gfx/wykrzyk.svg'

const SERVICE_TYPES = {
   'udt_examination': 'Badanie UDT',
   'fault': 'Awaria',
   'conservation': 'Konserwacja',
   'measurement': 'Pomiary',
   'other': 'Inne'
};

const USER_TYPES = {
   'customer': 'Klient',
   'employee': 'Pracownik',
   'admin': 'Administrator',
};

const UserDetails = ({ user, lastUserActivities, getUserDevices, userDevices, getUserPermissions, userPermissions, getLastUserActivities }) => {

   const getStatus = (service) => {
      let { dateTo, startDate, completionDate } = service;

      if (completionDate) return 'success';
      if (!completionDate && !startDate && moment(dateTo).isBefore(moment())) return 'warning';
      if (startDate && !completionDate) return 'primary';
      return ''
   }

   const getStatusByColor = (service) => {
      const colorStatus = getStatus(service);

      switch (colorStatus) {
         case 'success':
            return <div className="service-status status-complete" >
               <img src={TickIcon} className="m--1" />
            </div >;
         case 'warning':
            return <div className="service-status status-warning">
               <img src={WykrzykIcon} className="m--1" />
            </div>;
         case 'primary':
            return <div className="service-status status-in-progress">
               <div className="spinner-border text-primary ml-1 mr-4 mt-1" role="status"></div>
            </div>;
         default:
            return <div className="service-status status-new"></div>;
      }
   }

   const renderCertificates = () => {
      if (!user || !user.qualificationCertificates) return;

      return user.qualificationCertificates.map((cert, i) => {
         return (
            <div key={i}>
               <span>{cert.certificateNumber}</span> - <span>{cert.description}</span>
            </div>
         )
      })
   }

   const renderUserDetails = () => {
      return <div className="row">
         <div className="col-md-7 col-sm-12 d-flex justify-content-center flex-wrap align-items-center">
            <div className="usercolor-container rounded-circle float-left mr-3 mt-2">
               <div className="usercolor rounded-circle" style={{ backgroundColor: _.get(user, 'color', 'white') }}></div>
            </div>
            <h2 id="nazwa-urzadzenia" className="mt-3 mb-1 text-center"> {_.get(user, 'name', 'brak')} </h2>
            <div className="w-100">
               <h4 className="w-100 text-center">
                  <span className="font-weight-light">Świadectwa kwalifikacji:</span>
                  {renderCertificates()}
               </h4>
            </div>
         </div>
         <div className="col-md-5 col-sm-12">
            <h3 className="text-right"><span className="font-weight-light float-left">Typ:</span> {USER_TYPES[_.get(user, 'type')] || '-'} </h3>
            <h3 className="text-right"><span className="font-weight-light float-left">Rola:</span> {_.get(user, 'role.name', 'brak')}</h3>
            <h3 className="text-right"><span className="font-weight-light float-left">Adres e-mail:</span> {_.get(user, 'email', 'brak')}</h3>
            <h3 className="text-right"><span className="font-weight-light float-left">Telefon:</span> {_.get(user, 'phone', 'brak')}</h3>
            <h3 className="text-right"><span className="font-weight-light float-left">Badania lekarskie:</span> {_.get(user, 'medicalExaminations', 'brak')}</h3>
         </div>
      </div>
   }

   const getActivityDuration = (lastUserActivities) => {
      let { startDate, completionDate } = lastUserActivities;
      let duration;

      if (!completionDate) {
         return '-';
      }

      if (completionDate) {
         duration = moment(completionDate).diff(startDate);
      } else {
         duration = moment().diff(startDate);
      }

      return `${moment.utc(duration).format("H:mm").split(':')[0]}h ${moment.utc(duration).format("H:mm").split(':')[1]}m`;
   }

   const renderTodaysActivityTab = () => {
      if (!_.isEmpty(lastUserActivities)) {
         return lastUserActivities.length > 0 && lastUserActivities.map((lastUserActivity, index) => {
            return <tr key={index} className={`table-${getStatus(lastUserActivity)}`}>
               <td>{`${index + 1}.`}</td>
               <td>
                  <Link to={`/services/${lastUserActivity._id}`}>
                     {_.get(lastUserActivity, 'number', '-')}
                  </Link>
               </td>
               <td>
                  <Link to={`/services/${lastUserActivity._id}`}>
                     {SERVICE_TYPES[lastUserActivity.type] || '-'}
                  </Link>
               </td>
               <td>{moment(lastUserActivity.dateFrom).format("DD.MM.YYYY HH:mm")}</td>
               <td>{moment(lastUserActivity.dateTo).format("DD.MM.YYYY HH:mm")}</td>
               <td>{getActivityDuration(lastUserActivity)}</td>
               <td> <Link to={`/users/${lastUserActivity.conservator.conservatorId}`}> {_.get(lastUserActivity, 'conservator.name', '-')} </Link></td>
               <td>
                  {getStatusByColor(lastUserActivity)}
               </td>
            </tr>
         })
      } else {
         return <tr><td className="text-center" colSpan="8">Brak elementów</td></tr>
      }
   }

   const renderDevicesTab = () => {
      if (!_.isEmpty(userDevices)) {
         return userDevices.length > 0 && userDevices.map((device, index) => {
            return <tr key={`user-device-${index}`}>
               <td>{_.get(device, 'name', 'brak')}</td>
               <td>{_.get(device, 'address.formattedAddress', 'brak')}</td>
               <td>{_.get(device, 'electricalMeasurmentsExpirationDate') && moment(_.get(device, 'electricalMeasurmentsExpirationDate', 'brak')).format("YYYY-MM-DD")}</td>
            </tr>
         })
      } else {
         return <tr><td className="text-center" colSpan="6">Brak elementów</td></tr>
      }
   }

   const renderUserPermissionsColumnNames = () => {
      return <tr>
         <th>Nazwa</th>

         {PermissionsModuleList.map((permission, index) => {
            let i = _.findIndex(userPermissions.permissions, p => p.controller == permission.controller);

            if (i > -1 && userPermissions.permissions[i]) {
               return <th key={`user-permission-name-${index}`}>{permission.name}</th>
            }
         })}
      </tr>
   }

   const renderPermissionsTab = () => {
      if (!_.isEmpty(permissionsList)) {
         return <tr>
            <td>{_.get(userPermissions, 'name', 'brak')}</td>

            {permissionsList.permissions.map((permission, permIndex) => {
               let i = _.findIndex(userPermissions.permissions, p => p.controller == permission.controller);

               if (i > -1) {
                  let currentUserPermission = userPermissions.permissions[i];

                  return <td className="crud-controls" key={`user-permission-${permIndex}`}>
                     <div className="custom-control custom-checkbox">
                        {permission.actions.map((action, index) => {
                           let actionIndex = _.findIndex(currentUserPermission.actions, act => act == action.action);

                           if (actionIndex > -1) {
                              return <div key={`${action.action}-${index}`}>
                                 <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    readOnly={true}
                                    checked={true} />
                                 <label className="custom-control-label" >{action.name}</label><br />
                              </div>
                           }
                        })}
                     </div>
                  </td>
               }
            })}

         </tr>
      }
   }

   // w pobieranych permissionach usera nie mamy nazwy modułu i nazwy akcji,
   // więc musimy parsować te permissiony z plikami zawierającymi te dane (permissionsList i modulePermissionsList)
   const renderUserPermissions = () => {
      if (!_.isEmpty(userPermissions.permissions)) {
         return <table className="table table-bordered rounded-10">
            <thead>
               {/* parsowanie nazwy modułów i wyświetlanie ich*/}
               {renderUserPermissionsColumnNames()}
            </thead>
            <tbody>
               {/* parsowanie akcji i wyświetlanie ich */}
               {renderPermissionsTab()}
            </tbody>
         </table>
      } else {
         return <div>Brak elementów</div>
      }
   }

   const scrollToElement = (el) => {
      setTimeout(() => {
         document.getElementById(el).scrollIntoView({ behavior: "smooth" })
      }, 200);
   }

   return (
      user && <div>

         {/* INFO O USERZE */}
         <div className="row mb-3 mt-2">
            <div className="col-md-12 col-sm-12 d-flex justify-content-between flex-wrap align-items-center">
               <h3 className="w-50 mt-3 mb-3">Szczegóły użytkownika</h3>
               <Link to={`/user/${user._id}`}>
                  <button type="submit" className="btn btn-outline-primary float-md-right">
                     Edytuj <img src={EditIcon} />
                  </button>
               </Link>
            </div>
         </div>
         <div className="card mb-3">
            <div className="card-body">
               {renderUserDetails()}
            </div>
         </div>

         <div className="row">
            <div className="col-12 col-md-12 col-sm-12">

               {/* TABSY */}
               <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {AclManager.isAllowedMethod('service/service', 'get') && <li className="nav-item" onClick={() => {
                     getLastUserActivities(_.get(user, '_id'))
                     scrollToElement('aktywnosc-tab')
                  }}>
                     <span className="nav-link" id="aktywnosc-tab" data-toggle="tab" href="#aktywnosc" role="tab" aria-controls="aktywnosc" aria-selected="true">
                        Dzisiejsza aktywność
                     </span>
                  </li>}
                  {AclManager.isAllowedMethod('device/device', 'get') && <li className="nav-item" onClick={() => {
                     getUserDevices(user._id)
                     scrollToElement('urzadzenia-tab')
                  }}>
                     <span className="nav-link" id="urzadzenia-tab" data-toggle="tab" href="#urzadzenia" role="tab" aria-controls="urzadzenia" aria-selected="false">
                        Przypisane urządzenia
                     </span>
                  </li>}
                  {AclManager.getIsAdmin() && _.get(user, 'role._id') && <li className="nav-item" onClick={() => {
                     getUserPermissions(_.get(user, 'role._id'))
                     scrollToElement('uprawnienia-tab')
                  }}>
                     <span className="nav-link" id="uprawnienia-tab" data-toggle="tab" href="#uprawnienia" role="tab" aria-controls="uprawnienia" aria-selected="false">
                        Uprawnienia
                     </span>
                  </li>}
               </ul>

               <div className="tab-content pt-3" id="myTabContent">

                  {/* DZISIEJSZA AKTYWNOSC */}
                  <div className="tab-pane fade" id="aktywnosc" role="tabpanel" aria-labelledby="aktywnosc-tab">
                     <div className="table-responsive ramkatabeli tabela-left mb-3">
                        <table className="table table-hover rounded-10 tabela-zlecenia">
                           <thead>
                              <tr>
                                 <th>Lp.</th>
                                 <th>Nr zlecenia</th>
                                 <th>Typ zlecenia</th>
                                 <th>Data rozpoczęcia</th>
                                 <th>Data zakończenia</th>
                                 <th>Czas realizacji</th>
                                 <th>Konserwator</th>
                                 <th>Stan</th>
                              </tr>
                           </thead>
                           <tbody>
                              {renderTodaysActivityTab()}
                           </tbody>
                        </table>
                     </div>
                  </div>

                  {/* PRZYPISANE URZADZENIA */}
                  <div className="tab-pane fade" id="urzadzenia" role="tabpanel" aria-labelledby="urzadzenia-tab">
                     <div className="table-responsive ramkatabeli mb-2">
                        <table className="table table-hover rounded-10 tabela-left">
                           <thead>
                              <tr>
                                 <th>Nazwa</th>
                                 <th>Adres</th>
                                 <th>Ważność przeglądu</th>
                              </tr>
                           </thead>
                           <tbody>
                              {renderDevicesTab()}
                           </tbody>
                        </table>
                     </div>
                  </div>

                  {/* UPRAWNIENIA */}
                  <div className="tab-pane fade" id="uprawnienia" role="tabpanel" aria-labelledby="uprawnienia-tab">
                     <div className="table-responsive tabela-left ramkatabeli mb-3">
                        {renderUserPermissions()}
                     </div>
                  </div>

               </div>
            </div>
         </div>

      </div>
   )
}

export default UserDetails;