import { Formik } from 'formik';
import _ from "lodash";
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import Select from "../../assets/components/Select.component";
import TextInput from "../../assets/components/TextInput.component";
import Autocomplete from "../../assets/components/Autocomplete.component";
import dodajIcon from "../../assets/gfx/dodaj.svg";
import { warehouseInitialState, warehouseValidationSchema } from "../schemas/warehouse.schema";

const Warehouse = ({ warehouse, saveWarehouse }) => {

    const { id } = useParams();
    const warehouseTypesOptions = [{ name: "Magazyn główny", value: "main" }, { name: "Magazyn konserwatora", value: "secondary" }];
    const [initialValues, setInitialValues] = useState(warehouseInitialState);
    const history = useHistory();

    useEffect(() => {
        _.isObject(warehouse) && setInitialValues(warehouse);
    }, [warehouse]);

    return (<div className="container-fluid dodawanie">
        <h3 className="mt-3 mb-3">{id ? "Edytuj" : "Dodaj nowy"} magazyn</h3>
        <Formik
            initialValues={initialValues}
            validationSchema={warehouseValidationSchema}
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { setErrors }) => {
                let res = await saveWarehouse(values)
                if (res.success) {
                    history.push(`/warehouses/${res.document}`);
                } else {
                    if (res.errors) {
                        setErrors(res.errors);
                    }
                }
            }}
        >
            {formik => (<form autoComplete="off" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <TextInput
                            isRequired={true}
                            id="name"
                            label="Nazwa"
                            type="text"
                            value={formik.values.name}
                            onChange={formik.setFieldValue}
                            errors={formik.errors.name}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <Select
                            id="type"
                            label="Typ magazynu"
                            options={warehouseTypesOptions}
                            onChange={formik.setFieldValue}
                            value={formik.values.type}
                            errors={formik.errors.type}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <Autocomplete
                            isRequired={true}
                            id="owner"
                            url="admin/user"
                            multi={false}
                            label="Przypisz właściciela"
                            optionLabelField="name"
                            optionIdField="_id"
                            prependText={null}
                            appendText={null}
                            onOptionSelect={formik.setFieldValue}
                            value={formik.values.owner}
                            errors={formik.errors.owner}
                            queryFields={["name"]}
                            additionalParams={{ limit: 20, select: "name" }}
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary mb-3 mt-3">Zapisz<img src={dodajIcon} /></button>
            </form>)}
        </Formik>
    </div>)
}

export default Warehouse;