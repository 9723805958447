import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import {
    getWarehouses,
    clearWarehouses,
    setPage,
    setLimit,
    setQuery,
    setFilters,
    clearPage,
    clearQuery,
    clearLimit,
    clearFilters,
    deleteWarehouse
} from '../actions/warehouses.actions';
import Warehouses from '../components/Warehouses.component';

const WarehousesContainer = (props) => {

    let { page } = useParams();

    useEffect(() => {
        props.setPage(page || 1);
        props.getWarehouses();
        return () => {
            props.clearWarehouses();
            props.clearPage();
            props.clearQuery();
            props.clearLimit();
            props.clearFilters();
        }
    }, [])

    return (
        <Warehouses
            {...props}
        />
    );
}

const mapStateToProps = (store) => ({
    warehouses: store.warehouses.warehouses,
    total: store.warehouses.total,
    page: store.warehouses.page,
    limit: store.warehouses.params.limit
});

const mapDispatchToProps = (dispatch) => {
    return {
        getWarehouses: () => dispatch(getWarehouses()),
        clearWarehouses: () => dispatch(clearWarehouses()),
        deleteWarehouse: (id) => dispatch(deleteWarehouse(id)),
        setPage: (page, reload) => dispatch(setPage(page, reload)),
        setLimit: (limit) => dispatch(setLimit(limit)),
        setQuery: (query) => dispatch(setQuery(query)),
        setFilters: (filtersData) => dispatch(setFilters(filtersData)),
        clearPage: () => dispatch(clearPage()),
        clearQuery: () => dispatch(clearQuery()),
        clearLimit: () => dispatch(clearLimit()),
        clearFilters: () => dispatch(clearFilters())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehousesContainer);
