import {
    SET_CUSTOMER,
    SET_CUSTOMERS,
    SET_QUERY,
    SET_OFFSET,
    SET_LIMIT,
    SET_FILTERS,
    SET_PAGE,
    SET_CUSTOMER_DEVICES,
    SET_CUSTOMER_SERVICES,
    SET_CUSTOMER_CONTRACTS,
    SET_CUSTOMER_EXPENSES,
    SET_ACTIVE_TAB,
    CLEAR_CUSTOMER,
    CLEAR_CUSTOMERS,
    CLEAR_QUERY,
    CLEAR_LIMIT,
    CLEAR_FILTERS,
    CLEAR_CUSTOMER_DEVICES,
    CLEAR_CUSTOMER_SERVICES,
    CLEAR_CUSTOMER_CONTRACTS,
    CLEAR_CUSTOMER_EXPENSES,
    CLEAR_ACTIVE_TAB

} from '../actions/customers.actions';

const defaultState = {
    customer: null,
    customers: [],
    total: 0,
    page: 1,
    query: '',
    queryFields: [
        'name[or]',
        'NIP[or]',
        'address.formattedAddres[or]',
        'contacts.description[or]',
        'contacts.phone[or]',
        'contacts.email[or]'
    ],
    params: {
        limit: 15,
        offset: 0,
    },
    activeTab: '',
    filters: {
        'sort[name]': 1,
    },
    customerDevices: [],
    customerServices: [],
    customerContracts: [],
    customerExpenses: []
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_CUSTOMER:
            return {
                ...state,
                customer: action.customer
            }
        case CLEAR_CUSTOMER:
            return {
                ...state,
                customer: null
            }
        case SET_CUSTOMERS:
            return {
                ...state,
                customers: action.customers,
                total: action.total
            }
        case CLEAR_CUSTOMERS:
            return {
                ...state,
                customers: []
            }
        case SET_PAGE: // TU NIE MA CLEAR, robi to clearPage z akcji
            return {
                ...state,
                page: action.page
            }
        case SET_OFFSET: // TU NIE MA CLEAR, robi to clearPage z akcji
            return {
                ...state,
                params: {
                    ...state.params,
                    offset: action.offset
                }
            }
        case SET_LIMIT:
            return {
                ...state,
                params: {
                    ...state.params,
                    limit: action.limit
                }
            }
        case CLEAR_LIMIT:
            return {
                ...state,
                params: {
                    ...defaultState.params,
                    limit: defaultState.params.limit,
                }
            }
        case SET_QUERY:
            return {
                ...state,
                query: action.query
            }
        case CLEAR_QUERY:
            return {
                ...state,
                query: ''
            }
        case SET_FILTERS:
            return {
                ...state,
                filters: action.filters
            }
        case CLEAR_FILTERS:
            return {
                ...state,
                filters: {}
            }
        case SET_CUSTOMER_DEVICES:
            return {
                ...state,
                customerDevices: action.customerDevices,
                total: action.total
            }
        case CLEAR_CUSTOMER_DEVICES:
            return {
                ...state,
                customerDevices: []
            }
        case SET_CUSTOMER_SERVICES:
            return {
                ...state,
                customerServices: action.customerServices,
                total: action.total
            }
        case CLEAR_CUSTOMER_SERVICES:
            return {
                ...state,
                customerServices: []
            }
        case SET_CUSTOMER_CONTRACTS:
            return {
                ...state,
                customerContracts: action.customerContracts,
                total: action.total
            }
        case CLEAR_CUSTOMER_CONTRACTS:
            return {
                ...state,
                customerContracts: []
            }
        case SET_CUSTOMER_EXPENSES:
            return {
                ...state,
                customerExpenses: action.customerExpenses,
                total: action.total
            }
        case CLEAR_CUSTOMER_EXPENSES:
            return {
                ...state,
                customerExpenses: []
            }
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.activeTabName
            }
        case CLEAR_ACTIVE_TAB:
            return {
                ...state,
                activeTab: defaultState.activeTab
            }
        default:
            return state;

    }
}