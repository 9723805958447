import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Autocomplete from '../../assets/components/Autocomplete.component';
import TextInput from '../../assets/components/TextInput.component';

import TrashIcon from '../../assets/gfx/kosz.svg';

const UsedModalComponent = ({ usedComponents, onSetUsedComponents, cmpIndex }) => {

   const [newUsedComponents, setNewUsedComponents] = useState({});

   useEffect(() => {
      setNewUsedComponents(usedComponents);
   }, [usedComponents]);

   const handleOnComponentSelect = (component) => {
      let tmpUsedComponents = _.cloneDeep(newUsedComponents);

      if (!_.isEmpty(tmpUsedComponents)) {
         let index = _.findIndex(tmpUsedComponents, cmp => cmp.componentId == component.componentId);

         if (index == -1) {
            setNewUsedComponents(_.concat([{ componentId: component.componentId, name: component.name, amount: 0 }], newUsedComponents));
         }
      } else {
         setNewUsedComponents(_.concat([{ componentId: component.componentId, name: component.name, amount: 0 }], newUsedComponents));
      }
   }

   const handleOnChangeComponentAmount = (index, amount) => {
      let tmpUsedComponents = _.cloneDeep(newUsedComponents);

      if (tmpUsedComponents[index]) {
         tmpUsedComponents[index].amount = amount;
         setNewUsedComponents(tmpUsedComponents);
      }
   }

   const handleDeleteUsedComponent = (index) => {
      onSetUsedComponents(_.without(newUsedComponents, _.nth(newUsedComponents, index)))
   }

   return (
      <div className="modal" id={`modalPodzespoly${cmpIndex}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div className="modal-content">
               <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenteredLabel">Wybierz zużyte części</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div className="modal-body">
                  <Autocomplete
                     id="components"
                     hideChips={true}
                     url="warehouse/items"
                     multi={false}
                     label="Wyszukaj komponent"
                     optionLabelField="name"
                     optionIdField="_id"
                     prependText={null}
                     appendText={null}
                     onOptionSelect={(id, cmp) => {
                        if (_.get(cmp, 'componentId')) {
                           handleOnComponentSelect(cmp);
                        }
                     }}
                     queryFields={["name"]}
                     additionalParams={{ limit: 20 }}
                     parseOption={(value) => {
                        value.componentId = value.component;
                        return value;
                     }}
                  />
                  <table id="zlecenieszczegol" className="table table-hover rounded-10 tabela-left">
                     <thead>
                        <tr>
                           <th className="w-50">Komponent</th>
                           <th>Ilość</th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {!_.isEmpty(newUsedComponents) ? newUsedComponents.map((usedComponent, usedCmpIndex) => {
                           return <tr key={`activity-${usedCmpIndex}`}>
                              <td>{_.get(usedComponent, 'name')}</td>
                              <td>
                                 <TextInput
                                    id="amount"
                                    type="number"
                                    label="Podaj ilość"
                                    value={newUsedComponents[usedCmpIndex] && newUsedComponents[usedCmpIndex].amount}
                                    onChange={(id, amount) => handleOnChangeComponentAmount(usedCmpIndex, amount)} />
                              </td>
                              <td>
                                 <span onClick={() => handleDeleteUsedComponent(usedCmpIndex)}>
                                    <img className="akcja usun" src={TrashIcon} />
                                 </span>
                              </td>
                           </tr>
                        }) : <tr><td className="text-center" colSpan="3">Brak wybranych podkomponentów </td></tr>}
                     </tbody>
                  </table>
               </div>
               <div className="row d-flex w-100 justify-content-center m-0">
                  <div className="modal-footer">
                     <button type="button" className="btn btn-success" onClick={() => onSetUsedComponents(newUsedComponents)}>Zatwierdź</button>
                     <button type="button" className="btn btn-secondary" data-dismiss="modal">Zamknij</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default UsedModalComponent;