import React, { Fragment, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import mmOutIcon from "../../assets/gfx/mm-out.svg";
import mmInIcon from "../../assets/gfx/mm-in.svg";
import konserwacjaIcon from "../../assets/gfx/konserwacja.svg";
import moment from "moment";
import _ from "lodash";

const WarehouseHistoryListCard = ({ history, index }) => {
    const { id } = useParams();
    let [collapsed, setCollapsed] = useState(true);

    const getToLabel = () => {
        if (history.transferType === "service") {
            return <Link to={`/services/${_.get(history, "service.serviceId", "")}`}>{_.get(history, "service.number", "-")}</Link>
        } else {
            return <Link to={`/warehouses/${_.get(history, "warehouseTo.warehouseId", "")}`}>{_.get(history, "warehouseTo.name", "-")}</Link>
        }
    }

    const getCardIcon = () => {
        if (history.transferType === "service") {
            return <img src={konserwacjaIcon} />;
        } else if (history.transferType === "change") {
            return <img src={mmInIcon} />;
        } else {
            if (_.get(history, "warehouseFrom.warehouseId") === id) {
                return <img src={mmOutIcon} />;
            } else {
                return <img src={mmInIcon} />;
            }
        }
    }

    const renderComponents = () => {
        if (!_.isEmpty(history.components)) {
            let showMore = _.size(history.components) > 6;
            let posColumn = [];
            let amountColumn = [];
            let posCollapse = [];
            let amountCollapse = [];
            _.each(history.components, (component, index) => {
                if (index < 6) {
                    posColumn.push(<li key={`history${history._id}pos${index}`}><Link to={`/components/${component.componentId}`}>{component.name}</Link></li>);
                    amountColumn.push(<li key={`history${history._id}amounts${index}`}>{component.amount} szt.</li>);
                } else {
                    posCollapse.push(<li key={`history${history._id}pos${index}`}><Link to={`/components/${component.componentId}`}>{component.name}</Link></li>);
                    amountCollapse.push(<li key={`history${history._id}amounts${index}`}>{component.amount} szt.</li>);
                }
            });

            if (showMore) {
                posColumn.push(<div className={`collapse ${collapsed ? '' : 'show'}`} id={`${history._id}pos`} key={`${history._id}pos`}>
                    <ul>{posCollapse}</ul>
                </div>);
                posColumn.push(<li key={`${history._id}btn`}><button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#${history._id}`} aria-expanded="false" aria-controls="collapseExample" onClick={() => setCollapsed(!collapsed)}>...zobacz jeszcze {_.size(history.components) - 6} więcej.</button></li>);
                amountColumn.push(<div className={`collapse ${collapsed ? '' : 'show'}`} id={`${history._id}amount`} key={`${history._id}amount`}>
                    <ul>{amountCollapse}</ul>
                </div>);
            }
            return <Fragment>
                <td className="vtop"><ul>{posColumn}</ul></td>
                <td className="vtop"><ul>{amountColumn}</ul></td>
            </Fragment>
        } else {
            return <Fragment>
                <td className="vtop">brak</td>
                <td className="vtop">brak</td>
            </Fragment>
        }
    }

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{getCardIcon()}</td>
            <td><Link to={`/warehouses/${_.get(history, "warehouseFrom.warehouseId", "")}`}>{_.get(history, "warehouseFrom.name", "-")}</Link></td>
            <td>{getToLabel()}</td>
            {renderComponents()}

            <td>{moment(history.createdAt).format("DD.MM.YYYY")}</td>
            <td><Link to={`/users/${_.get(history, "creator._id", "")}`}>{_.get(history, "creator.name", "-")}</Link></td>
        </tr>
    )
}

export default WarehouseHistoryListCard;