import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

const DebouncedTextarea = ({
   id,
   label,
   comment,
   errors,
   rows,
   cols,
   placeholder,
   disabled,
   setQuery,
   components
}) => {
   const [debounced, setDebounced] = useState(() => _.debounce((value, components) => setQuery(value, components), 1000));
   const [value, setValue] = useState('')

   useEffect(() => {
      setValue(comment);
   }, [comment])

   const handleOnChange = (inputValue) => {
      setValue(inputValue);
      debounced(inputValue, components);
   };

   const renderErrors = () => {
      if (!errors) return;

      if (Array.isArray(errors)) {
         return errors.map((e, i) => {
            return <div key={i} className="errormsg">{e}</div>
         })
      }

      if (typeof errors === 'string') {
         return <div className="errormsg">{errors}</div>
      }
   }

   return (
      <div className={`form-group d-flex flex-wrap w-100 flex-column justify-content-end ${errors ? 'error' : null}`}>
         <label>{label}</label>
         <textarea
            id={id}
            className="form-control"
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleOnChange(e.target.value)}
            disabled={disabled || false}
         />
         {renderErrors()}
      </div>
   )
}

DebouncedTextarea.propTypes = {
   setQuery: PropTypes.func.isRequired
}

export default DebouncedTextarea;